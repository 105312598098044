<footer class="footer" [ngClass]="{'img-disp':globals.hideHeaderFooter}">
	<div class="container container-fluid-md">
		<div class="row">
			<div class="col-12">
				<img src="./assets/img/QUDOCONNECT_BLACK.svg" alt="" class="footer_logo">
			</div>
			<div class="col-12 col-lg-5">
				<div class="row">
					<div class="col-6 col-lg-7">
						<h4 class="footer_title">Reach us</h4>
						<div class="footer_text">
							<p>New York City, New York</p>
							<p>support@Qudos.us</p>
						</div>
					</div>
					<div class="col-6 col-lg-5">
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-4">
				<div class="row">
					<div class="col-6">
						<h4 class="footer_title">About</h4>
						<div class="footer_list">
							<a href="https://ridequdos.com/#experience">Company</a>
							<a routerLink="/terms">Terms & Conditions</a>
							<a routerLink="/privacy">Privacy Policy</a>
							<a routerLink="/cookies">Cookies Policy</a>
						</div>
					</div>
					<div class="col-6">
						<h4 class="footer_title">Qudos</h4>
						<div class="footer_list">
							<a href="https://qudos.us/driver">Drive</a>
							<a href="https://qudos.us/rider">Ride</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-3">
				<h4 class="footer_title">Connect with Us</h4>
				<div class="footer_social">
					<a href="https://www.facebook.com/QudosTech/"><img src="./assets/img/facebook.png" alt=""></a>
					<!-- <a href="https://twitter.com/ridequdos"><img src="./assets/img/twitter.png" alt=""></a> -->
					<a href="https://www.linkedin.com/company/qudostech/"><img src="./assets/img/linkedin1.png" alt=""></a>
					<a href="https://www.instagram.com/qudostechnologiesinc/"><img src="./assets/img/instagram1.png" alt=""></a>
				</div>
			</div>
			<div class="col hidden-mobile">
				<div class="footer_downside">
					<div>
						<span class="footer_copyright">All Rights Reserved</span>
						<span class="footer_copyright">© Qudos Technologies, Inc</span>
					</div>
					<div>
						<a href="https://qudos.us/"><span class="footer_copyright">www.Qudos.us</span></a>
					</div>
					<div>
						<a routerLink="/terms" class="footer_link">Terms</a>
						<a routerLink="/privacy" class="footer_link">Privacy</a>
						<a routerLink="/posting" class="footer_link">Posting Policy</a>
						<a routerLink="/cookies" class="footer_link">Cookies Policy</a>
					</div>
				</div>
			</div>
			<div class="visible-mobile visible-mobile-footer">
				<div class="row text-center">
					<div class="col-12">
						<span class="footer_copyright">© Qudos Technologies, Inc</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>