<div class="page notification_pg" id="notificationPage">
  <div *ngIf="globals.onLoading">
    <div class="h-100vh">
      <div class="timeline-item centered mb-5 mt-5 mob-p-lr-0">
        <div class="dummy_content w-100 mb-5 mob-mt-5 mob-m-0"></div>
        <ul class="notificatio_dmy_list">
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
          <li class="d-flex mb-3 mt-3">
            <div class="dummy_image img-circle wh40"></div>
            <div class="dummy_wrapper_notfication">
              <div class="dummy_content"></div>
              <div class="dummy_content"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!globals.onLoading">
    <div class="col-12 px-0">
      <div class="card my-5 message__section">
        <div class="card-header mt-md">
          <h4 class="m-0"><i class="fal fa-bell"></i> Notification</h4>
        </div>
        <div class="card-body p-0">
          <div class="row m-0">
            <div class="col-md-12 p-0">
              <div
                class="
                  message__user__section
                  notification__section
                  mob-views
                  nothing_notification
                "
              >
                <div
                  class="d-flex justify-content-between mob-fix pad-md-0"
                  [ngClass]="{ unread__message: globals.totalNotifications }"
                >
                  <h4 *ngIf="globals.totalNotifications">
                    {{ globals.notificationCounter }} new notifications
                  </h4>
                  <h4
                    *ngIf="globals.totalNotifications"
                    (click)="openNotifications(globals.notifications, 1)"
                    class="pointer-cursor"
                  >
                    Mark as opened
                  </h4>
                </div>
                <div
                  class="person-mutual-content new__message-list nothing_post"
                  *ngIf="!globals.totalNotifications"
                >
                  <p class="no_post_text">
                    Something great is happening here.<br />
                    We will notify you when.
                  </p>
                  <img
                    src="./assets/img/no_notification_icon.png"
                    class="img-fluid"
                  />
                  <p class="no_post_text">Sorry! No notification yet.</p>
                </div>
                <ul class="list-group notification__user__list mob-mar-t-60">
                  <li
                    class="list-group-item"
                    *ngFor="let data of globals.notifications"
                    [ngClass]="{ active_notify: !data.is_read }"
                  >
                    <div
                      class="d-flex justify-content-between"
                      (click)="data.is_read || notificationRead(0, data, 0)"
                    >
                      <div class="message__user__list-thumb">
                        <img
                          src="{{ data?.user_image }}"
                          onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                          class="img-fluid"
                          alt="img"
                        />
                        <h4>
                          <strong (click)="notificationRead(1, data, 0)"
                            >{{ data.user_name }}
                          </strong>
                          <span
                            class="liked_yr"
                            *ngIf="data.flag == 1 || data.flag == 19"
                            >liked your
                          </span>
                          <span class="liked_yr" *ngIf="data.flag == 2"
                            >commented on your
                          </span>
                          <span
                            class="liked_yr"
                            *ngIf="data.flag == 3 || data.flag == 8"
                            >shared
                          </span>
                          <span class="liked_yr" *ngIf="data.flag == 4"
                            >joined
                          </span>
                          <span class="liked_yr" *ngIf="data.flag == 20"
                            >followed your
                          </span>
                          <span
                            class="liked_yr"
                            *ngIf="
                              data.flag == 5 ||
                              data.flag == 9 ||
                              data.flag == 6 ||
                              data.flag == 10
                            "
                            >has a new
                          </span>
                          <span class="liked_yr" *ngIf="data.flag == 11"
                            >shared your
                          </span>
                          <span
                            class="liked_yr"
                            *ngIf="data.flag == 12 || data.flag == 18"
                            >created a new
                          </span>
                          <span
                            class="liked_yr"
                            *ngIf="
                              data.flag == 15 &&
                              ((!data.qudos_user_type_from &&
                                !this.globals.user.qudos_user_type) ||
                                (data.qudos_user_type_from &&
                                  this.globals.user.qudos_user_type))
                            "
                            >sent you a
                          </span>

                          <span
                            class="liked_yr"
                            *ngIf="data.flag == 17 || data.flag == 21"
                            >tagged you in a
                          </span>

                          <span
                            class="liked_yr"
                            *ngIf="
                              data.flag == 15 &&
                              data.qudos_user_type_from &&
                                !this.globals.user.qudos_user_type
                            "
                            >wants to be your
                          </span>

                          <span
                            class="liked_yr"
                            *ngIf="
                              data.flag == 16 &&
                              ((!data.qudos_user_type_from &&
                                !this.globals.user.qudos_user_type) ||
                                (data.qudos_user_type_from &&
                                  this.globals.user.qudos_user_type) ||
                                (data.qudos_user_type_from &&
                                  !this.globals.user.qudos_user_type))
                            "
                            >accepted your
                          </span>

                          <span
                            class="liked_yr"
                            *ngIf="
                              data.flag == 16 &&
                              !data.qudos_user_type_from &&
                                this.globals.user.qudos_user_type
                            "
                            >added you as a
                          </span>

                          <strong
                            (click)="notificationRead(2, data, 0)"
                            *ngIf="
                              data.flag == 1 ||
                              data.flag == 2 ||
                              data.flag == 5 ||
                              data.flag == 6 ||
                              data.flag == 10 ||
                              data.flag == 11 ||
                              data.flag == 9 ||
                              data.flag == 17
                            "
                            >post.</strong
                          >

                          <strong
                            *ngIf="data.flag == 21"
                            (click)="notificationRead(2, data, 0)"
                            >comment.</strong
                          >

                          <strong
                            *ngIf="
                              data.flag == 3 ||
                              data.flag == 4 ||
                              data.flag == 12
                            "
                            (click)="notificationRead(3, data, 0)"
                            >group.</strong
                          >

                          <strong
                            *ngIf="
                              (data.flag == 15 &&
                                ((!data.qudos_user_type_from &&
                                  !this.globals.user.qudos_user_type) ||
                                  (data.qudos_user_type_from &&
                                    this.globals.user.qudos_user_type))) ||
                              (data.flag == 16 &&
                                ((!data.qudos_user_type_from &&
                                  !this.globals.user.qudos_user_type) ||
                                  (data.qudos_user_type_from &&
                                    this.globals.user.qudos_user_type) ||
                                  (data.qudos_user_type_from &&
                                    !this.globals.user.qudos_user_type)))
                            "
                            (click)="notificationRead(4, data, 0)"
                            >friend request.</strong
                          >

                          <strong
                            *ngIf="
                              (data.flag == 15 &&
                                data.qudos_user_type_from &&
                                  !this.globals.user.qudos_user_type) ||
                              (data.flag == 16 &&
                                !data.qudos_user_type_from &&
                                  this.globals.user.qudos_user_type)
                            "
                            (click)="notificationRead(4, data, 0)"
                            >favourite driver.</strong
                          >

                          <strong
                            *ngIf="
                              data.flag == 18 ||
                              data.flag == 19 ||
                              data.flag == 20 ||
                              data.flag == 8
                            "
                            (click)="notificationRead(5, data, 0)"
                            >page.</strong
                          >
                        </h4>
                        <p>
                          <i class="fal fa-clock"></i> {{ data.updated_time }}
                        </p>
                      </div>
                      <div class="message__user__time">
                        <span class="user-active" *ngIf="!data.is_read"></span>
                        <span class="user-off" *ngIf="data.is_read"></span>
                      </div>
                    </div>
                  </li>
                  <div *ngIf="globals.onLoading" class="whirl"></div>
                  <div class="row">
                    <div
                      class="col-12"
                      *ngIf="
                        globals.notifications.length <
                          globals.totalNotifications && !globals.onLoading
                      "
                    >
                      <button
                        class="results__more results_more_notification"
                        type="button"
                        (click)="loadMore()"
                        [disabled]="globals.onLoading"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="globals.pageLoad" class="whirl"></div>
</div>
