import { Injectable } from '@angular/core';
import { Globals, API } from 'src/app/helpers/constant';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { GlobalFunctions } from 'src/app/helpers/service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EventData } from 'src/app/helpers/events.shared';

@Injectable({
  providedIn: 'root'
})

//Service to manage post functionalities
export class PostService {

  constructor(public globals: Globals, private toaster: ToastrService, private http: HttpClient, private serviceFunction: GlobalFunctions) { }

  /////////////////////////////////////////////////////// FETCH POSTS /////////////////////////////////////////////////

  //Fetch posts
  getPosts(timelineVar, data, loadMore): Observable<any> {
    let searchFlag = data.search;
    let d = new Date();
    let n = d.getTimezoneOffset();

    if ((data.searchKeyword || data.searchCountry || data.searchZip) && searchFlag) {
      if (!loadMore) {
        data.posts = [];
        data.offset = 0;
      }
    }
    return this.http.post<any>(API.FETCH_POST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: loadMore ? 2 : 5,
      offset: data.offset,
      is_timeline: timelineVar,
      timezoneOffset: n,
      search_flag: searchFlag,
      searchString: data.searchKeyword,
      searchCountry: data.searchCountry,
      searchZipcode: data.searchZip
    }).pipe(
      map(response => {
        if (!loadMore)
          if (typeof (response) == 'string') {
            response = JSON.parse(response);
          }
        if (response.hasOwnProperty('posts')) {

          data.posts = data.posts.concat(response.posts);
          data.total_posts = response.total_posts;
          data.posts_length = response.posts.length;
          data.totalPosts = data.posts.length;
          this.globals.posts = data.posts;
          for (let i = data.offset; i < (data.posts_length + data.offset); i++) {
            if (timelineVar == 0) {
              let params = {
                index: i,
                scope: data,
                post: data.posts[i],
                option: false
              }
              if (data.posts[i].total_comments > 0)
                this.serviceFunction.emit(new EventData('getPostComments', params));
            }

            data.posts[i].tagWhitelist = data.posts[i].tagged_users ? data.posts[i].tagged_users.split(',') : [];
            data.posts[i].description = data.posts[i].description ? this.serviceFunction.convertTag(this.serviceFunction.urlify(decodeURIComponent(data.posts[i].description)), data.posts[i].tagged_users) : '';
            data.posts[i].shared_description = data.posts[i].shared_description ? this.serviceFunction.convertTag(this.serviceFunction.urlify(decodeURIComponent(data.posts[i].shared_description)), data.posts[i].shared_tagged_users) : '';
            data.posts[i].like_name = data.posts[i].ids ? (data.posts[i].ids.split(','))[0] : '';
            data.posts[i].like_count_value = data.posts[i].ids ? (data.posts[i].ids.split(',')).length : null;

            if (data.posts[i].like_count_value > 1) {
              data.posts[i].like_count = data.posts[i].ids ? (data.posts[i].ids.split(',')).length - 1 : null;
            }
          }
        } else {
          this.toaster.error(response.error, "ERROR");
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //fetch post details by id
  getPostById(type, data): Observable<any> {
    return this.http.post<any>(API.FETCH_POST_BY_ID, {
      qudos_user_type: this.globals.user.qudos_user_type,
      post_id: parseInt(data.postId),
      qudos_user_id: this.globals.user.user_id,
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.posts = data.posts.concat(response.post);
          data.posts_length = response.post.length;
          data.self = response.self;
          data.is_friend = response.is_friend;
          this.globals.posts = data.posts;

          for (let i = data.offset; i < (data.posts_length + data.offset); i++) {
            data.posts[i].tagWhitelist = data.posts[i].tagged_users ? data.posts[i].tagged_users.split(',') : [];
            data.posts[i].description = data.posts[i].description ? this.serviceFunction.convertTag(this.serviceFunction.urlify(decodeURIComponent(data.posts[i].description)), data.posts[i].tagged_users) : '';
            data.posts[i].shared_description = data.posts[i].shared_description ? this.serviceFunction.convertTag(this.serviceFunction.urlify(decodeURIComponent(data.posts[i].shared_description)), data.posts[i].shared_tagged_users) : '';
            data.posts[i].like_name = data.posts[i].ids ? (data.posts[i].ids.split(','))[0] : '';
            data.posts[i].like_count_value = data.posts[i].ids ? (data.posts[i].ids.split(',')).length : null;
            if (data.posts[i].like_count_value > 1) {
              data.posts[i].like_count = data.posts[i].ids ? (data.posts[i].ids.split(',')).length - 1 : null;
            }
            if (type) {
              if (!data.postType) {
                if (data.posts[i].privacy_status == 1) {
                  if (data.is_friend) {
                    data.viewPost = true;
                  }
                } else if (data.posts[i].privacy_status == 2) {
                  if (data.self) {
                    data.viewPost = true;
                  }
                } else if (data.posts[i].privacy_status == 0) {
                  data.viewPost = true;
                }
              } else {
                data.viewPost = true;
              }
            } else {
              if (data.posts[i].privacy_status == 1) {
                if (data.is_friend) {
                  data.viewPost = true;
                }
              } else if (data.posts[i].privacy_status == 2) {
                if (data.self) {
                  data.viewPost = true;
                }
              } else if (data.posts[i].privacy_status == 0) {
                data.viewPost = true;
              }
            }
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //fetch group posts
  getGroupPosts(data): Observable<any> {
    return this.http.post<any>(API.GROUP_POSTS, {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: 5,
      offset: data.offset,
      group_id: data.groupId
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.posts = data.posts.concat(response.posts);
          data.total_posts = response.total_posts;
          data.posts_length = response.posts.length;
          data.totalPosts = data.posts.length;

          for (let i = data.offset; i < (data.posts_length + data.offset); i++) {
            data.posts[i].tagWhitelist = data.posts[i].tagged_users ? data.posts[i].tagged_users.split(',') : [];
            data.posts[i].description = data.posts[i].description ? this.serviceFunction.convertTag(this.serviceFunction.urlify(decodeURIComponent(data.posts[i].description)), data.posts[i].tagged_users) : '';
            data.posts[i].shared_description = data.posts[i].shared_description ? this.serviceFunction.convertTag(this.serviceFunction.urlify(decodeURIComponent(data.posts[i].shared_description)), data.posts[i].shared_tagged_users) : '';

            data.posts[i].like_name = data.posts[i].ids ? (data.posts[i].ids.split(','))[0] : '';
            data.posts[i].like_count_value = data.posts[i].ids ? (data.posts[i].ids.split(',')).length : null;
            if (data.posts[i].like_count_value > 1) {
              data.posts[i].like_count = data.posts[i].ids ? (data.posts[i].ids.split(',')).length - 1 : null;
            }
          }
          this.globals.posts = data.posts;
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //fetch user posts
  getUserPosts(data): Observable<any> {
    let d = new Date();
    let n = d.getTimezoneOffset();

    return this.http.post<any>(API.USER_POSTS, {
      qudos_user_type: this.globals.user.qudos_user_type,
      person_id: data.personId,
      person_type: data.personType,
      limit: 5,
      offset: data.offset,
      timezoneOffset: n
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.posts = data.posts.concat(response.posts);
          data.total_posts = response.total_posts;
          data.posts_length = response.posts.length;
          data.totalPosts = data.posts.length;
          this.globals.posts = data.posts;

          for (let i = data.offset; i < (data.posts_length + data.offset); i++) {

            data.posts[i].tagWhitelist = data.posts[i].tagged_users ? data.posts[i].tagged_users.split(',') : [];
            data.posts[i].description = data.posts[i].description ? this.serviceFunction.convertTag(this.serviceFunction.urlify(decodeURIComponent(data.posts[i].description)), data.posts[i].tagged_users) : '';
            data.posts[i].shared_description = data.posts[i].shared_description ? this.serviceFunction.convertTag(this.serviceFunction.urlify(decodeURIComponent(data.posts[i].shared_description)), data.posts[i].shared_tagged_users) : '';
            data.posts[i].like_name = data.posts[i].ids ? (data.posts[i].ids.split(','))[0] : '';
            data.posts[i].like_count_value = data.posts[i].ids ? (data.posts[i].ids.split(',')).length : null;
            if (data.posts[i].like_count_value > 1) {
              data.posts[i].like_count = data.posts[i].ids ? (data.posts[i].ids.split(',')).length - 1 : null;
            }
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //fetch page posts
  fetchPagePosts(data): Observable<any> {

    return this.http.post<any>(API.PAGE_POSTS, {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: 5,
      offset: data.offset,
      page_id: data.pageId
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.posts = data.posts.concat(response.posts);
          data.total_posts = response.total_posts;
          data.posts_length = response.posts.length;
          data.totalPosts = data.posts.length;
          this.globals.posts = data.posts;

          for (let i = data.offset; i < (data.posts_length + data.offset); i++) {
            data.posts[i].tagWhitelist = data.posts[i].tagged_users ? data.posts[i].tagged_users.split(',') : [];
            data.posts[i].description = data.posts[i].description ? this.serviceFunction.convertTag(this.serviceFunction.urlify(decodeURIComponent(data.posts[i].description)), data.posts[i].tagged_users) : '';
            data.posts[i].shared_description = data.posts[i].shared_description ? this.serviceFunction.convertTag(this.serviceFunction.urlify(decodeURIComponent(data.posts[i].shared_description)), data.posts[i].shared_tagged_users) : '';
            data.posts[i].like_name = data.posts[i].ids ? (data.posts[i].ids.split(','))[0] : '';
            data.posts[i].like_count_value = data.posts[i].ids ? (data.posts[i].ids.split(',')).length : null;

            if (data.posts[i].like_count_value > 1) {
              data.posts[i].like_count = data.posts[i].ids ? (data.posts[i].ids.split(',')).length - 1 : null;
            }
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //////////////////////////////////////////////////////// LIKE/UNLIKE POSTS //////////////////////////////////////////

  //like posts
  likePost(index, post, status, data): Observable<any> {

    return this.http.post<any>(API.LIKE_UNLIKE_POST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      post_id: post.post_id,
      user_id: this.globals.user.user_id,
      like: status
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.posts[index].is_like = response.is_like;

          this.toaster.success(response.log, 'SUCCESS');

          let auth_data = JSON.parse(localStorage.getItem('auth'));
          if (response.is_like) {
            auth_data.total_likes += 1;
            if (data.posts[index].ids) {
              data.posts[index].ids = auth_data.user_name + "," + data.posts[index].ids;
            } else {
              data.posts[index].ids = auth_data.user_name;
            }
          } else {
            auth_data.total_likes -= 1;
            let unlike = (data.posts[index].ids.split(','));
            unlike.splice(0, 1);
            data.posts[index].ids = unlike.toString();
          }
          data.posts[index].like_name = data.posts[index].ids ? (data.posts[index].ids.split(','))[0] : '';
          data.posts[index].like_count_value = data.posts[index].ids ? (data.posts[index].ids.split(',')).length : null;

          if (data.posts[index].like_count_value > 1) {
            data.posts[index].like_count = data.posts[index].ids ? (data.posts[index].ids.split(',')).length - 1 : null;
          }
          if (auth_data.total_likes < 0) {
            auth_data.total_likes = 0;
          }
          localStorage.setItem('auth', JSON.stringify(auth_data));

          this.globals.user = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //View details of like
  viewLikes(data, post, loadMore = false): Observable<any> {

    return this.http.post<any>(API.FETCH_LIKES, {
      qudos_user_type: this.globals.user.qudos_user_type,
      post_id: post.post_id,
      limit: 10,
      offset: data.likesOffset
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          if (loadMore) {
            data.likedUsers = data.likedUsers.concat(response.users);
          } else {
            data.likedUsers = response.users;
          }
          data.users = data.likedUsers;
          data.totalUsers = response.total_users;
          data.totalLikedUsers = response.users;
          data.likedUsersLength = data.likedUsers.length;
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //////////////////////////////////////////////////////////// COMMENT /////////////////////////////////////////////////

  //Comment function
  commentDesktop(post, index, data, loadMore = false): Observable<any> {
    data.posts[index].comment_describe = this.globals.posts[index].comment_describe ? this.globals.posts[index].comment_describe : data.posts[index].comment_describe;
    if (typeof (data.posts[index].comment_describe) == 'undefined' || (data.posts[index].comment_describe).length == 0) {
      return throwError("Please enter a comment");
    } else {
      let params = new FormData();
      params.append("access_token", this.globals.user.web_access_token);
      params.append('user_id', this.globals.user.user_id);
      params.append('post_id', post.post_id);
      params.append('comments', encodeURIComponent(this.serviceFunction.htmlEncode((data.posts[index].comment_describe))));
      params.append('qudos_user_type', this.globals.user.qudos_user_type);
      params.append('tagged_users', this.globals.taggedArray.toString());
      params.append('tagged_members', JSON.stringify(this.globals.taggedMember));

      return this.http.post<any>(API.CREATE_COMMENT, params).pipe(
        map(response => {
          if (typeof (response) == 'string') {
            response = JSON.parse(response);
          }
          if (response.error) {
            this.toaster.error(response.error, "ERROR");
          } else {
            this.toaster.success(response.log, 'SUCCESS');
            data.posts[index].comment_describe = '';
            data.posts[index].total_comments += 1;

            if (Object.keys(this.globals.tagify).length) {
              this.globals.tagify.destroy();
              this.globals.tagify = {};
            }
            this.globals.posts[index].comment_describe = '';
          }
          return data;
        }),
        catchError(this.serviceFunction.handleError)
      )
    }
  };

  //Fetch Existing Comments on Post
  getPostComments(post, index, data, loadMore = false): Observable<any> {
    if (!loadMore) {
      data.posts[index].comments = [];
      data.commentOffset = 0;
    }
    return this.http.post<any>(API.FETCH_COMMENT, {
      post_id: post.post_id,
      user_id: this.globals.user.user_id,
      qudos_user_type: this.globals.user.qudos_user_type,
      offset: data.commentOffset,
      limit: 2
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          if (loadMore) {
            data.posts[index].comments = data.posts[index].comments.concat(response.comments);
          } else {
            if (data.posts[index]) {
              data.posts[index].comments = [];
              data.posts[index].comments = response.comments;
            }
          }
          if (data.posts[index]) {
            data.posts[index].commentPost = data.posts[index].comments;
            data.posts[index].totalComments = response.total_comments;
            data.total_comments = response.comments.length;
            data.posts[index].commentsLength = data.posts[index].comments.length;

            for (let i = data.commentOffset; i < (data.posts[index].commentsLength); i++) {
              data.posts[index].commentPost[i].comments = this.serviceFunction.convertTag(decodeURIComponent(data.posts[index].commentPost[i].comments), data.posts[index].commentPost[i].tagged_users);
            }
          }
          data.globals.onLoading = false;
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //////////////////////////////////////////// CREATE NEW POST ////////////////////////////////////////////////////////

  //create new post
  newPostInfo(data, type = 1) {
    let file = data.myFile;
    data.description = Object.keys(this.globals.tagify).length ? this.globals.description : data.description;
    if (file == undefined && data.content_type == undefined && ((data.description == undefined || (data.description.trim()).length == 0))) {
      return throwError("Empty Post");
    } else {
      if (file) {
        if (file.size >= 6291456 && (file.type.toLowerCase() == 'image/png' || file.type.toLowerCase() == 'image/jpg' || file.type.toLowerCase() == 'image/jpeg')) {
          return throwError("Image upload size is upto 6MB");
        } else if (file.size >= 15728640 && (file.type.toLowerCase() == 'video/mp4')) {
          return throwError("Video upload size is upto 15MB");
        } else {
          data.image = 1;
          data.file_name = file.name;
          if (file.type.toLowerCase() == 'video/mp4') {
            data.content_type = 2;
          } else if (file.type.toLowerCase() == 'image/png' || file.type.toLowerCase() == 'image/jpg' || file.type.toLowerCase() == 'image/jpeg') {
            data.content_type = 1;
          } else {
            return throwError("File type can be jpg,png,mp4 only.");
          }
        }
      } else
        data.image = 0;

      if ((data.description == undefined || (data.description.trim()).length == 0)) {
        data.description = '';
      } else {
        data.description = data.description;
      }
      let params = new FormData();
      params.append("access_token", this.globals.user.web_access_token);
      params.append('user_id', this.globals.user.user_id);
      params.append('description', encodeURIComponent(this.serviceFunction.htmlEncode((data.description))));
      params.append('content_url', file);
      params.append('content_type', data.content_type);
      params.append('image_flag', data.image);
      params.append('qudos_user_type', this.globals.user.qudos_user_type);
      params.append('tagged_users', this.globals.taggedArray.toString());
      params.append('tagged_members', JSON.stringify(this.globals.taggedMember))
      if (type == 2) {
        params.append('group_flag', '1');
        params.append('group_id', data.groupId);
      } else if (type == 3) {
        params.append('page_flag', '1');
        params.append('page_id', data.pageId);
      }
      return this.http.post<any>(API.CREATE_POST, params).pipe(
        map(response => {
          if (typeof (response) == 'string') {
            response = JSON.parse(response);
          }
          if (response.error) {
            this.toaster.error(response.error, "ERROR");
          } else {
            this.toaster.success(response.log, 'SUCCESS');
            this.globals.description = '';
            data.result = response.result;
            let auth_data = JSON.parse(localStorage.getItem('auth'));
            auth_data.total_posts += 1;
            localStorage.setItem('auth', JSON.stringify(auth_data));
            this.globals.user = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));
            if (Object.keys(this.globals.tagify).length) {
              this.globals.tagify.destroy();
              this.globals.tagify = {};
            }
          }
          return data;
        }),
        catchError(this.serviceFunction.handleError)
      )
    }
  };
  //////////////////////////////////////////// SHARE POST ////////////////////////////////////////////////////////

  //Share post
  sharePost(post, data, type = 1): Observable<any> {
    data.describe = this.globals.description ? this.globals.description : data.describe;

    let params = new FormData();
    params.append("access_token", this.globals.user.web_access_token);
    params.append('user_id', this.globals.user.user_id);
    params.append('post_id', post.post_id);
    params.append('qudos_user_type', this.globals.user.qudos_user_type);
    params.append('tagged_users', this.globals.taggedArray.toString());
    params.append('tagged_members', JSON.stringify(this.globals.taggedMember));
    params.append('description', encodeURIComponent(this.serviceFunction.htmlEncode((data.describe))));
    params.append('group_flag', type == 2 ? '1' : '0');
    params.append('group_id', type == 2 ? data.groupId : '');
    params.append('page_flag', type == 3 ? '1' : '0');
    params.append('page_id', type == 3 ? data.pageId : '');

    return this.http.post<any>(API.SHARE_POST, params).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          this.toaster.success(response.log, 'SUCCESS');
          this.globals.description = '';
          let auth_data = JSON.parse(localStorage.getItem('auth'));
          auth_data.total_posts += 1;
          localStorage.setItem('auth', JSON.stringify(auth_data));
          this.globals.user = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));
          if (Object.keys(this.globals.tagify).length) {
            this.globals.tagify.destroy();
            this.globals.tagify = {};
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //////////////////////////////////////////// COPYLINK   ////////////////////////////////////////////////////

  //Copy link for the post
  copyLink(copyvalue, type, option = 1) {
    let newVariable: any;
    newVariable = window.navigator;
    let x = document.createElement("INPUT");
    x.setAttribute("type", "text");
    x.setAttribute("value", copyvalue);
    x.setAttribute('id', "copyTextInput")

    document.body.appendChild(x);
    let copiedValue = document.getElementById('copyTextInput') as HTMLInputElement;

    if (/webOS|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      let oldContentEditable = copiedValue.contentEditable,
        oldReadOnly = copiedValue.readOnly,
        range = document.createRange();

      copiedValue.contentEditable = 'true';
      copiedValue.readOnly = false;
      range.selectNodeContents(copiedValue);
      let s = window.getSelection();
      s.removeAllRanges();
      s.addRange(range);
      copiedValue.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
      copiedValue.contentEditable = oldContentEditable;
      copiedValue.readOnly = oldReadOnly;
    } else {
      copiedValue.select();
    }
    document.execCommand("copy");

    if (this.globals.mobileView) {
      $('#body').removeClass("custom-backdrop");
      if (newVariable && newVariable.share) {
        newVariable.share({
          title: 'QudosConnect:',
          text: 'QudosConnect:',
          url: copyvalue
        }).then(() => {
        })
          .catch(err => {
          });
      } else {
      }
      if (type == 1) {
        this.serviceFunction.closeOptions(1);
      } else if (type == 2) {
        this.serviceFunction.closeOptions(2);
      } else if (type == 3) {
        this.serviceFunction.closeOptions(3);
      } else if (type == 4) {
        if (option == 1) {
          this.serviceFunction.closeOptions(4);
        }
      }
    }
    let element = document.getElementById('copyTextInput');
    element.parentNode.removeChild(element);
    return Observable.create((observer) => {
      return observer.next(true);
    });
  };
  //////////////////////////////////////////// DELETE POST   ////////////////////////////////////////////////////

  //Delete post
  delete(post_id, data): Observable<any> {

    return this.http.post<any>(API.DELETE_POST, {
      user_id: this.globals.user.user_id,
      qudos_user_type: this.globals.user.qudos_user_type,
      post_id: post_id,
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          this.toaster.success(response.log, 'SUCCESS');

          let auth_data = JSON.parse(localStorage.getItem('auth'));
          auth_data.total_posts -= 1;

          if (auth_data.total_posts < 0) {
            auth_data.total_posts = 0;
          }

          localStorage.setItem('auth', JSON.stringify(auth_data));

          this.globals.user = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));

          data.posts = data.posts.filter(function (val, index) {
            return val.post_id != post_id
          });
          data.total_posts -= 1;
          data.totalPosts -= 1;
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //////////////////////////////////////////// EDIT POST   ////////////////////////////////////////////////////

  //Edit post details
  editPost(post, data): Observable<any> {
    let file = data.myFile;
    if (file == undefined && data.content_type == undefined && (data.post.description == undefined || (data.post.description.trim()).length == 0)) {
      return throwError('Empty Post');
    }
    else {
      if (file) {
        if (file.size >= 6291456 && (file.type.toLowerCase() == 'image/png' || file.type.toLowerCase() == 'image/jpg' || file.type.toLowerCase() == 'image/jpeg')) {
          return throwError("Image upload size is upto 6MB");
        } else if (file.size >= 15728640 && (file.type.toLowerCase() == 'video/mp4')) {
          return throwError("Video upload size is upto 15MB");
        } else {
          data.image = 1;
          data.file_name = file.name;
          if (file.type.toLowerCase() == 'video/mp4') {
            data.content_type = 2;
          } else if (file.type.toLowerCase() == 'image/png' || file.type.toLowerCase() == 'image/jpg' || file.type.toLowerCase() == 'image/jpeg') {
            data.content_type = 1;
          } else {
            return throwError("File type can be jpg,png,mp4 only.");
          }
        }
      } else
        data.image = 0;

      if ((data.post.description == undefined || (data.post.description.trim()).length == 0)) {
        data.post.description = '';
      } else {
        data.post.description = data.post.description;
      }

      let params = new FormData();
      params.append("access_token", this.globals.user.web_access_token);
      params.append('post_id', post.post_id);
      params.append('user_id', this.globals.user.user_id);
      params.append('description', encodeURIComponent(this.serviceFunction.htmlEncode((data.post.description))));
      params.append('content_url', file);
      params.append('content_type', data.content_type);
      params.append('image_flag', data.image);
      params.append('qudos_user_type', this.globals.user.qudos_user_type);
      params.append('tagged_users', this.globals.taggedArray.toString());
      params.append('tagged_members', JSON.stringify(this.globals.taggedMember));

      return this.http.post<any>(API.UPDATE_POST, params).pipe(
        map(response => {
          if (typeof (response) == 'string') {
            response = JSON.parse(response);
          }
          if (response.error) {
            this.toaster.error(response.error, "ERROR");
          }
          else {
            this.toaster.success(response.log, 'SUCCESS');
            this.globals.description = '';
            if (Object.keys(this.globals.tagify).length) {
              this.globals.tagify.destroy();
              this.globals.tagify = {};
            }
          }
          return data;
        }),
        catchError(this.serviceFunction.handleError)
      )
    };
  };

  //Edit privacy
  editPrivacy(post, selectedIndex, data): Observable<any> {

    return this.http.post<any>(API.EDIT_PRIVACY, {
      post_id: data.post.post_id,
      privacy_status: data.privacy_type,
      qudos_user_type: this.globals.user.qudos_user_type
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          this.toaster.success(response.log, 'SUCCESS');
          data.posts[selectedIndex].privacy_status = parseInt(data.privacy_type);
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };
}