<div class="page">
	<div *ngIf="globals.pageLoad">
		<div class="row h-100vh hmpost">
			<div class="col-12 col-lg-3 col-xl-2 page_sidebar">
			</div>
			<div class="col-12 col-lg-6 col-xl-8 page_content full-md post__copy">
				<div class="timeline-item box-0 centered mb-5 panel">
					<ul class="dummy_post timeline-item box-0 border-0 pb-0 mt-2">
						<li class="d-flex mt-3 main_msg_dummy">
							<div class="dummy_image img-circle "></div>
							<div class="dummy_wrapper">
								<div class="dummy_content"> </div>
							</div>
						</li>
					</ul>
					<div class=" user share-user">
						<ul class="dummy_msg_content">
							<li class="d-flex mb-3 mt-3">
								<div class="dummy_image img-circle "></div>
								<div class="dummy_wrapper">
									<div class="dummy_content"> </div>
									<div class="dummy_content"> </div>
								</div>
							</li>
							<div class="dummy_content w-60 mb-4"> </div>
							<div class="dummy_content w-60 mb-4 "> </div>
							<div class="dummy_content w-60 mb-4"> </div>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row " *ngIf="!globals.pageLoad">
		<div class="col-12 col-lg-3 col-xl-2 page_sidebar">
		</div>
		<div class="col-12 col-lg-6 col-xl-8 page_content">
			<div class="panel panel_frnd_req post-info ng-scope">
				<div class="new-post__wrap">
					<div class="new-post__avatar">
						<img src="{{userData?.user_image}}" alt=""
							onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" class="pointer-cursor">
					</div>
					<div class="header-info">
						<div class="author-name ng-binding pointer-cursor"><span ngDefaultControl
								[(ngModel)]="userData.user_name">{{userData.user_name}}</span>
						</div>
						<div class="gray-label ng-binding">
							Friend Request
						</div>
					</div>
					<div class="request__back pointer-cursor" routerLink="/notification">
						Back
					</div>
				</div>
				<hr class="request_hr">
				<div>
					<!---------------------------------------------------- RE-share Shared  Details ---------------------------------------------------------->
					<div class="panel panel_request post-info no-shadow ng-scope">
						<div class="user request__user">
							<div class="user-info">
								<div class="user__img">
									<img src="{{userData?.user_image}}" alt=""
										onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" class="share-img">
								</div>
								<div class="user__meta share__meta">
									<h4 data-toggle="tooltip" data-placement="top" title={{userData.user_name}}>{{userData.user_name}}
									</h4>
									<p *ngIf="userData.country">{{userData.country}}</p>
								</div>
								<div>
									<p class="shared_post_icon" *ngIf="personType">
										<span class="share_post_text"><i class="fas fa-heart"></i> {{userData.fav_count}}</span>
										<span class="share_post_text"><i class="fas fa-star share-icon"></i>
											{{userData.total_points}}</span>
									</p>
									<p class="shared_post_icon" *ngIf="!personType">
										<span class="share_post_text"> <i class="fas fa-users "></i> {{userData.follower_count}}</span>
										<span class="share_post_text"><i class="fas fa-star share-icon"></i>
											{{userData.total_points}}</span>
									</p>
								</div>
							</div>
							<div>
								<p><button type="button" class="button-share_post know-more-about">
										<span routerLink="/userInfo/{{personId}}/{{personType}}">
											Know More About {{userData.user_name.split(" ")[0]}}
										</span>
									</button>
								</p>
								<p *ngIf="isFriend"><button type="button" class="button-share_post btn-primary request__btn"
										[class.disabled]="globals.onLoading">
										Accepted
									</button></p>
								<p *ngIf="isFriend">
									<button type="button" class="button-share_post request__btn btn-pad-0" [disabled]="globals.onLoading">
										<span (click)="removeFriend(userData,personType)"
											*ngIf="(!personType && !globals.user.qudos_user_type) || (!personType && globals.user.qudos_user_type) || (personType && globals.user.qudos_user_type)">Remove</span>
										<span (click)="removeFaveDriver(userData)"
											*ngIf="personType && !globals.user.qudos_user_type">Remove</span>
									</button>
								</p>
								<div *ngIf="requested">
									<p [hidden]="isFriend"><button type="button"
											class="button-share_post btn-primary request__btn btn-pad-0" [disabled]="globals.onLoading">
											<span (click)="addPerson(userData,personType)"
												*ngIf="(!personType && !globals.user.qudos_user_type) || (!personType && globals.user.qudos_user_type) || (personType && globals.user.qudos_user_type)">Accept
												Request</span>
											<span (click)="addFaveDriver(userData)" *ngIf="personType && !globals.user.qudos_user_type">Accept
												Request</span>
										</button></p>

									<p [hidden]="isFriend">
										<button type="button" class="button-share_post request__btn"
											(click)="rejectPerson(userData,personType)" [disabled]="globals.onLoading">Reject Request</button>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="globals.onLoading" class="whirl"></div>
	</div>
</div>