import { Component, OnInit, OnDestroy } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit,OnDestroy {

  image = '';
  content_type;
  posts = [];
  offset = 0;
  personId: number;
  personType: number;
  routeSubscribe: any;
  
  constructor(public globals: Globals, private router: ActivatedRoute) {
    this.globals.posts = [];
    this.routeSubscribe = this.router.params.subscribe(params => {
      this.personId = parseInt(params.personId);
      this.personType = parseInt(params.personType);
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.routeSubscribe.unsubscribe();
  }
}
