import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { GlobalFunctions } from 'src/app/helpers/service';
import { API } from 'src/app/helpers/constant';

@Injectable({
  providedIn: 'root'
})
  
  //service for the login page functionalities
export class LoginService {

  constructor(private http: HttpClient, private toaster: ToastrService, private serviceFunction:GlobalFunctions) { }

  //login
  login(data):Observable<any> {
    return this.http.post<any>(API.LOGIN_URL,data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Forget password
  forgotPassword(data): Observable<any> {
    return this.http.post<any>(API.LOGIN_FORGOT_PASSWORD, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Resend otp
  resendOtp(data): Observable<any> {
    return this.http.post<any>(API.RESEND_OTP, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Reset password
  resetPassword(data): Observable<any> {
    return this.http.post<any>(API.RESET_PASSWORD, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }
}
