import { FormGroup } from "@angular/forms";
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import { PostService } from "src/app/services/ViewServices/postServices/post.service";
import { Globals } from "src/app/helpers/constant";
import { GlobalFunctions } from "src/app/helpers/service";
import { EmojiService } from "src/app/services/emojiService/emoji.service";
import { ToastrService } from "ngx-toastr";
import { EventData } from "src/app/helpers/events.shared";
import { TagService } from "src/app/services/tagService/tag.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-post-template",
  templateUrl: "./post-template.component.html",
  styleUrls: ["./post-template.component.css"],
})
export class PostTemplateComponent implements OnInit, OnDestroy {
  // share_post : FormGroup;
	myFile: any;
	image: any;
	content_type: any;
	post = { shared_id: 0, content_type: 0, content_url: "", privacy_status: 0, comment_describe: "", post_id: "", totalComments: 0, commentPost: [], description: "", post_type: 0, group_id: 0, page_id: 0, };
	share_post_description: any;
	groupPage: any;
	is_group: any;
	totalUsers: any;
	likedUsersLength: any;
	likesOffset: any;
	selectedIndex: any;
	limit: 2;
	posts = [];
	is_post: any;
	tagList: any;
	editValue: any;
	tempPost = {};
	option: any;
	editingPost: any;
	postId: number;
	total_posts: number;
	totalPosts: number;
	scopeData: any;
	describe: any;
	description: any;
	searchKeyword: any;
	searchCountry: any;
	searchZip: any;
	users: [];
	faveUsersCount: [];
	deletePage: any;
	deleteGroup: any;
	groupId: any;
	pageId: any;
	pageView: any;
	eventSubscription: any;
	privacy_type: any;
	postType: number;
	totalLikedUsers: any;
	posts_length: any;
	constructor(
		public globals: Globals,
		private postHelper: PostService,
		private serviceFunction: GlobalFunctions,
		public emojiService: EmojiService,
		private toaster: ToastrService,
		public tagService: TagService,
		private router: Router
	) {}

	// resetForm(){
	//   this.modalForm = new FormGroup();
	//  }

	ngOnInit() {
		this.eventSubscription = this.serviceFunction.on(
		"modalOpen",
		(data: any) => {
			if (this.editingPost) {
			this.initEditPost();
			}
		}
		);
	}

	ngOnDestroy() {
		this.eventSubscription.unsubscribe();
	}

	@ViewChild("commentPost") commentPost: TemplateRef<any>;
	@ViewChild("deletePost") deletePost: TemplateRef<any>;
	@ViewChild("editPost") editPost: TemplateRef<any>;
	@ViewChild("editPrivacy") editPrivacy: TemplateRef<any>;
	@ViewChild("mobilePost") mobilePost: TemplateRef<any>;
	@ViewChild("searchPeople") searchPeople: TemplateRef<any>;
	@ViewChild("sharePost") sharePost: TemplateRef<any>;
	@ViewChild("viewLikesModal") viewLikesModal: TemplateRef<any>;

	//Function to manage the counts of likes on a post.
	public likesCount() {
		if (!this.globals.onLoading && this.totalUsers > this.likedUsersLength) {
		this.globals.onLoading = true;
		this.likesOffset = this.likedUsersLength;
		let params = {
			likesOffset: this.likesOffset,
			likedUsersLength: this.likedUsersLength,
			post: this.post,
			option: true,
		};
		this.serviceFunction.emit(new EventData("viewLikesModal", params));
		}
	}

	//Create a post in mobile view
	public mobileNewPost() {
		this.globals.onLoading = true;
		let type;
		let data = this.scopeData;
		data.myFile = this.myFile;
		data.image = this.image;
		data.content_type = this.content_type;
		data.description = this.description;
		if (location.pathname.split("/")[1] == "groupInfo") {
			type = 2;
		} else if (location.pathname.split("/")[1] == "pageInfo") {
			type = 3;
		} else {
			type = 1;
		}
		this.postHelper.newPostInfo(data, type).subscribe(
		(result) => {
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
			// location.reload();
			if (
			window.location.href.includes("groupInfo") ||
			window.location.href.includes("pageInfo")
			) {
			location.reload();
			} else {
			this.router.navigate(["/home"]);
			}
		},
		(err) => {
			this.serviceFunction.getDismissReason("Close");
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//View details of the clicked user
	public viewUser(id, type) {
		this.serviceFunction.getDismissReason("Close");
		this.router.navigate(["/userInfo/" + id + "/" + type]);
	}

	//Manage image attachments
	public fileName(data) {
		this.myFile = data[0];
		if (this.myFile) {
		let file_name = this.myFile.name;
		let file_type = this.myFile.type;
			if (
				file_type.toLowerCase() != "image/jpeg" &&
				file_type.toLowerCase() != "image/png"
			) {
				this.toaster.error("Image can be jpg,png only", "ERROR");
			} else {
				file_name = this.myFile.name;
				$("#fileName")[0].innerHTML = file_name.slice(0, 20);
				document.getElementById("fileName").style.color = "black";
			}
		}
	}

	//manage video attachments
	public videoName(data) {
		this.myFile = data[0];
		if (this.myFile) {
		let video_name = this.myFile.name;
		let file_type = this.myFile.type;
		if (file_type.toLowerCase() != "video/mp4") {
			this.toaster.error("Video can be mp4 only", "ERROR");
		} else {
			video_name = this.myFile.name;
			document.getElementById("fileName").innerHTML = video_name.slice(0, 20);
			document.getElementById("fileName").style.color = "black";
		}
		}
	}

	//manage filtering of posts
	public applyFilter = function (searchValue) {
		let param = {
		search: searchValue,
		searchKeyword: this.searchKeyword,
		searchCountry: this.searchCountry,
		searchZip: this.searchZip,
		};
		this.serviceFunction.emit(new EventData("fetchPost", param));
	};

	//Share post
	public sharePostInfo() {
		let type;
		this.globals.onLoading = true;
		this.describe = this.globals.description
		? this.globals.description
		: this.describe;
		if (this.groupPage) {
			type = 2;
		} else if (this.pageView) {
			type = 3;
		} else {
			type = 1;
		}
		if (this.describe === undefined || this.describe.length == 0) {
			this.toaster.error("Please enter a description.", "ERROR");
			this.globals.onLoading = false;
		} else {
			let data = this;
			this.postHelper.sharePost(this.post, data, type).subscribe(
				(result) => {
				this.serviceFunction.getDismissReason("Close");
				if (
					window.location.href.includes("groupInfo") ||
					window.location.href.includes("pageInfo")
				) {
					location.reload();
				}
				if (
					window.location.href.includes("groupInfo") ||
					window.location.href.includes("pageInfo")
				) {
					location.reload();
				} else if (this.post.group_id) {
					this.router.navigate(["/groupInfo", this.post.group_id]);
				} else if (this.post.page_id) {
					this.router.navigate(["/pageInfo", this.post.page_id]);
				} else {
					this.router.navigate(["/home"]);
				}
				// location.reload();
				this.globals.onLoading = false;
				window.onscroll = function () {};
				},
				(err) => {
				this.serviceFunction.getDismissReason("Close");
				this.toaster.error(err, "ERROR");
				this.globals.onLoading = false;
				}
			);
		}
	};

	//Create comment
	public comment(post) {
		this.globals.onLoading = true;
		let data = this;
		this.posts[this.selectedIndex].comment_describe = this.globals.description
		? this.globals.description
		: this.posts[this.selectedIndex].comment_describe;
		this.postHelper
		.commentDesktop(post, this.selectedIndex, data, false)
		.subscribe(
			(result) => {
			this.serviceFunction.getDismissReason("Close");
			let params = {
				index: this.selectedIndex,
				scope: data,
				post: post,
				option: false,
			};
			this.serviceFunction.emit(new EventData("getPostComments", params));
			this.globals.onLoading = false;
			},
			(err) => {
				this.serviceFunction.getDismissReason("Close");
				this.toaster.error(err, "ERROR");
				this.globals.onLoading = false;
			}
		);
	};

	//delete post
	public delete(postId) {
		this.globals.onLoading = true;
		let isLike =
		this.postType != 1 ? this.posts[this.selectedIndex].is_like : "";
		let data = this;
		this.postHelper.delete(postId, data).subscribe(
		(result) => {
			this.posts = result.posts;
			this.total_posts = result.total_posts;
			this.totalPosts = result.totalPosts;
			if (isLike && this.postType != 1) {
				let auth_data = JSON.parse(localStorage.getItem("auth"));
				auth_data.total_likes -= 1;
				if (auth_data.total_likes < 0) {
					auth_data.total_likes = 0;
				}
				localStorage.setItem("auth", JSON.stringify(auth_data));
				this.globals.user =
					localStorage.getItem("auth") == "undefined" ||
					!localStorage.getItem("auth")
					? ""
					: JSON.parse(localStorage.getItem("auth"));
			}
			this.serviceFunction.getDismissReason("Close");
			if (this.globals.mobileView) {
				$("#body").removeClass("custom-backdrop");
				this.serviceFunction.closeOptions(1);
			}
			if (this.postType || location.pathname.split("/")[1] == "postInfo") {
			// this.router.navigate(['/home']);
				if (
					window.location.href.includes("groupInfo") ||
					window.location.href.includes("pageInfo")
				) {
					location.reload();
				} else if (this.post.group_id) {
					this.router.navigate(["/groupInfo", this.post.group_id]);
				} else if (this.post.page_id) {
					this.router.navigate(["/pageInfo", this.post.page_id]);
				} else {
					this.router.navigate(["/home"]);
				}
			}
			// location.reload();
			this.globals.onLoading = false;
		},
		(err) => {
			this.serviceFunction.getDismissReason("Close");
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//Edit post details
	public editPostInfo(post, isEdit) {
		this.editValue = isEdit;
		this.globals.onLoading = true;
		this.post.description = Object.keys(this.globals.tagify).length
		? this.globals.description
		: this.post.description;
		let data = this;
		this.postHelper.editPost(post, data).subscribe(
		(result) => {
			this.serviceFunction.getDismissReason("Close");
			if (this.globals.mobileView) {
				$("#body").removeClass("custom-backdrop");
				this.serviceFunction.closeOptions(1);
			}
			this.globals.onLoading = false;
			// location.reload();
			if (
			window.location.href.includes("groupInfo") ||
			window.location.href.includes("pageInfo")
			) {
				location.reload();
			} else if (post.group_id) {
				this.router.navigate(["/groupInfo", post.group_id]);
			} else if (post.page_id) {
				this.router.navigate(["/pageInfo", post.page_id]);
			} else {
				this.router.navigate(["/home"]);
			}
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//Edit post privacy
	public editPrivacySetting(post) {
		this.globals.onLoading = true;
		let data = this;
		data.privacy_type = this.post.privacy_status;
		this.postHelper.editPrivacy(post, this.selectedIndex, data).subscribe(
		(result) => {
			this.serviceFunction.getDismissReason("Close");
			if (this.globals.mobileView) {
				if (!this.option) {
					$("#body").removeClass("custom-backdrop");
					this.serviceFunction.closeOptions(1);
				} else {
					this.serviceFunction.getDismissReason("Close");
				}
			}
			this.globals.onLoading = false;
		},
		(err) => {
			this.serviceFunction.getDismissReason("Close");
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//Manage tagging functionality while editing a post
	initEditPost = function () {
		this.globals.tagValue = [];
		if (this.tagList.length != 0) {
			for (let i = 0; i < this.tagList.length; i++) {
				this.globals.tagValue.push({
				value: this.tagList[i].split("_")[1].split("\r")[0],
				user_id: this.tagList[i].split("_")[0].split("/")[4],
				qudos_user_type: this.tagList[i].split("_")[0].split("/")[5],
				link: this.tagList[i].split("_")[0],
				});
			}
			let value = this.post.description;
			let tagElement = $(".text-desc")
			this.tagService.initTagify(tagElement, value);
		}
	};

	//manage events to load comments in a mobile view
	public loadMoreComments(data) {
		this.serviceFunction.emit(new EventData("loadMoreComments", data));
	}

	//Close modal functionality
	public closeModal = function (param) {
		if (param == "post") {
			this.posts[this.selectedIndex] = this.tempPost;
		} else if (param == "privacy") {
			this.posts[this.selectedIndex] = this.tempPost;
		} else if (param == "comment") {
			this.post.comment_describe = '';
		}
		this.serviceFunction.getDismissReason("Close");
	};

	public createTag(event, selectedIndex, commentId) {
		if(event.key === "Enter"){
			setTimeout(() => {
				this.post.comment_describe = this.post.comment_describe.replace(
				/(\r\n|\n|\r)/gm,
				""
				);
			}, 1);
		}
		let commentBox = document.getElementById(commentId);
		commentBox.scrollTop = commentBox.scrollHeight;
		if (this.globals.mobileView) {
			this.tagService.createTag(event, selectedIndex, false, true);
		}
	}

	public createTagService(data) {
		if (this.description !== null && this.description !== "") {
			this.tagService.createTag(data);
		}
	}
}
