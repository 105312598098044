<div class="service-wrap-header {{localStorageAuth ? 'service-wrap-login' : ''}}">
	<section class="section-core--policies section-banner">
		<div class="container">
		<div class="row">
			<div class="col-sm-12 d-flex flex-column mt-5">
				<span class="d-inline-block h-100">
				<h1 class="core-header-special-1">&nbsp;<span class="d-block">QudosConnect Posting Policy</span></h1>
				<p class="subheader__prime">Last Updated: April 8, 2021</p>
				</span>
			</div>
			</div>
		</div>
	</section>
</div>
<div class="service-wrap">
	<section class="section-core-rules bg-white">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 d-flex flex-column mt-5">
					<p class="core_parag">
					As part of Qudos’ unique features, QudosConnect aims to connect riders and drivers in a social media setting. Finding loyal clientele is important for drivers, finding drivers is as important for riders as well.  We recognize how important it is for QudosConnect to be a place for both drivers and riders to communicate and we take our role in keeping abuse off our service seriously. To ensure this, we have placed a set of Community Standard Policies that outline what is and what is not allowed. And we ask members of our community to follow these guidelines.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">1. Impersonation</h5>
					<p class="core_parag">
					Pretending to be someone else or to be representing a business in a way that is not truthful is not allowed. You are not allowed to use someone else's name, image, or other personal information to deceive others into thinking you are someone other than yourself or associated with a business or organization when you are not. You also may not use or attempt to use another individual's account or create a member profile for anyone other than yourself.       
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">2. Misrepresentation</h5>
					<p class="core_parag">
					Do not provide inaccurate information. Authenticity is the cornerstone of our community. We believe that people are more accountable for their statements and actions when they use their authentic identities. Our authenticity policies are intended to create a safe environment where people can trust and hold one another accountable.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">3. Honesty and Authenticity</h5>
					<p class="core_parag">
					Be honest, forthright, and respectful. You may not use the Services to share false content or information, including news stories, that presents untrue or unverified facts or events as though they are true or likely true. Do not share comments, opinions, or other content in a manner that is intended to be misleading or inaccurate or to conceal a conflict of interest or unlawful motive. You may not use the Services to share false content or information, including news stories, that presents untrue or unverified facts or events as though they are true or likely true. Reducing the spread of false news is a responsibility that we take seriously. We also recognize that this is a challenging and sensitive issue.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">4. Relevance</h5>
					<p class="core_parag">
					Your messages, posts, comments, and other content must be on-topic and relevant. The Services provide a professional community for sharing professional content and information, so do not post or otherwise provide content that is irrelevant or inappropriate.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">5. Adult Content</h5>
					<p class="core_parag">
					It's not acceptable to post content containing nudity, sexually explicit material, or pornography. Some adult content may be allowed in an educational, medical, scientific, or professional artistic context so long as it is not gratuitously graphic. We default to removing sexual imagery to prevent the sharing of non-consensual or underage content. Restrictions on the display of sexual activity also apply to digitally created content unless it is posted for educational, humorous, or satirical purposes.  The Services are never to be used for sexual exploitation of children. You also may not post content that threatens sexual violence or sexual assault. You may not use the Services to engage in or promote escort services, prostitution, or human trafficking.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">6. Bullying and Harassment</h5>
					<p class="core_parag">
					Bullying or harassment that targets individuals or groups to degrade or shame them is not allowed. This includes, but is not limited to, abusive or humiliating language, sexual advances and innuendo, revealing others' personal or sensitive information (aka "doxing") or posting content about them without consent, or inciting or engaging others to do any of the same. We do not tolerate this kind of behavior because it prevents people from feeling safe and respected.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">7. Hate, Violence, and Terrorism</h5>
					<p class="core_parag">
					We do not allow organizations or groups that engage in or promote violence or property damage, organized criminal activity, prejudice, or hate. Also, you may not use our Services to express support for such groups or to post content or otherwise use the Services to incite violence or hatred against particular individuals or groups. Content that depicts terrorist activity, that is intended to recruit for terrorist organizations, or promotes or supports terrorism in any manner, is not tolerated on the Services. We remove content that glorifies violence or celebrates the suffering or humiliation of others because it may create an environment that discourages participation. We do not allow hate because it creates an environment of intimidation and exclusion and in some cases may promote real-world violence.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">8. Harmful Content and Shocking Material</h5>
					<p class="core_parag">
					You may not post violent or graphic content or otherwise use the Services with the intent to shock or humiliate others. We do not allow activities that promote, organize, depict or facilitate criminal activity. We also do not allow content depicting or promoting instructional weapon making, drug abuse, and threats of theft. Content or activities that promote or encourage suicide or any type of self-injury, including self-mutilation and eating disorders, is also not allowed.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">9. Spam</h5>
					<p class="core_parag">
					Untargeted, irrelevant, unwanted, unsolicited, unauthorized, inappropriately commercial or promotional, or gratuitously repetitive messages and other similar content are considered spam and are not allowed on the Services. Please make the effort to create original, professional, relevant, and interesting content in order to gain popularity. We do not allow people to use misleading or inaccurate information to collect likes, followers, or shares.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">10. Privacy and Publicity</h5>
					<p class="core_parag">
					Privacy and the protection of personal information are fundamentally important values. You must respect others' privacy on the Services. You may not solicit email addresses or other personal information from Qudos members you don't know without authorization; use, disclose or distribute any data obtained in violation of any of our policies or agreements; disclose information that you do not have the consent to disclose (such as personal or confidential information of others, including your employer); or post or distribute content depicting children without the necessary consent from such child's legal guardian or parent. Do not use others' names, likenesses or photos to suggest an endorsement of a product, service or investment without their prior consent.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">11. Compliance with Law</h5>
					<p class="core_parag">
					Your use of the Services must comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements. You may not use the Services to create or operate a pyramid scheme, engage in fraud, or to sell or promote illegal drugs.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">12. Copyright and Trademark</h5>
					<p class="core_parag">
					We respect the intellectual property rights of others and do not allow copyright infringement. You may not violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets, or other proprietary rights. . You own all of the content and information you post and you control how it is shared through your privacy and application settings.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">13. Unauthorized Access and Use</h5>
					<p class="core_parag">
					You may access the Services solely in the manner that we expressly authorize, for the tasks that the Services are intended for. You may not access the Services except through the interfaces intentionally provided by Qudos, such as its mobile applications.                            
					</p>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">14. Respect Our Rules</h5>
					<p class="core_parag">
					You must follow the spirit and letter of our rules, agreements, and policies. You may not violate any additional terms concerning the specific Services that are provided when you sign up for or start using such Services.                            
					</p>
				</div>
			</div>
		</div>
	</section>
</div>