import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../authService/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private _router: Router, private _authService: AuthService) {
  }

  //Function to guarding the routes .
  //If the user is authenticated or matches location path move ahead otherwise return to login page.
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.isAuthenticated()) {
      return true;
    }
    else {
      if (location.pathname.match(/[\/]post[\/][0-9]{0,9}[\/][0][\/][0-9]{0,9}[\/][0-9]+/g)) {
        return true;
      }
    }
    // navigate to login page
    this._router.navigate(['/login']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;

  }
}
