<ng-template #confirmSignout let-modal>
    <div class="signout signout_confirm">
        <div class="row">
            <div class="popup-main-content">
                <div class="person-info-form">
                    <form name="profile">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <div class="col-10 offset-1">
                                        <p class="signout_content mobile-left">Are you sure you want to logout ? </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="signout-line">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <div class="col-10 offset-1 signup-button mobile-right">
                                        <button class="btn btn-default cbtn" type="button" (click)="modal.dismiss('Cross click')"
                                            [disabled]="globals.onLoading">
                                            No
                                        </button>&nbsp;
                                        <button class="btn btn-primary cbtn" type="button" (click)="authService.loggedOut()"
                                            [disabled]="globals.onLoading">
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>