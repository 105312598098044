<ng-template #commentPost let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-share-header" class="row edit-post-header">
					<div class="col-xs-8">
						<h1 class="modal-title">Comment</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class=" col-xs-4">
						<a type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="closeModal('comment')"></a>
						<!-- (click)="modal.close('Close click')" -->
					</div>
				</div>
				<div class="person-info-form ">
					<form name="share_post" class="comment-form grp-share-modal">
						<div class="row">
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div class="form-group row">
									<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
										<div class="share-input">
											<input class="comment-text " name="share" placeholder="Write your comment..."
												[(ngModel)]="post.comment_describe" id="cmt_{{post.post_id}}" style="padding-right: 45px;"
												(keydown)="createTag($event, selectedIndex, 'cmt_' + post.post_id)" />
											<img src="./assets/img/face.png" class="face__emoji modal-input__emoji"
												(click)="emojiService.openEmojiPicker($event)">
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- </div> -->
						<div class="row">
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div class="form-group row">
									<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 comment-scroll"
										id="cm1_{{selectedIndex}}_{{post.post_id}}" (scroll)="loadMoreComments($event)">
										<ng-container *ngIf="post.totalComments">
											<div class="new-post__avatar comment-user" *ngFor="let com of post.commentPost; index as $index">
												<table>
													<tr>
														<td class="comment-image">
															<img src="{{com?.user_image}}" alt=""
																onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
																class="cmt-img cmt-user-img"
																routerLink="/userInfo/{{com.qudos_user_id}}/{{com.qudos_user_type}}">
														</td>
														<td>
															<p class="cmt-comm cmt-msg-modal" disabled>
																<span class="cmt-user-name word-Wrap"
																	routerLink="/userInfo/{{com.qudos_user_id}}/{{com.qudos_user_type}}">{{com.user_name}}</span><br>
																<span class="cmt-user-comment" [innerHTML]="com.comments"></span>
															</p>
														</td>
													</tr>
												</table>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div class="form-group row">
									<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
										<button class="btn btn-primary submit-cmt" type="button" (click)="comment(post)"
											[disabled]="globals.onLoading">
											Add Comment
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #deletePost let-modal>
	<div class="signout">
		<div class="row">
			<div class="popup-main-content">

				<div class="person-info-form">
					<form name="profile">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-2 col-1">
									</div>
									<div class="col-md-8 col-10">
										<p class="text-center confirm-name" *ngIf="is_post">Are you sure you want to delete this post ? </p>
										<p class="confirm-name" *ngIf="!is_post">Are you sure you want to delete this
											<span *ngIf="is_group">group</span>
											<span *ngIf="!is_group">page</span> ?
										</p>
									</div>
									<div class="col-md-2 col-1">
									</div>
								</div>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12 signup-button">
										<button class="btn btn-default cbtn" type="button" (click)="modal.close('Close click')">
											<span>No</span>
										</button>&nbsp;

										<button class="btn btn-primary cbtn" type="button" (click)="delete(postId)"
											[disabled]="globals.onLoading" *ngIf="is_post">
											<span>Yes </span>
										</button>
										<button class="btn btn-primary cbtn" type="button" *ngIf="!is_post && is_group"
											(click)="deleteGroup()" [disabled]="globals.onLoading">
											<span>Yes</span>
										</button>
										<button class="btn btn-primary cbtn" type="button" *ngIf="!is_post && !is_group"
											(click)="deletePage()" [disabled]="globals.onLoading">
											<span>Yes</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>

				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #editPost let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-post-header" class="row edit-post-header">
					<div class="col-xs-8 word-Wrap">
						<h1 class="modal-title">Edit Post</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class="col-xs-4">
						<a type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog" (click)="closeModal('post')"></a>
					</div>
				</div>
				<div class="person-info-form">
					<form name="edit-post" class="mobile_post_form edit_post_form">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="post-input">
											<div class="post-text">
												<label>Description</label>
												<div class="input-icon usr__icn">
													<i class="fa fa-user"></i>
												</div>
												<!-- <span>
                                                    <input placeholder="Share your ideas..." type="text"
                                                        [(ngModel)]="post.description" class="text-desc" id="description"
                                                        (keydown)="tagService.createTag($event,selectedIndex,true)" name="describe">
                                                </span>
                                                <img src="./assets/img/face.png" class="face__emoji describe_emoji"
                                                    (click)="emojiService.openEmojiPicker($event,true)"> -->
												<input class="text-desc" name="sharePostDescribe" id="description"
													placeholder="Share your ideas..." [(ngModel)]="post.description" required
													(click)="tagService.createTag($event)">
												<img src="./assets/img/face.png" class="face__emoji describe_emoji"
													(click)="emojiService.openEmojiPicker($event)">

											</div>

										</div>
										<div *ngIf="!post.shared_id"
											[hidden]="(post.post_type == 2 || post.post_type == 3||post.post_type == 4||post.post_type == 7)">
											<img src="{{post?.content_url}}" class="edit-img" *ngIf="post.content_type == 1">
											<vg-player *ngIf="post.content_type == 2 && post.content_url" class="video-edit">
												<video [vgMedia]="media" #media id="singleVideo" preload="auto">
													<source src="{{post?.content_url}}" type="video/mp4">
												</video>
											</vg-player>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<br>
										<div class="mobile-post-content" *ngIf="!post.shared_id"
											[hidden]="(post.post_type == 2 || post.post_type == 3||post.post_type == 4||post.post_type == 7)">
											<div class="new-post__file_Edit new--post">
												<label for="file">
													<span class="img-name pointer-cursor"><i
															class="fas fa-image"></i>&nbsp;Image</span>&nbsp;&nbsp;
												</label>
												<input id="file" name="file" type="file" accept="image/png,image/jpg,image/jpeg"
													(change)="fileName($event.target.files)">
											</div>
											<div class="new-post__file_Edit new--post">
												<label for="file2">
													<span class="img-name pointer-cursor"><i class="fas fa-video"></i>
														Video </span>&nbsp;&nbsp;
												</label>
												<input id="file2" name="file2" type="file" accept="video/mp4,video/mov"
													(change)="videoName($event.target.files)">
											</div>
										</div>
										<div id="fileName"></div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="btn btn-primary submit" type="button" (click)="editPostInfo(post,true)"
											[disabled]="globals.onLoading">
											Update
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>

				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #editPrivacy let-modal>
	<div>
		<div class="margin-bottom">
			<h3 class="edit-privacy-title"><i class="fal fa-globe-americas edit_privacy-icon-public"></i>Public</h3>
			<p class="edit_privacy">Anyone in QudosConnect can see your post
				<span class="radio">
					<input type="radio" [value]=0 name="privacy" [(ngModel)]="post.privacy_status">
				</span>
			</p>
		</div>
		<div class="margin-bottom">
			<h3 class="edit-privacy-title"><i class="fal fa-user-friends edit_privacy-icon"></i>Friends</h3>
			<p class="edit_privacy">Only your friends can see your post.</p>
			<span class="radio">
				<input type="radio" [value]=1 name="privacy" [(ngModel)]="post.privacy_status">
			</span>
		</div>
		<div class="margin-bottom">
			<h3 class="edit-privacy-title"><i class="fal fa-user edit_privacy-icon"></i>Only Me</h3>
			<p class="edit_privacy">Only you can see your post.</p>
			<span class="radio">
				<input type="radio" [value]=2 name="privacy" [(ngModel)]="post.privacy_status">
			</span>
		</div>
		<hr class="signout-line">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
					<div class="col-md-12 edit_privacy-button">
						<button class="btn btn-default" type="button" (click)="closeModal('privacy')">
							<span>Cancel</span>
						</button>&nbsp;
						<button class="btn btn-primary" type="button" (click)="editPrivacySetting(post)"
							[disabled]="globals.onLoading">
							<span>Apply</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #mobilePost let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-post-header" class="row">
					<div class="col-xs-8">
						<h1 class="modal-title">Post</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class="col-xs-4">
						<a type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></a>
					</div>
				</div>
				<div class="person-info-form">
					<form name="mobile-post" class="mobile_post_form post_mobile">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<br>
										<div class="mobile-post-content">
											<div class="new-post__file new--post">
												<label for="postfile">
													<span class="img-name"><i class="fas fa-image"></i>Image
													</span>&nbsp;&nbsp;
												</label>
												<input id="postfile" name="file" type="file" accept="image/png,image/jpg,image/jpeg"
													(change)="fileName($event.target.files)">
											</div>
											&nbsp;&nbsp;
											<div class="new-post__file new--post">
												<label for="postfile2">
													<span class="img-name"><i class="fas fa-video"></i>
														Video</span>&nbsp;&nbsp;
												</label>
												<input id="postfile2" name="file2" type="file" accept="video/mp4,video/mov"
													(change)="videoName($event.target.files)">
											</div>
										</div>
										<div id="fileName"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="post-input">
											<div class="post-text" for="file">
												<label>Description</label>
												<div class="input-icon usr__icn mbl_usr__icn">
													<i class="fa fa-user"></i>
												</div>
												<input placeholder="Hey, share something today ..." type="text" [(ngModel)]="description"
													autofocus class="text-desc" id="description" (keydown)="createTagService($event)"
													name="describe">
												<img src="./assets/img/face.png" class="face__emoji describe_emoji"
													(click)="emojiService.openEmojiPicker($event)">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="btn btn-primary submit" type="button" (click)="mobileNewPost()"
											[disabled]="globals.onLoading">
											Post
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #searchPeople let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-profile-header" class="row">
					<div class="col-xs-8 word-Wrap">

						<h1 class="modal-title">Find People</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class="col-xs-4">
						<a type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></a>
					</div>
				</div>
				<div class="person-info-form">
					<form name="searchPeople">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<label class="control-label">Looking for</label>
										<div class="row-input">
											<input type="text" class="form-control filter-input" name="vehicleMake"
												[(ngModel)]="searchKeyword" required placeholder="People,Group,etc">
										</div>
									</div>
									<div class="col-md-12">
										<label class="control-label">Country</label>
										<div class="row-input">
											<input type="text" class="form-control filter-input" name="country" [(ngModel)]="searchCountry"
												required placeholder="Enter Country">
										</div>
									</div>
									<div class="col-md-12">
										<label class="control-label">Zipcode</label>
										<div class="row-input">
											<input type="tel" class="form-control filter-input" name="zipcode" placeholder="Enter Zip Code"
												[(ngModel)]="searchZip" required>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="submit" type="button" (click)="applyFilter(1)" [disabled]="globals.onLoading">
											<span>
												Search
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #sharePost let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-share-header" class="row edit-post-header">
					<div class="col-xs-8">
						<h1 class="modal-title">Share</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class=" col-xs-4">
						<a type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></a>
					</div>
				</div>
				<div class="person-info-form">
					<form name="sharepost" novalidate class="share-form grp-share-modal">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="share-input">
											<input class="share-text" name="sharePostDescribe" id="description"
												placeholder="Share your ideas..." [(ngModel)]="describe" required
												(click)="tagService.createTag($event)">
											<img src="./assets/img/face.png" class="face__emoji modal-input__emoji"
												(click)="emojiService.openEmojiPicker($event)">
										</div>
										<!-- <span *ngIf="sharepost.sharePostDescribe.$error.required && sharepost.sharePostDescribe.$touched" class="errorMessage">Description is required</span> -->
									</div>
								</div>
							</div>
						</div>
						<!-- </div> -->
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="share-post-content" name="content">
											<p [innerHTML]="share_post_description"></p>
										</div>
										<div class="share-post-content-url">
											<img src="{{post?.content_url}}" class="post-img" *ngIf="post.content_type == 1">
											<vg-player *ngIf="post.content_type == 2 && post.content_url" class="video-edit">
												<video [vgMedia]="media" #media id="singleVideo" preload="auto">
													<source src="{{post?.content_url}}" type="video/mp4">
												</video>
											</vg-player>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="btn btn-primary submit" type="button" (click)="sharePostInfo()"
											[disabled]="globals.onLoading">
											Post
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #viewLikesModal let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content view_likes">
				<div id="edit-share-header" class="row edit-post-header header_likes">
					<div class="col-xs-8">
						<div class="like-up likes-modal-header">
							<i class="fa fa-thumbs-up dot-thumb"></i>
							<span class="like-post">
								<strong>Likes</strong>
								<span class="like_count">{{(totalUsers)}}</span>
							</span>
						</div>
					</div>
					<div class=" col-xs-4">
						<a type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></a>

					</div>
				</div>
				<div class="person-info-form" id="likesScroll" (scroll)="likesCount()">
					<ul class="list-group list-likes">
						<li class="list-group-item" *ngFor="let data of users">
							<a>
								<span class="list-left pointer-cursor">
									<img src="{{data?.user_image}}" alt="user-image" class="rounded-circle" width="32px" height="32px"
										onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';">
									<p (click)="viewUser(data.qudos_user_id,data.qudos_user_type)">{{data.user_name}}
									</p>
								</span>
							</a>
							<!-- <span class="list-right">
                           <button class="btn btn-add">Add Friend</button>
                           </span> -->
						</li>
						<!-- <button class="results__more" type="button" >Load
                        More</button> -->
					</ul>
				</div>
			</div>
		</div>
	</div>
</ng-template>