import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  image = '';
  content_type;
  posts = [];
  offset = this.posts.length;
  is_post = false;
  searchFlag = 0;

  constructor() { }

  ngOnInit() {
  }

}
