import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.css']
})
export class GroupInfoComponent implements OnInit,OnDestroy {

  image = '';
  content_type;
  posts = [];
  offset = 0;
  groupId: any;
  routeSubscribe: any;

  constructor(private router: ActivatedRoute) {
    this.routeSubscribe = this.router.params.subscribe(params => {
      this.groupId = parseInt(params.groupId);
    })
   }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.routeSubscribe.unsubscribe();
  }
}
