<div class="page home-page">

	<div class="row h-100vh hmpost" *ngIf="globals.pageLoad">
		<div class="col-12 col-lg-3 col-xl-2 page_sidebar">
			<div class="timeline-item mt-5">
				<div class="text-center ">
					<div class="dummy_image d-block img-circle mb-4"></div>
				</div>
				<ul>
					<li class="dummy_content w-60"> </li>
					<li class="dummy_content w-60"> </li>
					<br>
					<li class="dummy_content w-60"> </li>
					<li class="dummy_content w-70"> </li>
					<br>
					<li class="dummy_content w-60"> </li>
					<li class="dummy_content w-70"> </li>
					<br>
					<li class="dummy_content w-60"> </li>
					<li class="dummy_content w-70"> </li>
				</ul>
			</div>
		</div>
		<div class="col-12 col-lg-6 col-xl-8 page_content mx-auto lg-full">
			<div class="timeline-item centered mb-4 mbl-mt-10 ">
				<div class="dummy_content"> </div>
			</div>
			<div class="timeline-item centered ">
				<ul>
					<div class="dummy_content"> </div>
					<div class="dummy_content"> </div>
					<li>
						<div class="dummyblock_main">
							<div class="dummy_post_img"></div>
							<div class="dummy_content"> </div>
							<div class="dummy_content w-90"> </div>
						</div>
					</li>
					<li>
						<div class="dummyblock_main">
							<div class="dummy_post_img"></div>
							<div class="dummy_content"> </div>
							<div class="dummy_content w-90"> </div>
						</div>
					</li>
					<li>
						<div class="dummyblock_main">
							<div class="dummy_post_img"></div>
							<div class="dummy_content"> </div>
							<div class="dummy_content w-90"> </div>
						</div>
					</li>
					<li>
						<div class="dummyblock_main">
							<div class="dummy_post_img"></div>
							<div class="dummy_content "> </div>
							<div class="dummy_content w-70 ml-0"> </div>
						</div>
					</li>
					<li>
						<div class="dummyblock_main">
							<div class="dummy_post_img"></div>
							<div class="dummy_content"> </div>
							<div class="dummy_content w-90"> </div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-12 col-lg-3 col-xl-2 page_sidebar">
			<div class="timeline-item ">
				<ul class="w-100">
					<li>
						<div class="dummy_content w-100 mb-5"> </div>
						<div class="dummy_content w-100"> </div>
						<div class="dummy_content dummy_input "> </div>
					</li>
					<li>
						<div class="dummy_content w-100"> </div>
						<div class="dummy_content dummy_input "> </div>
					</li>
					<li>
						<div class="dummy_content w-100"> </div>
						<div class="dummy_content dummy_input "> </div>
					</li>
					<li>
						<div class="dummy_content dummy_input "> </div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="row hmpost">
		<div class="col-12 col-xl-2 page_sidebar" *ngIf="!globals.pageLoad">
			<div class="summary">
				<div class="summary__avatar">
					<img src="{{globals.user?.user_image}}" alt=""
						onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';">
				</div>
				<div data-toggle="tooltip" data-placement="top" title="{{globals.user.user_name}}" class="summary__title"
					ngDefaultControl [(ngModel)]="globals.user.user_name">
					{{globals.user.user_name}}</div>
				<a routerLink="/profile" class="summary__profile">My Profile</a>
				<div class="summary__stat">
					<span ngDefaultControl [(ngModel)]="globals.user.total_followers">{{globals.user.total_followers}}</span>
					<p>Followers</p>
				</div>
				<div class="summary__stat">
					<span ngDefaultControl [(ngModel)]="globals.user.total_posts">{{globals.user.total_posts}}</span>
					<p>Posts</p>
				</div>
				<div class="summary__stat">
					<span ngDefaultControl [(ngModel)]="globals.user.total_likes">{{globals.user.total_likes}}</span>
					<p>Likes</p>
				</div>
			</div>
		</div>
		<div class="col-12 col-xl-8 page_content mx-auto lg-full">
			<post-preview></post-preview>
		</div>
		<div class="col-12 col-xl-2 page_sidebar" *ngIf="!globals.pageLoad">
			<div class="search-box">
				<div class="search-box__title">Find People</div>
				<div class="search-box__group">
					<label for="">Looking for</label>
					<input type="text" placeholder="People, groups, etc " ngDefaultControl [(ngModel)]="searchKeyword">
				</div>
				<div class="search-box__group">
					<label for="">Country</label>
					<input type="text" placeholder="Country" id="country" ngDefaultControl [(ngModel)]="searchCountry">
				</div>
				<div class="search-box__group">
					<label for="">Zip Code</label>
					<input type="text" placeholder="Enter Zip Code" ngDefaultControl [(ngModel)]="searchZip">
				</div>
				<button class="search-box__btn" type="button" (click)="fetchPost()" [hidden]="searchBar"
					[disabled]="globals.pageLoad">Search</button>
				<button class="search-box__btn" type="button" (click)="clear()" *ngIf="searchBar"
					[disabled]="globals.pageLoad">Clear</button>
			</div>
			<div class="search-box__group__sidebar">
				<div>
					<a routerLink="/terms" class="footer_link__sidebar">Terms</a>
					<a routerLink="/privacy" class="footer_link__sidebar">Privacy</a>
					<a routerLink="/posting" class="footer_link__sidebar">Posting Policy</a>
				</div>
				<div>
					<span class="footer_copyright__sidebar">© Qudos Technologies, Inc</span>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="globals.onLoading" class="whirl"></div>

	<app-instant-message></app-instant-message>
</div>