import { Component, OnInit, OnDestroy } from "@angular/core";
import * as $ from "jquery";
import { SocketService } from "./services/socket/socket.service";
import { Router, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { Globals } from "src/app/helpers/constant";
import { ToastrService } from "ngx-toastr";
import { UserService } from "./services/ViewServices/userServices/user.service";
import { filter } from "rxjs/operators";
import { ProfileService } from "./services/ViewServices/profileService/profile.service";
import { environment } from "src/environments/environment";

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  socketAuthCount: number;
  post_link: RegExpMatchArray;
  group_link: RegExpMatchArray;
  driver_link: RegExpMatchArray;
  rider_link: RegExpMatchArray;
  page_link: RegExpMatchArray;
  profileInfoRequest: {};
  title = "QudosConnect";
  $: any;
  socket_params: {};
  private history = [];
  notifySound: HTMLAudioElement;
  clearCounter;
  mySubscription: any;
  noAuthentication: string[];

  constructor(
    private socketService: SocketService,
    private router: Router,
    private location: Location,
    public globals: Globals,
    private toaster: ToastrService,
    private userHelper: UserService,
    private profileHelper: ProfileService
  ) {
    // Initialize Firebase
    firebase.initializeApp(environment.FIREBASE_CONFIG);
    firebase.analytics();

    //To check if emoji is added to input fields
    let parentScope = this;
    $(document).on("input", function (e) {
      if (e.target["tagName"] == "INPUT" && e.target["type"] == "text") {
        let str = e.originalEvent["data"];
        if (str == null) {
          parentScope.globals.invalidInput = false;
          return false;
        }
        if (navigator.userAgent.indexOf("Firefox") != -1) {
          let strMatch = str
            ? str.match(/^[a-zA-Z\d\`!@#$%\^&*()={}:;<>+'-\s]+$/g)
            : "";
          if (!(strMatch && strMatch.length)) {
            parentScope.globals.invalidInput = false;
            return false;
          }
        }
        let strMatch = str ? str.match(/^[a-zA-Z\d\@._-\s]+$/g): "";
        // str.match(/^[a-zA-Z\d\-_!@#$%.\s]+$/g) : "";
        if (!(strMatch && strMatch.length)) {
          e.target["value"] = e.target["value"].replace(str, "");
          parentScope.toaster.info(
            "Only alphanumeric,@,-,_,.,space is allowed",
            "INFORMATION"
          );
          parentScope.globals.invalidInput = true;
          parentScope.globals.invalidInputValue = str;
        } else {
          parentScope.globals.invalidInput = false;
        }
      }
    });

    //To save the last route path
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
        this.globals.oldPath =
          this.history[this.history.length - 2] != undefined
            ? this.history[this.history.length - 2]
            : this.history[this.history.length - 1];
      });

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //remove tooltip
        let isToolTipActive = $("body").children(".ui-tooltip");
        if (isToolTipActive) {
          isToolTipActive.addClass("hidden");
          isToolTipActive.remove();
        }

        //To update the side panel values automatically on page refresh
        if (localStorage.hasOwnProperty("auth")) {
          this.userHelper.getTaggedFriendsList();
          this.profileInfoRequest = {
            qudos_user_type: this.globals.user.qudos_user_type,
          };
          this.profileHelper
            .profileCountInfo(this.profileInfoRequest)
            .subscribe(
              (response) => {
                if (typeof response == "string") {
                  response = JSON.parse(response);
                }
                if (response.error) {
                  this.toaster.error(response.error, "ERROR");
                } else {
                  let userData = response.user_details;
                  let auth_data = JSON.parse(localStorage.getItem("auth"));
                  auth_data.total_likes = userData.total_likes;
                  auth_data.total_posts = userData.total_posts;
                  auth_data.total_followers = userData.total_followers;
                  auth_data.total_friends = userData.total_friends;
                  auth_data.total_comments = userData.total_comments;
                  localStorage.setItem("auth", JSON.stringify(auth_data));
                  this.globals.user =
                    localStorage.getItem("auth") == "undefined" ||
                    !localStorage.getItem("auth")
                      ? ""
                      : JSON.parse(localStorage.getItem("auth"));
                }
              },
              (err) => {
                this.toaster.error(err, "ERROR");
                this.globals.onLoading = false;
              }
            );
        }
        firebase
          .analytics()
          .logEvent("pageview", { Page: event.urlAfterRedirects });

        $("#body").removeClass("custom-backdrop");
        this.globals.description = "";
        this.globals.is_auth = localStorage.hasOwnProperty("auth");
        this.post_link = location
          .path()
          .match(/[/]post[/][0-9]{0,9}[/][0-9][/][0-9]{0,9}[/][0-9]+/g);
        this.group_link = location.path().match(/[/]group[/][0-9]+/g);
        this.driver_link = location.path().match(/[/]driver[/][0-9]+/g);
        this.rider_link = location.path().match(/[/]rider[/][0-9]+/g);
        this.page_link = location.path().match(/[/]page[/][0-9]+/g);
        let locationPath = location.path();
        this.noAuthentication = ["/terms", "/privacy", "/posting", "/cookies"];

        if (location.path() !== "/login" && location.path() !== "/signup") {
          if (!localStorage.hasOwnProperty("auth")) {
            if (this.post_link && this.post_link.length) {
              let postId = location.path().split("/")[2];
              this.globals.postCopylink =
                environment.LINK + "postInfo/" + postId;
            } else if (this.group_link && this.group_link.length) {
              let groupId = location.path().split("/")[2];
              this.globals.postCopylink =
                environment.LINK + "groupInfo/" + groupId;
            } else if (this.driver_link && this.driver_link.length) {
              let userId = location.path().split("/")[2];
              this.globals.postCopylink =
                environment.LINK + "userInfo/" + userId + "/1";
            } else if (this.rider_link && this.rider_link.length) {
              let userId = location.path().split("/")[2];
              this.globals.postCopylink =
                environment.LINK + "userInfo/" + userId + "/0";
            } else if (this.page_link && this.page_link.length) {
              let pageId = location.path().split("/")[2];
              this.globals.postCopylink =
                environment.LINK + "pageInfo/" + pageId;
            } else if (!this.noAuthentication.includes(locationPath)) {
              this.router.navigate(["/login"]);
            }
          }
        } else {
          if (localStorage.hasOwnProperty("auth")) {
            this.router.navigate(["/home"]);
          }
        }
        if (this.socketAuthCount == 0 && localStorage.hasOwnProperty("auth")) {
          this.socketAuthCount = 1;
          this.socket_params = {
            access_token: this.globals.user.web_access_token,
            user_type: this.globals.user.qudos_user_type ? "driver" : "user",
          };
          this.socketService.init();
          this.socketService.connect().subscribe((data) => {
            this.globals.reconnectCount += 1;
            this.socketService.auth(this.socket_params).subscribe((data) => {
              if (data.flag) {
                this.socketService.fetchNotification().subscribe((data) => {
                  if (data.flag) {
                    this.globals.notificationCounter = data.data.count;

                    clearTimeout(this.clearCounter);
                    this.clearCounter = setTimeout(() => {
                      this.socketService.fetchNotification();
                    }, 15000);
                  } else {
                    this.socketService.fetchNotification();
                  }
                });
                this.globals.chatLoad = true;
                this.socketService.fetchUnreadCount().subscribe((data) => {
                  if (data.flag) {
                    this.globals.unread_count = data.data.count;
                  } else {
                    this.socketService.fetchUnreadCount();
                  }
                });
              } else {
                this.socketService.auth(this.socket_params);
              }
            });
          });
        }
        // this.ngOnDestroy();
      }
    });
  }

  ngOnInit() {
    this.socketAuthCount = 0;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.globals.mobileView = true;
    } else {
      this.globals.mobileView = false;
    }
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
