<!-------------------------------------------POSTS MORE OPTIONS MOBILE VIEW  -------------------------------------------------->
<div class="cbp-spmenu-push" [hidden]="!globals.mobileView && !postType && postCopyLinkPage">
    <nav class="cbp-spmenu cbp-spmenu-horizontal cbp-spmenu-bottom" id="cbp-spmenu-s4">
        <div>
            <button type="button" class="fa fa-times pointer-cursor spmenu-button" (click)="serviceFunction.closeOptions(1)"></button>
        </div>
        <ul class="spmenu-options">
            <li class="mob-view">
                <span (click)="socialMediaShare(serviceFunction.optionMenu.id, 1, 1)"><i class="fab fa-facebook"></i>Share to Facebook</span
        >
      </li>
      <li class="mob-view">
        <span (click)="socialMediaShare(serviceFunction.optionMenu.id, 1, 2)"
          ><i class="fab fa-linkedin"></i>Share to LinkedIn</span
        >
      </li>
      <li class="mob-view">
        <span
          *ngIf="
            serviceFunction.optionMenu.menu_option &&
            serviceFunction.optionMenu.editPost
          "
          (click)="
            editPost(
              serviceFunction.optionMenu.post,
              serviceFunction.optionMenu.index
            )
          "
          ><i class="fal fa-pen"></i>Edit Post</span
        >
      </li>
      <li class="mob-view">
        <span
          *ngIf="
            serviceFunction.optionMenu.menu_option && (mainPage || profilePage)
          "
          (click)="
            editPrivacy(
              serviceFunction.optionMenu.post,
              serviceFunction.optionMenu.index,
              0
            )
          "
          ><i class="fal fa-globe-americas"></i>Edit Privacy</span
        >
      </li>
      <li class="mob-view">
        <span
          (click)="
            deletePost(
              serviceFunction.optionMenu.id,
              serviceFunction.optionMenu.index
            )
          "
          *ngIf="serviceFunction.optionMenu.menu_option"
          ><i class="fal fa-trash-alt"></i>Delete</span
        >
      </li>
      <li class="mob-view">
        <span (click)="linkCopy(serviceFunction.optionMenu.id, 1)"
          ><i class="fal fa-link"></i>Copy Link</span
        >
      </li>
    </ul>
  </nav>
</div>
<!------------------------------------------- PERSON MORE OPTIONS MOBILE VIEW  -------------------------------------------------->
<div class="cbp-spmenu-push" *ngIf="globals.mobileView">
  <nav class="cbp-spmenu cbp-spmenu-horizontal cbp-spmenu-bottom" id="cbp-spmenu-s6">
    <div>
      <button type="button" class="fa fa-times pointer-cursor spmenu-button" (click)="serviceFunction.closeOptions(2)" ></button>
    </div>
    <ul class="spmenu-options">
      <li class="mob-view">
        <span
          (click)="socialMediaShare(serviceFunction.personOptionMenu.id, 2, 1)"
          ><i class="fab fa-facebook"></i>Share to Facebook</span
        >
      </li>
      <li class="mob-view">
        <span
          (click)="socialMediaShare(serviceFunction.personOptionMenu.id, 2, 2)"
          ><i class="fab fa-linkedin"></i>Share to LinkedIn</span
        >
      </li>
      <li class="mob-view">
        <span (click)="linkCopy(serviceFunction.personOptionMenu.id, 2)"
          ><i class="fal fa-link"></i>Copy Link</span
        >
      </li>
    </ul>
  </nav>
</div>
<!------------------------------------------- GROUP MORE OPTIONS MOBILE VIEW  -------------------------------------------------->
<div class="cbp-spmenu-push" *ngIf="globals.mobileView">
  <nav class="cbp-spmenu cbp-spmenu-horizontal cbp-spmenu-bottom" id="cbp-spmenu-s7">
    <div>
      <button type="button" class="fa fa-times pointer-cursor spmenu-button" (click)="serviceFunction.closeOptions(3)"></button>
    </div>
    <ul class="spmenu-options">
      <li class="mob-view">
        <span
          (click)="socialMediaShare(serviceFunction.groupOptionMenu.id, 3, 1)"
          ><i class="fab fa-facebook"></i>Share to Facebook</span
        >
      </li>
      <li class="mob-view">
        <span
          (click)="socialMediaShare(serviceFunction.groupOptionMenu.id, 3, 2)"
          ><i class="fab fa-linkedin"></i>Share to LinkedIn</span
        >
      </li>
      <li class="mob-view">
        <span
          routerLink="/groupProfile/{{ groupId }}"
          *ngIf="is_admin"
          (click)="serviceFunction.closeOptions(3)"
          ><i class="fal fa-id-card"></i>Group Profile</span
        >
      </li>
      <li class="mob-view">
        <span (click)="shareGroupModal(serviceFunction.groupOptionMenu.group)"
          ><i class="fal fa-share-square"></i>Share Group</span
        >
      </li>
      <li class="mob-view">
        <span (click)="deleteGroupModal()" *ngIf="is_admin"
          ><i class="fal fa-trash-alt"></i>Delete
        </span>
            </li>
            <li class="mob-view">
                <span (click)="linkCopy(serviceFunction.groupOptionMenu.id, 3)"><i class="fal fa-link"></i>Copy Link</span
        >
      </li>
    </ul>
  </nav>
</div>
<!------------------------------------------- PAGE MORE OPTIONS MOBILE VIEW  -------------------------------------------------->
<div class="cbp-spmenu-push" *ngIf="globals.mobileView">
  <nav
    class="cbp-spmenu cbp-spmenu-horizontal cbp-spmenu-bottom"
    id="cbp-spmenu-s8"
  >
    <div>
      <button type="button" class="fa fa-times pointer-cursor spmenu-button" (click)="serviceFunction.closeOptions(4)"></button>
    </div>
    <ul class="spmenu-options">
      <li class="mob-view">
        <span
          (click)="socialMediaShare(serviceFunction.pageOptionMenu.id, 4, 1)"
          ><i class="fab fa-facebook"></i>Share to Facebook</span
        >
      </li>
      <li class="mob-view">
        <span
          (click)="socialMediaShare(serviceFunction.pageOptionMenu.id, 4, 2)"
          ><i class="fab fa-linkedin"></i>Share to LinkedIn</span
        >
      </li>
      <li class="mob-view">
        <span (click)="sharePageModal(serviceFunction.pageOptionMenu.page)"
          ><i class="fal fa-share-square"></i>Share Page</span
        >
      </li>
      <li class="mob-view">
        <span (click)="deletePageModal()" *ngIf="is_admin"
          ><i class="fal fa-trash-alt"></i>Delete</span
        >
      </li>
      <li class="mob-view">
        <span (click)="linkCopy(serviceFunction.pageOptionMenu.id, 4)"
          ><i class="fal fa-link"></i>Copy Link</span
        >
      </li>
    </ul>
  </nav>
</div>

<ng-container *ngIf="!globals.pageLoad">
  <div
    class="new-post crt_post hmpost"
    *ngIf="
      (!is_deleted && groupPage) ||
      mainPage ||
      (!is_deleted && pageView && is_admin)
    "
  >
    <div class="new-post__wrap" id="mobile-new-post__wrap">
      <div class="new-post__avatar">
        <img
          src="{{ globals.user?.user_image }}"
          alt=""
          onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
        />
      </div>
      <input type="text" class="new-post__input" placeholder="Want to share something?" *ngIf="!globals.mobileView" [(ngModel)]="description"  id="description" (click)="tagService.createTag($event)" />
      <img src="./assets/img/face.png" class="face__emoji" (click)="emojiService.openEmojiPicker($event)" *ngIf="!globals.mobileView"/>
      <input type="button" class="new-post__input post-button" value="Want to share something?" (click)="launchMobilePost(0)" *ngIf="globals.mobileView"/>
      <button class="new-post__send" type="button" (click)="newPostInfo()" [disabled]="globals.onLoading">
        <i class="fa fa-paper-plane"></i>
        <span>Post</span>
                </button>
                <span class="new-post__send" *ngIf="globals.mobileView && mainPage">
        <i
          class="fas fa-search"
          *ngIf="!searchBar"
          (click)="filterPeople()"
        ></i>
        <i class="fas fa-times-circle" *ngIf="searchBar" (click)="clear()"></i>
      </span>
</div>
<div class="new-post__actions">
    <div class="new-post__file">
        <label for="file">
          <span><i class="fas fa-image"></i>Image</span>&nbsp;&nbsp;
          <span id="imageName"></span>
        </label>
        <input id="file" name="file" type="file" accept="image/png,image/jpg,image/jpeg" (change)="fileName($event.target.files)" />
    </div>
    <div class="new-post__file">
        <label for="file2">
          <span><i class="fas fa-video"></i> Video</span>&nbsp;&nbsp;
          <span id="videoName"></span>
        </label>
        <input id="file2" name="file2" type="file" accept="video/mp4,video/mov" (change)="videoName($event.target.files)" />
    </div>
    <div class="new-post__file flex-grow-1 text-right" *ngIf="createNewPost">
        <button class="cancel-post__send btn btn-danger" (click)="clear()">
          <i class="fas fa-times"></i>
          <span>Cancel</span>
        </button>
    </div>
    <div *ngIf="globals.onLoading && newPost" class="whirl-load"></div>
</div>
</div>

<div class="panel post-info ng-scope" *ngFor="let post of posts; index as $index" [hidden]="
      (is_deleted && groupPage) ||
      (post.post_deleted && (postInfoPage || postCopyLinkPage)) ||
      (!viewPost &&
        !post.post_deleted &&
        (postInfoPage || postCopyLinkPage) &&
        !posts.length) ||
      (pageView && !pageData.is_like && pageData.page_type) ||
      (pageView && is_deleted)
    " [ngClass]="{ 'black-theme': postType && postCopyLinkPage }">
    <div class="new-post__wrap" [ngClass]="{ 'black-theme': postType && postCopyLinkPage }">
        <div class="new-post__avatar">
            <img src="{{ post?.user_image }}" alt="" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" routerLink="/userInfo/{{ post.qudos_user_id }}/{{
            post.qudos_user_type
          }}" class="pointer-cursor" [class.disabled]="!postType && postCopyLinkPage" />
        </div>
        <div class="header-info">
            <div class="author-name ng-binding pointer-cursor" *ngIf="!groupPage">
                <span ngDefaultControl data-toggle="tooltip" data-placement="top" title="{{ post.user_name }}" [(ngModel)]="post.user_name" class="post-user-name" routerLink="/userInfo/{{ post.qudos_user_id }}/{{
              post.qudos_user_type
            }}" [class.disabled]="!postType && postCopyLinkPage">{{ post.user_name }}
            <span *ngIf="post.qudos_user_type === '1'" class="userType"
              >Driver
            </span>
                <span *ngIf="post.qudos_user_type === '0'" class="userType">Rider
            </span>
                </span>
                <span *ngIf="post.group_id || (post.page_id && !pageView)" class="group-share">
            <i
              class="fas fa-share-alt-square group-share_icon"
              [hidden]="post.post_type == 6 || post.post_type == 8"
            ></i
          ></span>

                <span data-toggle="tooltip" data-placement="top" title="{{ post.group_title }}" *ngIf="post.group_id && post.post_type != 6" class="group-share__icon gp_icon1" routerLink="/groupInfo/{{ post.group_id }}" [class.disabled]="!postType && postCopyLinkPage">
            {{ post.group_title }}</span
          >

          <span
            data-toggle="tooltip"
            data-placement="top"
            title="{{ post.page_title }}"
            *ngIf="post.page_id && post.post_type != 8 && !pageView"
            class="group-share__icon gp_icon1"
            routerLink="/pageInfo/{{ post.page_id }}"
            [class.disabled]="!postType && postCopyLinkPage"
          >
            {{ post.page_title }}</span
          >

          <span
            *ngIf="post.post_type == 5"
            class="pic-share-icon profile_updated"
          >
            profile has been updated.</span
          >

          <span
            *ngIf="post.post_type == 6 || (post.post_type == 8 && !pageView)"
            class="pic-share-icon span_updated"
            >updated
          </span>

                <span *ngIf="post.post_type == 6" class="pic-share-icon post-group_title" routerLink="/groupInfo/{{ post.group_id }}" [class.disabled]="!postType && postCopyLinkPage">{{ post.group_title }}</span
          >

          <span
            *ngIf="post.post_type == 8 && !pageView"
            class="pic-share-icon post-group_title"
            routerLink="/pageInfo/{{ post.page_id }}"
            [class.disabled]="!postType && postCopyLinkPage"
            >{{ post.page_title }}</span
          >

          <span
            *ngIf="post.post_type == 6 || (post.post_type == 8 && !pageView)"
            class="pic-share-icon users_pc"
            >'s pic.</span
          >
        </div>
        <div class="author-name ng-binding pointer-cursor" *ngIf="groupPage">
          <span
            ngDefaultControl
            data-toggle="tooltip"
            data-placement="top"
            title="{{ post.user_name }}"
            [(ngModel)]="post.user_name"
            class="author-name-spm"
            routerLink="/userInfo/{{ post.qudos_user_id }}/{{
              post.qudos_user_type
            }}"
          >
            {{ post.user_name }}
            <span *ngIf="post.qudos_user_type === '1'" class="userType"
              >Driver
            </span>
                <span *ngIf="post.qudos_user_type === '0'" class="userType">Rider
            </span>
                </span>
            </div>
            <div class="gray-label ng-binding date_postd" ngDefaultControl [(ngModel)]="post.date_added">
                {{ post.date_added | date: "MMM d, y h:mm a" }}
                <span (click)="
              !(
                globals.user.user_id == post.qudos_user_id &&
                globals.user.qudos_user_type == post.qudos_user_type
              ) || editPrivacy(post, $index, 1)
            " *ngIf="mainPage || profilePage">
            <span *ngIf="post.privacy_status == 0" class="privacy-icon"
              ><i class="fas fa-globe-americas"></i
            ></span>
                <span *ngIf="post.privacy_status == 1" class="privacy-icon"><i class="fas fa-user-friends"></i
            ></span>
                <span *ngIf="post.privacy_status == 2" class="privacy-icon"><i class="fas fa-lock"></i
            ></span>
                </span>
            </div>
        </div>
        <div class="more-options-post" [hidden]="!postType && postCopyLinkPage">
            <button id="userMenuButton" (click)="serviceFunction.moreOptions(post, $index, 1)">
          <i class="far fa-ellipsis-h fa-lg" *ngIf="globals.mobileView"></i>
        </button>
            <span class="dropdown" (click)="serviceFunction.moreOptionsDesktop(post, 1)" *ngIf="!globals.mobileView">
          <i
            class="far fa-ellipsis-h fa-lg menu-dropdown pointer-cursor"
            type="button"
          >
          </i>
          <ul
            class="dropdown-menu custom pointer-cursor"
            id="postoption_{{ post.post_id }}"
          >
            <li (click)="socialMediaShare(post.post_id, 1, 1)">
              <i class="fab fa-facebook"></i>Share to Facebook
            </li>
            <li (click)="socialMediaShare(post.post_id, 1, 2)">
              <i class="fab fa-linkedin"></i>Share to LinkedIn
            </li>
            <li
              *ngIf="
                globals.user.user_id == post.qudos_user_id &&
                globals.user.qudos_user_type == post.qudos_user_type &&
                post.post_type != 5 &&
                post.post_type != 6
              "
              (click)="editPost(post, $index)"
            >
              <i class="fal fa-pen"></i>Edit Post
            </li>
            <li
              *ngIf="
                globals.user.user_id == post.qudos_user_id &&
                globals.user.qudos_user_type == post.qudos_user_type &&
                (mainPage || profilePage)
              "
              (click)="editPrivacy(post, $index, 0)"
            >
              <i class="fal fa-globe-americas"></i>Edit Privacy
            </li>
            <li
              (click)="deletePost(post, $index)"
              *ngIf="
                globals.user.user_id == post.qudos_user_id &&
                globals.user.qudos_user_type == post.qudos_user_type
              "
            >
              <i class="fal fa-trash-alt"></i>Delete
            </li>
            <li (click)="linkCopy(post.post_id, 1)">
              <i class="fal fa-link"></i>Copy Link
            </li>
          </ul>
        </span>
        </div>
    </div>
    <div>
        <h4 class="post-action ng-binding break-word describe-content">
            <p [innerHTML]="emojiDecoding(post.description)"></p>
        </h4>
        <!---------------------------------------------------- Shared Post Details ----------------------------------------------------------->
        <div class="panel post-info ng-scope shared-border" *ngIf="
          (post.shared_post_type == 5 ||
            post.shared_post_type == 1 ||
            post.shared_post_type == 6 ||
            post.shared_post_type == 8) &&
          post.post_type == 1 &&
          post.is_deleted == 0
        " [ngClass]="{ 'black-theme': postType && postCopyLinkPage }">
            <div class="new-post__wrap">
                <div class="new-post__avatar">
                    <img src="{{ post?.shared_image }}" alt="" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" routerLink="/userInfo/{{ post.shared_by_id }}/{{
                post.shared_by_type
              }}" class="pointer-cursor" [class.disabled]="!postType && postCopyLinkPage" />
                </div>
                <div class="header-info">
                    <div class="author-name ng-binding pointer-cursor" *ngIf="!groupPage">
                        <span ngDefaultControl data-toggle="tooltip" data-placement="top" title="{{ post.shared_name }}" [(ngModel)]="post.shared_name" class="post-user-name" routerLink="/userInfo/{{ post.shared_by_id }}/{{
                  post.shared_by_type
                }}" [class.disabled]="!postType && postCopyLinkPage">{{ post.shared_name }}
                <span *ngIf="post.shared_by_type === '1'" class="userType"
                  >Driver
                </span>
                        <span *ngIf="post.shared_by_type === '0'" class="userType">Rider
                </span>
                        </span>
                        <span *ngIf="post.parent_group_id || post.parent_page_id" class="group-share">
                <i
                  class="fas fa-share-alt-square group-share_icon"
                  [hidden]="
                    post.shared_post_type == 6 || post.shared_post_type == 8
                  "
                ></i>
              </span>
                        <span *ngIf="post.shared_post_type == 5" class="pic-share-icon profile_updated">
                profile has been updated.</span
              >
              <span
                *ngIf="post.shared_post_type == 6 || post.shared_post_type == 8"
                class="pic-share-icon span_updated"
                >updated
              </span>
                        <span data-toggle="tooltip" data-placement="top" title="{{ post.parent_group_title }}" class="group-share__icon gp_icon post-group_title" *ngIf="
                  (post.shared_post_type == 6 || post.shared_post_type == 1) &&
                  post.parent_group_id
                " routerLink="/groupInfo/{{ post.parent_group_id }}" [class.disabled]="!postType && postCopyLinkPage">
                {{ post.parent_group_title }}</span
              >

              <span
                data-toggle="tooltip"
                data-placement="top"
                title="{{ post.parent_page_title }}"
                class="group-share__icon gp_icon post-group_title"
                *ngIf="
                  (post.shared_post_type == 8 || post.shared_post_type == 1) &&
                  post.parent_page_id
                "
                routerLink="/pageInfo/{{ post.parent_page_id }}"
                [class.disabled]="!postType && postCopyLinkPage"
                >{{ post.parent_page_title }}</span
              >
              <span
                *ngIf="post.shared_post_type == 6 || post.shared_post_type == 8"
                class="pic-share-icon users_pc"
                >'s pic.</span
              >
            </div>
            <div
              class="author-name ng-binding pointer-cursor"
              *ngIf="groupPage"
            >
              <span
                ngDefaultControl
                data-toggle="tooltip"
                data-placement="top"
                title="{{ post.shared_name }}"
                [(ngModel)]="post.shared_name"
                class="author-name-spm"
                routerLink="/userInfo/{{ post.shared_by_id }}/{{
                  post.shared_by_type
                }}"
                >{{ post.shared_name }}</span
              >
            </div>
            <div
              ngDefaultControl
              class="gray-label date_postd ng-binding"
              [(ngModel)]="post.shared_date"
              name="shared-date"
            >
              {{ post.shared_date | date: "MMM d, y h:mm a" }}
            </div>
          </div>
        </div>
        <div class="panel-body">
          <h4
            class="post-title ng-binding describe-content"
            [innerHTML]="post.shared_description"
          ></h4>
          <div
            class="post-img ng-binding"
            ngDefaultControl
            [(ngModel)]="post.content_url"
          >
            <img
              src="{{ post?.content_url }}"
              class="post-img pointer-cursor"
              *ngIf="post.content_type == 1 && !postType"
              routerLink="/post/{{ post.post_id }}/1/{{
                globals.user.user_id
              }}/{{ globals.user.qudos_user_type }}"
            />
            <img
              src="{{ post?.content_url }}"
              class="post-img"
              *ngIf="post.content_type == 1 && postType"
            />
            <vg-player *ngIf="post.content_type == 2">
              <vg-overlay-play></vg-overlay-play>
              <vg-buffering></vg-buffering>

              <vg-controls>
                <vg-play-pause></vg-play-pause>
                <vg-playback-button></vg-playback-button>

                <vg-track-selector></vg-track-selector>
                <vg-mute></vg-mute>
                <vg-volume></vg-volume>

                <vg-fullscreen></vg-fullscreen>
              </vg-controls>

              <video
                [vgMedia]="media"
                #media
                id="singleVideo"
                preload="auto"
                
              >
                <source src="{{ post?.content_url }}" type="video/mp4" />
              </video>
            </vg-player>
          </div>
        </div>
      </div>

      <!---------------------------------------------------- RE-share Shared  Details ---------------------------------------------------------->
      <div
        class="panel post-info ng-scope shared-border"
        *ngIf="
          (post.shared_post_type == 2 ||
            post.shared_post_type == 3 ||
            post.shared_post_type == 4 ||
            post.shared_post_type == 7) &&
          post.is_deleted == 0
        "
      >
        <div class="new-post__wrap">
          <div class="new-post__avatar">
            <img
              src="{{ post?.shared_image }}"
              alt=""
              onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
              routerLink="/userInfo/{{ post.shared_by_id }}/{{
                post.shared_by_type
              }}"
              class="pointer-cursor"
              [class.disabled]="!postType && postCopyLinkPage"
            />
          </div>
          <div class="header-info">
            <div
              ngDefaultControl
              data-toggle="tooltip"
              data-placement="top"
              title="{{ post.shared_name }}"
              class="author-name ng-binding pointer-cursor"
              [(ngModel)]="post.shared_name"
              routerLink="/userInfo/{{ post.shared_by_id }}/{{
                post.shared_by_type
              }}"
              [class.disabled]="!postType && postCopyLinkPage"
            >
              {{ post.shared_name }}
              <span *ngIf="post.shared_by_type === '1'" class="userType"
                >Driver
              </span>
                        <span *ngIf="post.shared_by_type === '0'" class="userType">Rider
              </span>
                    </div>
                    <div class="gray-label ng-binding" ngDefaultControl [(ngModel)]="post.shared_date">
                        {{ post.shared_date | date: "MMM d, y h:mm a" }}
                    </div>
                </div>
            </div>
            <div>
                <h4 class="post-action ng-binding break-word describe-content">
                    <p [innerHTML]="emojiDecoding(post.shared_description)"></p>
                </h4>
            </div>
            <div class="user share-user">
                <div class="user-info" *ngIf="post.shared_post_type == 2 || post.shared_post_type == 3">
                    <div class="user__img">
                        <img src="{{ post?.parent_user_image }}" alt="" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" class="share-img" />
                    </div>
                    <div class="user__meta share__meta">
                        <h4 data-toggle="tooltip" data-placement="top" title="{{ post.parent_user_name }}">
                            {{ post.parent_user_name }}
                        </h4>
                        <p>{{ post.parent_user_country }}</p>
                    </div>
                    <div>
                        <p class="shared_post_icon" *ngIf="post.shared_post_type == 2">
                            <span class="share_post_text"><i class="fas fa-heart"></i>
                  {{ post.parent_fav_count }}</span
                >
                <span class="share_post_text"
                  ><i class="fas fa-star share-icon"></i>
                  {{ post.parent_total_rating_driver }}</span
                >
              </p>
              <p class="shared_post_icon" *ngIf="post.shared_post_type == 3">
                <span class="share_post_text"
                  ><i class="fas fa-users"></i>
                  {{ post.parent_follower_count }}</span
                >
                <span class="share_post_text"
                  ><i class="fas fa-star share-icon"></i>
                  {{ post.parent_total_rating_rider }}</span
                >
              </p>
            </div>
          </div>

          <div class="user-info" *ngIf="post.shared_post_type == 4">
            <div class="user__img">
              <img
                src="{{ post?.shared_group_image }}"
                alt=""
                onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                class="share-img"
              />
            </div>
            <div class="user__meta share__meta">
              <h4
                data-toggle="tooltip"
                data-placement="top"
                title="{{ post.shared_group_name }}"
              >
                {{ post.shared_group_name }}
              </h4>
              <p>Members ({{ post.shared_total_members }})</p>
            </div>
            <p class="shared_post_icon" *ngIf="post.shared_post_type == 4">
              <span class="share_post_text"
                ><i class="fas fa-users"></i>
                {{ post.shared_total_group_followers }}</span
              >
            </p>
          </div>

          <div class="user-info" *ngIf="post.shared_post_type == 7">
            <div class="user__img">
              <img
                src="{{ post?.shared_page_image }}"
                alt=""
                onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                class="share-img"
              />
            </div>
            <div class="user__meta share__meta">
              <h4
                data-toggle="tooltip"
                data-placement="top"
                title="{{ post.shared_page_name }}"
              >
                {{ post.shared_page_name }}
              </h4>
              <p>{{ post.shared_page_description }}</p>
            </div>
            <p class="shared_post_icon" *ngIf="post.shared_post_type == 7">
              <span class="share_post_text"
                ><i class="fas fa-users"></i>
                {{ post.shared_total_page_followers }}</span
              >
              <span class="share_post_text"
                ><i class="fas fa-star share-icon"></i>
                {{ post.shared_total_page_likes }}</span
              >
            </p>
          </div>
          <br />
          <div>
            <p>
              <button
                type="button"
                class="button-share_post"
                routerLink="/userInfo/{{ post.parent_user_id }}/1"
                *ngIf="post.shared_post_type == 2"
                [disabled]="!postType && postCopyLinkPage"
              >
                <span
                  >Know More About
                  {{ post.parent_user_name.split(" ")[0] }}</span
                >
              </button>
            </p>
            <p>
              <button
                type="button"
                class="button-share_post"
                routerLink="/userInfo/{{ post.parent_user_id }}/0"
                *ngIf="post.shared_post_type == 3"
                [disabled]="!postType && postCopyLinkPage"
              >
                <span
                  >Know More About
                  {{ post.parent_user_name.split(" ")[0] }}</span
                >
              </button>
            </p>
            <p>
              <button
                type="button"
                class="button-share_post"
                routerLink="/groupInfo/{{ post.shared_group_id }}"
                *ngIf="post.shared_post_type == 4"
                [disabled]="!postType && postCopyLinkPage"
              >
                <span>Know More About {{ post.shared_group_name }}</span>
                            </button>
                        </p>
                        <p>
                            <button type="button" class="button-share_post" routerLink="/pageInfo/{{ post.shared_page_id }}" *ngIf="post.shared_post_type == 7" [disabled]="!postType && postCopyLinkPage">
                <span>Know More About {{ post.shared_page_name }}</span>
              </button>
                        </p>
                    </div>
                </div>
            </div>
            <!-------------------------------------- Shared Post Details - If Original post deleted-------------------------------------------------->
            <div class="panel post-info ng-scope shared-border share_post_grp" *ngIf="post.is_deleted == 1">
                <div class="new-post__wrap">
                    <div class="new-post__avatar">
                        <img src="{{ post?.shared_image }}" alt="" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" routerLink="/userInfo/{{ post.shared_by_id }}/{{
                post.shared_by_type
              }}" class="pointer-cursor" [class.disabled]="!postType && postCopyLinkPage" />
                    </div>
                    <div class="header-info">
                        <div data-toggle="tooltip" ngDefaultControl data-placement="top" title="{{ post.shared_name }}" class="author-name ng-binding pointer-cursor" [(ngModel)]="post.shared_name" routerLink="/userInfo/{{ post.shared_by_id }}/{{
                post.shared_by_type
              }}" [class.disabled]="!postType && postCopyLinkPage">
                            {{ post.shared_name }}
                        </div>
                        <div class="gray-label ng-binding" ngDefaultControl [(ngModel)]="post.shared_date">
                            {{ post.shared_date | date: "MMM d, y h:mm a" }}
                        </div>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="post-img ng-binding">
                        <p class="content-center">Content Not Available</p>
                    </div>
                </div>
            </div>

            <!---------------------------------------------------- Shared Driver Details ----------------------------------------------------------->
            <div class="panel post-info ng-scope share-border-user share_post_grp" *ngIf="post.post_type == 2">
                <div class="user share-user">
                    <div class="user-info">
                        <div class="user__img">
                            <img src="{{ post?.shared_user_image }}" alt="" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" class="share-img" />
                        </div>
                        <div class="user__meta share__meta">
                            <h4 data-toggle="tooltip" data-placement="top" title="{{ post.shared_user_name }}">
                                {{ post.shared_user_name }}
                            </h4>
                            <p>{{ post.shared_user_country }}</p>
                        </div>
                        <div>
                            <p class="shared_post_icon">
                                <span class="share_post_text"><i class="fas fa-heart"></i> {{ post.fav_count }}</span
                >
                <span class="share_post_text"
                  ><i class="fas fa-star share-icon"></i>
                  {{ post.total_rating_driver }}</span
                >
              </p>
            </div>
          </div>
          <br />
          <div>
            <button
              type="button"
              class="button-share_post"
              routerLink="/userInfo/{{ post.shared_user_id }}/1"
            >
              <span
                >Know More About {{ post.shared_user_name.split(" ")[0] }}</span
              >
            </button>
          </div>
        </div>
      </div>

      <!---------------------------------------------------- Shared Rider Details ----------------------------------------------------------->
      <div
        class="panel post-info ng-scope share-border-user share_post_grp"
        *ngIf="post.post_type == 3"
      >
        <div class="user share-user">
          <div class="user-info">
            <div class="user__img">
              <img
                src="{{ post?.shared_user_image }}"
                alt=""
                onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                class="share-img"
              />
            </div>
            <div class="user__meta share__meta">
              <h4
                data-toggle="tooltip"
                data-placement="top"
                title="{{ post.shared_user_name }}"
              >
                {{ post.shared_user_name }}
              </h4>
              <p>{{ post.shared_user_country }}</p>
            </div>
            <div>
              <p class="shared_post_icon">
                <span class="share_post_text">
                  <i class="fas fa-users"></i> {{ post.follower_count }}</span
                >
                <span class="share_post_text"
                  ><i class="fas fa-star share-icon"></i>
                  {{ post.total_rider_points }}</span
                >
              </p>
            </div>
          </div>
          <br />
          <div>
            <button
              type="button"
              class="button-share_post"
              routerLink="/userInfo/{{ post.shared_user_id }}/0"
              [disabled]="!postType && postCopyLinkPage"
            >
              <span
                >Know More About {{ post.shared_user_name.split(" ")[0] }}</span
              >
            </button>
          </div>
        </div>
      </div>

      <!---------------------------------------------------- Shared Group Details ----------------------------------------------------------->
      <div
        class="panel post-info ng-scope share-border-user share_post_grp"
        *ngIf="post.post_type == 4"
      >
        <div class="user share-user">
          <div class="user-info">
            <div class="user__img">
              <img
                src="{{ post?.group_image }}"
                alt=""
                onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                class="share-img"
              />
            </div>
            <div class="user__meta share__meta">
              <h4
                data-toggle="tooltip"
                data-placement="top"
                title="{{ post.group_name }}"
              >
                {{ post.group_name }}
              </h4>
              <p>Members ({{ post.total_members }})</p>
            </div>
            <div>
              <p class="shared_post_icon">
                <span class="share_post_text"
                  ><i class="fas fa-users"></i>
                  {{ post.total_group_followers }}</span
                >
              </p>
            </div>
          </div>
          <br />
          <div>
            <button
              type="button"
              class="button-share_post"
              routerLink="/groupInfo/{{ post.master_group_id }}"
              [disabled]="!postType && postCopyLinkPage"
            >
              <span>Know More About {{ post.group_name }}</span>
                                </button>
                        </div>
                    </div>
                </div>
                <!---------------------------------------------------- Shared Page Details ----------------------------------------------------------->
                <div class="panel post-info ng-scope share-border-user share_post_grp" *ngIf="post.post_type == 7">
                    <div class="user share-user">
                        <div class="user-info">
                            <div class="user__img">
                                <img src="{{ post?.page_image }}" alt="" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" class="share-img" />
                            </div>
                            <div class="user__meta share__meta">
                                <h4 data-toggle="tooltip" data-placement="top" title="{{ post.page_name }}">
                                    {{ post.page_name }}
                                </h4>
                                <p>{{ post.page_description }}</p>
                            </div>
                            <div>
                                <p class="shared_post_icon">
                                    <span class="share_post_text">
                  <i class="fas fa-users"></i>
                  {{ post.total_page_followers }}</span
                >
                <span class="share_post_text">
                  <i class="fas fa-thumbs-up share-icon"></i>
                  {{ post.total_page_likes }}</span
                >
              </p>
            </div>
          </div>
          <br />
          <div>
            <button
              type="button"
              class="button-share_post"
              routerLink="/pageInfo/{{ post.master_page_id }}"
              [disabled]="!postType && postCopyLinkPage"
            >
              <span>Know More About {{ post.page_name }}</span>
                                    </button>
                            </div>
                        </div>
                    </div>

                    <div ngDefaultControl class="post-img post_space ng-binding" [(ngModel)]="post.content_url" *ngIf="
          !post.shared_from &&
          (post.post_type == 1 ||
            post.post_type == 5 ||
            post.post_type == 6 ||
            post.post_type == 8)
        " [ngClass]="{ 'post-action': !globals.mobileView }">
                        <img src="{{ post?.content_url }}" class="post-img pointer-cursor" *ngIf="post.content_type == 1 && !postType" routerLink="/post/{{ post.post_id }}/1/{{ globals.user.user_id }}/{{
            globals.user.qudos_user_type
          }}" />
                        <img src="{{ post?.content_url }}" class="post-img" *ngIf="post.content_type == 1 && postType" />
                        <!-- <vg-player *ngIf="post.content_type == 2">
                    <video [vgMedia]="media" #media id="singleVideo1" preload="auto" controls>
                        <source src="{{post?.content_url}}" type="video/mp4">
                    </video>
                </vg-player> -->
                        <vg-player *ngIf="post.content_type == 2">
                            <vg-overlay-play></vg-overlay-play>
                            <vg-buffering></vg-buffering>

                            <vg-controls>
                                <vg-play-pause></vg-play-pause>
                                <vg-playback-button></vg-playback-button>

                                <vg-track-selector></vg-track-selector>
                                <vg-mute></vg-mute>
                                <vg-volume></vg-volume>

                                <vg-fullscreen></vg-fullscreen>
                            </vg-controls>

                            <video [vgMedia]="media" #media id="singleVideo" preload="auto">
            <source src="{{ post?.content_url }}" type="video/mp4" />
          </video>
                        </vg-player>
                    </div>
                </div>

                <div class="post-action likes-cmt_actions" [ngClass]="{ 'black-theme': postType && postCopyLinkPage }" [hidden]="!postType && postCopyLinkPage">
                    <div class="post-like">
                        <div class="like-up">
                            <i class="fa fa-thumbs-up dot-thumb" *ngIf="post.like_count_value"></i>
                            <span class="like-post" *ngIf="post.like_count_value == 1">
            <span class="like_post_value">{{ post.like_name }}</span>
                            </span>
                            <span class="like-post" *ngIf="
              post.like_count_value > 1 &&
              post.like_count_value <= 2 &&
              post.is_like
            ">
            <span class="like_post_value"
              >You and {{ post.like_count }}
              <span
                class="pointer-cursor"
                (click)="globals.onLoading || viewLikes(post)"
                >other</span
              ></span
            >
          </span>
                            <span class="like-post" *ngIf="
              post.like_count_value > 1 &&
              post.like_count_value <= 2 &&
              !post.is_like
            ">
            <span class="like_post_value"
              >{{ post.like_name }} and {{ post.like_count }}
              <span
                class="pointer-cursor"
                (click)="globals.onLoading || viewLikes(post)"
                >other</span
              ></span
            >
          </span>
                            <span class="like-post" *ngIf="post.like_count_value > 2 && post.is_like">
            <span class="like_post_value"
              >You and {{ post.like_count }}
              <span
                class="pointer-cursor"
                (click)="globals.onLoading || viewLikes(post)"
                >others</span
              ></span
            >
          </span>
                            <span class="like-post" *ngIf="post.like_count_value > 2 && !post.is_like">
            <span class="like_post_value"
              >{{ post.like_name }} and {{ post.like_count }}
              <span
                class="pointer-cursor"
                (click)="globals.onLoading || viewLikes(post)"
                >others</span
              ></span
            >
          </span>
                        </div>
                        <div class="post-comment pointer-cursor" *ngIf="post.total_comments">
                            <span (click)="globals.onLoading || commentPost(post, $index)" *ngIf="globals.mobileView">{{ post.total_comments }} comments</span
          >
          <span
            *ngIf="!globals.mobileView"
            (click)="globals.onLoading || getPostComments(post, $index, 0)"
            >{{ post.total_comments }} comments</span
          >
        </div>
      </div>
      <div class="gray-label ng-binding"></div>
    </div>

    <div
      class="panel-footer"
      [ngClass]="{ 'black-theme': postType && postCopyLinkPage }"
      [hidden]="!postType && postCopyLinkPage"
    >
      <div [ngClass]="{ 'black-theme': postType && postCopyLinkPage }">
        <button
          class="btn btn-default pull-left cmt-btn"
          [ngClass]="{
            like: post.is_like,
            'black-theme': postType && postCopyLinkPage
          }"
          (click)="likePost($index, post, post.is_like ? 0 : 1)"
          [disabled]="globals.onLoading"
        >
          <i class="fal fa-thumbs-up"></i>
          <span *ngIf="!post.is_like">Like</span>
                            <span *ngIf="post.is_like">Unlike</span>
                            </button>
                            <button class="btn btn-default cmt-btn" (click)="commentPost(post, $index)" [disabled]="globals.onLoading" [ngClass]="{ 'black-theme': postType && postCopyLinkPage }">
          <i class="fal fa-comment"></i>
          Comment
        </button>
                            <button class="btn btn-default pull-right cmt-btn" id="1" (click)="sharePost(post)" [disabled]="globals.onLoading" [ngClass]="{ 'black-theme': postType && postCopyLinkPage }">
          <i class="fal fa-share"></i>
          Share
        </button>
                        </div>
                        <div>
                            <div class="comment-scroll" *ngIf="post.totalComments" id="cm3_{{ $index }}_{{ post.post_id }}" (scroll)="loadMoreComments($event)">
                                <ng-container *ngIf="post.totalComments">
                                    <div class="new-post__avatar comment-user" *ngFor="let com of post.commentPost; index as $index">
                                        <table>
                                            <tr>
                                                <td class="comment-image">
                                                    <img src="{{ com?.user_image }}" alt="" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" class="cmt-img cmt-user-img pointer-cursor" routerLink="/userInfo/{{ com.qudos_user_id }}/{{
                        com.qudos_user_type
                      }}" />
                                                </td>
                                                <td>
                                                    <p class="cmt-comm" disabled>
                                                        <span class="cmt-user-name word-Wrap pointer-cursor" routerLink="/userInfo/{{ com.qudos_user_id }}/{{
                          com.qudos_user_type
                        }}">{{ com.user_name }}</span
                      ><br />
                      <span
                        class="cmt-user-comment"
                        [innerHTML]="emojiDecoding(com.comments)"
                      ></span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="new-post__avatar comment-user" [ngClass]="{ 'comment-user-fix': post.totalComments }">
                                <img src="{{ globals.user?.user_image }}" alt="" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" class="cmt-img" />
                                <input type="button" value="Write a comment..." class="cmt-txt" (click)="commentPost(post, $index)" *ngIf="globals.mobileView" [ngClass]="{ 'black-theme': postType && postCopyLinkPage }" />

                                <input type="text" placeholder="Write a comment..." class="cmt-txt-desktop commentEmoji" *ngIf="!globals.mobileView" [(ngModel)]="post.comment_describe" [ngClass]="{ 'black-theme': postType && postCopyLinkPage }" id="cmt_{{ post.post_id }}" (keypress)="
              callTAgService(
                $event,
                $index,
                post.comment_describe,
                'cmt_' + post.post_id
              )
            " (paste)="
              callTAgService(
                $event,
                $index,
                post.comment_describe,
                'cmt_' + post.post_id
              )
            " />
                                <img src="./assets/img/face.png" class="face__emoji comment_emoji" (click)="emojiService.openEmojiPicker($event, false, true)" *ngIf="!globals.mobileView" />
                                <button type="button" (click)="commentDesktop(post, $index)" *ngIf="!globals.mobileView" [disabled]="globals.onLoading">
            <i class="fal fa-paper-plane dot-comment"></i>
          </button>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngFor="let post of posts; index as $index">
                    <div class="panel post-info ng-scope" *ngIf="
        post.post_deleted && (postInfoPage || postCopyLinkPage) && posts.length
      ">
                        <div class="post-img ng-binding">
                            <p class="postcontent-center">Content Not Available</p>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngFor="let post of posts; index as $index">
                    <div class="panel post-info ng-scope" *ngIf="
        !viewPost &&
        !post.post_deleted &&
        (postInfoPage || postCopyLinkPage) &&
        posts.length &&
        !is_friend
      ">
                        <div class="post-img ng-binding">
                            <p class="postcontent-center">This is a private post</p>
                        </div>
                    </div>
                </ng-container>

                <div class="panel post-info ng-scope" *ngIf="pageView && !pageData.is_like && pageData.page_type">
                    <div class="post-img ng-binding">
                        <p class="postcontent-center">
                            This is a private page. Please like to view content
                        </p>
                    </div>
                </div>

                <div class="panel post-info ng-scope" *ngIf="
      !posts.length &&
      !globals.onLoading &&
      !groupProfilePage &&
      !(pageView && !pageData.is_like && pageData.page_type)
    ">
                    <div class="post-img ng-binding nothing_post" [ngClass]="{ 'black-theme': postType, 'border-post': postType }">
                        <p class="postcontent-center no_post_text">
                            Yay! This will be your first post, go ahead and make a lasting impression.
                        </p>
                        <img src="./assets/img/FirstPerson.svg" class="img-fluid" />
                        <p class="no_post_text text-center">No posts added yet.</p>
                    </div>
                </div>
                </ng-container>

                <app-post-template></app-post-template>
                <app-network-template></app-network-template>