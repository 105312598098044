<div class="blue-background"></div>

<div class="page page--profile">
    <div *ngIf="globals.pageLoad">
        <div class="h-100vh mt--1 container">
            <div class="d-flex">
                <div class="col-3s">
                    <div class="dummy_content h-200 img-rounded"> </div>
                </div>
                <div class="col-9s">
                    <div class="dummy_content w-60 mt-5 ml-0"> </div>
                    <div class="dummy_content w-60 ml-0"> </div>
                    <div class="dummy_content w-60 ml-0"> </div>
                    <div class="dummy_content w-60 ml-0"> </div>
                </div>
            </div>
            <div class="row">
                <div class="qudoslist_profile_container profile-container mr-auto">
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="row mar-lr-15">
                        <div class="col-12 col-md-6 ">
                            <div class="dummy_content h-90"> </div>
                        </div>
                        <div class="col-12 col-md-6 ">
                            <div class="dummy_content h-90"> </div>
                        </div>
                    </div>
                    <div class="dummy_content mt-4"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                    <div class="dummy_content mt-5"> </div>
                </div>
                <div class="col-12 col-lg-3 col-xl-2 page_sidebar">
                    <div class="timeline-item ">
                        <div class="text-center ">
                            <div class="dummy_image d-block img-circle mb-4"></div>
                        </div>
                        <ul>
                            <li class="dummy_content w-60"> </li>
                            <li class="dummy_content w-60"> </li>
                            <br>
                            <li class="dummy_content w-60"> </li>
                            <li class="dummy_content w-70"> </li>
                            <br>
                            <li class="dummy_content w-60"> </li>
                            <li class="dummy_content w-70"> </li>
                            <br>
                            <li class="dummy_content w-60"> </li>
                            <li class="dummy_content w-70"> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="qudoslist_profile_container profile-container mr-auto">
            <div class="ql-profile">
                <ng-container *ngIf="!globals.pageLoad">
                    <div class="profile-view">
                        <div class="row">
                            <div class="col-4 ">
                                <img align="left" class="ql-image-profile thumbnail" src="{{data?.user_image}}"
                                    onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" alt="avatar"
                                    [hidden]="groupPage|| groupProfilePage || pageInfoView" id="userImage" />

                                <img align="left" class="ql-image-profile thumbnail" src="{{groupData?.group_image}}" alt="avatar"
                                    *ngIf="(groupPage|| groupProfilePage)" id="groupImage"
                                    onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" />

                                <img align="left" class="ql-image-profile thumbnail" src="{{pageData?.page_image}}" alt="avatar" *ngIf="pageInfoView"
                                    id="pageImage" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" />
                            </div>
                            <div class="col-8 ">
                                <!-- User Profile / Profile Page -->
                                <div class="ql-profile-text user-profile-text mob-mar-0" [hidden]="groupPage|| groupProfilePage || pageInfoView">
                                    <h1><a data-toggle="tooltip" data-placement="top" title="{{data.user_name}}">{{data.user_name}}</a>
                                    </h1>
                                    <div class="more-options-post profile-view-options" *ngIf="userPage">
                                        <button id="personButton" (click)="serviceFunction.moreOptions(data,0,2)"><i class="far fa-ellipsis-h fa-lg"
                                                *ngIf="globals.mobileView"></i></button>
                                        <span class="dropdown" (click)="serviceFunction.moreOptionsDesktop(data,2)" *ngIf="!globals.mobileView">
                                            <i class="far fa-ellipsis-h fa-lg menu-dropdown pointer-cursor" type="button">
                                            </i>
                                            <ul class="dropdown-menu custom pointer-cursor" id="personoption_{{data.user_id}}">
                                                <li (click)="socialMediaShare(data.user_id,2,1)"><i class="fab fa-facebook"></i>Share to
                                                    Facebook</li>
                                                <li (click)="socialMediaShare(data.user_id,2,2)"><i class="fab fa-linkedin"></i>Share to
                                                    LinkedIn</li>
                                                <li (click)="linkCopy(data.user_id,2)"><i class=" fal fa-link"></i>Copy Link</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <p [hidden]="userPage"><strong>Phone:</strong> {{globals.user.user_mobile}}</p>
                                    <p [hidden]="userPage"><strong>Email:</strong> {{globals.user.user_email}}</p>
                                    <p [hidden]="userPage"><strong>Location:</strong> <span *ngIf="globals.user.state_name">
                                            {{globals.user.state_name}}</span> , <span *ngIf="globals.user.country">{{globals.user.country}}</span>
                                    </p>
                                    <p *ngIf="userPage && data.country"><strong>Country:</strong> {{data.country}}</p>
                                    <p class="personal-user" *ngIf="userPage && !personType">
                                        <span class="share_post_text "><i class="fas fa-users "></i> {{data.follower_count}}</span>
                                        <span class="share_post_text"><i class="fas fa-star share-icon"></i> {{data.total_points}}</span>
                                    </p>
                                    <p class="personal-user" *ngIf="userPage && personType">
                                        <span class="share_post_text"><i class="fas fa-heart pointer-cursor" (click)="viewFaveCount(data)"></i>
                                            {{data.fav_count}}</span>
                                        <span class="share_post_text"><i class="fas fa-star share-icon"></i> {{data.total_points}}</span>
                                    </p>
                                </div>
                                <a class="btn qudos-primary-btn edit-profile-btn" (click)="showEditProfile()"
                                    [hidden]="(userPage || groupPage ||pageInfoView || groupProfilePage)" id="editButton">Edit Profile</a>
                                <div [hidden]="((globals.user.user_id == personId) && (globals.user.qudos_user_type == personType)) || !userPage"
                                    class="cobine-action user__actions user_btns" id="userButton">
                                    <span *ngIf="!globals.user.qudos_user_type">
                                        <a class="anchor-pad-0">
                                            <span class="span_padding" (click)="removeFavouriteDriver(data)"
                                                *ngIf="!globals.user.qudos_user_type && personType  && is_fav_driver">Remove</span>
                                            <span *ngIf="!globals.user.qudos_user_type && personType  && !is_fav_driver"
                                                (click)="addFavouriteDriver(data)">
                                                <span class="span_padding" *ngIf="!requested">Add</span>
                                                <span class="span_padding" *ngIf="requested">Accept</span>
                                            </span>
                                            <span class="span_padding" (click)="removePersonFriend(data,personType)"
                                                *ngIf="!globals.user.qudos_user_type && !personType && is_friend ">Remove</span>
                                            <span *ngIf="!globals.user.qudos_user_type && !personType && !is_friend">
                                                <span class="span_padding" *ngIf="!requested && !friend_request"
                                                    (click)="sendFriendRequest(data,personType)">Add</span>
                                                <span class="span_padding" *ngIf="requested && !friend_request"
                                                    (click)="addPerson(data,personType)">Accept</span>
                                                <span class="span_padding" *ngIf="friend_request && !requested"
                                                    (click)="cancelRequest(data,personType)">Cancel</span>
                                            </span>
                                        </a>
                                    </span>
                                    <span *ngIf="globals.user.qudos_user_type">
                                        <a class="anchor-pad-0">
                                            <span class="span_padding" (click)="removePersonFriend(data,personType)" *ngIf="is_friend ">Remove</span>
                                            <span *ngIf="!is_friend">
                                                <span class="span_padding" *ngIf="!requested && !friend_request"
                                                    (click)="sendFriendRequest(data,personType)">Add</span>
                                                <span class="span_padding" *ngIf="requested && !friend_request"
                                                    (click)="addPerson(data,personType)">Accept</span>
                                                <span class="span_padding" *ngIf="friend_request && !requested"
                                                    (click)="cancelRequest(data,personType)">Cancel</span>
                                            </span>
                                        </a>
                                    </span>
                                    <a *ngIf="requested && !friend_request" class="anchor-pad-0">
                                        <span class="span_padding" (click)="rejectPerson(data,personType)">Reject</span>
                                    </a>
                                    <a class="anchor-pad-0">
                                        <span class="span_padding" *ngIf="!is_follower" (click)="addFollower()">Follow</span>
                                        <span class="span_padding" *ngIf="is_follower" (click)="removeFollower()">Unfollow</span>
                                    </a>
                                    <a [hidden]="requested && !friend_request" class="anchor-pad-0">
                                        <span class="span_padding" (click)="sharePerson(data)">Share</span>
                                    </a>
                                </div>
                                <!-- Group Profile Page -->
                                <div class="ql-profile-text grp-profile-text" *ngIf="groupPage || groupProfilePage">
                                    <h1><a title="{{groupData.group_name}}" data-toggle="tooltip" data-placement="top">{{groupData.group_name}}</a>
                                    </h1>
                                    <button type="button" class="more-options-post profile-view-options" *ngIf="groupProfilePage"
                                        routerLink="/groupInfo/{{groupId}}"><i class="fal fa-times" [hidden]="groupPage"></i></button>
                                    <div class="more-options-post profile-view-options" *ngIf="!is_deleted" [hidden]="groupProfilePage">
                                        <button id="groupMenuButton" (click)="serviceFunction.moreOptions(groupData,0,3)"><i
                                                class="far fa-ellipsis-h fa-lg" *ngIf="globals.mobileView"></i></button>
                                        <span class="dropdown" (click)="serviceFunction.moreOptionsDesktop(groupData,3)" *ngIf="!globals.mobileView">
                                            <i class="far fa-ellipsis-h fa-lg menu-dropdown pointer-cursor" type="button">
                                            </i>
                                            <ul class="dropdown-menu custom pointer-cursor" id="groupoption_{{groupData.group_id}}">
                                                <li (click)="socialMediaShare(groupData.group_id,3,1)"><i class="fab fa-facebook"></i>Share to
                                                    Facebook</li>
                                                <li (click)="socialMediaShare(groupData.group_id,3,2)"><i class="fab fa-linkedin"></i>Share to
                                                    LinkedIn</li>
                                                <li routerLink="/groupProfile/{{groupId}}" *ngIf="is_admin" (click)="serviceFunction.closeOptions(3)">
                                                    <i class="fal fa-id-card"></i>Group Profile</li>
                                                <li (click)="shareGroupModal(groupData)">
                                                    <i class="fal fa-share-square"></i>Share Group</li>
                                                <li (click)="deleteGroupModal()" *ngIf="is_admin">
                                                    <i class="fal fa-trash-alt"></i>Delete</li>
                                                <li (click)="linkCopy(groupData.group_id,3)"><i class=" fal fa-link"></i>Copy Link
                                                </li>
                                            </ul>
                                        </span>
                                    </div>
                                    <p><strong>Members:</strong> {{groupData.total_members}}</p>
                                    <p class="personal-user">
                                        <span class="share_post_text follow_img"><i class="fas fa-users"></i> {{groupData.total_followers}}</span>
                                    </p>
                                </div>
                                <div class="user__actions user_btns" *ngIf="!is_admin && !is_deleted && groupPage" id="groupPageButton">
                                    <a class="anchor-pad-0">
                                        <span class="span_padding" *ngIf="!is_member" (click)="joinGroupModal(groupData)"
                                            [class.disabled]="globals.onLoading">Join</span>
                                        <span class="span_padding" (click)="leaveGroupModal(groupData)" *ngIf="is_member"
                                            [class.disabled]="globals.onLoading">Unlist</span>
                                    </a>
                                    <a class="anchor-pad-0">
                                        <span class="span_padding" *ngIf="!is_follower" (click)="followGroup()"
                                            [class.disabled]="globals.onLoading">Follow</span>
                                        <span class="span_padding" *ngIf="is_follower" (click)="unfollowGroup()"
                                            [class.disabled]="globals.onLoading">Unfollow</span>
                                    </a>
                                </div>
                                <a class="btn qudos-primary-btn" (click)="editGroupModal(groupData)" [class.disabled]="globals.onLoading"
                                    *ngIf="groupProfilePage" id="groupEditButton">Edit your profile</a>
                                <!-- Page Profile Page -->
                                <div class="ql-profile-text grp-profile-text" *ngIf="pageInfoView">
                                    <h1><a data-toggle="tooltip" data-placement="top" title="{{pageData.page_name}}">{{pageData.page_name}}</a>
                                    </h1>
                                    <div class="more-options-post profile-view-options" *ngIf="!is_deleted">
                                        <button id="pageMenuButton" (click)="serviceFunction.moreOptions(pageData,0,4)"><i
                                                class="far fa-ellipsis-h fa-lg" *ngIf="globals.mobileView"></i></button>
                                        <span class="dropdown" (click)="serviceFunction.moreOptionsDesktop(pageData,4)" *ngIf="!globals.mobileView">
                                            <i class="far fa-ellipsis-h fa-lg menu-dropdown pointer-cursor" type="button">
                                            </i>
                                            <ul class="dropdown-menu custom pointer-cursor" id="pageOption_{{pageData.page_id}}">
                                                <li (click)="socialMediaShare(pageData.page_id,4,1)"><i class="fab fa-facebook"></i>Share to
                                                    Facebook</li>
                                                <li (click)="socialMediaShare(pageData.page_id,4,2)"><i class="fab fa-linkedin"></i>Share to
                                                    LinkedIn</li>
                                                <li (click)="sharePageModal(pageData)">
                                                    <i class="fal fa-share-square"></i>Share Page</li>
                                                <li (click)="deletePageModal()" *ngIf="is_admin">
                                                    <i class="fal fa-trash-alt"></i>Delete</li>
                                                <li (click)="linkCopy(pageData.page_id,4)"><i class=" fal fa-link"></i>Copy Link
                                                </li>
                                            </ul>
                                        </span>
                                    </div>
                                    <p class="personal-user">
                                        <span class="share_post_text ">
                                            <i class="fas fa-users "></i> {{pageData.total_followers}}</span>
                                        <span class="share_post_text"><i class="fas fa-thumbs-up share-icon"></i> {{pageData.total_likes}}</span>
                                    </p>
                                </div>
                                <div class="user__actions user_btns" *ngIf="!is_admin && !is_deleted && pageInfoView">
                                    <a class="anchor-pad-0">
                                        <span class="span_padding" *ngIf="!pageData.is_like" (click)="likeUnlikePage(pageData,1)"
                                            [class.disabled]="globals.onLoading">Like</span>
                                        <span class="span_padding" (click)="likeUnlikePage(pageData,0)" *ngIf="pageData.is_like"
                                            [class.disabled]="globals.onLoading">Unlike</span>
                                    </a>
                                    <a class="anchor-pad-0">
                                        <span class="span_padding" *ngIf="!pageData.is_follower" (click)="followUnfollowPage(pageData,1)"
                                            [class.disabled]="globals.onLoading">Follow</span>
                                        <span class="span_padding" *ngIf="pageData.is_follower" (click)="followUnfollowPage(pageData,0)"
                                            [class.disabled]="globals.onLoading">Unfollow</span>
                                    </a>
                                </div>
                                <a class="btn qudos-primary-btn" (click)="editPageModal(pageData)" [class.disabled]="globals.onLoading"
                                    *ngIf="pageInfoView && is_admin && !is_deleted" id="pageEditButton">Edit Profile</a>
                            </div>
                        </div>
                    </div>
                    <!-- About Section Start-->
                    <div class="about_wraper">
                        <div class="ql-profile-info-header row border-bottom">
                            <div class="about__section container">
                                <div class="col">
                                    <h3 [hidden]="groupPage || groupProfilePage|| pageInfoView"><strong>About</strong></h3>
                                    <h3 *ngIf="(groupPage && !is_deleted) || groupProfilePage || pageInfoView"><strong>Description</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div class="row ql-profile-info-body">
                            <div class="about__section container">
                                <div class="col-12">
                                    <h5 [hidden]="groupPage || groupProfilePage||pageInfoView"><strong>Bio</strong></h5>
                                    <p *ngIf="(groupPage && !is_deleted) || groupProfilePage">{{groupData.description}}</p>
                                    <p *ngIf="pageInfoView && !is_deleted">{{pageData.description}}</p>
                                    <p [hidden]="groupPage || groupProfilePage||pageInfoView">{{data.about}}</p>
                                    <p *ngIf="!data.about && !(groupPage || groupProfilePage||pageInfoView)">No Bio</p>

                                    <p *ngIf="is_deleted && groupPage">This group is deleted</p>
                                    <p *ngIf="is_deleted && pageInfoView">This page is deleted</p>
                                </div>
                                <div class="col-12" [hidden]="groupPage">
                                    <h5 [hidden]="groupProfilePage||pageInfoView"><strong>Interests</strong></h5>
                                    <p [hidden]="groupProfilePage||pageInfoView">{{data.interest}}</p>
                                    <p *ngIf="!data.interest && !(groupPage || groupProfilePage||pageInfoView)">No interests</p>
                                    <h5 class="group_created" *ngIf="groupProfilePage"><strong>Group Created</strong></h5>
                                    <p *ngIf="groupProfilePage">{{groupData.date_added | date : "MMMM d, y"}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- About Section End -->
                    </div>
                    <!--About_wraper-->
                    <!-- Network Section Start-->
                    <div class="ql-profile-info-header row border-bottom" [hidden]="(groupPage&&is_deleted)|| pageInfoView">
                        <div class="network__section container">
                            <div class="col">
                                <h3 [hidden]="groupPage || groupProfilePage"><strong>Network</strong></h3>
                                <h3 *ngIf="(groupPage&&!is_deleted) || groupProfilePage"><strong>Members</strong></h3>
                            </div>
                        </div>
                    </div>
                    <div class="row ql-profile-info-body mob-shadow" [hidden]="groupPage || groupProfilePage|| pageInfoView">
                        <!-- QudosConnect™ Card Start-->
                        <div class="col-md-6 col-xs-12">
                            <div class="card">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item item__header">
                                        <img src="./assets/img/qudos-faves-icon_3.png" alt="QudosFave™ logo" width="32px;">
                                        <strong *ngIf="personType">Favourite By Rider™</strong>
                                        <strong *ngIf="!personType">QudosFave™</strong>
                                    </li>
                                    <ng-container *ngIf="!personType">
                                        <li class="list-group-item" *ngFor="let data of driverData">
                                            <img src="{{data.user_image? data.user_image : data.driver_image}}"
                                                onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                                class="rounded-circle pointer-cursor" alt="user-avatar" width="32px;" height="32px;"
                                                routerLink="/userInfo/{{data.user_id? data.user_id : data.driver_id}}/{{data.qudos_user_type}}">
                                            <span data-toggle="tooltip" title="{{data.user_name?data.user_name : data.driver_name}}" data-placement="top"
                                                routerLink="/userInfo/{{data.user_id? data.user_id : data.driver_id}}/{{data.qudos_user_type}}"
                                                class="pointer-cursor frnd_name">{{data.user_name?data.user_name : data.driver_name}}</span>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="personType">
                                        <li class="list-group-item" *ngFor="let data of driverData">
                                            <img src="{{data.user_image? data.user_image : data.driver_image}}"
                                                onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                                class="rounded-circle pointer-cursor" alt="user-avatar" width="32px;" height="32px;"
                                                routerLink="/userInfo/{{data.user_id? data.user_id : data.driver_id}}/{{data.qudos_user_type}}">
                                            <span data-toggle="tooltip" title="{{data.user_name?data.user_name : data.driver_name}}" data-placement="top"
                                                routerLink="/userInfo/{{data.user_id? data.user_id : data.driver_id}}/{{data.qudos_user_type}}" class="pointer-cursor frnd_name">{{data.user_name?data.user_name : data.driver_name}}</span>
                                        </li>
                                    </ng-container>
                                    <li class="list-group-item" *ngIf="driverData.length == 0">
                                        <center *ngIf="personType"><span>No <span [hidden]="profilePage">Mutual</span> QudosFave™ Riders</span>
                                        </center>
                                        <center *ngIf="!personType"><span>No <span [hidden]="profilePage">Mutual</span> QudosFave™ Drivers</span>
                                        </center>
                                    </li>
                                </ul>
                                <div class="card-body">
                                    <div>
                                        <a class="card-link" routerLink="/viewMore/{{personId}}" state="{{personType}},2"
                                                *ngIf="userPage">VIEW ALL <i class="fas fa-angle-right"></i></a>
                                        <!-- <a class="card-link" routerLink="/viewMore/{{personId}}" state="{{personType}},1"
                                            *ngIf="userPage">VIEW ALL<i class="fas fa-angle-right"></i></a> -->
                                        <a class="card-link" [hidden]="userPage" routerLink="/network" state="1,1">VIEW MORE <i
                                                class="fas fa-angle-right"></i></a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- QudosConnect™ Card End-->
                        <!-- Friends Card Start-->
                        <div class="col-md-6 col-xs-12">
                            <div class="card">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item item__header">
                                        <svg class="social-relations"></svg><strong>Friends</strong></li>
                                    <li class="list-group-item" *ngFor="let data of friendData">
                                        <img src="{{data?.user_image}}" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                            class="rounded-circle pointer-cursor" alt="user-avatar" width="32px;" height="32px;"
                                            routerLink="/userInfo/{{data.user_id}}/0">
                                        <span data-toggle="tooltip" title="{{data.user_name}}" data-placement="top"
                                            routerLink="/userInfo/{{data.user_id}}/0" class="pointer-cursor frnd_name">{{data.user_name}}</span>
                                    </li>
                                    <li class="list-group-item" *ngIf="friendData.length == 0">
                                        <center><span>No <span [hidden]="profilePage">Mutual</span> Friends</span>
                                        </center>
                                    </li>
                                </ul>
                                <div class="card-body">
                                    <div>
                                        <a class="card-link" *ngIf="userPage" routerLink="/viewMore/{{personId}}" state="{{personType}},1">VIEW ALL
                                            <i class="fas fa-angle-right"></i></a>
                                        <a class="card-link" [hidden]="userPage" routerLink="/network" state="2,1">VIEW
                                            MORE <i class="fas fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Friends Card End-->
                         <!-- Groups Card Start-->
                         <div class="col-md-6 col-xs-12">
                            <div class="card">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item item__header">
                                        <svg class="social-relations"></svg><strong>Groups</strong></li>
                                    <li class="list-group-item">
                                        <img src="https://qudos-s3.s3.amazonaws.com/user_profile/user.png" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                            class="rounded-circle pointer-cursor" alt="user-avatar" width="32px;" height="32px;"
                                            >
                                        <span data-toggle="tooltip" title="Group 1" data-placement="top"
                                            class="pointer-cursor frnd_name">Group 1</span>
                                    </li>
                                    <!-- <li class="list-group-item" >
                                        <center><span>No <span [hidden]="profilePage">Mutual</span> Friends</span>
                                        </center>
                                    </li> -->
                                </ul>
                                <div class="card-body">
                                    <div>
                                        <a class="card-link" *ngIf="userPage" routerLink="/viewMore/{{personId}}" state="{{personType}},1">VIEW ALL
                                            <i class="fas fa-angle-right"></i></a>
                                        <a class="card-link" [hidden]="userPage" routerLink="/network" state="2,1">VIEW
                                            MORE <i class="fas fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Groups Card End-->
                        
                         <!-- Pages Card Start-->
                         <div class="col-md-6 col-xs-12">
                            <div class="card">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item item__header">
                                        <svg class="social-relations"></svg><strong>Pages</strong></li>
                                    <li class="list-group-item">
                                        <img src="https://qudos-s3.s3.amazonaws.com/user_profile/user.png" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                            class="rounded-circle pointer-cursor" alt="user-avatar" width="32px;" height="32px;"
                                            >
                                        <span data-toggle="tooltip" title="Page 1" data-placement="top"
                                            class="pointer-cursor frnd_name">Page 1</span>
                                    </li>
                                    <!-- <li class="list-group-item" >
                                        <center><span>No <span [hidden]="profilePage">Mutual</span> Friends</span>
                                        </center>
                                    </li> -->
                                </ul>
                                <div class="card-body">
                                    <div>
                                        <a class="card-link" *ngIf="userPage" routerLink="/viewMore/{{personId}}" state="{{personType}},1">VIEW ALL
                                            <i class="fas fa-angle-right"></i></a>
                                        <a class="card-link" [hidden]="userPage" routerLink="/network" state="2,1">VIEW
                                            MORE <i class="fas fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Pages Card End-->
                    </div>
                    <!-- Network Section End -->
                    <div class="row ql-profile-info-body mob-shadow" *ngIf="(!is_deleted && groupPage) || groupProfilePage">
                        <div class="col-md-6 col-xs-12">
                            <div class="card">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item item__header">
                                        <img alt="" class="svg-rl" src="./assets/img/social-relations.svg">
                                        <strong>Friends</strong></li>
                                    <li class="list-group-item" *ngFor="let data of members">
                                        <img src="{{data?.user_image}}" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                            class="rounded-circle pointer-cursor" alt="user-avatar" width="32px;" height="32px;"
                                            routerLink="/userInfo/{{data.user_id}}/{{data.qudos_user_type}}">
                                        <span data-toggle="tooltip" title="{{data.user_name}}" data-placement="top"
                                            routerLink="/userInfo/{{data.user_id}}/{{data.qudos_user_type}}"
                                            class="pointer-cursor frnd_name">{{data.user_name}}</span>
                                    </li>
                                    <li class="list-group-item" *ngIf="!groupData?.total_members">
                                        <center><span>No Members</span></center>
                                    </li>
                                </ul>
                                <div class="card-body">
                                    <div>
                                        <a class="card-link" routerLink="/viewMore/{{groupData.group_id}}" state="0,3">VIEW ALL <i
                                                class="fas fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ql-profile-info-header row border-bottom"
                        [hidden]="groupProfilePage || (groupPage&&is_deleted)||(pageInfoView && is_deleted)">
                        <div class="post__section container">
                            <div class="col">
                                <h3><strong>Posts</strong></h3>
                            </div>
                        </div>
                    </div>
                    <div class="row ql-profile-info-body">
                    </div>
                </ng-container>
                <post-preview></post-preview>
            </div>
        </div>
        <div class="col-12 col-lg-3 col-xl-2 page_sidebar" *ngIf="!globals.pageLoad">
            <div class="summary">
                <div class="summary__avatar">
                    <img src="{{globals.user?.user_image}}" alt="" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';">
                </div>
                <div data-toggle="popover" data-placement="top" title="{{globals.user.user_name}}" class="summary__title" ngDefaultControl
                    [(ngModel)]="globals.user.user_name">
                    {{globals.user.user_name}}</div>
                <a routerLink="/profile" class="summary__profile" [hidden]="profilePage">My Profile</a>
                <div class="summary__stat">
                    <span ngDefaultControl [(ngModel)]="globals.user.total_followers">{{globals.user.total_followers}}</span>
                    <p>Followers</p>
                </div>
                <div class="summary__stat">
                    <span ngDefaultControl [(ngModel)]="globals.user.total_posts">{{globals.user.total_posts}}</span>
                    <p>Posts</p>
                </div>
                <div class="summary__stat">
                    <span ngDefaultControl [(ngModel)]="globals.user.total_likes">{{globals.user.total_likes}}</span>
                    <p>Likes</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="globals.onLoading" class="whirl"></div>
</div>

<app-user-template></app-user-template>
<app-network-template></app-network-template>