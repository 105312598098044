import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './user.component';
import { AuthGuardService } from 'src/app/services/authGuard/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { NetworkComponent } from './network/network.component';
import { SettingsComponent } from './settings/settings.component';
import { MessageComponent } from './message/message.component';
import { NotificationComponent } from './notification/notification.component';
import { ProfileComponent } from './profile/profile.component';
import { ViewMoreComponent } from './view-more/view-more.component';
import { GroupProfileComponent } from './group-profile/group-profile.component';
import { PostInfoComponent } from './post-info/post-info.component';
import { FriendRequestComponent } from './friend-request/friend-request.component';
import { PageInfoComponent } from './page-info/page-info.component';
import { GroupInfoComponent } from './group-info/group-info.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { PostComponent } from './copylink/post/post.component';

const routes: Routes = [
  {
    path: '',
    component: UserComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers:'always',
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'network', component: NetworkComponent, data: { selectedIndex: 2, selectedInnerIndex: 1 } },
      { path: 'network/:type', component: NetworkComponent, data: { selectedIndex: 2, selectedInnerIndex: 1 } },
      { path: 'network/:type/:tab', component: NetworkComponent, data: { selectedIndex: 2, selectedInnerIndex: 1 } },
      { path: 'settings', component: SettingsComponent },
      { path: 'messages', component: MessageComponent },
      { path: 'notification', component: NotificationComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'viewMore/:id', component: ViewMoreComponent, data: { requestType: null, userType: null } },
      { path: 'groupProfile/:groupId', component: GroupProfileComponent },
      { path: 'post/:postId/:type/:personId/:personType', component: PostComponent },
      { path: 'postInfo/: postId', component: PostInfoComponent },
      { path: 'friendRequest/:personId/:personType', component: FriendRequestComponent },
      { path: 'pageInfo/:pageId', component: PageInfoComponent },
      { path: 'groupInfo/:groupId', component: GroupInfoComponent },
      { path: 'userInfo/:personId/:personType', component: UserInfoComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
