import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { GroupComponent } from './components/user/copylink/group/group.component';
import { PageComponent } from './components/user/copylink/page/page.component';
import { DriverComponent } from './components/user/copylink/driver/driver.component';
import { RiderComponent } from './components/user/copylink/rider/rider.component';
import { TermsConditionsComponent } from './components/no-authentication/terms-conditions/terms-conditions.component';
import { PostingPolicyComponent } from './components/no-authentication/posting-policy/posting-policy.component';
import { PrivacyPolicyComponent } from './components/no-authentication/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './components/no-authentication/cookies-policy/cookies-policy.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { innerIndex: 1 } },
  { path: 'signup', component: SignupComponent },
  { path: 'group/:groupId', component: GroupComponent },
  { path: 'page/:pageId', component: PageComponent },
  { path: 'driver/:driverId', component: DriverComponent },
  { path: 'rider/:riderId', component: RiderComponent },
  { path: '', loadChildren: () => import('./components/user/user.module').then(m => m.UserModule)},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'terms', component: TermsConditionsComponent },
  { path: 'posting', component: PostingPolicyComponent },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'cookies', component: CookiesPolicyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: "reload"
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
