import { Injectable } from '@angular/core';
import { Globals, API } from 'src/app/helpers/constant';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { GlobalFunctions } from 'src/app/helpers/service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

//Service for the notification page functionalites
export class NotificationService {

  constructor(public globals: Globals, private toaster: ToastrService, private http: HttpClient, private serviceFunction: GlobalFunctions) { }

  //Fetch notifications
  getNotification(data): Observable<any> {
    return this.http.post<any>(API.GET_NOTIFICATIONS, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Read notifications
  readNotification(data): Observable<any> {
    return this.http.post<any>(API.READ_NOTIFICATIONS, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

}
