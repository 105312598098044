import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';

@Component({
  selector: 'app-instant-message',
  templateUrl: './instant-message.component.html',
  styleUrls: ['./instant-message.component.css']
})
export class InstantMessageComponent implements OnInit {
  
	showNewMessageBox: boolean = false;
	showMessageList: boolean = false;
	isTextAreaLarge: boolean = true;
	newMessageEnlarge: boolean = false;

  constructor(public globals: Globals) { }

  ngOnInit(): void {
  }

  public increaseTextAreaHeight() {
		this.isTextAreaLarge = false;
	}

	public decreaseTextAreaHeight() {
		this.isTextAreaLarge = true;
	}

	public closeNewTextBox() {
		this.showNewMessageBox = false;
		this.isTextAreaLarge = true;
		this.newMessageEnlarge = false;
	}

}
