'use strict';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export const API = {
	"PROFILE_INFO": environment.API_URL + "profile_count_info",

	// LOGIN
	"LOGIN_URL": environment.API_URL + "user_login",
	"LOGIN_FORGOT_PASSWORD": environment.API_URL + "email_forgot_password",
	"RESEND_OTP": environment.API_URL + "resend_otp",
	"RESET_PASSWORD": environment.API_URL + "reset_password",

	// SIGNUP
	"SIGNUP_CHECK_DATA": environment.API_URL + "check_data",
	"FETCH_COUNTRY_LIST": environment.API_URL + "get_countries",
	"FETCH_STATE_LIST": environment.API_URL + "get_states",
	"SIGNUP_VERIFY_OTP": environment.API_URL + "verify_otp",
	"REGISTER_USER": environment.API_URL + "email_registration",

	// NOTIFICATION
	"GET_NOTIFICATIONS": environment.API_URL + "get_notification",
	"READ_NOTIFICATIONS": environment.API_URL + "read_notification",

	// GROUP
	"LEAVE_GROUP": environment.API_URL + "leave_group",
	"JOIN_GROUP": environment.API_URL + "join_group",
	"FOLLOW_GROUP": environment.API_URL + "follow_group",
	"UNFOLLOW_GROUP": environment.API_URL + "unfollow_group",
	"GROUP_DETAILS": environment.API_URL + "group_info",
	"SHARE_GROUP": environment.API_URL + "share_group",
	"DELETE_GROUP": environment.API_URL + "delete_group",
	"EDIT_GROUP": environment.API_URL + "update_group",
	"CREATE_GROUP": environment.API_URL + "create_group",
	"FETCH_GROUP_BY_ID": environment.API_URL + "fetch_group_by_id",
	"NETWORK_LIST": environment.API_URL + "network_list",
	"MEMBER_LIST": environment.API_URL + "all_members",

	//SETTINGS
	"GET_LANGUAGE": environment.API_URL + "get_available_languages",
	"SET_LANGUAGE": environment.API_URL + "set_language",
	"SET_LANGUAGE_privacy": environment.API_URL + "update_profile_settings",

	// PAGE
	"FOLLOW_UNFOLLOW_PAGE": environment.API_URL + "follow_unfollow_page",
	"FETCH_PAGES_LIST": environment.API_URL + "fetch_pages",
	"LIKE_UNLIKE_PAGE": environment.API_URL + "like_unlike_page",
	"DELETE_PAGE": environment.API_URL + "delete_page",
	"UPDATE_PAGE": environment.API_URL + "update_page",
	"CREATE_PAGE": environment.API_URL + "create_page",
	"PAGE_INFO": environment.API_URL + "page_info",
	"FETCH_PAGE_BY_ID": environment.API_URL + "fetch_page_by_id",
	"SHARE_PAGE": environment.API_URL + "share_page",

	// NETWORK
	"ADD_FAVE_DRIVER": environment.API_URL + "add_fav_driver",
	"REMOVE_FAVE_DRIVER": environment.API_URL + "remove_fav_driver",
	"VIEW_FAVE_COUNT": environment.API_URL + "list_people_fav_you",
	"UNFRIEND": environment.API_URL + "un_friend",
	"ADD_FRIEND": environment.API_URL + "add_friend",
	"SHARE_PERSON": environment.API_URL + "share_person",
	"REJECT_FRIEND_REQUEST": environment.API_URL + "reject_friend_request",
	"CANCEL_FRIEND_REQUEST": environment.API_URL + "cancel_friend_request",
	"ADD_FRIEND_REQUEST": environment.API_URL + "add_friend_request",
	"FOLLOW_PERSON": environment.API_URL + "follow",
	"UNFOLLOW_PERSON": environment.API_URL + "un_follow",
	"FRIEND_LIST": environment.API_URL + "friend_list",
	"QUDOS_NETWORK_LIST": environment.API_URL + "qudos_network_list",
	"PENDING_REQUESTS": environment.API_URL + "pending_requests",
	"QUDOS_GROUP_LIST": environment.API_URL + "qudos_group_list",
	"SEE_ALL_FRIENDS": environment.API_URL + "qudos_see_all_friend_type",
	"FETCH_PERSON_BY_ID": environment.API_URL + "fetch_person_by_id",
	"PERSON_DETAILS": environment.API_URL + "know_person",

	// POST
	"FETCH_POST": environment.API_URL + "fetch_post",
	"FETCH_POST_BY_ID": environment.API_URL + "fetch_post_by_id",
	"GROUP_POSTS": environment.API_URL + "group_timeline",
	"PAGE_POSTS": environment.API_URL + "page_timeline",
	"USER_POSTS": environment.API_URL + "fetch_timeline",
	"LIKE_UNLIKE_POST": environment.API_URL + "like_unlike_post",
	"FETCH_LIKES": environment.API_URL + "fetch_likes",
	"CREATE_COMMENT": environment.API_URL + "create_comment",
	"FETCH_COMMENT": environment.API_URL + "fetch_comment",
	"CREATE_POST": environment.API_URL + "create_post",
	"SHARE_POST": environment.API_URL + "share_post",
	"DELETE_POST": environment.API_URL + "delete_post",
	"UPDATE_POST": environment.API_URL + "update_post",
	"EDIT_PRIVACY": environment.API_URL + "edit_privacy",

	// PROFILE
	"VIEW_PROFILE": environment.API_URL + "view_profile",
	"EDIT_PROFILE": environment.API_URL + "edit_profile",

}
@Injectable()
export class Globals {

	// Common
	hideHeaderFooter: boolean = false;
	is_auth = false;
	user: any = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));
	taggedArray: any = [];
	taggedMember: any = [];
	tagify: any = {};
	network: any = [];
	mobileView: boolean;
	description: string = '';
	activeInput: any;
	message = '';
	postIndex: any;
	tagValue: any;
	total_network: number;
	onLoading: boolean = false;
	pageLoad = false;
	invalidInput = false;
	invalidInputValue = '';

	// Notification
	notificationCounter: number = 0;
	notifyOffset: number = 0;
	totalNotifications: any;
	notifications: any = [];

	//Messages
	chatLoad: any = false;
	unread_count: any;
	reconnectCount = 0;

	//Posts
	oldPath: any;
	postCopylink: any;
	posts: any;
	userMenuButton: HTMLElement;
	userMenuSlider: HTMLElement;
	personButton: HTMLElement;
	personMenuSlider: HTMLElement;
	groupMenuButton: HTMLElement;
	groupMenuSlider: HTMLElement;
	pageMenuButton: HTMLElement;
	pageMenuSlider: HTMLElement;

}