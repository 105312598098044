import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/helpers/constant';
import { GlobalFunctions } from 'src/app/helpers/service';
import { SocketService } from '../socket/socket.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isValid;
  constructor(private router: Router, public globals: Globals, private serviceFunction: GlobalFunctions, private socketHelper: SocketService) { }

  //to check whether the user logged in is authenticated or not
  public isAuthenticated(): boolean {
    this.isValid = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));
    if (this.isValid.web_access_token) {
      return true;
    }
  }

  //Manage logging out functionality
  public loggedOut() {
    this.serviceFunction.getDismissReason("Close");
    localStorage.clear();
    this.globals.is_auth = false;
    this.router.navigate(['/login']);
    this.socketHelper.getSocket().disconnect();
    location.reload();
  }
}
