<div class="page settings--page">
  <section class="">
    <div class="row">
      <div class="container">
        <div class="col my-3 py-2 border-bottom">
          <h3 class="mb-3 f-20">Settings Options</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="container btm-brdr">
        <div class="col">
          <form class="setting-form setLanguage">
            <div class="form-group">
              <label for="privacySettingsSelect">Account Privacy</label>
              <i class="fas fa-caret-down"></i>
              <select
                class="form-control"
                id="privacySettings"
                [(ngModel)]="languagePrivacy"
              >
                <option value="0">Public (Default)</option>
                <option value="1">Private</option>
              </select>
            </div>
            <div class="form-group">
              <label for="languageSettingsSelect">Language</label>
              <i class="fas fa-caret-down"></i>
              <select
                class="form-control"
                name="Language"
                [(ngModel)]="languageData"
                required
                id="languageSettings"
                (click)="getLanguagePrivacyData(languageData)"
              >
                <option *ngFor="let selectedItem of languageList">
                  {{ selectedItem.language }}
                </option>
              </select>
            </div>
            <!-- <button class="btn btn-primary" type="button" (click)="getLanguageData(languageData)">
                            <span>Save</span>
                        </button>
                        <button class="btn btn-default" type="button" (click)="cancelSetLanguage(languageData)">
                            <span>Cancel</span>
                        </button>&nbsp; -->
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-block qudos-fourth-btn logout"
          data-toggle="modal"
          *ngIf="globals.mobileView"
          (click)="logout()"
        >
          Sign-out
        </button>
      </div>
    </div>
  </section>
</div>
<app-auth-template></app-auth-template>
