import { Component, OnInit, OnDestroy } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.css']
})
export class PageInfoComponent implements OnInit,OnDestroy {

  image = '';
  content_type;
  posts = [];
  offset = 0;
  pageId: number;
  routeSubscribe: any;
  
  constructor(public globals: Globals, private router: ActivatedRoute) {
    this.globals.posts = [];
    this.routeSubscribe = this.router.params.subscribe(params => {
      this.pageId = parseInt(params.pageId)
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.routeSubscribe.unsubscribe();
  }
}
