<ng-template #confirmModalNetwork let-modal>
	<div class="confirm">
		<div class="row">
			<div class="popup-main-content">
				<div class="person-info-form">
					<form name="profile">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-3">
									</div>
									<div class="col-md-6">
										<p class="confirm-first-line">Are you sure you want to add</p>
										<p class="confirm-name-edit" *ngIf="!isRider && !globals.user.qudos_user_type">{{driver.user_name}}
										</p>
										<p class="confirm-name-edit" *ngIf="!isRider && globals.user.qudos_user_type">{{friend.user_name}}
										</p>
										<p class="confirm-name-edit" *ngIf="!isRider && !globals.user.qudos_user_type">
											{{driver.driver_name}}</p>
										<p class="confirm-name-edit" *ngIf="!isRider && globals.user.qudos_user_type">{{friend.driver_name}}
										</p>
										<p class="confirm-name-edit" *ngIf="isRider">{{friend.user_name}}</p>
										<p class="confirm-name" *ngIf="isRider">to your friends list ?</p>
										<p class="confirm-name" *ngIf="!isRider">to your favourite list ?</p>

									</div>
									<div class="col-md-3">
									</div>
								</div>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12 confirm-button">
										<button class="btn btn-default cbtn" type="button" (click)="modal.close('Close click')">
											No
										</button>&nbsp;
										<button class="btn btn-primary cbtn" type="button" (click)="addFaveDriver(driver)"
											*ngIf="!isRider && !globals.user.qudos_user_type" [disabled]="globals.onLoading">
											Yes
										</button>
										<button class="btn btn-primary cbtn" type="button" (click)="addFriend(friend)"
											*ngIf="!isRider && globals.user.qudos_user_type" [disabled]="globals.onLoading">
											Yes
										</button>
										<button class="btn btn-primary cbtn" type="button" (click)="addFriend(friend)" *ngIf="isRider"
											[disabled]="globals.onLoading">
											Yes
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #confirmModalQudos let-modal>
	<div class="confirm">
		<div class="row">
			<div class="popup-main-content">
				<div class="person-info-form">
					<form name="profile">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-3">
									</div>
									<div class="col-md-6">
										<p class="confirm-first-line">Are you sure you want to remove </p>
										<p class="confirm-name-edit" *ngIf="!isRider && !globals.user.qudos_user_type">
											{{driver.driver_name}}</p>
										<p class="confirm-name-edit" *ngIf="!isRider && globals.user.qudos_user_type">{{friend.driver_name}}
										</p>
										<p class="confirm-name-edit" *ngIf="!isRider && !globals.user.qudos_user_type">{{driver.user_name}}
										</p>
										<p class="confirm-name-edit" *ngIf="!isRider && globals.user.qudos_user_type">{{friend.user_name}}
										</p>
										<p class="confirm-name-edit" *ngIf="isRider">{{friend.user_name}}</p>
										<p class="confirm-name" *ngIf="isRider">from your friends list ?</p>
										<p class="confirm-name" *ngIf="!isRider">from your favourite list ?</p>
									</div>
									<div class="col-md-3">
									</div>
								</div>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12 confirm-button">
										<button class="btn btn-default cbtn" type="button" (click)="modal.close('Close click')">
											No
										</button>&nbsp;
										<button class="btn btn-primary cbtn" type="button" (click)="removeFaveDriver(driver)"
											*ngIf="!isRider && !globals.user.qudos_user_type" [disabled]="globals.onLoading">
											Yes
										</button>
										<button class="btn btn-primary cbtn" type="button" (click)="removeFriend(friend)"
											*ngIf="!isRider && globals.user.qudos_user_type" [disabled]="globals.onLoading">
											Yes
										</button>
										<button class="btn btn-primary cbtn" type="button" (click)="removeFriend(friend)" *ngIf="isRider"
											[disabled]="globals.onLoading">
											Yes
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #filterDriver let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-profile-header" class="row">
					<div class="col-xs-8 word-Wrap">
						<h1 class="modal-title">Filter</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class="col-xs-4">
						<button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></button>
					</div>
				</div>
				<div class="person-info-form">
					<form name="education">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<label class="control-label">Keyword</label>
										<div class="row-input">
											<input type="text" class="form-control filter-input" name="vehicleMake"
												[(ngModel)]="filter_keyword" required placeholder="Enter Name,Car Make,etc">
										</div>
									</div>
									<div class="col-md-12">
										<label class="control-label">Seating Capacity</label>
										<div class="row-input select_seats">
											<i class="fas fa-caret-down"></i>
											<select [ngModelOptions]="{standalone: true}" [(ngModel)]="filter_seats" class="filter-input">
												<option value=undefined selected>Choose number of seats</option>
												<option value="3">3</option>
												<option value="4">4</option>
												<option value="6">6</option>
												<option value="8">8</option>
											</select>
										</div>
									</div>
									<div class="col-md-12">
										<label class="control-label">Country</label>
										<div class="row-input">
											<input type="text" class="form-control filter-input" name="city" [(ngModel)]="filter_country"
												required placeholder="Enter Country">
										</div>
									</div>
									<div class="col-md-12">
										<label class="control-label">Zipcode</label>
										<div class="row-input">
											<input type="tel" class="form-control filter-input" name="zipcode" placeholder="Enter Zip Code"
												[(ngModel)]="filter_zip" required>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="submit" type="button" (click)="filterInnerTable(tab,innerTab,1)"
											[disabled]="globals.onLoading">
											Search
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #filterRider let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-profile-header" class="row">
					<div class="col-xs-8 word-Wrap">

						<h1 class="modal-title">Filter</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class="col-xs-4">
						<button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></button>
					</div>
				</div>
				<div class="person-info-form">
					<form name="education">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<label class="control-label">Name</label>
										<div class="row-input">
											<input type="text" class="form-control filter-input" name="riderName" [(ngModel)]="filter_name"
												required placeholder="Name">
										</div>
									</div>
									<div class="col-md-12">
										<label class="control-label">Country</label>
										<div class="row-input">
											<input type="text" class="form-control filter-input" name="riderCountry"
												[(ngModel)]="filter_country" required placeholder="Country">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="submit" type="button" (click)="filterInnerTable(tab,innerTab,1)"
											[disabled]="globals.onLoading">
											Search
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #shareDetails let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-share-header" class="row">
					<div class="col-xs-8">
						<h1 class="modal-title">Share</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class=" col-xs-4">
						<button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></button>
					</div>
				</div>
				<div class="person-info-form">
					<form name="share-post" class="share-form grp-share-modal">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="share-input">
											<!-- Network Share -->
											<input class="share-text" placeholder="Share your ideas..." [(ngModel)]="describe"
												id="description" (click)="tagService.createTag($event)" name="share">
											<img src="./assets/img/face.png" class="face__emoji modal-input__emoji"
												(click)="emojiService.openEmojiPicker($event)">
										</div>

									</div>
								</div>
							</div>
						</div>
						<!-- </div> -->
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="share-post-content">
										</div>
										<div class="user" *ngIf="usertype">
											<div class="user-info">
												<div class="user__img">
													<img src="{{driver?.driver_image}}" alt=""
														onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
														class="share-img" *ngIf="!viewMorePage">
													<img src="{{driver?.user_image}}" alt=""
														onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
														class="share-img" *ngIf="viewMorePage">
												</div>
												<div class="user__meta">
													<h4 *ngIf="!viewMorePage">{{driver.driver_name}}</h4>
													<h4 *ngIf="viewMorePage">{{driver.user_name}}</h4>
													<p>{{driver.referral_code}}</p>
													<p *ngIf="driver.country">{{driver.country}}</p>
													<div class="icon_counts">
														<p *ngIf="!viewMorePage"><i class="fas fa-heart"></i> {{driver.fav_count}}</p>
														<p *ngIf="viewMorePage"><i class="fas fa-heart"></i> {{driver.fav_count}}</p>
														<p><i class="fas fa-star share-icon"></i> {{driver.points}}</p>
													</div>
												</div>
											</div>
										</div>
										<div class="user" *ngIf="!usertype">
											<div class="user-info">
												<div class="user__img">
													<img src="{{rider?.user_image}}" alt=""
														onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
														class="share-img">
												</div>
												<div class="user__meta">
													<h4>{{rider.user_name}}</h4>
													<p>{{rider.referral_code}}</p>
													<p *ngIf="rider.country">{{rider.country}}</p>
													<div class="icon_counts">
														<p><i class="fas fa-users "></i> {{rider.follower_count}}</p>
														<p><i class="fas fa-star share-icon"></i> {{rider.points}}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="btn btn-primary submit" type="button" (click)="share()"
											[disabled]="globals.onLoading">
											Post
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #cancelGroup let-modal>
	<div class="signout">
		<div class="row">
			<div class="popup-main-content">
				<div class="person-info-form">
					<form name="profile">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row justify-content-center">
									<div class="col-md-8 col-12">
										<p class="group-content text-center">Do you want to cancel creating a
											<span *ngIf="is_group">group ?</span>
											<span *ngIf="!is_group">page ?</span>
										</p>
									</div>

								</div>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12 signup-button">
										<button class="btn btn-default cbtn" type="button" (click)="modal.close('Close click')">
											No
										</button>&nbsp;
										<button class="btn btn-primary cbtn" type="button" (click)="cancelgroup()"
											[disabled]="globals.onLoading" *ngIf="is_group">
											Yes
										</button>
										<button class="btn btn-primary cbtn" type="button" (click)="cancelPage()"
											[disabled]="globals.onLoading" *ngIf="!is_group">
											Yes
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #confirmGroup let-modal>
	<div class="signout">
		<div class="row">
			<div class="popup-main-content">
				<div class="person-info-form">
					<form name="profile">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-3 col-2">
									</div>
									<div class="col-md-6 col-8 ">
										<p class="group-content confirm_group text-center" *ngIf="!isMember">Are you sure you want to join
											<br><span class="confirm-name-edit">{{group.group_name}} ?</span></p>
										<p class="group-content confirm_group text-center" *ngIf="isMember">Are you sure you want to unlist
											from
											<br><span class="confirm-name-edit">{{group.group_name}} ?</span>
										</p>
									</div>
									<div class="col-md-3 col-2">
									</div>
								</div>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12 signup-button">
										<button class="btn btn-default cbtn" type="button" (click)="modal.close('Close click')"
											[disabled]="globals.onLoading">
											No
										</button>
										&nbsp;
										<button class="btn btn-primary cbtn" type="button" (click)="leaveGroup()"
											[disabled]="globals.onLoading" *ngIf="isMember">
											Yes
										</button>
										<button class="btn btn-primary cbtn" type="button" (click)="joinGroup()"
											[disabled]="globals.onLoading" *ngIf="!isMember">
											Yes
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #createGroupSucess let-modal>
	<div class="signout">
		<div class="row">
			<div class="popup-main-content">
				<div class="person-info-form">
					<form name="profile">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-3 col-2">
									</div>
									<div class="col-md-6 col-8">
										<p class="group-content text-center">Everything looks good and your group is created . Qudos to you!
										</p>
									</div>
									<div class="col-md-3 col-2">
									</div>
								</div>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12 signup-button">
										<button class="btn btn-primary cbtn" type="button" (click)="createGroup()"
											[disabled]="globals.onLoading">
											OK
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #filterGroup let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-profile-header" class="row">
					<div class="col-xs-8 word-Wrap">
						<h1 class="modal-title">Filter</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class="col-xs-4">
						<button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></button>
					</div>
				</div>
				<div class="person-info-form">
					<form name="education">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<label class="control-label">Group Name</label>
										<div class="row-input">
											<input type="text" class="form-control filter-input" name="groupName"
												[(ngModel)]="filter_group_name" required placeholder="Name">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="submit" type="button" (click)="filterInnerTable(tab,innerTab,1)"
											[disabled]="globals.onLoading">
											Search
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #shareGroupModal let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-share-header" class="row">
					<div class="col-xs-8">
						<h1 class="modal-title">Share</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class=" col-xs-4">
						<button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></button>
					</div>
				</div>
				<div class="person-info-form">
					<form name="share-post" class="share-form grp-share-modal">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="share-input">
											<input class="share-text" name="describe" placeholder="Share your ideas..." [(ngModel)]="describe"
												id="description" (click)="tagService.createTag($event)">
											<img src="./assets/img/face.png" class="face__emoji modal-input__emoji"
												(click)="emojiService.openEmojiPicker($event)">
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- </div> -->
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="share-post-content">
										</div>
										<div class="user">
											<div class="user-info">
												<div class="user__img">
													<img src="{{group?.group_image}}" alt=""
														onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
														class="share-img">
												</div>
												<div class="user__meta">
													<h4>{{group.group_name}}</h4>
													<p>Members ({{group.total_members}})</p>
													<p><i class="fas fa-users "></i> {{group.total_followers}}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="btn btn-primary submit" type="button" (click)="shareGroup()"
											[disabled]="globals.onLoading">
											Post
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #editPageModal let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-profile-header" class="row">
					<div class="col-xs-8">
						<h1 class="modal-title">Edit Page</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class="col-xs-4">
						<button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="closeModal('page')"></button>
					</div>
				</div>
				<div class="person-info-form edit_gr_profile">
					<form name="page" #pageForm="ngForm" novalidate>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12" [ngclass]="{'has-error': !PageName.valid && PageName.touched}">
										<label class="control-label">Page Name</label>
										<div class="row-input">
											<div class="input-icon">
												<i class="fa fa-user"></i>
											</div>
											<input type="text" class="form-control" name="PageName" [(ngModel)]="editData.page_name"
												placeholder="Enter page Name" required #PageName="ngModel">
										</div>
										<div class="help-block" *ngIf="!PageName.valid && PageName.touched">Page Name is Required
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12" [ngclass]="{'has-error': !Description.valid && Description.touched}">
										<label class="control-label">Description</label>
										<div class="row-input">
											<div class="input-icon">
												<i class="fa fa-user"></i>
											</div>
											<input type="text" class="form-control" name="Description" [(ngModel)]="editData.description"
												placeholder="Enter Description" required #Description="ngModel">
										</div>
										<div class="help-block" *ngIf="!Description.valid && Description.touched">Description is Required
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12 col-md-12 ">
								<div class="profile-avatar">
									<div class="new-post__file profile-post-content">
										<label for="pagefile">
											<span class="filename"><i class="fal fa-image img-edit"></i>Upload new image</span>
										</label>
										<input id="pagefile" name="file" type="file" accept="image/png,image/jpg,image/jpeg"
											(change)="imageName($event.target.files,'0')">
									</div>
								</div>
							</div>
						</div>
						<br>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="submit" type="button" (click)="editPage(pageForm.form)"
											[disabled]="globals.onLoading">
											Update
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #editGroupModal let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-profile-header" class="row">
					<div class="col-xs-8">
						<h1 class="modal-title">Edit Group</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class="col-xs-4">
						<button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="closeModal('group')"></button>
					</div>
				</div>
				<div class="person-info-form edit_gr_profile">
					<form name="page" #groupForm="ngForm" novalidate>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12" [ngclass]="{'has-error': !GroupName.valid && GroupName.touched}">
										<label class="control-label">Group Name</label>
										<div class="row-input">
											<div class="input-icon">
												<i class="fa fa-user"></i>
											</div>
											<input type="text" class="form-control" name="GroupName" [(ngModel)]="editData.group_name"
												placeholder="Enter Group Name" required #GroupName="ngModel">
										</div>
										<div class="help-block" *ngIf="!GroupName.valid && GroupName.touched">Group Name is Required
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12" [ngclass]="{'has-error': !Description.valid && Description.touched}">
										<label class="control-label">Description</label>
										<div class="row-input">
											<div class="input-icon">
												<i class="fa fa-user"></i>
											</div>
											<input type="text" class="form-control" name="Description" [(ngModel)]="editData.description"
												placeholder="Enter Description" required #Description="ngModel">
										</div>
										<div class="help-block" *ngIf="!Description.valid && Description.touched">Description is Required
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12 col-md-12 ">
								<div class="profile-avatar">
									<div class="new-post__file profile-post-content">
										<label for="groupfile">
											<span class="filename"><i class="fal fa-image img-edit"></i>Upload new image</span>
										</label>
										<input id="groupfile" name="file" type="file" accept="image/png,image/jpg,image/jpeg"
											(change)="imageName($event.target.files,'0')">
									</div>
								</div>
							</div>
						</div>
						<br>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="submit" type="button" (click)="editGroupFunction(groupForm.form)"
											[disabled]="globals.onLoading">
											Update
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #filterPage let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-profile-header" class="row">
					<div class="col-xs-8 word-Wrap">

						<h1 class="modal-title">Filter</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class="col-xs-4">
						<button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></button>
					</div>
				</div>
				<div class="person-info-form">
					<form name="education">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<label class="control-label">Page Name</label>
										<div class="row-input">
											<input type="text" class="form-control filter-input" name="pageName"
												[(ngModel)]="filter_page_name" required placeholder="Name">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">

										<button class="submit" type="button" (click)="filterInnerTable(tab,innerTab,1)"
											[disabled]="globals.onLoading">
											Search
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #sharePageModal let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-share-header" class="row">
					<div class="col-xs-8">
						<h1 class="modal-title">Share</h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
							<g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
								<line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
									stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
								<line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
									stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
							</g>
						</svg>
					</div>
					<div class=" col-xs-4">
						<button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.close('Close click')"></button>
					</div>
				</div>
				<div class="person-info-form">
					<form name="share-post" class="share-form grp-share-modal">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="share-input">
											<input class="share-text" name="describe" placeholder="Share your ideas..." [(ngModel)]="describe"
												id="description" (click)="tagService.createTag($event)">
											<img src="./assets/img/face.png" class="face__emoji modal-input__emoji"
												(click)="emojiService.openEmojiPicker($event)">

										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- </div> -->
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="share-post-content">
										</div>
										<div class="user">
											<div class="user-info">
												<div class="user__img">
													<img src="{{page?.page_image}}" alt=""
														onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
														class="share-img">
												</div>
												<div class="user__meta">
													<h4>{{page.page_name}}</h4>
													<p>{{page.description}}</p>
													<div class="icon_counts">
														<p><i class="fas fa-users "></i> {{page.total_followers}}</p>
														<p><i class="fas fa-thumbs-up share-icon"></i> {{page.total_likes}}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="btn btn-primary submit" type="button" (click)="sharePage()"
											[disabled]="globals.onLoading">
											Post
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #deletePost let-modal>
	<div class="signout">
		<div class="row">
			<div class="popup-main-content">
				<div class="person-info-form">
					<form name="profile">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-2 col-1">
									</div>
									<div class="col-md-8 col-10">
										<p class="text-center confirm-name" *ngIf="is_post">Are you sure you want to delete this post ? </p>
										<p class="confirm-name" *ngIf="!is_post">Are you sure you want to delete this
											<span *ngIf="is_group">group</span>
											<span *ngIf="!is_group">page</span> ?
										</p>
									</div>
									<div class="col-md-2 col-1">
									</div>
								</div>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12 signup-button">
										<button class="btn btn-default cbtn" type="button" (click)="modal.close('Close click')">
											<span>No</span>
										</button>&nbsp;
										<button class="btn btn-primary cbtn" type="button" *ngIf="is_post" (click)="delete(post)"
											[disabled]="globals.onLoading">
											<span>Yes</span>
										</button>
										<button class="btn btn-primary cbtn" type="button" (click)="deleteGroup()"
											[disabled]="globals.onLoading" *ngIf="!is_post && is_group">
											<span>Yes</span>
										</button>
										<button class="btn btn-primary cbtn" type="button" (click)="deletePage()"
											[disabled]="globals.onLoading" *ngIf="!is_post && !is_group">
											<span>Yes</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #shareUser let-modal>
	<div class="dialog-content">
		<div class="row">
			<div class="popup-main-content">
				<div id="edit-share-header" class="row">
					<div class="col-xs-6 ">
						<h3>Share</h3>
					</div>
					<div class=" col-xs-6 ">
						<button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
							(click)="modal.dismiss('Cross click')"></button>
					</div>
				</div>
				<div class="person-info-form">
					<form name="share-post" class="share-form">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="share-input">
											<input class="share-text" name="describe" placeholder="Share your ideas..." [(ngModel)]="describe"
												id="description" (click)="tagService.createTag($event)">
											<img src="./assets/img/face.png" class="face__emoji modal-input__emoji"
												(click)="emojiService.openEmojiPicker($event)">
										</div>

									</div>
								</div>
							</div>
						</div>
						<!-- </div> -->

						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<div class="share-post-content">
										</div>
										<div class="user">
											<div class="user-info">
												<div class="user__img">
													<img src="{{person?.user_image}}" alt=""
														onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
														class="share-img">
												</div>
												<div class="user__meta">
													<h4>{{person.user_name}}</h4>
													<p>{{person.referral_code}}</p>
													<p *ngIf="person.country">{{person.country}}</p>
													<div *ngIf="selectedType" class="icon_counts">
														<p><i class="fas fa-heart"></i> {{person.fav_count}}</p>
														<p><i class="fas fa-star share-icon"></i> {{person.total_points}}</p>
													</div>
													<div *ngIf="!selectedType" class="icon_counts">
														<p><i class="fas fa-users "></i> {{person.follower_count}}</p>
														<p><i class="fas fa-star share-icon"></i> {{person.total_points}}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="globals.onLoading" class="whirl"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-12">
										<button class="btn btn-primary submit" type="button" (click)="share()"
											[disabled]="globals.onLoading">
											<span>
												Post
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>