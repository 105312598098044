import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from '../authService/auth-service.service';
import { Router, ActivationEnd, ActivatedRouteSnapshot, ActivationStart, NavigationEnd } from '@angular/router';
import { HttpRequestService } from '../httpRequestService/http-request.service';
import { Globals } from 'src/app/helpers/constant';

@Injectable({
  providedIn: 'root'
})
export class AppInterceptorService implements HttpInterceptor {

  private access_token;

  //Function to intercept http calls being made to the server
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.access_token = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));
    this.access_token = this.access_token.web_access_token;

    //To add access token in a HTTP post request if the data being sent is not form data
    if (req.method == "POST" && this.access_token && Object.keys(req.body).length) {
      const duplicate = req.clone({ body: { ...req.body, access_token: this.access_token } });
      return next.handle(duplicate).pipe((takeUntil(this.httpService.onCancelPendingRequests())),
        map(resp => {
          if (resp.type && this.access_token) {
            if (resp instanceof HttpResponse) {
              if (resp.body.flag == 101) {
                this.authService.loggedOut();
              }
            }
          }
          // Several HTTP events go through that Observable 
          // so we make sure that this is a HTTP response
          return resp;
        })
      );
    }
    //To add access token in a HTTP post request if the data being sent is form data
    else if (req.method == "POST" && this.access_token && !Object.keys(req.body).length) {
      return next.handle(req).pipe((takeUntil(this.httpService.onCancelPendingRequests())),
        map(resp => {
          if (resp.type && this.access_token) {
            if (resp instanceof HttpResponse) {
              if (resp.body.flag == 101) {
                this.authService.loggedOut();
              }
            }
          }
          return resp;
        })
      );
    }
    return next.handle(req);
  }

  constructor(private authService: AuthService, private router: Router,
    private httpService: HttpRequestService, public globals: Globals) {

    router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        // Cancel pending calls
        let eventPath = event.url.split("/");
        let oldPath = this.globals.oldPath.split('/');

        if (eventPath[1] !== (oldPath[1])) {
          this.httpService.cancelPendingRequests();
        }
      }
    });
  }
}
