import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { UserService } from 'src/app/services/ViewServices/userServices/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalFunctions } from 'src/app/helpers/service';
import { NetworkTemplateComponent } from '../../templates/modals/network-template/network-template.component';

@Component({
  selector: 'app-view-more',
  templateUrl: './view-more.component.html',
  styleUrls: ['./view-more.component.css'],
})
export class ViewMoreComponent implements OnInit, OnDestroy {
  id: number;
  userType: any;
  requestType: any;
  offset = 0;
  searchString = "";
  data = [];
  totalData = 0;
  searchBar = false;

  @ViewChild(NetworkTemplateComponent) networkTemplateComponent: NetworkTemplateComponent;
  routeSubscriber: any;
  isRider: boolean;
  eventSubscription: any;

  constructor(public globals: Globals, private userHelper: UserService, private route: ActivatedRoute, private toaster: ToastrService, private serviceFunction: GlobalFunctions, private router: Router) {

    this.routeSubscriber = this.route.params.subscribe(params => {
      this.id = parseInt(params.id);
    })

    this.requestType = this.router.getCurrentNavigation().extras.state != undefined ? this.router.getCurrentNavigation().extras.state.split(',')[1] : this.userType;
    this.userType = this.router.getCurrentNavigation().extras.state != undefined ? this.router.getCurrentNavigation().extras.state.split(',')[0] : this.requestType;

    this.requestType = this.requestType != null ? localStorage.setItem('requestType', JSON.stringify((parseInt(this.requestType)))) : parseInt(this.requestType);
    this.userType = this.userType != null ? localStorage.setItem('userType', JSON.stringify((parseInt(this.userType)))) : (parseInt(this.userType));

    this.userType = (localStorage.getItem('userType') == 'undefined' || !localStorage.getItem('userType')) ? '' : JSON.parse(localStorage.getItem('userType'));
    this.requestType = (localStorage.getItem('requestType') == 'undefined' || !localStorage.getItem('requestType')) ? '' : JSON.parse(localStorage.getItem('requestType'));

    $('#searchRiderQudos').click(function () {
      document.getElementById('searchRiderQudos').style.borderBottom = "1px solid #8080803d";
    })
  }

  ngOnInit() {
    if(this.requestType == 2) {
      this.getQudosFavList();
    } else {
      this.getFriendList();
    }

  }

  ngOnDestroy() {
    this.routeSubscriber.unsubscribe();
  }

  public clear() {
    this.searchString = '';
    this.searchBar = false;
    this.getFriendList();
  }

  ///////////////////////////////////////////// FRIEND LIST //////////////////////////////////////////////

  //Get List of friends
  public getFriendList(loadMore = false) {
    // this.globals.onLoading = true;
    this.globals.pageLoad = true;
    if (this.globals.invalidInput) {
      this.globals.onLoading = false;
      this.globals.pageLoad = false;
      return;
    }
    if (this.searchString.length > 0 && !loadMore) {
      this.offset = 0;
      this.data = [];
    }
    let data = this;
    this.userHelper.seeAllFriendsType(data, loadMore).subscribe(result => {
      this.data = result.data;
      this.totalData = result.totalData;
      this.globals.onLoading = false;
      this.globals.pageLoad = false;
    },
      err => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      })
  }

  public getQudosFavList(loadMore = false) {
    // this.globals.onLoading = true;
    this.globals.pageLoad = true;
    if (this.globals.invalidInput) {
      this.globals.onLoading = false;
      this.globals.pageLoad = false;
      return;
    }
    if (this.searchString.length > 0 && !loadMore) {
      this.offset = 0;
      this.data = [];
    }
    let data = this;
    this.userHelper.getQudosFavList(data, loadMore).subscribe(result => {
      this.data = result.data;
      this.totalData = result.totalData;
      this.globals.onLoading = false;
      this.globals.pageLoad = false;
    },
      err => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      })
  }

  ////////////////////////////////////////////////////////// LOAD MORE ////////////////////////////////////////////
  //Load More Data 
  public loadMore() {
    this.offset += 10;
    this.getFriendList(true);
  }

  // Reject Person
  public rejectPerson(user, type, index) {
    this.globals.onLoading = true;

    let data = this;
    this.userHelper.rejectPerson(data, user, type, index, 3).subscribe(result => {
      this.data = result.data;
      this.totalData = result.totalData;
      this.globals.onLoading = false;
    },
      err => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      })
  };

  // Add Person
  public addPerson(user, type, index) {
    this.globals.onLoading = true;

    let data = this;
    this.userHelper.addPerson(data, user, type, index, 3).subscribe(result => {
      this.data = result.data;
      this.totalData = result.totalData;
      this.globals.onLoading = false;
    },
      err => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      })
  };

  //To cancel the friend request
  public cancelRequest(user, type, index) {
    this.globals.onLoading = true;

    let data = this;
    this.userHelper.cancelRequest(data, user, type, index, 3).subscribe(result => {
      this.data = result.data;
      this.totalData = result.totalData;
      this.globals.onLoading = false;
    },
      err => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      })
  };

  ////////////////////////////////////////////////////////// ADD DRIVER/RIDER ////////////////////////////////////////////// 
  //Modal to add driver
  public addFavouriteDriver(driver, index) {
    this.isRider = false
    this.networkTemplateComponent.isRider = this.isRider;
    this.networkTemplateComponent.driver = driver;
    this.networkTemplateComponent.selectedIndex = index;
    this.networkTemplateComponent.data = this.data;
    this.networkTemplateComponent.option = 3;
    this.serviceFunction.openModal(this.networkTemplateComponent.confirmModalNetwork);
    this.eventSubscription = this.serviceFunction.on('modalClosed', (data: any) => {
      if (data != 'Close click') {
        this.data[index].is_fav_driver = this.networkTemplateComponent.data[index].is_fav_driver;
        this.data[index].requested = this.networkTemplateComponent.data[index].requested;
        this.data[index].is_follower = this.networkTemplateComponent.data[index].is_follower;
        this.data[index].is_friend = this.networkTemplateComponent.data[index].is_friend;
        this.data[index].fav_count = this.data[index].fav_count >= 0 ? this.data[index].fav_count + 1 : 0;
      }
      this.eventSubscription.unsubscribe();
    })
  };

  //Modal function to add rider/driver friend 
  public sendFriendRequest(friend, type, index) {
    this.networkTemplateComponent.friend = friend;
    this.networkTemplateComponent.selectedType = type;
    this.networkTemplateComponent.selectedIndex = index;
    this.networkTemplateComponent.isRider = !type;
    this.networkTemplateComponent.data = this.data;
    this.networkTemplateComponent.option = 3;
    this.serviceFunction.openModal(this.networkTemplateComponent.confirmModalNetwork);
  };


  //////////////////////////////////////////// FOLLOW/UNFOLLOW //////////////////////////////////////////////////////
  //To follow a Driver/Rider
  public addFollower(user, type, index) {
    this.globals.onLoading = true;
    let data = this
    this.userHelper.addFollow(data, user, type, index, 3).subscribe(result => {
      this.data = result.data;
      this.totalData = result.totalData;
      this.globals.onLoading = false;
    },
      err => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      })
  }

  //To remove the follower
  public removeFollower(user, type, index) {
    this.globals.onLoading = true;
    let data = this
    this.userHelper.removeFollow(data, user, type, index, 3).subscribe(result => {
      this.data = result.data;
      this.totalData = result.totalData;
      this.globals.onLoading = false;
    },
      err => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      })
  }

  ///////////////////////////////////////////////// SHARE ////////////////////////////////////////////////////////

  //Modal to share driver/rider details
  public sharePerson(person, type) {
    this.networkTemplateComponent.viewMorePage = true;
    this.networkTemplateComponent.usertype = type;
    this.networkTemplateComponent.selectedType = type;
    if (type) {
      this.networkTemplateComponent.driver = person;
      this.networkTemplateComponent.personId = person.user_id;
    }
    else {
      this.networkTemplateComponent.rider = person;
      this.networkTemplateComponent.personId = person.user_id;
    }

    let modalOptions = {
      windowClass: 'app-modal-window',
      size: 'md',
      backdropClass: 'modal-backdrop-custom',
      keyboard: false,
      backdrop: 'static'
    }
    this.serviceFunction.openModal(this.networkTemplateComponent.shareDetails, modalOptions);
  }

  /////////////////////////////////////////////////////// REMOVE DRIVER/RIDER //////////////////////////////////////////////////////
  //confirm modal to remove driver
  public removeFavouriteDriver(driver, index) {
    this.networkTemplateComponent.isRider = false;
    this.networkTemplateComponent.selectedIndex = index;
    this.networkTemplateComponent.driver = driver;
    this.networkTemplateComponent.data = this.data;
    this.networkTemplateComponent.option = 3;

    this.serviceFunction.openModal(this.networkTemplateComponent.confirmModalQudos);
    this.eventSubscription = this.serviceFunction.on('modalClosed', (data: any) => {
      if (data != 'Close click') {
        this.data[index].is_fav_driver = this.networkTemplateComponent.data[index].is_fav_driver;
        this.data[index].is_follower = this.networkTemplateComponent.data[index].is_follower;
        this.data[index].is_friend = this.networkTemplateComponent.data[index].is_friend;
        this.data[index].fav_count = this.data[index].fav_count > 0 ? this.data[index].fav_count - 1 : 0;
      }
      this.eventSubscription.unsubscribe();
    })

  };

  //Modal to remove rider/driver as a friend
  public removePersonFriend(friend, type, index) {

    this.networkTemplateComponent.friend = friend;
    this.networkTemplateComponent.isRider = !type;
    this.networkTemplateComponent.selectedType = type;
    this.networkTemplateComponent.selectedIndex = index;
    this.networkTemplateComponent.data = this.data;
    this.networkTemplateComponent.option = 3;

    this.serviceFunction.openModal(this.networkTemplateComponent.confirmModalQudos);
  };

}
