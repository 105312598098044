import { Injectable } from '@angular/core';
import { emoji_arr } from 'src/app/helpers/emojiArray';
import { Globals } from 'src/app/helpers/constant';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root' 
})
//Service to create an emoji picker
export class EmojiService {

  activeCategory = 0;
  activeCategoryName = "Smileys & Emotion";
  mainDiv: any; activeEmojiArray: any; mainEditor: any; innerDiv;
  childDiv: any; childContentDiv: any; innerChildSpan: any;
  innerSpan: any; editorId: any; editor: any; 
  emojiPickerVisible: boolean = false;
  editorClass: any;
  isComment = false;

  emojis = {
    "Smileys & Emotion": [],
    "People & Body": [],
    "Animals & Nature": [],
    "Food & Drink": [],
    "Travel & Places": [],
    "Activities": [],
    "Objects": [],
    "Symbols": [],
    "Flags": []
  }
  constructor(public globals: Globals) {
    this.init();
    let parentScope = this;

    //function to manage clicks inside/outside of the emoji picker
    $('body').click(function (evt) {
      //Targetting the id and class of the emoji icon used .
      if (evt.target.id == "emoji-picker" || evt.target.className == 'face__emoji' || evt.target.className == "face__emoji comment_emoji")
        return;
      //For descendants of emoji-picker being clicked, remove this check if you do not want to put constraint on descendants.
      if ($(evt.target).closest('#emoji-picker').length)
        return;

      //Do processing of click event here for every element except with id emoji-picker
      if (parentScope.emojiPickerVisible) {
        $("#emoji-picker").hide();
        parentScope.emojiPickerVisible = false;
      }
    });
  }

  init() {
    let parentScope = this;
    //Filtering the emoji array to remove emoji with unciode verison greater than 11 and then categorising it .
    _.forEach(emoji_arr, function (value, key) {
      if (value.unicode_version <= (11 as unknown as string)) // unicode version check
      {
        if (!parentScope.globals.mobileView) {
          if (value.unicode_version != '') {
            parentScope.emojis[value.category].push(value);
          }
        }
        else {
          parentScope.emojis[value.category].push(value);
        }
      }
    });

    //creation of the emoji picker view
    this.mainDiv = document.createElement('div') as HTMLElement;
    this.mainDiv.setAttribute('class', 'emoji__content');
    this.mainDiv.setAttribute('id', 'emoji-picker');
    this.mainDiv.setAttribute('onmousedown', 'return false');
    this.mainDiv.setAttribute('onselectstart', 'return false')

    this.innerDiv = document.createElement('div') as HTMLElement;
    this.innerDiv.setAttribute('class', 'emoji__outer')

    this.childDiv = document.createElement('div') as HTMLElement;
    this.childDiv.setAttribute('class', 'emoji-title');

    _.forEach(parentScope.emojis, function (value, key) {
      let i = Object.keys(parentScope.emojis).indexOf(key)
      parentScope.innerSpan = document.createElement('span') as HTMLElement;
      parentScope.innerSpan.innerText = value[0].emoji;
      parentScope.innerSpan.id = "innerSpan_" + i;
      parentScope.innerSpan.className = 'category-list';
      parentScope.innerSpan.setAttribute('data-categoryname', key);

      parentScope.childDiv.appendChild(parentScope.innerSpan);
      (function (i) {
        parentScope.innerSpan.addEventListener("click", function () {
          parentScope.activeCategory = i;
          parentScope.activeCategoryName = key;
          parentScope.changeEmojiCategory(parentScope.activeCategory, parentScope.activeCategoryName)
        }, false);
      })(i);
    })

    this.innerChildSpan = document.createElement('span') as HTMLElement;
    this.innerChildSpan.setAttribute('class', 'category__name')
    this.innerChildSpan.innerHTML = this.activeCategoryName;

    this.childContentDiv = document.createElement('div') as HTMLElement;
    this.childContentDiv.setAttribute('class', 'emoji-area');
    this.childContentDiv.setAttribute('id', 'emojiarea');

    this.activeEmojiArray = this.emojis[this.activeCategoryName];
    _.forEach(this.activeEmojiArray, function (value, key) {
      parentScope.innerSpan = document.createElement('span') as HTMLElement;
      parentScope.innerSpan.innerText = value.emoji;
      parentScope.innerSpan.className = 'emoji__face';
      parentScope.childContentDiv.appendChild(parentScope.innerSpan);
      (function (value, key) {
        parentScope.innerSpan.addEventListener("click", function () {
          parentScope.selectedEmoji(value);
        }, false);
      })(parentScope.activeEmojiArray[key], key);
    })

    this.mainDiv.appendChild(this.innerDiv);
    this.innerDiv.appendChild(this.childDiv)
    this.innerDiv.appendChild(this.innerChildSpan)
    this.innerDiv.appendChild(this.childContentDiv);
  }

  //change cateogry in emoji picker
  public changeEmojiCategory(value, activeCategoryName) {
    let parentScope = this;
    this.activeCategory = value;
    $('#innerSpan_' + value).addClass('active');
    $("span:not(#innerSpan_" + value + ")").removeClass('active')
    $('#emojiarea').html("");
    this.innerChildSpan.innerHTML = activeCategoryName
    this.activeEmojiArray = this.emojis[activeCategoryName]

    _.forEach(this.activeEmojiArray, function (value, key) {
      parentScope.innerSpan = document.createElement('span') as HTMLElement;
      parentScope.innerSpan.innerText = value.emoji;
      parentScope.innerSpan.className = 'emoji__face';
      parentScope.childContentDiv.appendChild(parentScope.innerSpan);
      (function (value, key) {
        parentScope.innerSpan.addEventListener("click", function () {
          parentScope.selectedEmoji(value);
        }, false);
      })(parentScope.activeEmojiArray[key], key);
    })
  };

  //Function to add the selected emoji in the desired location 
  public selectedEmoji(value) {
    //If the editor is changed to a contenteditable div as tagging has been initialized
    if (Object.keys(this.globals.tagify).length && this.globals.activeInput.id == this.editorId) {
      let sel, range;
      if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        //To enter emoji in the selected contenteditable div only
         if (window.getSelection()['baseNode'].parentNode.className == 'tagify__input' || window.getSelection()['baseNode'].parentNode.tagName == 'TAGS') {
          if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            // Range.createContextualFragment() would be useful here but is
            // only relatively recently standardized and is not supported in
            // some browsers (IE9, for one)
            let el = document.createElement("div");
            el.innerHTML = value.emoji;
            let frag = document.createDocumentFragment(), node, lastNode;
            while ((node = el.firstChild)) {
              lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);
            // Preserve the selection
            if (lastNode) {
              range = range.cloneRange();
              range.setStartAfter(lastNode);
              range.collapse(true);
              sel.removeAllRanges();
              sel.addRange(range);
            }
          }
         }
      }
      else if (document['selection'] && document['selection'].type != "Control") {
        // IE < 9
        document['selection'].createRange().pasteHTML(value.emoji);
      }

      if (this.isComment) {
        var selectedElement = $("#" + this.editorId);
      }
      else {
        var selectedElement = $('.' + this.editorClass);
      }

      if ($(this.editor).prop('class').split(" ")[2] && !this.isComment) {
        this.globals.description = selectedElement[0].textContent;
      }
      else if (this.isComment) {
        this.globals.posts[this.globals.postIndex].comment_describe = selectedElement[0].textContent;
      }
    }
    else {
      //If the editor is of input type 
      if (this.editor.selectionStart || this.editor.selectionStart == '0') {
        let startPos = this.editor.selectionStart;
        let endPos = this.editor.selectionEnd;
        this.editor.value = this.editor.value.substring(0, startPos) +
          value.emoji +
          this.editor.value.substring(endPos, this.editor.value.length);
        this.editor.selectionStart = startPos + value.emoji.length;
        this.editor.selectionEnd = endPos + value.emoji.length;
        this.editor.scrollTop = this.editor.scrollHeight;
      }
      else {
        this.editor.value += value.emoji;
      }

      if (this.isComment)
        var selectedInput = document.getElementById(this.editorId);
      else {
        let selectedInput = <HTMLElement>(document.getElementsByClassName(this.editorClass)[0]);
      }
      selectedInput.dispatchEvent(new Event("input"));
      selectedInput.dispatchEvent(new Event("change"));

      if (this.isComment)
        var selected_Input = $("#" + this.editorId);
      else {
        let selected_Input = $("." + this.editorClass);
      }

      let cursorPosition = selected_Input.prop('selectionEnd');

      //If the emoji is to be inserted at random position
      selected_Input.val((<string>selected_Input.val()).substring(0, cursorPosition) + '' + (<string>selected_Input.val()).substring(cursorPosition))
        .focus()
        .prop({ 'selectionStart': cursorPosition, 'selectionEnd': cursorPosition }); //To focus back at the caret position after one insertion
    }
  };

  //Function to open emoji picker and managing position .
  public openEmojiPicker(event, isEdit = false, isComment = false) {
    this.isComment = isComment;

    if (!isEdit) {
      if (Object.keys(this.globals.tagify).length && (this.globals.activeInput.id == event.target.previousElementSibling.id)) {
        this.editorId = event.target.previousElementSibling.previousElementSibling.firstElementChild.id;
        this.editorClass = event.target.previousElementSibling.previousElementSibling.firstElementChild.dataset['class'].split(' ')[0]
      }
      else {
        this.editorId = event.target.previousElementSibling.id;
        this.editorClass = event.target.previousElementSibling.className.split(" ")[0];
      }
    }
    else {
      if (Object.keys(this.globals.tagify).length) {
        this.editorId = event.target.previousElementSibling.firstElementChild.firstElementChild.id;
        this.editorClass = event.target.previousElementSibling.firstElementChild.firstElementChild.dataset['class'].split(" ")[0];
      }
      else {
        this.editorId = event.target.previousElementSibling.firstElementChild.id;
        this.editorClass = event.target.previousElementSibling.firstElementChild.className.split(" ")[0];
      }
    }
    this.mainEditor = event.target.offsetParent;

    if (this.isComment)
      this.editor = $('#' + this.editorId)[0];
    else
      this.editor = $('.' + this.editorClass)[0];

    this.mainDiv.setAttribute('style', 'display: initial');
    this.mainEditor.appendChild(this.mainDiv);

    this.changeEmojiCategory(0, "Smileys & Emotion");
    $('#innerSpan_0').addClass('active');
    $('#' + this.editorId).bind("cut copy paste", function (e) {
      e.preventDefault();
    });
    $('.' + this.editorClass).bind("cut copy paste", function (e) {
      e.preventDefault();
    });

      $('#emojiarea').click(function(event){
          if((event.target.parentNode.parentNode.parentNode.parentNode.children[1]?.tagName == "TAGS"
          && event.target.parentNode.parentNode.parentNode.parentNode.children[1].children[0]?.id == "description") 
          || (event.target.parentNode.parentNode.parentNode.parentNode.children[1]?.id == "description")
          )
          {
            $("#description").animate({scrollLeft: $("#description").scrollLeft() + 50}, 10);
          }
          else if(event.target.parentNode.parentNode.parentNode.parentNode.children[2]!==undefined 
          && (event.target.parentNode.parentNode.parentNode.parentNode.children[2].tagName=="TAGS"
          || event.target.parentNode.parentNode.parentNode.parentNode.children[2].tagName=="INPUT")){
            let description = event.target.parentNode.parentNode.parentNode.parentNode.children[2];
            if(description.tagName=="TAGS")
            {
              $(description.children[0]).animate({scrollLeft: $(description.children[0]).scrollLeft() + 50}, 10);
            }
            else{
              description.scrollLeft = description.scrollWidth;
            }
          }
          else if((event.target.parentNode.parentNode.parentNode.parentNode.children[0].children[0]?.id == "description")
          || (event.target.parentNode.parentNode.parentNode.parentNode.children[0].children[0]?.children[0]?.id == "description"))
          {
            let description1 = event.target.parentNode.parentNode.parentNode.parentNode.children[0].children[0];
            let description2 = event.target.parentNode.parentNode.parentNode.parentNode.children[0].children[0].children[0];

            let description = description1.id!==undefined ? description1 : description2;

            if(description.tagName=="TAGS")
            {
              $(description.children[0]).animate({scrollLeft: $(description.children[0]).scrollLeft() + 50}, 10);
            }
            else{
              description.scrollLeft = description.scrollWidth;
            }
            
          }
          else
          {
            let editorId = event.target.parentNode.parentNode.parentNode.parentNode.children[3]?.children[1].children[0].children[1];
            if(editorId?.children.length > 0)
            {
              $(editorId?.children[0]).animate({scrollLeft: $(editorId.children[0]).scrollLeft() + 50}, 10);
            }
            else{
              $(editorId).animate({scrollLeft: $(editorId).scrollLeft() + 50}, 10);
            }
          }
          
      });

    
    $(this.editor).focus();

    setTimeout(() => {
      if ($("#emoji-picker").is(":visible")) {
        this.emojiPickerVisible = true;
      } else {
        this.emojiPickerVisible = false;
      }
    }, 100);
  };
}
function isInView(el) {
  let rect = el.getBoundingClientRect();           // absolute position of video element
  return !(rect.top > $(window).height() || rect.bottom < 0);   // visible?
}

$(document).on("scroll", function() {
  $( "video" ).each(function() {
    let vid = <HTMLVideoElement>$(this)[0];
    if (isInView($(this)[0])) {                    // visible?
      if (vid.paused) vid.play();    // play if not playing
    }
    else {
      if (!vid.paused) vid.pause();  // pause if not paused
    }
  });  
});  
