import { Injectable } from '@angular/core';
import { API, Globals } from 'src/app/helpers/constant';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { GlobalFunctions } from 'src/app/helpers/service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

//Service for the group functionalities.
export class GroupService {

  constructor(private http: HttpClient, private toaster: ToastrService, public globals: Globals, private serviceFunction: GlobalFunctions) { }

  //Leave Group 
  leaveGroup(data, selectedData = '', type = 1): Observable<any> {
    return this.http.post<any>(API.LEAVE_GROUP, {
      qudos_user_type: this.globals.user.qudos_user_type,
      group_id: type == 1 ? data.groupId : selectedData['group_id']
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 2) {
            data['groups'] = data['groups'].filter(function (val, index) {
              return val.group_id != selectedData['group_id']
            });
            data['total_groups'] -= 1;
          }
          else if (type == 1) {
            data.total_followers = response.total_followers;
            data.total_members = response.total_members;
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Join Group 
  joinGroup(data, selectedData = '', type = 1): Observable<any> {
    return this.http.post<any>(API.JOIN_GROUP, {
      qudos_user_type: this.globals.user.qudos_user_type,
      group_id: type == 1 ? data.groupId : selectedData['group_id']
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
          if (type == 1) {
            data.is_member = 0;
            data.is_follower = 0;
          }
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 1) {
            data.is_member = 1;
            data.is_follower = 1;
            data.total_followers = response.total_followers;
            data.total_members = response.total_members;
          }
          else if (type == 2) {
            data['groups'] = data['groups'].filter(function (val, index) {
              return val.group_id != selectedData['group_id']
            });
            data['total_groups'] -= 1;
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Follow Group
  followGroup(data, group = '', index = 0, type = 1): Observable<any> {

    return this.http.post<any>(API.FOLLOW_GROUP, {
      qudos_user_type: this.globals.user.qudos_user_type,
      group_id: type == 1 ? data.groupId : group['group_id']
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
          data.total_followers = '';
          data.total_members = '';
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 1) {
            data.is_follower = 1;
            data.total_followers = response.total_followers;
            data.total_members = response.total_members;
          } else if (type == 2) {
            data.groups[index].is_follower = 1;
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Unfollow Group
  unfollowGroup(data, group = '', index = 0, type = 1): Observable<any> {
    return this.http.post<any>(API.UNFOLLOW_GROUP, {
      qudos_user_type: this.globals.user.qudos_user_type,
      group_id: type == 1 ? data.groupId : group['group_id']
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 1) {
            data.is_follower = 0;
            data.total_followers = response.total_followers;
            data.total_members = response.total_members;
          } else if (type == 2) {
            data.groups[index].is_follower = 0;
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Get group Info
  getGroupDetails(data, type = 1, id = '', limit = 2): Observable<any> {

    return this.http.post<any>(API.GROUP_DETAILS, {
      qudos_user_type: this.globals.user.qudos_user_type,
      group_id: type == 1 ? data.groupId : id,
      limit: limit,
      offset: data.offset
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.groupData = response['group_data'][0];
          data.members = response['member_data'];
          data.total_members = response['total_members'];
          data.is_admin = response['is_admin'];
          data.is_member = response['is_member'];
          data.is_follower = response['is_follower'];
          data.is_deleted = response['group_data'][0].is_deleted;
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Share Group
  shareGroup(data, selectedData = '', type = 1): Observable<any> {
    data.describe = this.globals.description ? this.globals.description : data.describe;
    if ((data.describe) === undefined || data.describe.length == 0) {
      return throwError('Please enter a description.');
    } else {
      let params = new FormData();
      params.append("access_token", this.globals.user.web_access_token);
      params.append('qudos_user_type', this.globals.user.qudos_user_type);
      params.append('tagged_users', this.globals.taggedArray.toString());
      params.append('tagged_members', JSON.stringify(this.globals.taggedMember));
      params.append('description', encodeURIComponent(this.serviceFunction.htmlEncode((data.describe))));
      params.append('group_id', type == 1 ? data.groupId : selectedData['group_id'])

      return this.http.post<any>(API.SHARE_GROUP, params).pipe(
        map(response => {
          if (typeof (response) == 'string') {
            response = JSON.parse(response);
          }
          if (response.error) {
            this.toaster.error(response.error, "ERROR");
          } else {
            this.toaster.success(response.log, 'SUCCESS');
            let auth_data = JSON.parse(localStorage.getItem('auth'));
            auth_data.total_posts += 1;
            this.globals.user = auth_data;
            localStorage.setItem('auth', JSON.stringify(auth_data));
            if (Object.keys(this.globals.tagify).length) {
              this.globals.tagify.destroy();
              this.globals.tagify = {};
            }
            this.globals.description = '';
          }
          return data;
        }),
        catchError(this.serviceFunction.handleError)
      )
    }
  }

  //Delete Group
  deleteGroup(data): Observable<any> {

    return this.http.post<any>(API.DELETE_GROUP, {
      qudos_user_type: this.globals.user.qudos_user_type,
      group_id: data.groupId,
    }).pipe(
      map(response => {
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else
          this.toaster.success(response.log, 'SUCCESS');
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Edit Group Details
  editGroup(data, image_flag, type = 1): Observable<any> {
    let params = new FormData();
    params.append("access_token", this.globals.user.web_access_token);
    params.append('qudos_user_type', this.globals.user.qudos_user_type);
    params.append('group_id', data.groupId);
    params.append("image", data.myFile)
    params.append('image_flag', image_flag);
    if (type == 2) {
      params.append('members', JSON.stringify(data.members))
      params.append('name', (data.newGroupName));
    } else {
      params.append('description', data.editData.description);
      params.append('name', data.editData.group_name);
    }
    return this.http.post<any>(API.EDIT_GROUP, params).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          this.toaster.success(response.log, 'SUCCESS');
          data.updatedGroupImage = response.group_image;
          data.updatedMembers = response.total_members;
          if (type == 1) {
            this.getGroupDetails(data);
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }
  //Create Group 
  createGroup(data): Observable<any> {
    let file = data.myFile;
    if (file) {
      data.image = 1;
    } else {
      data.image = 0;
    }
    if ((data.description == undefined || (data.description.trim()).length == 0)) {
      data.description = '';
    } else {
      data.description = data.description;
    }
    let params = new FormData();
    params.append("access_token", this.globals.user.web_access_token);
    params.append('group_name', data.groupName);
    params.append('description', data.description);
    params.append('image', file);
    params.append('group_type', data.group_type);
    params.append('image_flag', data.image);
    params.append('qudos_user_type', this.globals.user.qudos_user_type);
    params.append('members', JSON.stringify(data.members))
    return this.http.post<any>(API.CREATE_GROUP, params).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.groupName = '';
          this.toaster.success(response.log, 'SUCCESS');
          $(".friendList").prop('checked', false);
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Fetch group details by group id
  fetchGroupById(data): Observable<any> {

    return this.http.post<any>(API.FETCH_GROUP_BY_ID, {
      group_id: data.groupId
    }).pipe(
      map(response => {
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.group = response['groupInfo'][0];
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //To get the list of all riders and drivers .
  getNetworkList(data, loadMore): Observable<any> {

    return this.http.post<any>(API.NETWORK_LIST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: 10,
      offset: data.offset,
      searchString: data.searchFriend
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, 'ERROR')
        } else {
          data.total_friends = response['total_friends'];
          if (loadMore) {
            data.friends = data.friends.concat(response['friends']);
          } else {
            data.friends = response['friends'];
          }
          data.friends_diff = _.differenceWith(data.friends, data.members, function (a, b) {
            return (a['qudos_user_id'] == (b)['qudos_user_id'] && a['qudos_user_type'] == (b)['qudos_user_type'])
          });
          data.friends_diff = data.members.concat(data.friends_diff);
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //To get the list of all users and groups .
  getMemberList(data): Observable<any> {

    return this.http.post<any>(API.MEMBER_LIST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: 25,
      offset: data.offset,
      searchString: data.searchFriend
    }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }
}
