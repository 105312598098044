import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewChecked,
  ChangeDetectorRef,
} from "@angular/core";
import { Globals } from "src/app/helpers/constant";
import { UserService } from "src/app/services/ViewServices/userServices/user.service";
import { PageService } from "src/app/services/ViewServices/pageServices/page.service";
import { ToastrService } from "ngx-toastr";
import { GlobalFunctions } from "src/app/helpers/service";
import { GroupService } from "src/app/services/ViewServices/groupService/group.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NetworkTemplateComponent } from "../../templates/modals/network-template/network-template.component";
import * as bodyScrollLock from "src/assets/js/bodyScrollLock.js";
import { PostService } from "src/app/services/ViewServices/postServices/post.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-network",
  templateUrl: "./network.component.html",
  styleUrls: ["./network.component.css"],
})
export class NetworkComponent implements OnInit, OnDestroy, AfterViewChecked {
  selectedIndex: any;
  selectedInnerIndex: any;
  drivers = [];
  riders = [];
  groups = [];
  members = [];
  peoples = [];
  pages = [];
  faveDrivers = [];
  confirmPeople = [];
  friends_diff = [];
  total_drivers = 0;
  total_riders = 0;
  total_groups = 0;
  total_pages = 0;
  filter = {};
  friends = [];
  searchString = "";
  offset = 0;
  tab = 1;
  innerTab: number;
  searchBar = false;
  emptyResult = false;
  filterModalDriver = false;
  filterModalRider = false;
  filterModalGroup = false;
  uploadImage = false;
  groupName: any;
  filter_name: any;
  filter_country: any;
  filter_keyword: any;
  filter_city: any;
  filter_zip: any;
  filter_seats: any;
  filter_group_name: any;
  filter_page_name: any;
  total_people: any;
  totalFaveDrivers: any;
  group_type = 0;
  pageName: any;
  myFile: any;
  routeSubscriber: any;
  total_confirmations: any;
  searchFriend: any;
  total_friends: any;
  createTab: any;
  previousPage = 0;
  newPage = 0;
  type;
  pageType = [
    { name: "Public (Default)", value: 0 },
    { name: "Private", value: 1 },
  ];
  selectedPageType = this.pageType[0];
  pressedKeyCount: any = 0;
  eventSubscription: any;
  isSearchActive: boolean;
  eventSubscribe: JQuery<Document>;
  filterModalsSubscription: any;
  modalOpenSubscriber: any;
  isModalOpen: boolean;
  modalClosedSubscriber: any;
  public tabType: string;

  @ViewChild(NetworkTemplateComponent)
  networkTemplateComponent: NetworkTemplateComponent;
  description: any;
  page_type: number;
  targetElement: any;

  constructor(
    public globals: Globals,
    private toaster: ToastrService,
    private serviceFunction: GlobalFunctions,
    private groupHelper: GroupService,
    private userHelper: UserService,
    private pageHelper: PageService,
    private route: ActivatedRoute,
    private postHelper: PostService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.routeSubscriber = this.route.data.subscribe((params) => {
      this.selectedIndex = params.selectedIndex;
      this.selectedInnerIndex = params.selectedInnerIndex;
    });

    //Fetch the data values sent through the routes.
    this.selectedIndex =
      this.router.getCurrentNavigation().extras.state != undefined
        ? this.router.getCurrentNavigation().extras.state.split(",")[0]
        : this.selectedIndex;
    this.selectedInnerIndex =
      this.router.getCurrentNavigation().extras.state != undefined
        ? this.router.getCurrentNavigation().extras.state.split(",")[1]
        : this.selectedInnerIndex;

    const targetElement = document.querySelector("#body");
    let parentScope = this;

    /* To check the letter typed in search in mobile view and
       search functionality is run on pressing enter on keypad and
       return/next button on android or ios
    */
    this.eventSubscribe = $(document).on("keyup", function (e) {
      if (
        location.pathname == "/network" &&
        parentScope.globals.mobileView &&
        !parentScope.isModalOpen
      ) {
        parentScope.pressedKeyCount += 1;
        if (e.keyCode == 13 || e.key === "Enter") {
          parentScope.innerTable(parentScope.tab, parentScope.innerTab);
        }
      } else {
        e.preventDefault();
      }
      parentScope.cdRef.detectChanges();
    });
  }

  ngOnInit() {
    this.route.url.subscribe((res) => {
      this.type = this.route.snapshot.params.type;
      this.tabType = this.route.snapshot.params.tab;
      if (this.type) {
        if (this.type == "group") {
          this.selectedIndex = 3;
          if (this.tabType && this.tabType == "explore") {
            this.innerTab = 2;
            this.selectedInnerIndex = 2;
          } else if (this.tabType && this.tabType == "mygroups") {
            this.innerTab = 1;
            this.selectedInnerIndex = 1;
          } else if (this.tabType && this.tabType == "creategroups") {
            this.innerTab = 3;
            this.selectedInnerIndex = 3;
          }
          this.initTable(this.selectedIndex);
        } else if (this.type == "page") {
          this.selectedIndex = 4;
          if (this.tabType && this.tabType == "explore") {
            this.innerTab = 2;
            this.selectedInnerIndex = 2;
          } else if (this.tabType && this.tabType == "mypage") {
            this.innerTab = 1;
            this.selectedInnerIndex = 1;
          } else if (this.tabType && this.tabType == "createpages") {
            this.innerTab = 3;
            this.selectedInnerIndex = 3;
          }
          this.initTable(this.selectedIndex);
        }else if (this.type == "people") {
          this.selectedIndex = 2;
          if (this.tabType && this.tabType == "explore") {
            this.innerTab = 2;
            this.selectedInnerIndex = 2;
          } else if (this.tabType && this.tabType == "myfriends") {
            this.innerTab = 1;
            this.selectedInnerIndex = 1;
          } else if (this.tabType && this.tabType == "pending") {
            this.innerTab = 3;
            this.selectedInnerIndex = 3;
          }
          this.initTable(this.selectedIndex);
        }
         else if (this.type == "qudosFav") {
          this.selectedIndex = 1;
          if (this.tabType && this.tabType == "myFav") {
            this.innerTab = 1;
            this.selectedInnerIndex = 1;
          } else if (this.tabType && this.tabType == "explore") {
            this.innerTab = 2;
            this.selectedInnerIndex = 2;
          } else if (this.tabType && this.tabType == "pending") {
            this.innerTab = 3;
            this.selectedInnerIndex = 3;
          }
          this.initTable(this.selectedIndex);
        }
      } else {
        this.selectedIndex = 2;
        this.initTable(this.selectedIndex);
      }
    });

    this.initTable(this.selectedIndex);
    this.filterModalsSubscription = this.serviceFunction.on(
      "FilterModals",
      (data: any) => {
        this.filter_name = data.filter_name;
        this.filter_country = data.filter_country;
        this.filter_keyword = data.filter_keyword;
        this.filter_city = data.filter_city;
        this.filter_zip = data.filter_zip;
        this.filter_seats = data.filter_seats;
        this.filter_group_name = data.filter_group_name;
        this.filter_page_name = data.filter_page_name;
        this.filterInnerTable(data.page, data.innerTab, data.searchType);
        this.serviceFunction.getDismissReason("Close");
      }
    );

    this.modalOpenSubscriber = this.serviceFunction.on(
      "modalOpen",
      (data: any) => {
        this.isModalOpen = true;
      }
    );

    this.modalClosedSubscriber = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        this.isModalOpen = false;
      }
    );
  }

  ngOnDestroy() {
    this.routeSubscriber.unsubscribe();
    this.modalOpenSubscriber.unsubscribe();
    this.modalClosedSubscriber.unsubscribe();
    this.filterModalsSubscription.unsubscribe();
    this.eventSubscribe.off("keyup");
  }

  ngAfterViewChecked() {
    /* 
    Functionality to change the filter icon to search icon
    on entering any letter in the search field in mobile view and
    vice versa on empty search field.
    */

    if (this.globals.mobileView) {
      if (this.searchString.length) {
        this.isSearchActive = true;
      } else {
        this.isSearchActive = false;
        if (this.emptyResult && this.isModalOpen) {
          this.emptyResult = false;
          this.innerTable(this.tab, this.innerTab);
        } else if (this.pressedKeyCount && this.searchString.length == 0) {
          this.pressedKeyCount = 0;
          this.innerTable(this.tab, this.innerTab);
        }
      }
    }
    this.cdRef.detectChanges();
  }

  //To manage the files attached (images)
  fileName(input, isPage, val = "") {
    this.myFile = input[0];
    let file_name = this.myFile.name;
    let file_type = this.myFile.type;
    if (
      file_type.toLowerCase() != "image/jpeg" &&
      file_type.toLowerCase() != "image/png"
    ) {
      this.toaster.error("Image can be jpg,png only", "ERROR");
      this.myFile = undefined;
    } else {
      if (isPage) {
        $(".uplaod_txt")[val].innerHTML = file_name.slice(0, 20);
        document.getElementsByClassName("uplaod_txt")[val].style.color =
          "black";
      } else {
        file_name = (<string>$("#file").val()).split("\\")[2];
        if (file_name) {
          this.uploadImage = true;
          $("#newGroupImage").show();
        } else {
          this.uploadImage = false;
        }
      }
    }
    if (input && input[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        const path = (<FileReader>e.target).result as string;
        $("#newGroupImage").attr("src", path).width(150).height(200);
      };
      reader.readAsDataURL(input[0]);
    }
  }

  //Reset to original values and call the default function again
  public clear() {
    this.searchBar = false;
    this.searchString = "";
    this.filter_name = this.networkTemplateComponent.filter_name = "";
    this.filter_country = this.networkTemplateComponent.filter_country = "";
    this.filter_keyword = this.networkTemplateComponent.filter_keyword = "";
    this.filter_city = this.networkTemplateComponent.filter_city = "";
    this.filter_zip = this.networkTemplateComponent.filter_zip = "";
    this.filter_seats = this.networkTemplateComponent.filter_seats = undefined;
    this.filter_group_name = this.networkTemplateComponent.filter_group_name =
      "";
    this.filter_page_name = this.networkTemplateComponent.filter_page_name = "";
    this.globals.invalidInput = false;
    this.globals.invalidInputValue = "";
    this.innerTable(this.tab, this.innerTab);
  };

  // Reject Person
  public rejectPerson(user, type, index) {
    this.globals.onLoading = true;
    let data = this;

    this.userHelper.rejectPerson(data, user, type, index, 4).subscribe(
      (result) => {
        this.confirmPeople = result.confirmPeople;
        this.innerTab = 2;
        this.innerTable(this.tab, this.innerTab);
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      }
    );
  };

  // Add Person
  public addPerson(user, type, index) {
    this.globals.onLoading = true;

    let data = this;
    this.userHelper.addPerson(data, user, type, index).subscribe(
      (result) => {
        this.confirmPeople = result.confirmPeople;
        this.innerTab = 1;
        this.innerTable(this.tab, this.innerTab);
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      }
    );
  };

  //To cancel the friend request
  public cancelRequest(user, type, index) {
    this.globals.onLoading = true;

    let data = this;
    this.userHelper.cancelRequest(data, user, type, index).subscribe(
      (result) => {
        this.drivers = result.drivers;
        this.riders = result.riders;
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      }
    );
  };

  ////////////////////////////////////////////////////////// ADD DRIVER/RIDER //////////////////////////////////////////////
  //Modal to add driver
  public addFavouriteDriver(driver) {
    this.networkTemplateComponent.isRider = false;
    this.networkTemplateComponent.driver = driver;

    this.serviceFunction.openModal(
      this.networkTemplateComponent.confirmModalNetwork
    );

    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        if (data != "Close click") {
          this.innerTab = 1;
          this.innerTable(this.tab, this.innerTab);
        }
        this.eventSubscription.unsubscribe();
      }
    );
  };

  //Modal function to add rider/driver friend
  public sendFriendRequest(friend, type, index) {
    this.networkTemplateComponent.friend = friend;
    this.networkTemplateComponent.selectedType = type;
    this.networkTemplateComponent.selectedIndex = index;
    this.networkTemplateComponent.isRider = !type;

    this.serviceFunction.openModal(
      this.networkTemplateComponent.confirmModalNetwork
    );
  };

  //////////////////////////////////////////// FOLLOW/UNFOLLOW //////////////////////////////////////////////////////
  //To follow a Driver/Rider
  public addFollower(user, type, index) {
    this.globals.onLoading = true;

    let data = this;
    this.userHelper.addFollow(data, user, type, index).subscribe(
      (result) => {
        this.riders = result.riders;
        this.drivers = result.drivers;
        this.faveDrivers = result.faveDrivers;
        this.confirmPeople = result.confirmPeople;
        this.peoples = result.peoples;
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      }
    );
  };

  //To remove the follower
  public removeFollower(user, type, index) {
    this.globals.onLoading = true;

    let data = this;
    this.userHelper.removeFollow(data, user, type, index).subscribe(
      (result) => {
        this.riders = result.riders;
        this.drivers = result.drivers;
        this.faveDrivers = result.faveDrivers;
        this.confirmPeople = result.confirmPeople;
        this.peoples = result.peoples;
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      }
    );
  };

  //To follow a Group
  public followGroup(group, index) {
    this.globals.onLoading = true;

    let data = this;
    this.groupHelper.followGroup(data, group, index, 2).subscribe(
      (result) => {
        this.groups = result.groups;
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      }
    );
  };

  //Unfollow page
  public unfollowGroup(group, index) {
    this.globals.onLoading = true;

    let data = this;
    this.groupHelper.unfollowGroup(data, group, index, 2).subscribe(
      (result) => {
        this.groups = result.groups;
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      }
    );
  };

  //To follow a page
  public followUnfollowPage(page, index, followType) {
    this.globals.onLoading = true;

    let data = this;
    this.pageHelper.followUnfollow(data, page, index, followType).subscribe(
      (result) => {
        this.pages = result.pages;
        this.pages[index].total_followers = result.total_followers;
        this.pages[index].total_likes = result.total_likes;
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      }
    );
  };

  ///////////////////////////////////////////// FRIEND LIST //////////////////////////////////////////////

  //Get List of friends(riders & drivers)
  public getFriendList(loadMore = false) {
    this.globals.onLoading = true;
    $("#group-4").addClass("hidden");

    if (this.globals.invalidInput) {
      this.globals.pageLoad = false;
      return;
    }
    if (this.searchFriend && !loadMore) {
      this.offset = 0;
      this.friends = [];
    }
    let data = this;
    this.groupHelper.getNetworkList(data, loadMore).subscribe(
      (result) => {
        this.friends = result.friends;
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      }
    );
  };

  ///////////////////////////////////////////// GROUP LIST //////////////////////////////////////////////

  //Get List of groups
  public getGroupList(type, loadMore = false) {
    let data = this;
    this.userHelper.groupList(data, type, loadMore).subscribe(
      (result) => {
        this.networkTemplateComponent.groups = this.groups = result.groups;
        // this.groups.sort((a,b) => (a['group_id'] < b['group_id']) ? 1 : ((b['group_id'] < a['group_id']) ? -1: 0));
        this.networkTemplateComponent.total_groups = this.total_groups =
          result.total_groups;
        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      }
    );
  };

  ///////////////////////////////////////////////// SHARE ////////////////////////////////////////////////////////

  //Modal to share driver/rider details
  public sharePerson(person, type) {
    this.networkTemplateComponent.describe = "";
    this.networkTemplateComponent.selectedType = type;
    this.networkTemplateComponent.person = person;
    this.networkTemplateComponent.usertype = type;

    if (type) {
      this.networkTemplateComponent.driver = person;
      this.networkTemplateComponent.personId = person.driver_id;
    } else {
      this.networkTemplateComponent.rider = person;
      this.networkTemplateComponent.personId = person.user_id;
    }

    let modalOptions = {
      windowClass: "app-modal-window",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };

    this.serviceFunction.openModal(
      this.networkTemplateComponent.shareDetails,
      modalOptions
    );
  };

  //////////////////////////////////////////////////// FILTER ///////////////////////////////////////////////////////

  //Modal to filter Drivers
  public filterDriverModal() {
    let modalOptions = {
      windowClass: "app-modal-window",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.networkTemplateComponent.tab = this.tab;
    this.networkTemplateComponent.innerTab = this.innerTab;
    this.serviceFunction.openModal(
      this.networkTemplateComponent.filterDriver,
      modalOptions
    );
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        if (data == "Close click") {
          this.clear();
        }
        this.eventSubscription.unsubscribe();
      }
    );
  };

  //Modal to filter riders
  public filterRiderModal() {
    this.networkTemplateComponent.isRider = true;
    let modalOptions = {
      windowClass: "app-modal-window",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.networkTemplateComponent.tab = this.tab;
    this.networkTemplateComponent.innerTab = this.innerTab;
    this.serviceFunction.openModal(
      this.networkTemplateComponent.filterRider,
      modalOptions
    );
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        if (data == "Close click") {
          this.clear();
        }
        this.eventSubscription.unsubscribe();
      }
    );
  };

  //Modal to filter groups
  public filterGroupModal() {
    let modalOptions = {
      windowClass: "app-modal-window",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.networkTemplateComponent.tab = this.tab;
    this.networkTemplateComponent.innerTab = this.innerTab;
    this.serviceFunction.openModal(
      this.networkTemplateComponent.filterGroup,
      modalOptions
    );
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        if (data == "Close click") {
          this.clear();
        }
        this.eventSubscription.unsubscribe();
      }
    );
  };

  //Modal to filter pages
  public filterPageModal() {
    let modalOptions = {
      windowClass: "app-modal-window",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.networkTemplateComponent.tab = this.tab;
    this.networkTemplateComponent.innerTab = this.innerTab;
    this.serviceFunction.openModal(
      this.networkTemplateComponent.filterPage,
      modalOptions
    );
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        if (data == "Close click") {
          this.clear();
        }
        this.eventSubscription.unsubscribe();
      }
    );
  };

  //Function to manage the filtering in riders/drivers/groups/pages.
  filterInnerTable(page, innerTab, searchType = 0) {
    this.offset = 0;
    this.innerTab = innerTab;
    if (this.globals.invalidInput) {
      this.filter_name = this.filter_name
        ? this.filter_name.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_city = this.filter_city
        ? this.filter_city.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_zip = this.filter_zip
        ? this.filter_zip.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_keyword = this.filter_keyword
        ? this.filter_keyword.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_page_name = this.filter_page_name
        ? this.filter_page_name.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_country = this.filter_country
        ? this.filter_country.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_group_name = this.filter_group_name
        ? this.filter_group_name.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_seats = this.filter_seats
        ? this.filter_seats.replace(this.globals.invalidInputValue, "")
        : undefined;
    }

    if (
      searchType &&
      (typeof this.filter_name == "undefined" ||
        this.filter_name.length == 0) &&
      (typeof this.filter_city == "undefined" ||
        this.filter_city.length == 0) &&
      (typeof this.filter_zip == "undefined" || this.filter_zip.length == 0) &&
      (typeof this.filter_keyword == "undefined" ||
        this.filter_keyword.length == 0) &&
      (typeof this.filter_page_name == "undefined" ||
        this.filter_page_name.length == 0) &&
      (typeof this.filter_country == "undefined" ||
        this.filter_country.length == 0) &&
      (typeof this.filter_group_name == "undefined" ||
        this.filter_group_name.length == 0) &&
      (typeof this.filter_seats == "undefined" || this.filter_seats.length == 0)
    ) {
      this.toaster.error("Please enter a search value", "ERROR");
      this.globals.pageLoad = false;
      this.clear();
      return;
    }

    if (page == 1) {
      if (this.innerTab == 1) {
        this.networkList(4);
      } else if (this.innerTab == 2) {
        this.networkList(2);
      } else if (this.innerTab == 3) {
        this.getPendingList(1);
      }
    } else if (page == 2) {
      if (this.innerTab == 1) {
        this.networkList(3);
      } else if (this.innerTab == 2) {
        this.networkList(1);
      } else if (this.innerTab == 3) {
        this.getPendingList(0);
      }
    } else if (page == 3) {
      if (this.innerTab == 1) {
        this.getGroupList(1);
      } else if (this.innerTab == 2) {
        this.getGroupList(2);
      } else if (this.innerTab == 3) {
        $("#userSearch").hide();
        $("#group-3").removeClass("hidden");
        $("#group-3").addClass("active");
        this.getFriendList();
      }
    } else if (page == 4) {
      if (this.innerTab == 1) {
        this.getPageList(0);
      } else if (this.innerTab == 2) {
        this.getPageList(1);
      } else if (this.innerTab == 3) {
      }
    }
  }

  ///////////////////////////////////////////// PAGE LIST //////////////////////////////////////////////

  //Get List of pages
  public getPageList(type, loadMore = false) {
    let data = this;
    data.filter_page_name = this.filter_page_name;
    this.pageHelper.getPagesList(data, type, loadMore).subscribe(
      (result) => {
        this.networkTemplateComponent.pages = this.pages = result.pages;
        this.networkTemplateComponent.total_pages = this.total_pages =
          result.total_pages;

        this.globals.pageLoad = false;
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      }
    );
  };

  //To like/unlike a page
  public likeUnlikePage(page, index, type) {
    this.globals.onLoading = true;

    let data = this;
    this.pageHelper.likeUnlike(data, page, index, type).subscribe(
      (result) => {
        this.pages = result.pages;
        this.pages[index].total_followers = result.total_followers;
        this.pages[index].total_likes = result.total_likes;
        this.globals.onLoading = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
      }
    );
  };

  //To delete a page
  public deletePageModal(page, type) {
    this.networkTemplateComponent.is_post = false;
    this.networkTemplateComponent.is_group = false;
    this.networkTemplateComponent.page = page;
    this.networkTemplateComponent.selectedType = type;

    let modalOptions = {
      windowClass: "app-modal-window-custom",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.serviceFunction.openModal(
      this.networkTemplateComponent.deletePost,
      modalOptions
    );
  };

  //To edit a page
  public editPageModal(pageData) {
    this.networkTemplateComponent.pageData = pageData;
    this.networkTemplateComponent.editData = pageData;
    this.networkTemplateComponent.tempData = { ...pageData };

    let modalOptions = {
      windowClass: "app-modal-window",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.serviceFunction.openModal(
      this.networkTemplateComponent.editPageModal,
      modalOptions
    );
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        if (data != "Close click") {
          this.innerTable(this.tab, this.innerTab);
        }
        this.eventSubscription.unsubscribe();
      }
    );
  };

  //Create Page
  public createPage() {
    this.globals.onLoading = true;
    if (this.globals.invalidInput) {
      this.pageName = this.pageName
        ? this.pageName.replace(this.globals.invalidInputValue, "")
        : "";
    }
    if (this.pageName.length == 0) {
      this.toaster.error("Please enter page name.", "ERROR");
      this.globals.onLoading = false;
    } else if (this.pageName.length > 255) {
      this.toaster.error(
        "Name of the page should not be greater than 255",
        "ERROR"
      );
      this.globals.onLoading = false;
    } else {
      let data = this;
      data.myFile = this.myFile;
      data.description = this.description;
      data.page_type = this.selectedPageType.value;

      this.pageHelper.createPage(data).subscribe(
        (result) => {
          this.pages = result.pages;
          this.innerTab = 1;
          this.innerTable(this.tab, this.innerTab);
          this.pageName = "";
          this.globals.onLoading = false;
        },
        (err) => {
          this.toaster.error(err, "ERROR");
          this.globals.onLoading = false;
        }
      );
    }
  };

  //Copy link
  public linkCopy(value) {
    bodyScrollLock.disableBodyScroll(this.targetElement);
    let copyValue = environment.LINK + "page/" + value;
    this.postHelper.copyLink(copyValue, 4, 2).subscribe((result) => {
      if (result) {
        bodyScrollLock.clearAllBodyScrollLocks();
        this.toaster.info("Link Copied", "INFORMATION");
      }
    });
  };

  ////////////////////////////////////////////////////////// CREATE GROUP /////////////////////////////////////////

  //Function to check valid values while creating a group.
  public createGroupStep() {
    if (this.globals.invalidInput) {
      this.groupName = this.groupName
        ? this.groupName.replace(this.globals.invalidInputValue, "")
        : "";
    }
    if (!this.groupName) {
      this.toaster.error("Enter Group Name", "ERROR");
    } else if (this.groupName.length > 255) {
      this.toaster.error(
        "Name of the group should not be greater than 255",
        "ERROR"
      );
    } else {
      if (this.members.length == 0) {
        this.toaster.error("Select users for the group", "ERROR");
      } else {
        $("#group-3").addClass("hidden");
        $("#group-4").removeClass("hidden");
        $("#group-4").addClass("active");
      }
    }
  };

  //Function to manage the selected friends while creating a group
  public getCheckedValue() {
    this.members = [];
    let parentScope = this;
    $("input:checkbox:checked").each(function () {
      let indexVal: any = $(this).val();
      parentScope.friends_diff[indexVal].checked = true;
      parentScope.members.push(parentScope.friends_diff[indexVal]);
    });
  };

  //Modal to create group
  public createGroupModal() {
    this.offset = 0;
    this.networkTemplateComponent.myFile = this.myFile;
    this.networkTemplateComponent.description = this.description;
    this.networkTemplateComponent.members = this.members;
    this.networkTemplateComponent.groupName = this.groupName;
    this.networkTemplateComponent.group_type = this.group_type;

    let modalOptions = {
      windowClass: "app-modal-window-custom",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.serviceFunction.openModal(
      this.networkTemplateComponent.createGroupSucess,
      modalOptions
    );

    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        this.innerTab = 1;
        this.innerTable(this.tab, this.innerTab);
        $("#group-4").addClass("hidden");
        this.eventSubscription.unsubscribe();
      }
    );
  };

  /////////////////////////////////////////////////// CANCEL GROUP //////////////////////////////////////////////

  //Modal to  cancel creation of group
  public cancelGroupModal(val) {
    this.offset = 0;
    this.networkTemplateComponent.is_group = val;

    let modalOptions = {
      windowClass: "app-modal-window-custom",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.serviceFunction.openModal(
      this.networkTemplateComponent.cancelGroup,
      modalOptions
    );
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        if (data != "Close click") {
          $("#group-6").show();
          $("#userSearch").show();
          this.groupName = "";
          $(".friendList").prop("checked", false);
          this.uploadImage = false;
          $("#newGroupImage").hide();
          this.innerTable(this.tab, 2);
        }
        this.eventSubscription.unsubscribe();
      }
    );
  };

  //Cancel creation of page
  public cancelPageModal(val) {
    this.offset = 0;
    this.networkTemplateComponent.is_group = val;

    let modalOptions = {
      windowClass: "app-modal-window-custom",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.serviceFunction.openModal(
      this.networkTemplateComponent.cancelGroup,
      modalOptions
    );
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        if (data != "Close click") {
          this.pageName = "";
          this.innerTable(this.tab, 2);
        }
        this.eventSubscription.unsubscribe();
      }
    );
  };

  ///////////////////////////////////////////// SHARE GROUP/PAGE ////////////////////////////////////////
  // Modal to share details of a group
  public shareGroupModal(group) {
    this.networkTemplateComponent.describe = "";
    this.networkTemplateComponent.group = group;
    this.networkTemplateComponent.option = 0;

    let modalOptions = {
      windowClass: "app-modal-window",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.serviceFunction.openModal(
      this.networkTemplateComponent.shareGroupModal,
      modalOptions
    );
  };

  // Modal to share details of a page
  public sharePageModal(page) {
    this.networkTemplateComponent.describe = "";
    this.networkTemplateComponent.page = page;

    let modalOptions = {
      windowClass: "app-modal-window",
      size: "md",
      backdropClass: "modal-backdrop-custom",
      keyboard: false,
      backdrop: "static",
    };
    this.serviceFunction.openModal(
      this.networkTemplateComponent.sharePageModal,
      modalOptions
    );
  };

  ////////////////////////////////////////////////////////// JOIN/LEAVE GROUP /////////////////////////////////////////

  //join group
  public joinGroupModal(group) {
    this.networkTemplateComponent.isMember = 0;
    this.offset = 0;
    this.networkTemplateComponent.group = group;
    this.networkTemplateComponent.option = 2;

    this.serviceFunction.openModal(this.networkTemplateComponent.confirmGroup);
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        if (data != "Close click") {
          this.groups = this.networkTemplateComponent.groups;
          this.total_groups = this.networkTemplateComponent.total_groups;
        }
        this.eventSubscription.unsubscribe();
      }
    );
  };

  //Modal to create group
  public leaveGroupModal(group) {
    this.networkTemplateComponent.isMember = 1;
    this.offset = 0;
    this.networkTemplateComponent.group = group;
    this.networkTemplateComponent.option = 2;

    this.serviceFunction.openModal(this.networkTemplateComponent.confirmGroup);
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        if (data != "Close click") {
          this.groups = this.networkTemplateComponent.groups;
          this.total_groups = this.networkTemplateComponent.total_groups;
        }
        this.eventSubscription.unsubscribe();
      }
    );
  };

  ///////////////////////////////////////////////// LOAD MORE ////////////////////////////////////////////////////

  //Load More Data
  public loadMore(val) {
    if (val == 1) {
      //QudosFave Explore
      this.offset += 10;
      if (this.filterModalDriver) {
        this.networkList(2, true);
      } else {
        this.networkList(2, true);
      }
    } else if (val == 2) {
      //People Explore
      this.offset += 10;
      if (this.filterModalRider) {
        // this.applyRiderFilter(true);
        this.networkList(1, true);
      } else {
        this.networkList(1, true);
      }
    } else if (val == 3) {
      //Group Explore
      this.offset += 10;
      if (this.filterModalGroup) {
        this.getGroupList(2, true);
      } else {
        this.getGroupList(2, true);
      }
    } else if (val == 4) {
      //QudosFave Friends
      this.offset += 10;
      if (this.filterModalDriver) {
        this.networkList(4, true);
      } else {
        this.networkList(4, true);
      }
    } else if (val == 5) {
      //People Friends
      this.offset += 10;
      if (this.filterModalRider) {
        this.networkList(3, true);
      } else {
        this.networkList(3, true);
      }
    } else if (val == 6) {
      this.offset += 10;
      this.getFriendList(true);
    } else if (val == 7) {
      //My Group
      this.offset += 10;
      if (this.filterModalGroup) {
        this.getGroupList(1, true);
      } else {
        this.getGroupList(1, true);
      }
    } else if (val == 8) {
      //QudosFave Pending
      this.offset += 10;
      if (this.filterModalDriver) {
        this.getPendingList(1, true);
      } else {
        this.getPendingList(1, true);
      }
    } else if (val == 9) {
      //People Pending
      this.offset += 10;
      if (this.filterModalRider) {
        this.getPendingList(0, true);
      } else {
        this.getPendingList(0, true);
      }
    } else if (val == 10) {
      //My Pages
      this.offset += 10;
      if (this.filterModalRider) {
        // this.getPendingList(0, true);
      } else {
        this.getPageList(0, true);
      }
    } else if (val == 11) {
      //Pages Explore
      this.offset += 10;
      if (this.filterModalRider) {
        // this.getPendingList(0, true);
      } else {
        this.getPageList(1, true);
      }
    }
  };

  /////////////////////////////////////////////////////// REMOVE DRIVER/RIDER //////////////////////////////////////////////////////

  //confirm modal to remove driver
  public removeFavouriteDriver(driver) {
    this.networkTemplateComponent.isRider = false;
    this.networkTemplateComponent.driver = driver;

    this.serviceFunction.openModal(
      this.networkTemplateComponent.confirmModalQudos
    );
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        this.totalFaveDrivers = this.networkTemplateComponent.totalFaveDrivers;
        this.faveDrivers = this.networkTemplateComponent.faveDrivers;
        this.eventSubscription.unsubscribe();
      }
    );
  };

  //Modal to remove rider/driver as a friend
  public removePersonFriend(friend, type) {
    this.networkTemplateComponent.isRider = !type;
    this.networkTemplateComponent.friend = friend;
    this.networkTemplateComponent.selectedType = type;
    this.networkTemplateComponent.option = 1;

    this.serviceFunction.openModal(
      this.networkTemplateComponent.confirmModalQudos
    );
    this.eventSubscription = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        this.peoples = this.networkTemplateComponent.peoples;
        this.total_people = this.networkTemplateComponent.total_people;
        this.eventSubscription.unsubscribe();
      }
    );
  };

  ///////////////////////////////////////////// INITIAL MAIN FUNCTION //////////////////////////////////////////////

  //Function to manage the user data in the rider driver tab
  public networkList(type, loadMore = false) {
    this.globals.pageLoad = true;
    let data = this;
    this.userHelper.networkList(data, type, loadMore).subscribe(
      (result) => {
        if (type == 1) {
          this.networkTemplateComponent.riders = this.riders = result.riders;
          this.networkTemplateComponent.total_riders = this.total_riders =
            result.total_riders;
        } else if (type == 2) {
          this.networkTemplateComponent.drivers = this.drivers = result.drivers;
          this.networkTemplateComponent.total_drivers = this.total_drivers =
            result.total_drivers;
        } else if (type == 3) {
          this.networkTemplateComponent.peoples = this.peoples = result.peoples;
          this.networkTemplateComponent.total_people = this.total_people =
            result.total_people;
        } else if (type == 4) {
          this.networkTemplateComponent.totalFaveDrivers =
            this.totalFaveDrivers = result.totalFaveDrivers;
          this.networkTemplateComponent.faveDrivers = this.faveDrivers =
            result.faveDrivers;
        }
        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      }
    );
  };

  //Filter modals
  public filterModals() {
    if (this.tab == 1) {
      this.filterDriverModal();
    } else if (this.tab == 2) {
      this.filterRiderModal();
    } else if (this.tab == 3) {
      this.filterGroupModal();
    } else if (this.tab == 4) {
      this.filterPageModal();
    }
  };

  //Fetch pending lists
  public getPendingList(userType, loadMore = false) {
    let data = this;
    this.userHelper.pendingList(data, userType, loadMore).subscribe(
      (result) => {
        this.networkTemplateComponent.total_confirmations =
          this.total_confirmations = result.total_confirmations;
        this.networkTemplateComponent.confirmPeople = this.confirmPeople =
          result.confirmPeople;

        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      },
      (err) => {
        this.toaster.error(err, "ERROR");
        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      }
    );
  };

  //Function to manage the inner tabs
  public innerTable(page, innerTab, searchType = 0) {
    this.previousPage = this.newPage;
    this.newPage = page;
    this.offset = 0;
    this.innerTab = innerTab;
    $("#group-6").show();
    this.createTab = false;
    this.globals.pageLoad = true;

    if (this.newPage != this.previousPage && this.previousPage != 0) {
      this.searchBar = false;
      this.searchString = "";
      this.filter_name = this.networkTemplateComponent.filter_name = "";
      this.filter_country = this.networkTemplateComponent.filter_country = "";
      this.filter_keyword = this.networkTemplateComponent.filter_keyword = "";
      this.filter_city = this.networkTemplateComponent.filter_city = "";
      this.filter_zip = this.networkTemplateComponent.filter_zip = "";
      this.filter_seats = this.networkTemplateComponent.filter_seats =
        undefined;
      this.filter_group_name = this.networkTemplateComponent.filter_group_name =
        "";
      this.filter_page_name = this.networkTemplateComponent.filter_page_name =
        "";
      this.globals.invalidInput = false;
      this.globals.invalidInputValue = "";
    }

    //If there is an invalid input then that value should be cleared
    if (this.globals.invalidInput) {
      this.filter_name = this.filter_name
        ? this.filter_name.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_city = this.filter_city
        ? this.filter_city.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_zip = this.filter_zip
        ? this.filter_zip.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_keyword = this.filter_keyword
        ? this.filter_keyword.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_page_name = this.filter_page_name
        ? this.filter_page_name.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_country = this.filter_country
        ? this.filter_country.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_group_name = this.filter_group_name
        ? this.filter_group_name.replace(this.globals.invalidInputValue, "")
        : "";
      this.filter_seats = this.filter_seats
        ? this.filter_seats.replace(this.globals.invalidInputValue, "")
        : undefined;
      this.searchString = this.searchString
        ? this.searchString.replace(this.globals.invalidInputValue, "")
        : "";
    }

    //To check if the search in the filter modals in empty
    if (
      searchType &&
      (typeof this.filter_name == "undefined" ||
        this.filter_name.length == 0) &&
      (typeof this.filter_city == "undefined" ||
        this.filter_city.length == 0) &&
      (typeof this.filter_zip == "undefined" || this.filter_zip.length == 0) &&
      (typeof this.filter_keyword == "undefined" ||
        this.filter_keyword.length == 0) &&
      (typeof this.filter_page_name == "undefined" ||
        this.filter_page_name.length == 0) &&
      (typeof this.filter_country == "undefined" ||
        this.filter_country.length == 0) &&
      (typeof this.filter_group_name == "undefined" ||
        this.filter_group_name.length == 0) &&
      (typeof this.filter_seats == "undefined" || this.filter_seats.length == 0)
    ) {
      this.toaster.error("Please enter a search value", "ERROR");
      this.globals.pageLoad = false;
      return;
    }
    if (page == 1) {
      if (this.innerTab == 1) {
        this.networkList(4);
      } else if (this.innerTab == 2) {
        this.networkList(2);
      } else if (this.innerTab == 3) {
        this.getPendingList(1);
      } else if (this.innerTab == 4) {
        this.globals.pageLoad = false;
      }
    } else if (page == 2) {
      if (this.innerTab == 1) {
        this.networkList(3);
      } else if (this.innerTab == 2) {
        this.networkList(1);
      } else if (this.innerTab == 3) {
        this.getPendingList(0);
      }
    } else if (page == 3) {
      if (this.innerTab == 1) {
        $("#userSearch").show();
        this.groupName = "";
        $(".friendList").prop("checked", false);
        this.getGroupList(1);
      } else if (this.innerTab == 2) {
        $("#userSearch").show();
        this.groupName = "";
        $(".friendList").prop("checked", false);
        this.getGroupList(2);
      } else if (this.innerTab == 3) {
        this.createTab = true;
        this.uploadImage = false;
        $("#newGroupImage").hide();
        $("#userSearch").hide();
        $("#group-3").removeClass("hidden");
        $("#group-3").addClass("active");
        $("#group-6").hide();
        this.getFriendList();
        this.globals.pageLoad = false;
      }
    } else if (page == 4) {
      if (this.innerTab == 1) {
        this.getPageList(0);
        this.pageName = "";
      } else if (this.innerTab == 2) {
        this.getPageList(1);
        this.pageName = "";
      } else if (this.innerTab == 3) {
        this.createTab = true;
        this.globals.pageLoad = false;
      }
    }
    window.scrollTo(0, 0);
  };

  //Initial Table
  public initTable(page) {
    this.globals.invalidInput = false;
    this.globals.pageLoad = true;

    this.offset = 0;
    this.tab = page;
    this.groupName = "";
    this.pageName = "";
    this.searchString = "";
    this.searchBar = false;
    this.searchFriend = "";
    this.innerTable(page, this.selectedInnerIndex, 0);
    if (this.tab == 1) {
      $("#userSearch").show();
      $(".friendList").prop("checked", false);
    } else if (this.tab == 2) {
      $("#userSearch").show();
      $(".friendList").prop("checked", false);
    } else if (this.tab == 3) {
      $("#group-3").addClass("active");
      $("#group-4").addClass("hidden");
      $(".friendList").prop("checked", false);
    } else if (this.tab == 4) {
      $(".friendList").prop("checked", false);
    }
  };

  checkSearchString(event) {
    if (!this.searchString) {
      event.stopPropagation();
      this.toaster.error("Please enter at least one character !", "ERROR");
      return;
    }
  }
}
