import { ProfileService } from 'src/app/services/ViewServices/profileService/profile.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from 'src/app/services/ViewServices/settingsServices/settings.service';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/helpers/constant';
import { GlobalFunctions } from 'src/app/helpers/service';
import { AuthTemplateComponent } from '../../templates/modals/auth-template/auth-template.component';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  languageData: any;
  languagePrivacy: any;
  languageList: [];
  firstLoad: boolean;
  @ViewChild(AuthTemplateComponent) authTemplate: AuthTemplateComponent;
  privacy: number;

  constructor(private settingsHelper: SettingsService, private toaster: ToastrService, public globals: Globals, private serviceFunction: GlobalFunctions, private ProfileService:ProfileService ) { }

  ngOnInit() {
    this.getLanguages();
    this.getPrivacy();
  }

  public getPrivacy(){
    this.ProfileService.viewProfile().subscribe(res=>{
    })
  }
  //load language list
  public getLanguages() {
    this.globals.onLoading = true;
    let data = this;
    this.settingsHelper.getLanguages(data).subscribe(
      result => {
        // this.setLanguage();
        this.globals.onLoading = false;
      },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
    );
  }

  //Manage the selected language data
  public getLanguageData(data) {
    this.languageData = data;
    this.setLanguage();
  }
  public getLanguagePrivacyData(data) {
    this.languageData = data;
    this.setLanguagePrivacy();
  }
  //set the language
  public setLanguage() {
    let data = this;
    this.globals.onLoading = true;
    this.settingsHelper.setLanguage(data).subscribe(
      result => {
        this.globals.onLoading = false;
        if(!this.firstLoad){
          this.toaster.success(result.log, "SUCCESS");
          this.firstLoad = false;
        }
      },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
    )
  }
  public setLanguagePrivacy() {
    let data = this;
    data.privacy = 0;
    this.globals.onLoading = true;
    this.settingsHelper.setLanguagePrivacy(data).subscribe(
      result => {      
        this.globals.onLoading = false;
        if(!this.firstLoad){
          this.toaster.success(result.log, "SUCCESS");
          this.firstLoad = false;
        }
      },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
    )
  }
  //logout functionality
  public logout() {
    let modalOptions = {
      windowClass: 'app-modal-window-custom',
      backdropClass: 'modal-backdrop-custom',
      keyboard: false,
      backdrop: 'static',
      size: 'md'
    }
    this.serviceFunction.openModal(this.authTemplate.confirmSignout, modalOptions)
  }

  public cancelSetLanguage(data){
    this.getLanguages();
  }
}
