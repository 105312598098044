<div class="service-wrap-header {{localStorageAuth ? 'service-wrap-login' : ''}}">
	<section class="section-core--policies section-banner">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 d-flex flex-column mt-5">
					<span class="d-inline-block h-100">
						<h1 class="core-header-special-1">&nbsp;<span class="d-block">QudosConnect Cookies Policy</span></h1>
						<p class="subheader__prime">Last Updated: April 8, 2021</p>
					</span>
				</div>
			</div>
		</div>
	</section>
</div>
<div class="service-wrap">
	<section class="section-core-rules bg-white">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">1. Cookies & other storage technologies</h5>
					<div>
						<p class="core_parag">
							Cookies are small pieces of text used to store information on web browsers. Cookies are used to store and
							receive identifiers and other information on computers, phones and other devices. Other technologies,
							including data we store on your web browser or device, identifiers associated with your device, and other
							software, are used for similar purposes. In this policy, we refer to all of these technologies as
							“cookies.”
						</p>
						<p class="core_parag">
							We use cookies if you have a Qudos account, use the Qudos Products, including our website and apps, or
							visit other websites and apps that use the Qudos Products (including the Like button or other Qudos
							Technologies). Cookies enable Qudos to offer the Qudos Products to you and to understand the information
							we receive about you, including information about your use of other websites and apps, whether or not you
							are registered or logged in.
						</p>
						<p class="core_parag">
							This policy explains how we use cookies and the choices you have. Except as otherwise stated in this
							policy, the Data Policy will apply to our processing of the data that we collect via cookies.
						</p>
					</div>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">2. Why do we use cookies?</h5>
					<div>
						<p class="core_parag">Cookies help us provide, protect and improve the Qudos Products, such as by
							personalizing content, tailoring and measuring ads, and providing a safer experience. The cookies that we
							use include session cookies, which are deleted when you close your browser, and persistent cookies, which
							stay on your browser until they expire or you delete them. While the cookies that we use may change from
							time to time as we improve and update the Qudos Products, we use them for the following purposes:</p>
						<div class="core_parag">
							<span class="bold">Authentication</span><br />
							<span>We use cookies to verify your account and determine when you’re logged in so we can make it easier
								for you to access the Qudos Products and show you the appropriate experience and features.</span>
							<p class="core_parag">
								For example: We use cookies to keep you logged in as you navigate between Qudos Pages. Cookies also help
								us remember your browser so you do not have to keep logging in to Qudos and so you can more easily log
								in to Qudos via third-party apps and websites. For example, we use the ‘c_user’ and ‘xs’ cookies for
								this purpose, with a lifespan of 365 days.
								Security, site and product integrity
							</p>
							<p class="core_parag">
								We use cookies to help us keep your account, data and the Qudos Products safe and secure.
								For example: Cookies can help us identify and impose additional security measures when someone may be
								attempting to access a Qudos account without authorisation, for instance, by rapidly guessing different
								passwords. We also use cookies to store information that allows us to recover your account in the event
								that you forget your password or to require additional authentication if you tell us that your account
								has been hacked. This includes, for example, our ‘sb’ and ‘dbln’ cookies, which enable us to identify
								your browser securely.
								We also use cookies to combat activity that violates our policies or otherwise degrades our ability to
								provide the Qudos Products.
							</p>
							<p class="core_parag">For example: Cookies help us fight spam and phishing attacks by enabling us to
								identify computers that are used to create large numbers of fake Qudos accounts. We also use cookies to
								detect computers infected with malware and to take steps to prevent them from causing further harm. Our
								‘csrf’ cookie, for example, helps us prevent cross-site request forgery attacks. Cookies also help us
								prevent underage people from registering for Qudos accounts.
								Advertising, recommendations, insights and measurement
								We use cookies to help us show ads and to make recommendations for businesses and other organisations to
								people who may be interested in the products, services or causes they promote.
							</p>
							<p class="core_parag">For example: Cookies allow us to help deliver ads to people who have previously
								visited a business’s website, purchased its products or used its apps and to recommend products and
								services based on that activity. Cookies also allow us to limit the number of times that you see an ad
								so you don’t see the same ad over and over again. For example, the ‘fr’ cookie is used to deliver,
								measure and improve the relevancy of ads, with a lifespan of 90 days.
								We also use cookies to help measure the performance of ad campaigns for businesses that use the Qudos
								Products.
							</p>
							<p class="core_parag">For example: We use cookies to count the number of times that an ad is shown and to
								calculate the cost of those ads. We also use cookies to measure how often people do things such as make
								a purchase following an ad impression. The ‘_qcp’ cookie identifies browsers for the purposes of
								providing advertising and site analytics services and has a lifespan of 90 days. .
								Cookies help us serve and measure ads across different browsers and devices used by the same person.
							</p>
							<p class="core_parag">For example: We can use cookies to prevent you from seeing the same ad over and over
								again across the different devices that you use.</p>
							<p class="core_parag">Cookies also allow us to provide insights about the people who use the Qudos
								Products, as well as the people who interact with the ads, websites and apps of our advertisers and the
								businesses that use the Qudos Products.</p>
							<p class="core_parag">For example: We use cookies to help businesses understand the kinds of people who
								like their Qudos Page or use their apps so they can provide more relevant content and develop features
								that are likely to be interesting to their customers.</p>
							<p class="core_parag">We also use cookies, like our ‘oo’ cookie, which has a lifespan of five years, to
								help you opt out of seeing ads from Qudos based on your activity on third-party websites. Learn more
								about the information we receive, how we decide which ads to show you on and off the Qudos Products and
								the controls that are available to you.
							</p>
						</div>
						<div class="core_parag">
							<span class="bold">Site features and services</span><br />
							<span>We use cookies to enable the functionality that helps us provide the Qudos Products.
								For example: Cookies help us store preferences, know when you’ve seen or interacted with Qudos Products’
								content and provide you with customised content and experiences. For instance, cookies allow us to make
								suggestions to you and others, and to customise content on third-party sites that integrate our social
								plugins. If you are a page administrator, cookies allow you to switch between posting from your personal
								Qudos account and the Page. We use cookies like the session-based ‘presence’ cookie to support your use
								of Messenger chat windows.
							</span>
							<p class="core_parag">We also use cookies to help provide you with content relevant to your locale.
								For example: We store information in a cookie that is placed on your browser or device so that you will
								see the site in your preferred language.
							</p>
						</div>
						<div class="core_parag">
							<span class="bold">Performance</span><br />
							<span>We use cookies to provide you with the best experience possible.</span><br />
							<span>For example: Cookies help us route traffic between servers and understand how quickly Qudos Products
								load for different people. Cookies also help us record the ratio and dimensions of your screen and
								windows and know whether you’ve enabled high-contrast mode, so that we can render our sites and apps
								correctly. For example, we set the ‘dpr’ and ‘wd’ cookies, each with a lifespan of 7 days, to deliver an
								optimal experience for your device’s screen.</span>
						</div>
						<div class="core_parag">
							<span class="bold">Analytics and research</span><br />
							<span>We use cookies to better understand how people use the Qudos Products so that we can improve them.
							</span>
							<p class="core_parag">For example: Cookies can help us understand how people use the Qudos service,
								analyse which parts of the Qudos Products people find most useful and engaging, and identify features
								that could be improved.
							</p>
						</div>
						<div class="core_parag">
							<span class="bold">Google Analytics</span><br />
							<span>We also set cookies from the Qudos.com domain that work with the Google Analytics service to help us
								understand how businesses use Qudos's developer sites. These cookies have names such as __utma, __utmb,
								__utmc, __utmz, __qca and _ga.</span>
						</div>
						<div class="core_parag">
							<span class="bold">Third-party websites and apps</span><br />
							<span>Our business partners may also choose to share information with Qudos from cookies set in their own
								websites' domains, whether or not you have a Qudos account or are logged in. Specifically, cookies named
								_qcc or _qcp may be set on the domain of the Qudos business partner whose site you're visiting. Unlike
								cookies that are set on Qudos's own domains, these cookies aren’t accessible by Qudos when you're on a
								site other than the one on which they were set, including when you are on one of our domains. They serve
								the same purposes as cookies set in Qudos's own domain, which are to personalise content (including
								ads), measure ads, produce analytics and provide a safer experience, as set out in this Cookies
								Policy.</span>
						</div>
					</div>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">3. Where do we use cookies?</h5>
					<div class="core_parag">
						<p class="core_parag">We may place cookies on your computer or device, and receive information stored in
							cookies, when you use or visit:</p>
						<span class="bold">Third-party websites and apps</span><br />
						<span>Products provided by other members of the Qudos Companies; and
							Websites and apps provided by other companies that use the Qudos Products, including companies that
							incorporate the Qudos Technologies into their websites and apps. Qudos uses cookies and receives
							information when you visit those sites and apps, including device information and information about your
							activity, without any further action from you. This occurs whether or not you have a Qudos account or are
							logged in.</span>
						<p class="core_parag">Do other parties use cookies in connection with the Qudos Products?</p>
						<p class="core_parag">Yes, other parties may use cookies on the Qudos Products to provide services to us and
							the businesses that advertise on Qudos.</p>
						<p class="core_parag">For example, our measurement partners use cookies on the Qudos Products to help
							advertisers understand the effectiveness of their Qudos advertising campaigns and to compare the
							performance of those campaigns to ads displayed on other websites and apps. Learn more about the companies
							that use cookies on the Qudos Products.</p>
						<p class="core_parag">Third parties also use cookies on their own sites and apps in connection with the
							Qudos Products. To understand how other parties use cookies, please review their policies.</p>
					</div>
				</div>
				<div class="col-sm-12 d-flex flex-column mt-5">
					<h5 class="qudos_blue_text my-3">4. How can you control Qudos’s use of cookies?</h5>
					<div class="core_parag">
						<span>We use cookies to help personalize and improve content and services, provide a safer experience and to
							show you useful and relevant ads on and off Qudos. You can control how we use data to show you ads and
							more by using the tools described below.</span>
						<div class="core_parag">
							<span class="bold">If you have a Qudos account:</span><br />
							<span>You can use your ad preferences to learn why you’re seeing a particular ad and control how we use
								information that we collect to show you ads.</span>
							<p class="core_parag">To show you better ads, we use data that advertisers and other partners provide us
								about your activity off Qudos Company Products, including websites and apps. You can control whether we
								use this data to show you ads in your ad settings.</p>
							<p class="core_parag">The Qudos Audience Network is a way for advertisers to show you ads in apps and
								websites off the Qudos Company Products. One of the ways Audience Network shows relevant ads is by using
								your ad preferences to determine which ads you may be interested in seeing. You can control this in your
								ad settings.</p>
							<p class="core_parag">You can review your Off-Qudos activity, which is a summary of activity that
								businesses and organizations share with us about your interactions with them, such as visiting their
								apps or websites. They use our Business Tools, like Qudos Pixel, to share this information with us. This
								helps us do things like give you a more personalized experience on Qudos. Learn more about off-Qudos
								activity, how we use it, and how you can manage it.</p>
						</div>
						<p class="core_parag">
							<span class="bold">Everyone:</span><br />
							<span>You can opt out of seeing online interest-based ads from Qudos and other participating companies
								through the Digital Advertising Alliance in the US, the Digital Advertising Alliance of Canada in Canada
								or the European Interactive Digital Advertising Alliance in Europe or through your mobile device
								settings, where available, using Android, iOS 13 or an earlier version of iOS. Please note that ad
								blockers and tools that restrict our cookie use may interfere with these controls.</span>
						</p>
						<p class="core_parag">
							<span class="bold">More information about online advertising:</span><br />
							<span>The advertising companies we work with generally use cookies and similar technologies as part of
								their services. To learn more about how advertisers generally use cookies and the choices they offer,
								you can review the following resources:</span><br />
							<span>Digital Advertising Alliance</span><br />
							<span>Digital Advertising Alliance of Canada</span><br />
							<span>European Interactive Digital Advertising Alliance</span><br />
							<span>Browser cookie controls:</span><br />
						</p>
						<p class="core_parag">In addition, your browser or device may offer settings that allow you to choose
							whether browser cookies are set and to delete them. These controls vary by browser, and manufacturers may
							change both the settings they make available and how they work at any time. As of 5 October 2020, you may
							find additional information about the controls offered by popular browsers at the links below. Certain
							parts of the Qudos Products may not work properly if you have disabled browser cookie use. Please be aware
							these controls are distinct from the controls that Qudos offers you. </p>
						<p class="core_parag">
							<span>Google Chrome</span><br />
							<span>Internet Explorer</span><br />
							<span>Firefox</span><br />
							<span>Safari</span><br />
							<span>Safari Mobile</span><br />
							<span>Opera</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>