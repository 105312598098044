<div class="page copy_page ">
    <div *ngIf="globals.onLoading">
        <div class="row h-100vh">
            <div class="col-12 col-lg-3 col-xl-2 page_sidebar">
            </div>
            <div class="col-12 col-lg-6 col-xl-8 page_content full-md post__copy">
                <div class="timeline-item box-0 centered mb-5 mt-3 mob-m-0 panel">
                    <ul class="dummy_msg_content user share-user copy-user">
                        <li class="d-flex mb-3 mt-3">
                            <div class="dummy_image img-circle "></div>
                            <div class="dummy_wrapper">
                                <div class="dummy_content"> </div>
                                <div class="dummy_content"> </div>
                            </div>
                        </li>
                        <div class="dummy_content w-60 mb-5"> </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!globals.onLoading">
        <div class="col-12 col-lg-3 col-xl-2 page_sidebar">
        </div>
        <div class="col-12 col-lg-6 col-xl-8 page_content full-md post__copy">
            <div class="copy-content" *ngIf="!globals.is_auth">
                <p><b>{{rider.user_name}} is on QudosConnect</b></p>
                <p>Join QudosConnect today to connect with {{rider.user_name.split(' ')[0]}}</p>
                <button type="button" class="join_button" routerLink="/signup" *ngIf="!globals.mobileView">Join</button>
                <button type="button" class="join_button" (click)="serviceFunction.redirectToApp()" *ngIf="globals.mobileView">Join</button>
                <p>or</p>
                <button type="button" class="login-button" routerLink="/login" *ngIf="!globals.mobileView">Log In</button>
                <button type="button" class="login-button" (click)="serviceFunction.redirectToApp()" *ngIf="globals.mobileView">Log In</button>
            </div>
            <div class="panel post-info panel_frnd_req ng-scope">
                <!------------------------------------------------- Shared Rider Details ----------------------------------------------------------->
                <div class="panel post-info ng-scope panel_frnd_req share-border-user copylink-post">
                    <div class="user share-user copy-user">
                        <div class="user-info">
                            <div class="user__img">
                                <img src="{{rider?.user_image}}" alt="" onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                    class="share-img">
                            </div>
                            <div class="user__meta share__meta">
                                <h4>{{rider.user_name}}</h4>
                                <p *ngIf="rider.country">{{rider.country}}</p>
                            </div>
                            <div>
                                <p class="shared_post_icon">
                                    <span class="share_post_text"><i class="fas fa-users "></i> {{rider.follower_count}}</span>
                                    <span class="share_post_text"><i class="fas fa-star share-icon"></i> {{rider.total_points}}</span>
                                </p>
                            </div>
                        </div>
                        <br>
                        <div>
                            <button type="button" class="button-share_post" routerLink="/userInfo/{{riderId}}/0"><span>Know More About
                                    {{rider.user_name.split(" ")[0]}}</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>