import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { AppInterceptorService } from './services/appInterceptor/app-interceptor.service';
import { Globals } from './helpers/constant';
import { HeaderComponent } from 'src/app/components/common/header/header.component';
import { FooterComponent } from 'src/app/components/common/footer/footer.component';
import { TopnavComponent } from 'src/app/components/common/topnav/topnav.component';
import { GlobalFunctions } from './helpers/service';
import { DriverComponent } from './components/user/copylink/driver/driver.component';
import { RiderComponent } from './components/user/copylink/rider/rider.component';
import { PageComponent } from './components/user/copylink/page/page.component';
import { GroupComponent } from './components/user/copylink/group/group.component';
import { UserModule } from './components/user/user.module';
import { TermsConditionsComponent } from './components/no-authentication/terms-conditions/terms-conditions.component';
import { PostingPolicyComponent } from './components/no-authentication/posting-policy/posting-policy.component';
import { PrivacyPolicyComponent } from './components/no-authentication/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './components/no-authentication/cookies-policy/cookies-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    HeaderComponent, FooterComponent, TopnavComponent,
    DriverComponent,
    RiderComponent,
    PageComponent,
    GroupComponent,
    TermsConditionsComponent,
    PostingPolicyComponent,
    PrivacyPolicyComponent,
    CookiesPolicyComponent,
  ],
  imports: [
    BrowserModule,
    NgbModalModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      autoDismiss: false,
      positionClass: 'toast-top-right',
      timeOut: 1500,
      extendedTimeOut: 1500,
      closeButton: false,
      tapToDismiss: true,
      progressBar: false,
      newestOnTop: true,
      maxOpened: 1,
      preventDuplicates: false,
    }),
    Ng2TelInputModule,
    UserModule
  ],
  providers: [Globals, { provide: HTTP_INTERCEPTORS, useClass: AppInterceptorService, multi: true }, Location, { provide: LocationStrategy, useClass: PathLocationStrategy }, GlobalFunctions],
  bootstrap: [AppComponent],
})
export class AppModule { }
