import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	constructor(public globals: Globals, router: Router, location: Location) {
		router.events
			.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					//Hide footer in only image view during route change
					if ((location.path().split("/")[1] == 'post' && location.path().split("/")[3] == '1')) {
						this.globals.hideHeaderFooter = true;
					} else {
						this.globals.hideHeaderFooter = false;
					}
				}
			});
		//Hide footer in only image view during page refresh
		if (window.location.pathname.split("/")[1] == 'post' && window.location.pathname.split("/")[3] == '1') {
			this.globals.hideHeaderFooter = true;
		} else {
			this.globals.hideHeaderFooter = false;
		}
	}
	ngOnInit() {
		this.globals.hideHeaderFooter = false;
	}
}
