<div class="page friends_page">
	<div *ngIf="globals.pageLoad">
		<div class="row h-100vh">
			<div class="col-12 col-lg-3 col-xl-2 page_sidebar">
				<div class="timeline-item mt-5">
					<div class="text-center ">
						<div class="dummy_image d-block img-circle mb-4"></div>
					</div>
					<ul>
						<li class="dummy_content w-60"> </li>
						<li class="dummy_content w-60"> </li>
						<br>
						<li class="dummy_content w-60"> </li>
						<li class="dummy_content w-70"> </li>
						<br>
						<li class="dummy_content w-60"> </li>
						<li class="dummy_content w-70"> </li>
						<br>
						<li class="dummy_content w-60"> </li>
						<li class="dummy_content w-70"> </li>
					</ul>
				</div>
			</div>
			<div class="col-12 col-lg-6 col-xl-8 page_content full-md friends_page_content">
				<div class="d-mds-none">
					<div class="timeline-item centered mb-5 mbl-mb-0">
						<div class="dummy_content"> </div>
					</div>
					<div class="timeline-item centered ">
						<ul>
							<li>
								<div class="row mar-lr-15">
									<div class="col-12 col-xs-6 col-lg-12 col-xl-6">
										<div class="user flex-row dummy_animation">
										</div>
									</div>
									<div class="col-12 col-xs-6 col-lg-12 col-xl-6">
										<div class="user flex-row dummy_animation">
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="row mar-lr-15">
									<div class="col-12 col-xs-6 col-lg-12 col-xl-6">
										<div class="user flex-row dummy_animation">
										</div>
									</div>
									<div class="col-12 col-xs-6 col-lg-12 col-xl-6">
										<div class="user flex-row dummy_animation">
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="row mar-lr-15">
									<div class="col-12 col-xs-6 col-lg-12 col-xl-6">
										<div class="user flex-row dummy_animation">
										</div>
									</div>
									<div class="col-12 col-xs-6 col-lg-12 col-xl-6">
										<div class="user flex-row dummy_animation">
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="row mar-lr-15">
									<div class="col-12 col-xs-6 col-lg-12 col-xl-6">
										<div class="user flex-row dummy_animation">
											<div class="user-info">
											</div>
										</div>
									</div>
									<div class="col-12 col-xs-6 col-lg-12 col-xl-6">
										<div class="user flex-row dummy_animation">
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="row mar-lr-15">
									<div class="col-12 col-xs-6 col-lg-12 col-xl-6">
										<div class="user flex-row dummy_animation">
										</div>
									</div>
									<div class="col-12 col-xs-6 col-lg-12 col-xl-6">
										<div class="user flex-row dummy_animation">
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="d-mds-block dummy_network_user">
					<div class="dummy_content w-60 mb-4"> </div>
					<div class="dummy_content w-70 "> </div>
					<ul class="dummy_msg_content ">
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6 ">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
						<li class="row ">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content "> </div>
										<div class="dummy_content "> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6">
								<div class="d-flex">
									<div class="dummy_image img-circle "></div>
									<div class="dummy_wrapper">
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
										<div class="dummy_content"> </div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="!globals.pageLoad">
		<div class="col-12 col-lg-3 col-xl-2 page_sidebar">
			<div class="summary">
				<div class="summary__avatar">
					<img src="{{globals.user?.user_image}}" alt="">
				</div>
				<div class="summary__title" data-toggle="tooltip" data-placement="top" title="{{globals.user.user_name}}"
					[(ngModel)]="globals.user.user_name" ngDefaultControl>
					{{globals.user.user_name}}</div>
				<a routerLink="/profile" class="summary__profile" ngDefaultControl>My Profile</a>
				<div class="summary__stat">
					<span [(ngModel)]="globals.user.total_followers" ngDefaultControl>{{globals.user.total_followers}}</span>
					<p>Followers</p>
				</div>
				<div class="summary__stat">
					<span [(ngModel)]="globals.user.total_posts" ngDefaultControl>{{globals.user.total_posts}}</span>
					<p>Posts</p>
				</div>
				<div class="summary__stat">
					<span [(ngModel)]="globals.user.total_likes" ngDefaultControl>{{globals.user.total_likes}}</span>
					<p>Likes</p>
				</div>
			</div>
		</div>

		<div class="col-12 col-lg-6 col-xl-8 page_content full-md friends_page_content">
			<div class="page_panel mob-filter search_frnds">
				<span class="mob-filter__results searchbar__fix srch-bar"
					[ngClass]="{'serch-refresh':searchString || searchBar}">
					<i class="fal fa-search"></i> <input type="text" class="search-content" id="searchRiderQudos"
						placeholder="Click to Search" [(ngModel)]="searchString" (keyup)="getFriendList()">
				</span>
				<div class="mob-filter__btns">
					<button type="button" *ngIf="searchString || searchBar" (click)="clear()" class="clear_all_btn">Clear
						All</button>
				</div>
			</div>
			<div class="results_content friend_heading-container">
				<div class="friend_heading">
					<b>
						<span *ngIf="requestType==1 || requestType==3"><svg class="social-relations"></svg> Friends</span>
						<span *ngIf="requestType==2"><img src="./assets/img/qudos-faves-icon_3.png" alt="QudosFave™ logo"
								width="32px;">
							QudosFaves™</span>
					</b>
				</div>
			</div>
			<div class="page_panel results frnds-panel">
				<div class="results_content vw-more">
					<div class="">
						<div class="row">
							<div class="col-12 col-md-6 col-lg-12 col-xl-6 md-half" *ngFor="let people of data;index as $index">
								<div class="user">
									<div class="user-info">
										<div class="user__img">
											<img src="{{people?.user_image}}" alt=""
												onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';">
										</div>
										<div class="user__meta">
											<h3 data-toggle="tooltip" data-placement="top" title="{{people.user_name}}" class="pointer-cursor"
												routerLink="/userInfo/{{people.user_id}}/{{people.follower_type}}">
												{{people.user_name}}</h3>
											<p>{{people.referral_code}}</p>
											<p class="personal-user" *ngIf="requestType==1">
												<span class="share_post_text "><i class="fas fa-users "></i> {{people.follower_count}}</span>
												<span class="share_post_text"><i class="fas fa-star share-icon"></i> {{people.points}}</span>
											</p>
											<p class="personal-user" *ngIf="requestType==2">
												<span class="share_post_text"><i class="fas fa-heart"></i> {{people.fav_count}}</span>
												<span class="share_post_text"><i class="fas fa-star share-icon"></i>
													{{people.points}}</span>
											</p>
											<p class="personal-user" *ngIf="requestType==3">
												<span class="share_post_text" *ngIf="people.qudos_user_type"><i class="fas fa-heart"></i>
													{{people.fav_count}}</span>
												<span class="share_post_text" *ngIf="!people.qudos_user_type"><i class="fas fa-users "></i>
													{{people.follower_count}}</span>
												<span class="share_post_text"><i class="fas fa-star share-icon"></i> {{people.points}}</span>
											</p>
										</div>
									</div>
									<div
										[hidden]="((globals.user.user_id == people.user_id) && (globals.user.qudos_user_type == people.follower_type))"
										class="user__actions">
										<span *ngIf="!globals.user.qudos_user_type">
											<a class="anchor-pad-0">
												<span class="span_padding" (click)="removeFavouriteDriver(people,$index)"
													*ngIf="!globals.user.qudos_user_type && people.follower_type  && people.is_friend">Remove</span>
												<span class="span_padding"
													*ngIf="!globals.user.qudos_user_type && people.follower_type  && !people.is_friend"
													(click)="addFavouriteDriver(people,$index)">
													<span *ngIf="!people.requested">Add</span>
													<span *ngIf="people.requested">Accept</span>
												</span>
												<span class="span_padding" (click)="removePersonFriend(people,people.follower_type,$index)"
													*ngIf="!globals.user.qudos_user_type && !people.follower_type && people.is_friend ">Remove</span>
												<span *ngIf="!globals.user.qudos_user_type && !people.follower_type && !people.is_friend">
													<span class="span_padding" *ngIf="!people.requested && !people.friend_request"
														(click)="sendFriendRequest(people,people.follower_type,$index)">Add</span>
													<span class="span_padding" *ngIf="people.requested && !people.friend_request"
														(click)="addPerson(people,people.follower_type,$index)">Accept</span>
													<span class="span_padding" *ngIf="people.friend_request && !people.requested"
														(click)="cancelRequest(people,people.follower_type,$index)">Cancel</span>
												</span>
											</a>
										</span>
										<span *ngIf="globals.user.qudos_user_type">
											<a class="anchor-pad-0">
												<span class="span_padding" (click)="removePersonFriend(people,people.follower_type,$index)"
													*ngIf="people.is_friend ">Remove</span>
												<span *ngIf="!people.is_friend">
													<span class="span_padding" *ngIf="!people.requested && !people.friend_request"
														(click)="sendFriendRequest(people,people.follower_type,$index)">Add</span>
													<span class="span_padding" *ngIf="people.requested && !people.friend_request"
														(click)="addPerson(people,people.follower_type,$index)">Accept</span>
													<span class="span_padding" *ngIf="people.friend_request && !people.requested"
														(click)="cancelRequest(people,people.follower_type,$index)">Cancel</span>
												</span>
											</a>
										</span>
										<a *ngIf="people.requested && !people.friend_request" class="anchor-pad-0">
											<span (click)="rejectPerson(people,people.follower_type,$index)"
												class="span_padding">Reject</span>
										</a>
										<a class="anchor-pad-0">
											<span *ngIf="!people.is_follower"
												(click)="addFollower(people.user_id,people.follower_type,$index)"
												class="span_padding">Follow</span>
											<span *ngIf="people.is_follower"
												(click)="removeFollower(people.user_id,people.follower_type,$index)"
												class="span_padding">Unfollow</span>
										</a>
										<a [hidden]="people.requested && !people.friend_request" class="anchor-pad-0">
											<span (click)="sharePerson(people,people.follower_type)" class="span_padding">Share</span>
										</a>
									</div>
								</div>
							</div>
							<div class="col-md-12 text-center" *ngIf="!totalData  && !globals.onLoading">
								<h3 class="empty-data">No data exists to display.</h3>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12" *ngIf="(data.length < totalData) && !globals.onLoading">
							<button class="results__more" type="button" (click)="loadMore()" [disabled]="globals.onLoading">Load
								More</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-3 col-xl-2 page_sidebar">
		</div>
	</div>
</div>

<app-network-template></app-network-template>