import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-posting-policy',
    templateUrl: './posting-policy.component.html',
    styleUrls: ['./posting-policy.component.css']
})
export class PostingPolicyComponent implements OnInit {

    localStorageAuth = localStorage.hasOwnProperty('auth');
    
    constructor() { }

    ngOnInit(): void {
    }

}
