import { Component, OnInit, AfterViewChecked, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Globals } from 'src/app/helpers/constant';
import { SocketService } from 'src/app/services/socket/socket.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalFunctions } from 'src/app/helpers/service';
import { GroupService } from 'src/app/services/ViewServices/groupService/group.service';
import { EmojiService } from 'src/app/services/emojiService/emoji.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit, AfterViewChecked, OnDestroy {
  newGroupName: any;
  user: any;
  searchFriend: string;
  roomId: any;
  room_type: any;
  receiverName: any;
  recipient_id: any;
  recipient_type: number;
  totalMembers: any;
  adminId: any;
  adminType: number;
  user_image: any;
  online: any;
  sent: {};
  receive: {};
  message: string;
  chatHistory = [];
  fullChatHistory = [];
  dialogList = [];
  activeTab = false;
  previousIndex = 0;
  newIndex = 0;
  limit = 100;
  skip = 0;
  chatView = false;
  createMessageView = false;
  createMessageSuccess = false;
  memberList = [];
  total_members = 0;
  offset = 0;
  friendListView = false;
  members = [];
  groupNameValue = false;
  chatdialogs = [];
  firstLoad = true;
  editGroupView = false;
  members_diff = [];
  myFile: any;
  attachedFile: any;
  base64textString: string;
  chatdialogsLength: any;
  moveChatBox: boolean = false;
  receiveSocketSubscribe: any;
	groupId: any;
	activeIndex: number;
	dialogData: any;
	messageCount: number;
	read: any[];
	readAll: any;

  constructor(public globals: Globals, private socketService: SocketService, private toaster: ToastrService, private serviceFunction: GlobalFunctions, private groupHelper: GroupService, public emojiService: EmojiService, private cdrf: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.globals.pageLoad = true;
    this.receiveMessage();
    this.fetchDialogs();
    this.fetchMessageHistory();
    this.sentMessageSuccess();

    setInterval(() => {
      this.socketService.fetchChatRooms();
    }, 60000);
  }

  public ngAfterViewChecked() {
    if (this.moveChatBox) {
      $('#chat-1').scrollTop($('#chat-1')[0].scrollHeight);
      this.moveChatBox = false;
    }

    if (this.globals.chatLoad) {
      this.init();
      this.globals.chatLoad = false;
    }
    this.cdrf.detectChanges();
  }

  public ngOnDestroy() {
    this.receiveSocketSubscribe.unsubscribe();
  }

  //Back button in the mobile view 
  public back() {
    this.chatView = false;
    this.createMessageView = false;
    this.globals.message = '';
    if (this.globals.mobileView) {
      this.chatdialogs[this.newIndex].activeTab = false;
      this.roomId = '';
    }
  }

  //Remove selected user during group creation
  public close(index) {
    let parentScope = this;
    let indexVal = _.findIndex(this.members_diff, function (i) {
      if (!i['group_id']) {
        if (i['qudos_user_id'] == parentScope.members[index].qudos_user_id && i['qudos_user_type'] == parentScope.members[index].qudos_user_type) {
          i['checked'] = false;
        }
      }
      else {
        if (i['group_id'] == parentScope.members[index].group_id) {
          i['checked'] = false;
        }
      }
    })
    this.members = _.filter(this.members, function (val) {
      if (!val.group_id) {
        return (val.user_email != parentScope.members[index].user_email);
      }
      else {
        return (val.group_id != parentScope.members[index].group_id);
      }
    });
    if (this.members.length == 0) {
      this.friendListView = false;
      this.groupNameValue = false;
    }
    else if (this.members.length < 2) {
      this.groupNameValue = false;
    }
  }

  //Cancel creation of new chat 
  public cancel() {
    if (!this.globals.mobileView) {
      if (this.chatdialogsLength) {
        this.chatView = true;
      }
      else {
        this.chatView = false;
      }
      this.createMessageView = false;
      this.friendListView = false;
      document.getElementById('top-section').style.border = "1px solid #8080801f";
    }
    else {
      this.chatView = false;
      this.createMessageView = false;
      this.friendListView = false;
      this.chatdialogs[this.newIndex].activeTab = false;
    }

    this.globals.message = '';
    this.groupNameValue = false;
  }

  //Edit group functionality
  public editGroup(id) {
    this.globals.onLoading = true;
    let data = this;
    this.groupHelper.getGroupDetails(data, 2, id, this.totalMembers).subscribe(
      result => {
        this.members = result.members;
        for (let i = 0; i < this.members.length; i++) {

          this.members[i].checked = true;
          this.members[i].qudos_user_id = this.members[i].user_id;
        }
        this.getMemberList();
        this.newGroupName = result.groupData.group_name;
        this.editGroupView = true;
        if (!this.globals.mobileView) {
          this.chatView = false;
        }
        else {
          this.chatView = true;
        }
        this.groupNameValue = true;
        this.createMessageView = true;
        this.friendListView = true;
        this.globals.onLoading = false;
      },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
    )
  }

  //Update group image functionality
  public updateGroupImage(data) {
    this.myFile = data[0];
    let file_name = this.myFile.name;
    let file_type = this.myFile.type
    if ((file_type.toLowerCase() != "image/jpeg") && file_type.toLowerCase() != "image/png") {
      this.toaster.error('Image can be jpg,png only', 'ERROR');
      this.myFile = undefined;
    }
    else {
      $('.upload_img')[0].innerHTML = file_name.slice(0, 20);
      $('.upload_img')[0].style.color = "black";
    }
  }

  //Function to manage updation of group
  public updateGroup(id) {
    let data = this;
    this.globals.onLoading = true;
    let image_flag;

    if (this.members.length == 0) {
      this.toaster.error('Please select users/group to start chat', "ERROR");
      this.friendListView = false;
      this.createMessageView = true;
      this.globals.onLoading = false;
      if (this.globals.mobileView)
        this.chatView = true;
      else
        this.chatView = false;
    }
    else if (!this.newGroupName && this.members.length > 1) {
      this.toaster.error('Please enter group name', 'ERROR');
      this.createMessageView = true;
      this.globals.onLoading = false;
      if (this.globals.mobileView)
        this.chatView = true;
      else
        this.chatView = false;
    }
    else if (this.members.length == 1) {
      this.toaster.error('Please select more users to create a group chat', "ERROR");
      this.createMessageView = true;
      this.globals.onLoading = false;
      if (this.globals.mobileView)
        this.chatView = true;
      else
        this.chatView = false;
    }
    else if (this.newGroupName.length > 255) {
      this.toaster.error('Name of the group should not be greater than 255', "ERROR");
      this.globals.onLoading = false;
    }
    else {
      if (this.myFile) {
        image_flag = 1;
        this.myFile = this.myFile;
      }
      else {
        image_flag = 0;
        this.myFile = undefined;
      }

      data.myFile = this.myFile;
      data.groupId = id;

      this.groupHelper.editGroup(data, image_flag, 2).subscribe(
        result => {
          this.receiverName = result.newGroupName;
          this.user_image = result.updatedGroupImage;
          this.totalMembers = result.updatedMembers;
          this.socketService.fetchChatRooms();
          this.cancel();
          this.globals.onLoading = false;
        },
        err => {
          this.toaster.error(err, 'ERROR');
          this.globals.onLoading = false;
        }
      )
    }
  }

  //////////////////////////////////////////////////// CREATE NEW CHAT MESSAGE ///////////////////////////////////////////////////
  //To open the view for selecting a new friend to a new start chat
  public createMessage(val) {
    this.searchFriend = '';
    this.editGroupView = false;
    this.groupNameValue = false;
    this.newGroupName = '';

    if (val) {
      this.members = [];
      this.friendListView = false;
      this.createMessageView = true;

      if (this.globals.mobileView)
        this.chatView = true;
      else
        this.chatView = false;

      this.getMemberList();
    }
  }

  //Manage details of the checked users while new chat creation
  getCheckedValue = function() {
    this.members = [];
    let parentScope = this;

    $("input:checkbox:checked").each(function (this) {
      let indexVal: any = $(this).val();
      parentScope.friendListView = true;

      parentScope.members_diff[indexVal].checked = true;
      parentScope.members_diff[indexVal].user_image = parentScope.members_diff[indexVal].user_image ? parentScope.members_diff[indexVal].user_image : 'https://qudos-s3.s3.amazonaws.com/user_profile/user.png';
      parentScope.members.push(parentScope.members_diff[indexVal]);
      if (parentScope.members.length > 1) {
        if (parentScope.members_diff[indexVal].group_id) {
          parentScope.toaster.error("You cannot create a group with group", "ERROR");
          parentScope.members = _.filter(parentScope.members, function (o) {
            return (o.group_id != parentScope.members_diff[indexVal].group_id)
          });
          parentScope.members_diff[indexVal].checked = false;
        }
        else {
          let index = _.find(parentScope.members, function (a) {
            if (a.group_id) {
              return a;
            }
          });
          if (index) {
            parentScope.members_diff[indexVal].checked = false;
            parentScope.toaster.err("You cannot add users to an already created group", "ERROR");
            parentScope.members = _.filter(parentScope.members, function (o) {
              return (o.qudos_user_id != parentScope.members_diff[indexVal].qudos_user_id && o.qudos_user_type == parentScope.members_diff[indexVal].qudos_user_type)
            });
          }
          else {
            parentScope.groupNameValue = true;
          }
        }
      }
      else {
        parentScope.groupNameValue = false;
      }
    });
    if (this.members.length == 0) {
      this.friendListView = false;
    }
  }

  //Create new chat functionality
  public createNewMessage() {
    let occupantId = [], occupantType = [];
    this.createMessageView = false;
    this.chatView = true;
    this.createMessageSuccess = true;

    if (this.members.length == 0) {
      this.toaster.error('Please select users/group to start chat', "ERROR");
      this.friendListView = false;
      this.createMessageView = true;

      if (this.globals.mobileView)
        this.chatView = true;
      else
        this.chatView = false;
    }
    else if (!this.newGroupName && this.members.length > 1) {
      this.toaster.error('Please enter group name', 'ERROR');
      this.createMessageView = true;

      if (this.globals.mobileView)
        this.chatView = true;
      else
        this.chatView = false;
    }
    else if (this.newGroupName.length > 255) {
      this.toaster.error('Name of the group should not be greater than 255', "ERROR");
      this.createMessageView = true;

      if (this.globals.mobileView)
        this.chatView = true;
      else
        this.chatView = false;
    }
    else if (this.members.length > 1) {
      for (let i = 0; i < this.members.length; i++) {
        occupantId.push(this.members[i].qudos_user_id);
        occupantType.push(this.members[i].qudos_user_type);
      }
      let joinGroupRoom = {
        occupant_id: occupantId,
        occupant_type: occupantType,
        group_name: this.newGroupName
      }
      this.socketService.joinRooms(1, joinGroupRoom);
    }
    else {
      this.groupNameValue = false;
      if (this.members[0].group_id) {
        occupantId.push(this.members[0].group_id);
        occupantType.push(2);
        let joinRoom = {
          occupant_id: occupantId,
          occupant_type: occupantType,
          group_name: this.members[0].group_name,
          member_count: this.members[0].member_list.length
        }
        this.socketService.joinRooms(2, joinRoom)
      }
      else {
        occupantId.push(this.members[0].qudos_user_id);
        occupantType.push(this.members[0].qudos_user_type);
        let joinRoom = {
          occupant_id: occupantId,
          occupant_type: occupantType,
        }
        this.socketService.joinRooms(3, joinRoom);
      }

    }
    this.socketService.sendJoinedRoomSuccess().subscribe(
      result => {
        this.searchFriend = '';
        this.chatHistory = [];
        this.fullChatHistory = [];
        if (result.flag == 0) {
          if (this.members.length > 1) {
            for (let i = 0; i < this.members.length; i++) {
              occupantId.push(this.members[i].group_id);
            }
            occupantType.push(2);
            let joinGroupRoom = {
              occupant_id: occupantId,
              occupant_type: occupantType,
              group_name: this.newGroupName
            }
            this.socketService.joinRooms(1, joinGroupRoom);
          }
          else {
            if (this.members[0].group_id) {
              occupantId.push(this.members[0].group_id);
              occupantType.push(2);
              let joinGroupRoom = {
                occupant_id: occupantId,
                occupant_type: occupantType,
                group_name: this.members[0].group_name
              }
              this.socketService.joinRooms(1, joinGroupRoom);
            }
            else {
              occupantId.push(this.members[0].qudos_user_id);
              occupantType.push(this.members[0].qudos_user_type);
              let joinRoom = {
                occupant_id: occupantId,
                occupant_type: occupantType,
              }
              this.socketService.joinRooms(3, joinRoom);
            }

          }
        }
        else if (!result.data.new_room) {
          if (result.data.details[0].group_id) {
            this.groupNameValue = false;
            this.roomId = result.data.details[0].room_id;
            this.room_type = result.data.details[0].room_type;
            this.receiverName = result.data.details[0].group_name;
            this.recipient_id = result.data.details[0].group_id;
            this.recipient_type = 2;
            this.totalMembers = result.data.details[0].total_members;
            this.adminId = result.data.details[0].qudos_user_id;
            this.adminType = parseInt(result.data.details[0].qudos_user_type);
            this.user_image = result.data.details[0].group_image ? result.data.details[0].group_image : "https://qudos-s3.s3.amazonaws.com/user_profile/user.png";
            this.socketService.fetchChatRooms();

            let messageHistoryRequest = {
              room_id: this.roomId,
              receiver_id: this.recipient_id,
              receiver_type: this.recipient_type,
              limit: this.limit,
              skip: this.skip
            }
            this.socketService.fetchMessageHistory(messageHistoryRequest);
          }
          else {
            this.roomId = result.data.details[0].room_id;
            this.room_type = result.data.details[0].room_type;
            this.receiverName = result.data.details[0].friend_name;
            this.recipient_id = result.data.details[0].friend_id;
            this.recipient_type = result.data.details[0].friend_type;
            this.user_image = result.data.details[0].friend_image ? result.data.details[0].friend_image : "https://qudos-s3.s3.amazonaws.com/user_profile/user.png";
            this.socketService.fetchChatRooms();
            let messageHistoryRequest = {
              room_id: this.roomId,
              receiver_id: this.recipient_id,
              receiver_type: this.recipient_type,
              limit: this.limit,
              skip: this.skip
            }
            this.socketService.fetchMessageHistory(messageHistoryRequest);
          }
        }
        else if (result.flag && result.data.new_room) {
          if (result.data.details[0].group_id) {
            this.groupNameValue = false;
            this.roomId = result.data.details[0].room_id;
            this.receiverName = result.data.details[0].group_name;
            this.recipient_id = result.data.details[0].group_id;
            this.recipient_type = 2;
            this.online = result.data.details[0].online_status;
            this.room_type = result.data.details[0].room_type;
            this.user_image = result.data.details[0].group_image ? result.data.details[0].group_image : "https://qudos-s3.s3.amazonaws.com/user_profile/user.png";
            this.totalMembers = result.data.details[0].total_members;
            this.adminId = result.data.details[0].qudos_user_id;
            this.adminType = parseInt(result.data.details[0].qudos_user_type);
            this.socketService.fetchChatRooms();
          }
          else {
            this.roomId = result.data.details[0].room_id;
            this.online = result.data.details[0].online_status;
            this.receiverName = result.data.details[0].friend_name;
            this.recipient_id = result.data.details[0].friend_id;
            this.recipient_type = result.data.details[0].friend_type;
            this.room_type = result.data.details[0].room_type;
            this.user_image = result.data.details[0].friend_image ? result.data.details[0].friend_image : "https://qudos-s3.s3.amazonaws.com/user_profile/user.png";
            this.socketService.fetchChatRooms();
          }
        }
      }
    )
  }

  //////////////////////////////////////////////////// FRIEND LIST  ///////////////////////////////////////////////////
  //Get List of friends
  public getMemberList(loadMore = false) {
    this.globals.onLoading = true;
    let data = this;

    if (this.globals.invalidInput) {
      this.globals.onLoading = false;
      return;
    }
    if (this.searchFriend && !loadMore) {
      this.offset = 0;
      this.memberList = [];
    }
    this.groupHelper.getMemberList(data).subscribe(
      response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, 'ERROR');
        }
        else {
          if (loadMore) {
            this.memberList = this.memberList.concat(response.members);
          }
          else {
            this.memberList = response.members;
          }
          this.total_members = response.total_members;
          this.members_diff = _.differenceWith(this.memberList, this.members, function (a, b) {
            if (!b['group_id']) {
              return ((a['qudos_user_id'] == (b)['qudos_user_id'] && a['qudos_user_type'] == (b)['qudos_user_type']))
            }
            else {
              return (a['group_id'] == (b)['group_id'])
            }
          });
          this.members_diff = this.members.concat(this.members_diff);
        }
        this.globals.onLoading = false;
      },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
    )
  }

  //Load more members
  public loadMore() {
    this.offset += 25;
    this.getMemberList(true)
  }

  //////////////////////////////////////////////////// CHAT DIALOGS ///////////////////////////////////////////////////

  //Fetch chat dialogs
  fetchDialogs = function() {
    this.socketService.fetchChatRooms().subscribe(
      result => {
        if (result.flag) {
          this.dialogList = [];
          this.chatdialogs = result.data.paginated_rooms;
          this.chatdialogsLength = result.data.paginated_rooms.length;
          if (this.chatdialogsLength) {
            if (this.firstLoad && !this.globals.mobileView) {
              this.firstLoad = false;
              this.receiverName = this.chatdialogs[0].name;
              this.user_image = this.chatdialogs[0].image != null ? this.chatdialogs[0].image : 'https://qudos-s3.s3.amazonaws.com/user_profile/user.png';
              this.recipient_id = this.chatdialogs[0].occupant_id;
              this.recipient_type = this.chatdialogs[0].occupant_type;
              this.roomId = this.chatdialogs[0].room_id;
              this.room_type = this.chatdialogs[0].room_type
              this.getChatHistory(this.chatdialogs[0], 0, this.limit, this.skip);
              this.chatdialogs[0].activeTab = true;
              this.totalMembers = this.chatdialogs[0].members_count
            }
            for (let i = 0; i < this.chatdialogs.length; i++) {

              if ((this.chatdialogs[i].room_id == this.roomId) && !this.globals.mobileView) {
                this.chatdialogs[i].activeTab = true;
                this.activeIndex = i;
              }
              else {
                this.chatdialogs[i].activeTab = false
              }

              this.chatdialogs[i].updated_time = moment(this.chatdialogs[i].last_message_sent_at).fromNow();
              this.chatdialogs[i].last_message_sent = this.chatdialogs[i].last_message != null ? decodeURIComponent(this.chatdialogs[i].last_message).slice(0, 30) : decodeURIComponent(this.chatdialogs[i].last_message);
              this.chatdialogs[i].last_message_type = this.chatdialogs[i].last_message_type;
              this.dialogList = this.dialogList.concat(this.chatdialogs[i].room_id);
              this.chatdialogs[i].image = this.chatdialogs[i].image != null ? this.chatdialogs[i].image : 'https://qudos-s3.s3.amazonaws.com/user_profile/user.png';
            }
            this.socketService.subscribeRooms(this.dialogList).subscribe(
              result => {
                if (result.flag) {
                }
                else {
                  this.socketService.subscribeRooms(this.dialogList);
                }
              },
              err => {
                this.toaster.error(err, 'ERROR');
                this.globals.onLoading = false;
              }
            );
          }
        }
        else {
          this.socketService.fetchChatRooms();
        }
        this.globals.pageLoad = false;
        this.globals.onLoading = false;
      },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
    )
  }

  //////////////////////////////////////////////////// MESSAGE SEND/RECEIVE ///////////////////////////////////////////////////

  //Function to manage attachments like files,text,pdf
  public manageAttachment(data) {
    let parentScope = this;
    this.attachedFile = data[0];
    let file_name = $('#fileAttached').val();
    let file_type = ($('#fileAttached').val() as string).slice(($('#fileAttached').val() as string).lastIndexOf('.'))
    if ((file_type.toLowerCase() != ".jpg" && file_type.toLowerCase() != '.jpeg') && file_type.toLowerCase() != ".png" && file_type.toLowerCase() != ".pdf" && file_type.toLowerCase() != ".txt" && file_type.toLowerCase() != ".doc" && file_type.toLowerCase() != ".docx") {
      this.toaster.error('Invalid File Type. File type can be jpg,png,pdf,txt,doc only', 'ERROR');
      this.attachedFile = undefined;
    }
    else {
      if (this.globals.mobileView) {
        $('.fa-file-import').removeClass('hidden');
        $('.fa-paperclip').addClass('hidden');
      }
      else {
        file_name = (<string>$('#fileAttached').val()).split('\\')[2];
        document.getElementById("imageName").innerHTML = file_name.slice(0, 20);
        document.getElementById("imageName").style.color = '#626262';
      }
    }
    let reader = new FileReader();
    reader.onload = function (this) {
      parentScope.base64textString = btoa(this.result as string);
      parentScope.base64textString = parentScope.attachedFile.type + ';base64,' + parentScope.base64textString;
    }
    reader.readAsBinaryString(this.attachedFile);
  }

  //Send message/attachment in a chat
  public sendMessage() {
    this.globals.onLoading = true;
    this.createMessageSuccess = false;

    if (this.attachedFile != undefined) {
      let file = this.attachedFile;
      let messageType;

      if (file.size <= 4194304) {
        if ((file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg'))
          messageType = 1;
        else if ((file.type == 'application/msword' || file.type == 'application/pdf' || file.type == 'text/plain'))
          messageType = 2;
        else
          messageType = 0;

        let sendMessageRequest = {
          occupant_id: this.recipient_id,
          occupant_type: this.recipient_type,
          message: (this.globals.message != undefined && (this.globals.message.trim()).length != 0) ? encodeURIComponent(this.serviceFunction.htmlEncode((this.globals.message))) : '',
          message_type: messageType,
          room_id: this.roomId,
          room_type: this.room_type,
          content_url: this.base64textString
        }
        this.socketService.sendMessage(sendMessageRequest);
      }
      else {
        this.toaster.error('File size can be upto 4MB only', 'ERROR');
      }
    }
    else {
      if (this.globals.message != undefined && (this.globals.message.trim()).length != 0) {
        let sendMessageRequest = {
          occupant_id: this.recipient_id,
          occupant_type: this.recipient_type,
          message: (this.globals.message != undefined && (this.globals.message.trim()).length != 0) ? encodeURIComponent(this.serviceFunction.htmlEncode((this.globals.message))) : '',
          message_type: 0,
          room_id: this.roomId,
          room_type: this.room_type,
          content_url: ''
        }
        this.socketService.sendMessage(sendMessageRequest);
      }
      else {
        this.toaster.error("Empty Message", 'ERROR');
        this.globals.onLoading = false;
      }
    }
  };

  //Receive sent message success
  public sentMessageSuccess() {
    this.socketService.getSendMessageSuccess().subscribe(
      result => {
        if (!this.globals.mobileView) {
          $('#imageName')[0].innerHTML = 'Attachments';
        }
        $('#fileAttached').val('');
        $('.fa-file-import').addClass('hidden');
        $('.fa-paperclip').removeClass('hidden');
        this.attachedFile = undefined;

        for (let i = 0; i < result.data.alert_id.length; i++) {
          if (this.globals.user.user_id == result.data.alert_id[i].qudos_user_id && this.globals.user.qudos_user_type == result.data.alert_id[i].qudos_user_type) {
            var alertId = result.data.alert_id[i].alert_id;
          }
        }
        this.sent = {
          content: this.serviceFunction.urlify(decodeURIComponent(result.data.message)),
          type: 1,
          updated_at: moment().format('LT'),
          user_image: result.data.user_image,
          message_type: result.data.message_type,
          file: result.data.filePath,
          fileName: result.data.filePath != null ? result.data.filePath.slice(result.data.filePath.lastIndexOf('.')) : ''
        }
        this.chatHistory = this.chatHistory.concat(this.sent);
        this.globals.message = '';
        if (this.room_type) {
          this.readMessages(0, alertId, 1)
        }
        this.socketService.fetchChatRooms();
        this.globals.onLoading = false;

        this.moveChatBox = true;
      },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
    )
  }

  //Receive message event
  public receiveMessage() {
    this.receiveSocketSubscribe = this.socketService.receiveMessage().subscribe(
      result => {
        if (result.flag) {
          let roomId = result.data.room_id;
          let msg = decodeURIComponent(result.data.message);
          this.socketService.fetchChatRooms();

          for (let i = 0; i < result.data.alert_id.length; i++) {
            if (this.globals.user.user_id == result.data.alert_id[i].qudos_user_id && this.globals.user.qudos_user_type == result.data.alert_id[i].qudos_user_type) {
              var alertId = result.data.alert_id[i].alert_id;
            }
          }
          if (this.roomId == roomId) {
            if (this.globals.user.user_id == result.data.qudos_user_id && this.globals.user.qudos_user_type == result.data.qudos_user_type) {
              this.sent = {
                content: this.serviceFunction.urlify(msg),
                type: 1,
                updated_at: moment().format('LT'),
                user_image: this.globals.user.user_image,
                sender_name: this.globals.user.user_name,
                message_type: result.data.message_type,
                file: result.data.filePath,
                fileName: result.data.filePath != null ? result.data.filePath.slice(result.data.filePath.lastIndexOf('.')) : ''
              }
              this.chatHistory = this.chatHistory.concat(this.sent);
            }
            else {
              this.receive = {
                content: this.serviceFunction.urlify(msg),
                type: 2,
                updated_at: moment().format('LT'),
                user_image: result.data.user_image,
                sender_name: result.data.qudos_user_name,
                message_type: result.data.message_type,
                file: result.data.filePath,
                fileName: result.data.filePath != null ? result.data.filePath.slice(result.data.filePath.lastIndexOf('.')) : ''
              }
              this.chatHistory = this.chatHistory.concat(this.receive);
            }
            this.readMessages(0, alertId, 1)
          }
          if ($('#chat-1').length) {
            this.moveChatBox = true;
          }
        }
        this.globals.message = '';
      }
    )
  }

  //////////////////////////////////////////////////// MESSAGE HISTORY ///////////////////////////////////////////////////

  //To retrieve the chat history for a particular chat dialog
  public getChatHistory(data, index = 0, limit = 100, skip = 0) {
    this.globals.onLoading = true;
    this.skip = 0;
    this.globals.message = '';
    this.dialogData = data;
    this.previousIndex = this.newIndex;
    this.chatdialogs[this.previousIndex].activeTab = false;
    if (!this.globals.mobileView && this.activeIndex) {
      this.chatdialogs[this.activeIndex].activeTab = false;
    }
    this.newIndex = index;
    this.chatdialogs[this.newIndex].activeTab = true;
    this.online = this.chatdialogs[index].online_status;
    this.roomId = data.room_id;
    this.totalMembers = data.members_count;
    this.room_type = data.room_type;
    this.recipient_id = data.occupant_id;
    this.recipient_type = data.occupant_type;
    this.receiverName = data.name;
    this.adminId = data.qudos_user_id;
    this.adminType = (data.qudos_user_type);
    this.user_image = data.image ? data.image : "https://qudos-s3.s3.amazonaws.com/user_profile/user.png";
    this.chatHistory = [];
    this.fullChatHistory = [];
    this.messageCount = 0;
    let messageHistoryRequest = {
      room_id: this.roomId,
      receiver_id: this.recipient_id,
      receiver_type: this.recipient_type,
      limit: limit,
      skip: skip
    }
    this.socketService.fetchMessageHistory(messageHistoryRequest);

    this.globals.onLoading = false;
    this.globals.pageLoad = false;
  }

  //fetch chat history for particular chat
  fetchMessageHistory = function() {
    this.socketService.receiveMessageHistory().subscribe(
      result => {
        this.globals.onLoading = true;
        this.chatView = true;
        this.createMessageView = false;
        this.createMessageSuccess = false;
        if (result.flag) {
          this.fullChatHistory = (result.data.paginated_messages);
          this.messageCount = result.data.total_messages;
          this.readMessages(0, this.fullChatHistory);
          if (this.fullChatHistory.length == 0) {
            this.createMessageSuccess = true;
            this.chatHistory = [];
            this.fullChatHistory = [];
          }
          else {
            this.createMessageSuccess = false;
          }
          for (let i = 0; i < this.fullChatHistory.length; i++) {
            this.fullChatHistory[i].updated_at_time = moment(this.fullChatHistory[i].created_at).format('LT');
            if (this.fullChatHistory[i].room_type == 0) {
              if (this.fullChatHistory[i].sender_id == this.globals.user.user_id) {
                this.sent = [{
                  content: this.serviceFunction.urlify(decodeURIComponent(this.fullChatHistory[i].message)),
                  type: 1,
                  updated_at: this.fullChatHistory[i].updated_at_time,
                  user_image: this.fullChatHistory[i].sender_image,
                  message_type: this.fullChatHistory[i].message_type,
                  file: this.fullChatHistory[i].filePath,
                  fileName: this.fullChatHistory[i].filePath != null ? this.fullChatHistory[i].filePath.slice(this.fullChatHistory[i].filePath.lastIndexOf('.')) : ''
                }]
                this.chatHistory = this.sent.concat(this.chatHistory);
              }
              else if (this.fullChatHistory[i].receiver_id == this.globals.user.user_id) {
                this.receive = [{
                  content: this.serviceFunction.urlify(decodeURIComponent(this.fullChatHistory[i].message)),
                  type: 2,
                  updated_at: this.fullChatHistory[i].updated_at_time,
                  user_image: this.fullChatHistory[i].receiver_image,
                  message_type: this.fullChatHistory[i].message_type,
                  file: this.fullChatHistory[i].filePath,
                  fileName: this.fullChatHistory[i].filePath != null ? this.fullChatHistory[i].filePath.slice(this.fullChatHistory[i].filePath.lastIndexOf('.')) : ''
                }]
                this.chatHistory = this.receive.concat(this.chatHistory);
              }
            }
            else if (this.fullChatHistory[i].room_type == 1) {
              if (this.fullChatHistory[i].sender_id == this.globals.user.user_id) {
                this.sent = [{
                  content: this.serviceFunction.urlify(decodeURIComponent(this.fullChatHistory[i].message)),
                  type: 1,
                  updated_at: this.fullChatHistory[i].updated_at_time,
                  user_image: this.fullChatHistory[i].receiver_image,
                  message_type: this.fullChatHistory[i].message_type,
                  file: this.fullChatHistory[i].filePath,
                  fileName: this.fullChatHistory[i].filePath != null ? this.fullChatHistory[i].filePath.slice(this.fullChatHistory[i].filePath.lastIndexOf('.')) : ''
                }]
                this.chatHistory = this.sent.concat(this.chatHistory);
              }
              else if (this.fullChatHistory[i].receiver_id == this.globals.user.user_id) {
                this.receive = [{
                  content: this.serviceFunction.urlify(decodeURIComponent(this.fullChatHistory[i].message)),
                  type: 2,
                  updated_at: this.fullChatHistory[i].updated_at_time,
                  user_image: this.fullChatHistory[i].sender_image,
                  sender_name: this.fullChatHistory[i].sender_name,
                  message_type: this.fullChatHistory[i].message_type,
                  file: this.fullChatHistory[i].filePath,
                  fileName: this.fullChatHistory[i].filePath != null ? this.fullChatHistory[i].filePath.slice(this.fullChatHistory[i].filePath.lastIndexOf('.')) : ''
                }]
                this.chatHistory = this.receive.concat(this.chatHistory);
              }
            }
          }
        }
        else {
          let messageHistoryRequest = {
            room_id: this.roomId,
            receiver_id: this.recipient_id,
            receiver_type: this.recipient_type,
          }
          this.socketService.fetchMessageHistory(messageHistoryRequest);
        }
        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      }
    )
  }

  //////////////////////////////////////////////////// READ MESSAGEs ///////////////////////////////////////////////////

  //Read messages
  public readMessages(readAll, data = [], type = 0) {
    this.read = [];
    this.readAll = readAll;
    if (type) {
      this.read = data;
    }
    else {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          this.read.push(data[i].alert_id);
        }
      }
    }
    this.read.push(this.read.toString())

    let messageReadRequest = {
      qudos_user_id: this.globals.user.user_id,
      qudos_user_type: this.globals.user.qudos_user_type,
      is_all: readAll,
      alert_id: this.read
    }
    this.socketService.readMessages(messageReadRequest).subscribe(
      result => {
        this.globals.onLoading = true;
        this.globals.pageLoad = false;
        if (result.flag) {
          if (this.globals.unread_count) {
            this.globals.unread_count = this.globals.unread_count - this.chatdialogs[this.newIndex].unread_messages >= 0 ? this.globals.unread_count - this.chatdialogs[this.newIndex].unread_messages : this.globals.unread_count;
          }
          if (this.readAll) {
            for (let i = 0; i < this.chatdialogs.length; i++) {
              this.chatdialogs[i].unread_messages = 0;
            }
          } else {
            this.chatdialogs[this.newIndex].unread_messages = 0;
          }
          if (this.skip == 0 && this.chatView && !this.createMessageSuccess && !this.createMessageView) {
            if ($('#chat-1').length) {
              this.moveChatBox = true;
            }
          }
          let parentScope = this;
          if ($('#chat-1').length) {
            $("#chat-1").scroll(function () {
              if ($('#chat-1').scrollTop() <= 15 && !parentScope.globals.onLoading) {
                if (parentScope.messageCount > parentScope.skip && parentScope.messageCount > parentScope.limit) {
                  if ((parentScope.skip + 10) < parentScope.messageCount) {
                    parentScope.globals.onLoading = true;
                    parentScope.skip += 10;
                    let messageHistoryRequest = {
                      room_id: parentScope.roomId,
                      receiver_id: parentScope.recipient_id,
                      receiver_type: parentScope.recipient_type,
                      limit: parentScope.limit,
                      skip: parentScope.skip
                    }
                    parentScope.socketService.fetchMessageHistory(messageHistoryRequest);
                  }
                }
              }
              else {
                parentScope.globals.onLoading = false;
                parentScope.globals.pageLoad = false;
              }
            });
          }
        }
        else {
          let messageReadRequest = {
            qudos_user_id: this.globals.user.user_id,
            qudos_user_type: this.globals.user.qudos_user_type,
            is_all: readAll,
            alert_id: this.read
          }
          this.socketService.readMessages(messageReadRequest);

        }
        this.globals.onLoading = false;
        this.globals.pageLoad = false;
      },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
    )

  }

  //////////////////////////////////////////////////// INTIAL FUNCTION ///////////////////////////////////////////////////

  init = function () {
    this.globals.pageLoad = true;
    this.firstLoad = this.globals.reconnectCount == 1 ? true : false;
    if (this.globals.reconnectCount > 1 && this.roomId) {
      this.chatHistory = [];

      let messageHistoryRequest = {
        room_id: this.roomId,
        receiver_id: this.recipient_id,
        receiver_type: this.recipient_type,
        limit: this.limit,
        skip: this.skip
      }
      this.socketService.fetchMessageHistory(messageHistoryRequest);
    }
  };

}