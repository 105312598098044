<!--General Header  -->
<header class="header" [ngClass]="{'img-disp':globals.hideHeaderFooter}">
	<div class="container container-fluid-md">
		<div class="row">
			<div class="col-12">
				<div class="header_content">
					<a class="header_logo pointer-cursor" (click)="stateCheck()" [ngClass]="{'post-info-top_nav':checkPage}">
						<img src="./assets/img/QUDOCONNECT_WHITE.svg" alt="" class="header_logo_img">
					</a>
					<form action="" class="header_form" *ngIf="globals.is_auth">
						<input type="text" placeholder="Search">
					</form>
					<a routerLink="/profile" class="header_timeline-mob" *ngIf="globals.is_auth">
						<img src="{{globals.user?.user_image}}" alt="">
					</a>
					<nav class="header_nav" *ngIf="globals.is_auth">
						<div class="header_nav-title">
							Menu
						</div>
						<ul class="header_nav-list ">
							<li>
								<a routerLink="/home" routerLinkActive="activeMenu">
									<i class="fal fa-home "></i>
									<span>Home</span>
								</a>
							</li>
							<li>
								<a routerLink="/network" routerLinkActive="activeMenu">
									<i class="fal fa-user-friends"></i>
									<span>Network</span>
								</a>
							</li>
							<li class="visible-mobile">
								<a routerLink="/settings" routerLinkActive="active">
									<i class="fal fa-user"></i>
									<span>Settings</span>
								</a>
							</li>
							<li class="visible-mobile">
								<a routerLink="/messages" routerLinkActive="activeMenu">
									<i class="fal fa-comments-alt"></i>
									<span>Messaging</span>
								</a>
							</li>
							<li class="visible-mobile" routerLinkActive="active">
								<a routerLink="/notification">
									<i class="fal fa-bell"></i>
									<span>Notification</span>
								</a>
							</li>
							<div class="hidden-mobile dropdown">
								<button class="header_not-btn" type="button">
									<span *ngIf="globals.unread_count"
										[ngClass]="{'spn-counter':globals.unread_count>=100}">{{globals.unread_count}}</span>
									<a href="/messages" routerLinkActive="activeMenu"><i class="fal fa-comments-alt"></i></a>
								</button>
							</div>
							<div class="hidden-mobile dropdown">
								<button class="header_not-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown"
									aria-haspopup="true" aria-expanded="false" (click)="toggle()">
									<span *ngIf="globals.notificationCounter"
										[ngClass]="{'spn-counter':globals.notificationCounter>=100}">{{globals.notificationCounter}}</span>
									<i class="fal fa-bell notify" routerLinkActive="activeMenu"></i>
								</button>
								<div class="notification-drop dropdown-menu" aria-labelledby="dropdownMenuButton">
									<div class="notification-drop_header">
										<span>Notifications</span>
										<span>{{globals.totalNotifications}}</span>
									</div>
									<a class="notification-drop_header_page pointer-cursor" routerLink="/notification">Page View</a>
									<div class="notification-drop_body page_panel results_notification">
										<div class="results_content">
											<div class="notification" *ngFor="let data of globals.notifications" routerLink="/notification">
												<div class="notification_img">
													<img src="{{data?.user_image}}"
														onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
														class="img-fluid" alt="img">
												</div>
												<div class="notification_text">
													<span>{{data.user_name}} </span>
													<span *ngIf="data.flag == 1">liked your post.</span>
													<span *ngIf="data.flag == 2">commented on your post.</span>
													<span *ngIf="data.flag == 3">shared group.</span>
													<span *ngIf="data.flag == 4">joined group.</span>
													<span *ngIf="data.flag == 5 || data.flag == 9 ||data.flag == 6||data.flag == 10">has a new
														post.</span>
													<span *ngIf="data.flag == 11">shared your post.</span>
													<span *ngIf="data.flag == 12">created a new group.</span>
													<span
														*ngIf="data.flag == 15 && ((!data.qudos_user_type_from && !globals.user.qudos_user_type) || (data.qudos_user_type_from && globals.user.qudos_user_type))">sent
														you a friend request.</span>
													<span
														*ngIf="data.flag == 15 && (data.qudos_user_type_from && !globals.user.qudos_user_type)">wants
														to
														be your favourite driver.</span>
													<span
														*ngIf="data.flag == 16 && ((!data.qudos_user_type_from && !globals.user.qudos_user_type) || (data.qudos_user_type_from && globals.user.qudos_user_type) || (data.qudos_user_type_from && !globals.user.qudos_user_type))">accepted
														your friend request.</span>
													<span
														*ngIf="(data.flag == 16 && (!data.qudos_user_type_from && globals.user.qudos_user_type) )">added
														you as a favourite driver.</span>
													<span *ngIf="data.flag == 8">shared page.</span>
													<span *ngIf="data.flag == 17">tagged you in a new post.</span>
													<span *ngIf="data.flag == 18">created a new page.</span>
													<span *ngIf="data.flag == 19">liked your page.</span>
													<span *ngIf="data.flag == 20">followed your page.</span>
													<span *ngIf="data.flag == 21">tagged you in a new comment.</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="hidden-mobile">
								<a routerLink="/profile" class="header_timeline-desk">
									<img src="{{globals.user?.user_image}}" alt="">
								</a>
							</div>
							<div class="hidden-mobile dropdown">
								<button class="header__more" type="button" id="dropdownMenuButton2" data-toggle="dropdown"
									aria-haspopup="true" aria-expanded="false">
									<i class="fas fa-caret-down"></i>
								</button>
								<div class="header__more-menu dropdown-menu pointer-cursor" aria-labelledby="dropdownMenuButton2">
									<a routerLink="/settings" routerLinkActive="activeMenu-profile">
										<i class="fal fa-user"></i>
										<span>Settings</span>
									</a>
									<a (click)="logout()" class="pointer-cursor">
										<i class="fal fal fa-power-off"></i>
										<span>Sign Out</span>
									</a>
								</div>
							</div>
							<li class="visible-mobile">
								<a (click)="logout()">
									<i class="fal fal fa-power-off"></i>
									<span class="visible-mobile">Sign Out</span>
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>
</header>

<app-auth-template></app-auth-template>