import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { GlobalFunctions } from 'src/app/helpers/service';
import { UserService } from 'src/app/services/ViewServices/userServices/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-rider',
  templateUrl: './rider.component.html',
  styleUrls: ['./rider.component.css']
})
export class RiderComponent implements OnInit {
  riderId: number;
  rider = {
    user_name: "",
    user_image: "",
    country: "",
    follower_count: 0,
    total_likes: 0,
    total_points: 0,
    rider_id: 0
  };
	offset: any;
	userId: number;
	toaster: any;

  constructor(public globals: Globals, public serviceFunction: GlobalFunctions, private userHelper: UserService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.riderId = parseInt(params.riderId);
    })
  }

  ngOnInit() {
    this.getRiderInfo();
  }

  //Get details of the rider
  public getRiderInfo() {
    this.globals.onLoading = true;
    let data = this;
    data.offset = this.offset;
    data.userId = this.riderId;
    this.userHelper.fetchUserById(data, 0).subscribe(result => {
      this.globals.onLoading = false;
    }),
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
  }
}
