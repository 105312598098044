import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule as VgCoreModule } from '@videogular/ngx-videogular/core';
import { ControlsModule as VgControlsModule } from '@videogular/ngx-videogular/controls';
import { OverlayPlayModule as VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { BufferingModule as VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { NetworkComponent } from './network/network.component';
import { SettingsComponent } from './settings/settings.component';
import { MessageComponent } from './message/message.component';
import { NotificationComponent } from './notification/notification.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ViewMoreComponent } from './view-more/view-more.component';
import { GroupProfileComponent } from './group-profile/group-profile.component';
import { PostInfoComponent } from './post-info/post-info.component';
import { FriendRequestComponent } from './friend-request/friend-request.component';
import { PageInfoComponent } from './page-info/page-info.component';
import { PostComponent } from './copylink/post/post.component';
import { GroupInfoComponent } from './group-info/group-info.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { NetworkTemplateComponent } from '../templates/modals/network-template/network-template.component';
import { PostTemplateComponent } from '../templates/modals/post-template/post-template.component';
import { UserTemplateComponent } from '../templates/modals/user-template/user-template.component';
import { PostPreviewComponent } from '../templates/post-preview/post-preview.component';
import { ProfilePreviewComponent } from '../templates/profile-preview/profile-preview.component';
import { AuthTemplateComponent } from '../templates/modals/auth-template/auth-template.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { InstantMessageComponent } from './instant-message/instant-message.component';

@NgModule({
  declarations: [
    UserComponent,
    NetworkComponent,
    SettingsComponent,
    MessageComponent,
    NotificationComponent,
    HomeComponent,
    ProfileComponent,
    ViewMoreComponent,
    GroupProfileComponent,
    PostInfoComponent,
    FriendRequestComponent,
    PageInfoComponent,
    PostComponent,
    GroupInfoComponent,
    UserInfoComponent,
    NetworkTemplateComponent,
    PostPreviewComponent, ProfilePreviewComponent,
    UserTemplateComponent, PostTemplateComponent, AuthTemplateComponent, InstantMessageComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,
    NgbModalModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    Ng2TelInputModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  exports: [AuthTemplateComponent],
  entryComponents: [NetworkTemplateComponent, UserTemplateComponent, PostTemplateComponent, AuthTemplateComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class UserModule { }
