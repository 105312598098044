<!-- messaging box-->
<div class="message-box d-none d-lg-inline" *ngIf="globals.is_auth">

	<div class="message-heading">
		<img src="/assets/img/QUDOSLIST_WHITE.svg" height="20px" width="60px">
		<span class="message-heading-text" (click)="showMessageList = !showMessageList">Messaging</span>

		<div class="messageMenuButton float-right dropup">
			<i class="far fa-ellipsis-h fa-lg menu-dropdown pointer-cursor dropdown-toggle dropdown-toggle-split"
				type="button" id="dropdownMenuButton02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			</i>
			<div class="dropdown-menu message__more-menu top-dropdown" aria-labelledby="dropdownMenuButton02">
				<a routerLinkActive="activeMenu-profile">
					<span>Create Group Chat</span>
				</a>
				<a class="pointer-cursor">
					<span>Message Settings</span>
				</a>
			</div>
		</div>

		<span class="messageMenuButton float-right mr-3" (click)="showNewMessageBox = true">
			<i class="far fa-edit"></i>
		</span>
	</div>
	<div class="message-body" *ngIf="showMessageList">
		<div class="row">
			<div class="col-12">
				<div class="message-list-header">
					<input type="text" class="form-control message-search">
					<span class="search-icon"><i class="fas fa-search"></i></span>
				</div>
				<div class="message-list">
					<ul>
						<li>
							<div class="row">
								<div class="col-3">
									<img src="../../../../assets/img/user.png" class="rounded-img user-dp" alt="" height="32px">
								</div>
								<div class="col-9 pl-0">
									<h5 class="mb-0">Mark</h5>
									<p class="user-message">Mark: this is his message.</p>
								</div>
							</div>
						</li>
						<li>
							<div class="row">
								<div class="col-3">
									<img src="../../../../assets/img/user.png" class="rounded-img user-dp" alt="" height="32px">
								</div>
								<div class="col-9 pl-0">
									<h5 class="mb-0">Mark</h5>
									<p class="user-message">Mark: this is his message.</p>
								</div>
							</div>
						</li>
						<li>
							<div class="row">
								<div class="col-3">
									<img src="../../../../assets/img/user.png" class="rounded-img user-dp" alt="" height="32px">
								</div>
								<div class="col-9 pl-0">
									<h5 class="mb-0">Mark</h5>
									<p class="user-message">Mark: this is his message.</p>
								</div>
							</div>
						</li>
						<li>
							<div class="row">
								<div class="col-3">
									<img src="../../../../assets/img/user.png" class="rounded-img user-dp" alt="" height="32px">
								</div>
								<div class="col-9 pl-0">
									<h5 class="mb-0">Mark</h5>
									<p class="user-message">Mark: this is his message.</p>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="float-right dropdown hide-after-dropdown">
					<i class="far fa-sliders-h fa-lg menu-dropdown pointer-cursor dropdown-toggle dropdown-toggle-split search-slider"
						type="button" id="dropdownMenuButton02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					</i>
					<div class="dropdown-menu message__more-menu message-option-dropdown" aria-labelledby="dropdownMenuButton02">
						<a class="pointer-cursor message-option-active">
							<span>All Messages</span>
						</a>
						<a class="pointer-cursor">
							<span>Archived</span>
						</a>
						<a class="pointer-cursor">
							<span>Spam</span>
						</a>
						<a class="pointer-cursor">
							<span>Unread</span>
						</a>
						<a class="pointer-cursor">
							<span>Blocked</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- new message box -->
<div class="new-message-box d-none d-lg-inline"
	[ngClass]="newMessageEnlarge ? 'large-message-box' : 'small-message-box'"
	*ngIf="globals.is_auth && showNewMessageBox">
	<div class="message-heading">
		<span class="message-heading-text">New Message</span>

		<!-- <button id="messageMenuButton"><i class="far fa-ellipsis-h fa-lg float-right"
            *ngIf="globals.mobileView"></i></button> -->
		<span class="messageMenuButton float-right " (click)="closeNewTextBox()">
			<i class="far fa-times"></i>
		</span>
		<span class="messageMenuButton float-right mr-4" (click)="newMessageEnlarge = !newMessageEnlarge">
			<i class="fas fa-expand-alt"></i>
		</span>
	</div>
	<div class="message-body">
		<div class="row">
			<div class="col-12">
				<input type="text" class="form-control message-name-input px-4 py-3" name="reciptantName"
					placeholder="Type a name">
			</div>
			<div class="col-12">
				<div class="rowDiv" [ngClass]="isTextAreaLarge ? 'largeRowDiv': 'smallRowDiv'">
					<div class="message-wrapper">
						<div class="right">
							<div class="chat-bubble right slide-right">
								<div class="message">Message</div>
								<!-- <div class="small-f float-right">time</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="message-text-box">
					<textarea id="message" class="form-control text-message"
						[ngClass]="isTextAreaLarge? 'small-text-message': 'large-text-message'"
						placeholder="Write something..."></textarea>
					<span class="expand-text-icon" *ngIf="isTextAreaLarge" (click)="increaseTextAreaHeight()"><i
							class="fas fa-chevron-up"></i></span>
					<span class="expand-text-icon" *ngIf="!isTextAreaLarge" (click)="decreaseTextAreaHeight()"><i
							class="fas fa-chevron-down"></i></span>
				</div>
			</div>
			<div class="col-12">
				<div class="message-sent-box">
					<span><i class="fas fa-paperclip"></i></span>
					<img src="/assets/img/face.png" class="face__emoji ml-2">
					<span class="submit float-right" style="margin-top: 0.8rem"><i
							class="fas fa-paper-plane sent-image"></i></span>
				</div>
			</div>
		</div>

	</div>
</div>