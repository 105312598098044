<ng-template #editProfileModal let-modal>
    <div>
        <div class="edit__page">
            <!-- Profile Html Strat -->
            <div class="row">
                <div class="col-12">
                    <div class="qudoslist_profile_container">
                        <!-- Edit Form Start -->
                        <div class="edit__container">
                            <div class="row ql-profile-info-header">
                                <div class="col-xs-8 word-Wrap">
                                    <h1 class="modal-title">Edit Profile</h1>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="107" height="4" viewBox="0 0 107 4">
                                        <g id="coloured-bottom-border.obj" transform="translate(-14.5 -219.5)">
                                            <line id="Line_1" data-name="Line 1" x2="103" transform="translate(16.5 221.5)" fill="none"
                                                stroke="#50c7e4" stroke-linecap="round" stroke-width="4" />
                                            <line id="Line_2" data-name="Line 2" x2="56" transform="translate(63.5 221.5)" fill="none"
                                                stroke="#238ec6" stroke-linecap="round" stroke-width="4" />
                                        </g>
                                    </svg>
                                    
                                </div>
                                <div class="col-xs-4">
                                    <button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog"
                                        (click)="modal.dismiss('Cross click')"></button>
                                </div>
                            </div>
                            <form class="edit__forms" name="profile" [formGroup]="editProfileForm">
                                <div class="form-group">
                                    <h3><strong>Basic Info</strong></h3>
                                    <label class="small-label" for="edit_zipcode">First Name</label>
                                    <span class="form-icons">
                                        <div class="inner-addon left-addon">
                                            <i class="fas fa-user"></i>
                                            <input type="text" class="form-control" name="Firstname" placeholder="Your First Name" id="fna"
                                                [formControl]="firstName" [(ngModel)]="editData.firstname" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div *ngIf="editProfileForm.controls.firstName.errors">
                                            <div class="help-block"
                                                *ngIf="editProfileForm.controls.firstName.errors.pattern && editProfileForm.controls.firstName.touched">
                                                Firstname can have alphabets only</div>
                                            <div class="help-block"
                                                *ngIf="editProfileForm.controls.firstName.errors.required && editProfileForm.controls.firstName.touched">
                                                This field is required</div>
                                        </div>
                                    </span>
                                </div>
                                <div class="form-group">
                                    <label class="small-label" for="edit_last_name">Last Name</label>
                                    <span class="form-icons">
                                        <div class="inner-addon left-addon">
                                            <i class="fas fa-user"></i>
                                            <input type="text" class="form-control" name="Lastname" [(ngModel)]="editData.lastname"
                                                placeholder="Your Last Name" [formControl]="lastName" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div *ngIf="editProfileForm.controls.lastName.errors">
                                            <div class="help-block"
                                                *ngIf="editProfileForm.controls.lastName.errors.pattern && editProfileForm.controls.lastName.touched">
                                                Lastname can have alphabets only</div>
                                            <div class="help-block"
                                                *ngIf="editProfileForm.controls.lastName.errors.required && editProfileForm.controls.lastName.touched">
                                                This field is required</div>
                                        </div>
                                    </span>
                                </div>
                                <div class="form-group edit-image-button">
                                    <label class="btn btn-block border" id="imageButton">
                                        <i class="fas fa-image"></i> <span id="image-name">Upload new image</span>
                                        <input id="file" type="file" class="d-none" name="image" accept="image/png,image/jpg,image/jpeg"
                                            (change)="imageName($event.target.files)" name="file"></label>
                                </div>
                                <div class="form-group">
                                    <h3><strong>Contact Info</strong></h3>
                                    <label class="small-label col pl-0" for="phone_country_select">Mobile
                                        Number</label>
                                    <input type="tel" ng2TelInput class="form-control mobile m-nmbr" [(ngModel)]="editData.user_mobile"
                                        [ngModelOptions]="{standalone: true}" name="Mobile" placeholder="Mobile number" id="mobile"
                                        inputmode="numeric" disabled class="col" (countryChange)="onCountryChange($event)">
                                </div>
                                <div class="form-group">
                                    <label class="small-label" for="edit_email">Email Address</label>
                                    <span class="form-icons">
                                        <div class="inner-addon left-addon">
                                            <i class="fas fa-envelope"></i>
                                            <input type="email" class="form-control" name="Email" [(ngModel)]="editData.user_email"
                                                [ngModelOptions]="{standalone: true}" placeholder="johnnypie@gmail.com" disabled>
                                        </div>
                                    </span>
                                </div>
                                <div class="form-group">
                                    <label class="small-label" for="edit_zipcode">Zip Code</label>
                                    <span class="form-icons">
                                        <div class="inner-addon left-addon">
                                            <i class="fas fa-map-marker-alt"></i>
                                            <input type="tel" class="form-control" name="Zipcode" [(ngModel)]="editData.zipcode"
                                                placeholder="Zip Code" required inputmode="numeric" maxlength="6" string-to-number
                                                [formControl]="zipCode" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div *ngIf="editProfileForm.controls.zipCode.errors">
                                            <div class="help-block"
                                                *ngIf="editProfileForm.controls.zipCode.errors.required && editProfileForm.controls.zipCode.touched">
                                                This field is required</div>
                                        </div>
                                    </span>
                                </div>
                                <div class="form-group">
                                    <h3><strong>About</strong></h3>
                                    <label class="small-label" for="edit_biography">Bio</label>
                                    <textarea type="text" class="form-control" name="Yourself" [(ngModel)]="editData.about"
                                        placeholder="Tell us about yourself" required rows="3" [formControl]="bio"
                                        [ngModelOptions]="{standalone: true}"></textarea>
                                    <div *ngIf="editProfileForm.controls.bio.errors">
                                        <div class="help-block"
                                            *ngIf="editProfileForm.controls.bio.errors.required && editProfileForm.controls.bio.touched">This
                                            field is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="small-label" for="edit_interests">Interests</label>
                                    <textarea type="text" class="form-control" name="Interest" [(ngModel)]="editData.interest"
                                        placeholder="Tell us about your interest" required rows="3" [formControl]="interest"
                                        [ngModelOptions]="{standalone: true}"></textarea>
                                    <div *ngIf="editProfileForm.controls.interest.errors">
                                        <div class="help-block"
                                            *ngIf="editProfileForm.controls.interest.errors.required && editProfileForm.controls.interest.touched">
                                            This field is required</div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <button type="button" class="btn btn-block" (click)="editProfile()" [disabled]="globals.onLoading">Update</button>
                                </div>
                            </form>
                            <!-- </form> Edit Form End -->
                        </div>
                    </div>
                </div>
                <!-- Profile Html Close -->
                <div *ngIf="globals.onLoading" class="whirl" [ngClass]="{'custom-backdrop-modal':globals.onLoading}"></div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #viewFaveCount let-modal>
    <div class="dialog-content">
        <div class="row">
            <div class="popup-main-content view_likes">
                <div id="edit-share-header" class="row edit-post-header header_likes">
                    <div class="col-xs-8">
                        <div class="like-up likes-modal-header">
                            <i class="fa fa-thumbs-up dot-thumb"></i>
                            <span class="like-post">
                                <strong>Users</strong>
                                <span class="like_count">{{(totalFaveUsers)}}</span>
                            </span>
                        </div>
                    </div>
                    <div class=" col-xs-4">
                        <button type="button" class="fa fa-times close-dlg-but" data-dismiss="dialog" (click)="modal.dismiss('Cross click')"></button>
                    </div>
                </div>
                <div class="person-info-form" id="countScroll" (scroll)="countFaves()">
                    <ul class="list-group list-likes">
                        <li class="list-group-item" *ngFor="let data of faveUsersCount">
                            <a>
                                <span class="list-left pointer-cursor">
                                    <img src="{{data?.user_image}}" alt="user-image" class="rounded-circle" width="32px" height="32px"
                                        onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';">
                                    <p (click)="viewUser(data.user_id,data.qudos_user_type)">{{data.user_name}}</p>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
