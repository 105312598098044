import { Injectable } from '@angular/core';
import { Globals, API } from 'src/app/helpers/constant';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { GlobalFunctions } from 'src/app/helpers/service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

//Service to manage the user functionalities
export class UserService {

  faveDrivers = [];
  totalFaveDrivers = [];

  constructor(public globals: Globals, private toaster: ToastrService, private http: HttpClient, private serviceFunction: GlobalFunctions) {
  }

  //////////////////////////////////////////// FAVE DRIVER //////////////////////////////////////////

  //Add Favourite Driver
  addFaveDriver(data, driver, type = 1, index = ''): Observable<any> {

    return this.http.post<any>(API.ADD_FAVE_DRIVER, {
      driver_id: type == 1 ? driver.driver_id : driver.user_id,
      referral_code: driver.referral_code
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.flag === 702) {
          if (type == 1) {
            data.drivers = data.drivers.filter(function (val, index) {
              return val.driver_id != driver.driver_id
            });
            data.total_drivers = data.total_drivers - 1;
          } else if (type == 2) {
            data.is_fav_driver = 1;
            data.is_follower = 1;
            data.requested = 0;
          } else if (type == 3) {
            data.data[index].is_fav_driver = 1;
            data.data[index].is_follower = 1;
            data.data[index].requested = 0;
            data.data[index].is_friend = 1;
          }
          this.toaster.success(response.log, 'SUCCESS');
        }
        else {
          this.toaster.error(response.error, "ERROR");
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Remove Favourite Driver
  removeFaveDriver(data, driver, type = 1, index = ''): Observable<any> {

    return this.http.post<any>(API.REMOVE_FAVE_DRIVER, {
      driver_id: type == 1 ? driver.driver_id : driver.user_id
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.flag === 704) {
          if (type == 1) {
            this.toaster.success(response.log, 'SUCCESS');
            data.faveDrivers = data.faveDrivers.filter(function (val, index) {
              return val.driver_id != driver.driver_id
            });
            data.totalFaveDrivers -= 1;
          } else if (type == 2) {
            data.is_fav_driver = 0;
            data.is_follower = 0;
          } else if (type == 3) {
            this.toaster.success(response.log, 'SUCCESS');
            data.data[index].is_fav_driver = 0;
            data.data[index].is_follower = 0;
            data.data[index].is_friend = 0;
          }
        }
        else {
          this.toaster.error(response.error, "ERROR");
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //View the details of the people who have made a driver favourite
  viewFaveCountfunction(data, user, loadMore = false): Observable<any> {

    return this.http.post<any>(API.VIEW_FAVE_COUNT, {
      qudos_user_type: this.globals.user.qudos_user_type,
      driver_id: user.user_id,
      limit: 10,
      offset: data.viewFaveCountOffset
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          if (loadMore) {
            data.viewFave = data.viewFave.concat(response.users);
          } else {
            data.viewFave = response.users;
          }
          data.faveUsersCount = data.viewFave;
          data.totalFaveUsers = response.total_users;
          data.faveUsers = data.viewFave.length;
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //////////////////////////////////////////// RIDER/DRIVER //////////////////////////////////////////

  //Remove Person
  removePerson(data, friend, selectedType, type = 1, index = ''): Observable<any> {
    let follow_id = 0, follow_type = 0;
    if (type == 1) {
      if (selectedType == 0) {
        follow_id = friend.user_id;
        follow_type = 0;
      }
      else {
        follow_id = friend.driver_id;
        follow_type = 1;
      }
    }
    else if (type == 2 || type == 3) {
      follow_id = friend.user_id;
      follow_type = selectedType;
    }

    return this.http.post<any>(API.UNFRIEND, {
      qudos_user_type: this.globals.user.qudos_user_type,
      follower_id: follow_id,
      follower_type: follow_type
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 1) {
            if (!this.globals.user.qudos_user_type) {
              data.peoples = data.peoples.filter(function (val, index) {
                return val.user_id != friend.user_id
              });
              data.total_people -= 1;
            }
            else {
              if (selectedType) {
                data.drivers = data.drivers.filter(function (val, index) {
                  return val.driver_id != friend.driver_id
                });
                data.total_drivers -= 1;
              }
              else {
                data.peoples = data.peoples.filter(function (val, index) {
                  return val.user_id != friend.user_id
                });
                data.total_people -= 1;
              }
            }
          } else if (type == 2) {
            data.is_friend = 0;
            data.is_follower = 0;
          } else if (type == 3) {
            data.data[index].is_friend = 0;
            data.data[index].is_follower = 0;

          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Add Person
  addPerson(data, user, userType, index, type = 1): Observable<any> {
    let friend_id = 0, friend_type = 0;
    if (type == 1) {
      if (userType == 0) {
        friend_id = user.user_id;
        friend_type = 0;
      }
      else {
        friend_id = user.driver_id;
        friend_type = 1;
      }
    }
    else if (type == 2 || type == 3) {
      friend_id = user.user_id;
      friend_type = userType;
    }

    return this.http.post<any>(API.ADD_FRIEND, {
      qudos_user_type: this.globals.user.qudos_user_type,
      follower_id: friend_id,
      follower_type: friend_type
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 1) {
            if (!this.globals.user.qudos_user_type) {
              data.riders = data.riders.filter(function (val, index) {
                return val.user_id != user.user_id
              });
              data.total_riders -= 1;
            }
            else {
              if (userType) {
                data.drivers = data.drivers.filter(function (val, index) {
                  return val.driver_id != user.driver_id
                });
                data.total_drivers -= 1;
              }
              else {
                data.riders = data.riders.filter(function (val, index) {
                  return val.user_id != user.user_id
                });
                data.total_riders -= 1;
              }
            }
          } else if (type == 2) {
            data.is_friend = 1;
            data.is_follower = 1;
            data.requested = 0;
          } else if (type == 3) {
            data.data[index].is_friend = 1;
            data.data[index].is_follower = 1;
            data.data[index].requested = 0;
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Share Person
  sharePerson(data, personId, selectedType): Observable<any> {

    data.describe = this.globals.description ? this.globals.description : data.describe;
    if ((data.describe) === undefined || data.describe.length == 0) {
      return throwError('Please enter a description.');
    }
    else {
      let params = new FormData();
      params.append("access_token", this.globals.user.web_access_token);
      params.append('qudos_user_type', this.globals.user.qudos_user_type);
      params.append('tagged_users', this.globals.taggedArray.toString());
      params.append('tagged_members', JSON.stringify(this.globals.taggedMember));
      params.append('description', encodeURIComponent(this.serviceFunction.htmlEncode((data.describe))));
      params.append('person_id', personId);
      params.append('person_type', selectedType);
      params.append('user_id', this.globals.user.user_id);


      return this.http.post<any>(API.SHARE_PERSON, params).pipe(
        map(response => {
          if (typeof (response) == 'string') {
            response = JSON.parse(response);
          }
          if (response.error) {
            this.toaster.error(response.error, "ERROR");
          } else {
            this.toaster.success(response.log, 'SUCCESS');
            this.globals.description = '';
            let auth_data = JSON.parse(localStorage.getItem('auth'));
            auth_data.total_posts += 1;
            this.globals.user = auth_data;
            localStorage.setItem('auth', JSON.stringify(auth_data));
            if (Object.keys(this.globals.tagify).length) {
              this.globals.tagify.destroy();
              this.globals.tagify = {};
            }
          }
          return data;
        }),
        catchError(this.serviceFunction.handleError)
      )
    };
  }

  //////////////////////////////////////////// FRIEND REQUEST //////////////////////////////////////////

  //Reject Person Request
  rejectPerson(data, user, userType, index, type = 1): Observable<any> {
    let friend_id = 0, friend_type = 0;
    if (type == 1 || type == 4) {
      if (userType == 0) {
        friend_id = user.user_id;
        friend_type = 0;
      }
      else {
        friend_id = user.driver_id;
        friend_type = 1;
      }
    }
    else if (type == 2 || type == 3) {
      friend_id = user.user_id;
      friend_type = userType;
    }
    return this.http.post<any>(API.REJECT_FRIEND_REQUEST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      friend_id: friend_id,
      friend_type: friend_type
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 1) {
            if (userType == 0) {
              data.riders[index].requested = 0;
            }
            else {
              data.drivers[index].requested = 0;
            }
          } else if (type == 2) {
            data.requested = 0;
          } else if (type == 3) {
            data.data[index].requested = 0;
          } else if (type == 4) {
            data.confirmPeople[index].requested = 0;
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Cancel Person Request
  cancelRequest(data, user, userType, index, type = 1): Observable<any> {
    let follow_id = 0, follow_type = 0;
    if (type == 1) {
      if (userType == 0) {
        follow_id = user.user_id;
        follow_type = 0;
      }
      else {
        follow_id = user.driver_id;
        follow_type = 1;
      }
    }
    else if (type == 2 || type == 3) {
      follow_id = user.user_id;
      follow_type = userType;
    }
    return this.http.post<any>(API.CANCEL_FRIEND_REQUEST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      friend_id: follow_id,
      friend_type: follow_type
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 1) {
            if (userType == 0) {
              data.riders[index].friend_request = 0;
            }
            else {
              data.drivers[index].friend_request = 0;
            }
          } else if (type == 2) {
            data.friend_request = 0;
          } else if (type == 3) {
            data.data[index].friend_request = 0;
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Send Friend Request to Person
  sendFriendRequest(data, friend, selectedIndex, selectedType, type = 1): Observable<any> {
    let follow_id = 0, follow_type = 0;
    if (type == 1) {
      if (selectedType == 0) {
        follow_id = friend.user_id;
        follow_type = 0;
      }
      else {
        follow_id = friend.driver_id;
        follow_type = 1;
      }
    }
    else if (type == 2 || type == 3) {
      follow_id = friend.user_id;
      follow_type = selectedType
    }

    return this.http.post<any>(API.ADD_FRIEND_REQUEST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      friend_id: follow_id,
      friend_type: follow_type
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 1) {
            if (!this.globals.user.qudos_user_type) {
              data.riders[selectedIndex].requested = 0;
              data.riders[selectedIndex].friend_request = 1;
            }
            else {
              if (selectedType) {
                data.drivers[selectedIndex].requested = 0;
                data.drivers[selectedIndex].friend_request = 1;
              }
              else {
                data.riders[selectedIndex].requested = 0;
                data.riders[selectedIndex].friend_request = 1;
              }
            }
          } else if (type == 2) {
            data.requested = 0;
            data.friend_request = 1;
          } else if (type == 3) {
            data.data[selectedIndex].requested = 0;
            data.data[selectedIndex].friend_request = 1;
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //////////////////////////////////////////// FOLLOW/UNFOLLOW //////////////////////////////////////////

  //Follow Person
  addFollow(data, user, userType, index, type = 1): Observable<any> {
    let follow_id = 0, follow_type = 0;
    if (type == 1) {
      if (userType == 0) {
        follow_id = user.user_id;
        follow_type = 0;
      }
      else if (userType == 1) {
        follow_id = user.driver_id;
        follow_type = 1;
      }
    }
    else if (type == 2) {
      follow_id = user;
      follow_type = userType;
    }
    else if (type == 3) {
      follow_id = user;
      follow_type = userType;
    }

    return this.http.post<any>(API.FOLLOW_PERSON, {
      qudos_user_type: this.globals.user.qudos_user_type,
      follower_id: follow_id,
      follower_type: follow_type
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 1) {
            if (userType == 0) {
              if (data.innerTab == 1) {
                data.peoples[index].is_follower = 1;
              }
              else if (data.innerTab == 2) {
                data.riders[index].is_follower = 1;
              }
              else if (data.innerTab == 3) {
                data.confirmPeople[index].is_follower = 1;
              }
            }
            else {
              if (data.innerTab == 1) {
                data.faveDrivers[index].is_follower = 1;
              }
              else if (data.innerTab == 2) {
                data.drivers[index].is_follower = 1;
              }
              else if (data.innerTab == 3) {
                data.confirmPeople[index].is_follower = 1;
              }
            }
          } else if (type == 2) {
            data.is_follower = 1;
          } else if (type == 3) {
            data.data[index].is_follower = 1;
          }

        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Unfollow Person
  removeFollow(data, user, userType, index, type = 1): Observable<any> {
    let follow_id = 0, follow_type = 0;
    if (type == 1) {
      if (userType == 0) {
        follow_id = user.user_id;
        follow_type = 0;
      }
      else {
        follow_id = user.driver_id;
        follow_type = 1;
      }
    }
    else if (type == 2) {
      follow_id = user;
      follow_type = userType
    }
    else if (type == 3) {
      follow_id = user;
      follow_type = userType;
    }

    return this.http.post<any>(API.UNFOLLOW_PERSON, {
      qudos_user_type: this.globals.user.qudos_user_type,
      follower_id: follow_id,
      follower_type: follow_type
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          this.toaster.success(response.log, 'SUCCESS');
          if (type == 1) {
            if (userType == 0) {
              if (data.innerTab == 1) {
                data.peoples[index].is_follower = 0;
              }
              else if (data.innerTab == 2) {
                data.riders[index].is_follower = 0;
              }
              else if (data.innerTab == 3) {
                data.confirmPeople[index].is_follower = 0;
              }
            }
            else {
              if (data.innerTab == 1) {
                data.faveDrivers[index].is_follower = 0;
              }
              else if (data.innerTab == 2) {
                data.drivers[index].is_follower = 0;
              }
              else if (data.innerTab == 3) {
                data.confirmPeople[index].is_follower = 0;
              }
            }
          } else if (type == 2) {
            data.is_follower = 0;
          } else if (type == 3) {
            data.data[index].is_follower = 0;
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //////////////////////////////////////////// LISTS //////////////////////////////////////////

  //Generate Friend List
  getFriendList(data, searchString = '', loadMore = false): Observable<any> {

    let params = new FormData();
    params.append("access_token", this.globals.user.web_access_token);
    params.append('qudos_user_type', this.globals.user.qudos_user_type);
    params.append('limit', "10");
    params.append('offset', "0");
    params.append('searchString', searchString);
    params.append('selectedUsers', JSON.stringify(this.globals.taggedMember));

    return this.http.post<any>(API.FRIEND_LIST, params).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          data.total_network = response.total_friends;

          if (loadMore) {
            this.globals.network = this.globals.network.concat(response.friends);
          } else {
            this.globals.network = response.friends;
          }
          this.globals.total_network = response.total_friends;
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Generate Network List 
  networkList(data, type, loadMore): Observable<any> {
    if (type == 2 || type == 4) {                        // type = 2 QudosFave Explore , type = 4 QudosFave Friends
      if ((data.searchString.length > 0 && !loadMore) || ((data.filter_seats || data.filter_country || data.filter_zip || data.filter_keyword) && !loadMore)) {
        data.offset = 0;
        data.drivers = [];
        data.faveDrivers = [];
      }
    }
    else if (type == 1 || type == 3) {                    // type =1 People Explore , type =3 People Friends
      if ((data.searchString.length > 0 && !loadMore) || ((data.filter_name || data.filter_country) && !loadMore)) {
        data.offset = 0;
        data.riders = [];
        data.peoples = [];
      }
    }

    return this.http.post<any>(API.QUDOS_NETWORK_LIST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: 10,
      offset: data.offset,
      requestType: type,
      sort_by: "date_registered",
      sort_order: "desc",
      is_friend: 1,//0=Follower list , 1= friend list
      searchString: data.searchString,
      searchCount: data.filter_seats,
      searchCity: data.filter_city,
      searchZipcode: data.filter_zip,
      searchKeyword: data.filter_keyword,
      searchName: data.filter_name,
      searchCountry: data.filter_country
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          if (type == 1) {
            data.total_riders = response.total_riders;
            if (loadMore) {
              data.riders = data.riders.concat(response.riders);
            }
            else {
              data.riders = response.riders;
            }
            data.filter_name = data.filter_name;
            data.filter_country = data.filter_country;

            if (data.filter_name || data.filter_country) {
              data.searchBar = true;
              if (data.total_riders == 0) {
                data.emptyResult = true;
              }
            }
            else if (data.searchString) {
              if (data.total_riders == 0) {
                data.emptyResult = true;
              }
            }
          } else if (type == 2) {

            data.total_drivers = response.total_drivers;
            if (loadMore) {
              data.drivers = data.drivers.concat(response.drivers);
            }
            else {
              data.drivers = response.drivers;
            }
            data.filter_keyword = data.filter_keyword;
            data.filter_seats = data.filter_seats;
            data.filter_country = data.filter_country;
            data.filter_zip = data.filter_zip;

            if (data.filter_keyword || data.filter_seats || data.filter_country || data.filter_zip) {
              data.searchBar = true;
              if (data.total_drivers == 0) {
                data.emptyResult = true;
              }
            }
            else if (data.searchString) {
              if (data.total_drivers == 0) {
                data.emptyResult = true;
              }
            }
          } else if (type == 3) {

            data.total_people = response.total;
            if (loadMore) {
              data.peoples = data.peoples.concat(response.peoples);
            }
            else {
              data.peoples = response.peoples;
            }
            data.filter_name = data.filter_name;
            data.filter_country = data.filter_country;

            if (data.filter_name || data.filter_country) {
              data.searchBar = true;
              if (data.total_people == 0) {
                data.emptyResult = true;
              }
            }
            else if (data.searchString) {
              if (data.total_people == 0) {
                data.emptyResult = true;
              }
            }
          } else if (type == 4) {
            data.totalFaveDrivers = response.total_drivers;
            if (loadMore) {
              data.faveDrivers = data.faveDrivers.concat(response.drivers);
            }
            else {
              data.faveDrivers = response.drivers;
            }
            data.filter_keyword = data.filter_keyword;
            data.filter_seats = data.filter_seats;
            data.filter_country = data.filter_country;
            data.filter_zip = data.filter_zip;

            if (data.filter_keyword || data.filter_seats || data.filter_country || data.filter_zip) {
              data.searchBar = true;
              if (data.totalFaveDrivers == 0) {
                data.emptyResult = true;
              }
            }
            else if (data.searchString) {
              if (data.totalFaveDrivers == 0) {
                data.emptyResult = true;
              }
            }
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Generate Pending List
  pendingList(data, userType, loadMore): Observable<any> {
    if (userType == 0) {
      if ((data.searchString.length > 0 && !loadMore) || ((data.filter_name || data.filter_country) && !loadMore)) {
        data.offset = 0;
        data.confirmPeople = [];
      }
    }
    else if (userType == 1) {
      if ((data.searchString.length > 0 && !loadMore) || ((data.filter_seats || data.filter_city || data.filter_zip || data.filter_keyword) && !loadMore)) {
        data.offset = 0;
        data.confirmPeople = [];
      }
    }
    return this.http.post<any>(API.PENDING_REQUESTS, {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: 10,
      offset: data.offset,
      list: userType, //0=riders , 1=drivers
      searchString: data.searchString,
      searchCount: data.filter_seats,
      searchCity: data.filter_city,
      searchZipcode: data.filter_zip,
      searchKeyword: data.filter_keyword,
      searchName: data.filter_name,
      searchCountry: data.filter_country
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          data.total_confirmations = response.total_pending;
          if (loadMore) {
            data.confirmPeople = data.confirmPeople.concat(response.pending);
          } else {
            data.confirmPeople = response.pending;
          }
          data.filter_keyword = data.filter_keyword;
          data.filter_seats = data.filter_seats;
          data.filter_city = data.filter_city;
          data.filter_zip = data.filter_zip;
          data.filter_name = data.filter_name;
          data.filter_country = data.filter_country;

          if (data.filter_name || data.filter_country || data.filter_keyword || data.filter_seats || data.filter_city || data.filter_zip) {
            data.searchBar = true;
            if (data.total_confirmations == 0) {
              data.emptyResult = true;
            }
          } else if (data.searchString) {
            if (data.total_confirmations == 0) {
              data.emptyResult = true;
            }
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Generate Group List
  groupList(data, type, loadMore): Observable<any> {
    if ((data.searchString.length > 0 && !loadMore) || (data.filter_group_name && !loadMore)) {
      data.offset = 0;
      data.groups = [];
    }

    return this.http.post<any>(API.QUDOS_GROUP_LIST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      offset: data.offset,
      limit: 10,
      searchString: data.searchString,
      sort_by: "group_id",
      sort_order: "desc",
      searchName: data.filter_group_name,
      requestType: type //1= My Groups , 2 =Explore
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          data.total_groups = response.total_groups;
          if (loadMore) {
            data.groups = data.groups.concat(response.groups);
          } else {
            data.groups = response.groups;
          }
          // for (let i = 0; i < data.groups.length; i++) {
          //   data.groups[i].group_name = decodeURIComponent((data.groups[i].group_name));
          //   data.groups[i].description = decodeURIComponent((data.groups[i].description));
          // }
          data.filter_group_name = data.filter_group_name;
          if (data.filter_group_name) {
            data.searchBar = true;
            if (data.total_groups == 0) {
              data.emptyResult = true;
            }
          } else if (data.searchString) {
            if (data.total_groups == 0) {
              data.emptyResult = true;
            }

          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Fetch list based on friends type
  seeAllFriendsType(data, loadMore = false): Observable<any> {
    if (data.searchString.length > 0 && !loadMore) {
      data.offset = 0;
      data.data = [];
    }

    return this.http.post<any>(API.SEE_ALL_FRIENDS, {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: 10,
      offset: data.offset,
      searchString: data.searchString,
      person_type: data.userType,
      person_id: data.id,
      group_id: data.id,
      requestType: data.requestType
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          if (data.requestType == 1) {
            data.totalData = response.total;
            if (loadMore) {
              data.data = data.data.concat(response.peoples);
            }
            else {
              data.data = response.peoples;
            }
          } else if (data.requestType == 2) {
            data.totalData = response.total;
            if (loadMore) {
              data.data = data.data.concat(response.drivers);
            }
            else {
              data.data = response.drivers;
            }
          } else if (data.requestType == 3) {
            data.totalData = response.total;
            if (loadMore) {
              data.data = data.data.concat(response.members);
            }
            else {
              data.data = response.members;
            }
          }
          for (let i = 0; i < data.data.length; i++) {
            data.data[i].follower_type = parseInt(data.data[i].follower_type);
            data.data[i].qudos_user_type = parseInt(data.data[i].qudos_user_type);
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //get Qudos Fav List based on requestType
  getQudosFavList(data, loadMore = false): Observable<any> {
    if (data.searchString.length > 0 && !loadMore) {
      data.offset = 0;
      data.data = [];
    }

    return this.http.post<any>(API.QUDOS_NETWORK_LIST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: 10,
      offset: data.offset,
      searchString: data.searchString,
      person_type: data.userType,
      person_id: data.id,
      group_id: data.id,
      requestType: 4
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          if (data.requestType == 1) {
            data.totalData = response.total;
            if (loadMore) {
              data.data = data.data.concat(response.peoples);
            }
            else {
              data.data = response.peoples;
            }
          } else if (data.requestType == 2) {
            data.totalData = response.total;
            if (loadMore) {
              data.data = data.data.concat(response.drivers);
            }
            else {
              data.data = response.drivers;
            }
          } else if (data.requestType == 3) {
            data.totalData = response.total;
            if (loadMore) {
              data.data = data.data.concat(response.members);
            }
            else {
              data.data = response.members;
            }
          }
          for (let i = 0; i < data.data.length; i++) {
            data.data[i].follower_type = parseInt(data.data[i].follower_type);
            data.data[i].qudos_user_type = parseInt(data.data[i].qudos_user_type);
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //fetch user details based on id
  fetchUserById(data, type): Observable<any> {

    return this.http.post<any>(API.FETCH_PERSON_BY_ID, {
      qudos_user_id: data.userId,
      qudos_user_type: type
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          if (type) {
            data.driver = response.personInfo[0];
          } else {
            data.rider = response.personInfo[0];
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Fetch person details
  getPersonDetails(data, type): Observable<any> {
    let personType, personId;

    if (type == 0) {
      personId = data.personId;
      personType = data.personType
    }
    else {
      personId = type == 1 ? this.globals.user.user_id : location.href.split('/')[4];
      personType = type == 1 ? this.globals.user.qudos_user_type : location.href.split('/')[5]
    }

    return this.http.post<any>(API.PERSON_DETAILS, {
      qudos_user_type: this.globals.user.qudos_user_type,
      person_id: personId,
      person_type: personType
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        else {
          if (type == 0) {
            data.userData = response.profile_data[0];
          } else {
            data.data = response.profile_data[0];

            data.friendData = response.friends_data;
            data.totalMutualFriends = response.total_mutual_friends;
            data.driverData = response.drivers_data;
            data.totalMutualDrivers = response.total_mutual_drivers;

            data.is_follower = response.is_follower;
            data.is_fav_driver = response.is_fav_driver;
          }
          data.requested = response.requested;
          data.friend_request = response.friend_request;
          data.is_friend = response.is_friend;
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Fetch list of friends to be tagged.
  getTaggedFriendsList = function () {
    this.globals.onLoading = true;
    let data = this;
    this.getFriendList(data).subscribe(result => {
      if (this.globals.network) {
        for (let i = 0; i < this.globals.network.length; i++) {
          this.globals.network[i].value = this.globals.network[i].user_name ? "#" + this.globals.network[i].user_name.split(' ')[0] : '';;
          this.globals.network[i].link = environment.LINK + "userInfo/" + this.globals.network[i].user_id + "/" + this.globals.network[i].qudos_user_type;
        }
      }
      this.globals.onLoading = false;
    },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      })
  };

}
