<div class="service-wrap-header {{localStorageAuth ? 'service-wrap-login' : ''}}">
   <section class="section-core--policies section-banner">
      <div class="container">
      <div class="row">
         <div class="col-sm-12 d-flex flex-column mt-5">
            <span class="d-inline-block h-100">
               <h1 class="core-header-special-1">&nbsp;<span class="d-block">QudosConnect User Terms and Conditions</span></h1>
               <p class="subheader__prime">Last Updated: April 8, 2021</p>
            </span>
         </div>
         </div>
      </div>
   </section>
</div>
<div class="service-wrap">
<section class="section-core-rules bg-white">
   <div class="container">
      <div class="row">
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">1. Introduction</h5>
            <p class="core_parag">
               You agree that by joining QudosConnect, you are agreeing to enter into a legally binding contract with Qudos. If you do not agree to this contract, you may not access or use the Services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our Services.<br />
               <br />
               We may modify this Contract, our Privacy Policy and our Cookies Policies from time to time. If we make material changes to it, we will provide you notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. We agree that changes cannot be retroactive. If you object to any changes, you may close you account. Your continued use of our Services after we publish or send a notice about our changes to these terms means that you are consenting to the updated terms.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">2. Service Eligibility</h5>
            <p class="core_parag">
               To use the Services, you agree that: (1) you must be the “Minimum Age” (described below) or older; (2) you will only have one Qudos account, which must be in your real name; and (3) you are not already restricted by Qudos from using the Services. Creating an account with false information is a violation of our terms, including accounts registered on behalf of others or persons under the age of 16.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">3. Your Account</h5>
            <p class="core_parag">
               Members are account holders. You agree to: (1) try to choose a strong and secure password; (2) keep your password secure and confidential; (3) not transfer any part of your account (e.g., connections) and (4) follow the law and our list of Dos and Don’ts and Community Standards & Policies.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">4. Notices and Messages</h5>
            <p class="core_parag">
               You agree that we will provide notices and messages to you in the following ways: (1) within the Service, or (2) sent to the contact information you provided us (e.g., email, mobile number, physical address). You agree to keep your contact information up to date. You’re okay with us providing notices and messages to you through our websites, apps, and contact information. If your contact information is out of date, you may miss out on important notices.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">5. Sharing</h5>
            <p class="core_parag">
               Information and content that you share or post may be seen by other Members. Where we have made settings available, we will honor the choices you make about who can see content or information. We are not obligated to publish any information or content on our Service and can remove it in our sole discretion, with or without notice. When you share information on our Services, others can see, copy and use that information. We want people to use QudosConnect to express themselves and to share content that is important to them, but not at the expense of the safety and well-being of others or the integrity of our community.<br />
               <br />
               If we remove content that you have shared in violation of our Community Standards, we’ll let you know and explain any options you have to request another review, unless you seriously or repeatedly violate these Terms or if doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services, systems or Products; where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">6. Your License to QudosConnect</h5>
            <p class="core_parag">
               You own all of the content, feedback, and personal information you provide to us, but you also grant us a non-exclusive license to it. You can end this license for specific content by deleting such content from the Services, or generally by closing your account, except (a) to the extent you shared it with others as part of the Service and they copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems.<br />
               <br />
               You and QudosConnect agree that we may access, store, process and use any information and personal data that you provide in accordance with the terms of the Privacy Policy and your choices (including settings). You and QudosConnect agree that if content includes personal data, it is subject to our Privacy Policy.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">7. Service Availability</h5>
            <p class="core_parag">
               We may change, suspend or end any Service, or change and modify prices prospectively in our discretion. To the extent allowed under law, these changes may be effective upon notice provided to you. QudosConnect is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">8. Other Content, Sites and Apps</h5>
            <p class="core_parag">
               By using the Services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. QudosConnect generally does not review content provided by our Members or others. You agree that we are not responsible for others’ (including other Members’) content or information. Your use of others’ content and information posted on our Services, is at your own risk.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">9. Limits</h5>
            <p class="core_parag">
               We have the right to limit how you connect and interact on our Services. QudosConnect reserves the right to limit your use of the Services, including the number of your connections and your ability to contact other Members. QudosConnect reserves the right to restrict, suspend, or terminate your account if QudosConnect believes that you may be in breach of this Contract or law or are misusing the Services.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">10. Intellectual Property Rights</h5>
            <p class="core_parag">
               QudosConnect reserves all of its intellectual property rights in the Services. Using the Services does not give you any ownership in our Services or the content or information made available through our Services. We’re providing you notice about our intellectual property rights.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">11. Disclaimer and Limitation of Liability</h5>
            <p class="core_parag">
               AS ALLOWED UNDER LAW, QUDOSCONNECT AND ITS AFFILIATES (A) DISCLAIM ALL IMPLIED WARRANTIES AND REPRESENTATIONS, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY OF DATA, AND NONINFRINGEMENT; (B) DO NOT GUARANTEE THAT THE SERVICES WILL FUNCTION WITHOUT INTERRUPTION OF ERRORS, AND PROVIDE THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS.<br />
               <br />
               AS PERMITTED UNDER LAW, QUDOSCONNECT SHALL NOT BE LIABLE TO YOU OR OTHER FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF DATA, OPPORTUNITIES, REPUTATION, PROFITS OR REVENUES, RELATED TO THE SERVICES. THIS LIMITATION IS PART OF THE BASIS OF THE BARGAIN BETWEEN YOU AND QUDOSCONNECT AND APPLIES TO CLAIMS OF LIABILITY AND EVEN IF QUDOSCONNECT HAS BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE. SOME LAWS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY, SO THESE LIMITS MAY NOT APPLY TO YOU.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">12. Termination of Account</h5>
            <p class="core_parag">
               Both you and QudosConnect may terminate this Contract at any time with notice to the other. On termination, you lose the right to access or use the Services. We can each end this Contract anytime we want. If we determine that you have clearly, seriously or repeatedly breached our Terms or Policies, including in particular our Community Standards, we may suspend or permanently disable access to your account. We may also suspend or disable your account if you repeatedly infringe other people’s intellectual property rights or where we are required to do so for legal reasons.<br />
               <br />
               Where we take such action we’ll let you know and explain any options you have to request a review, unless doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services, systems or Products; or where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">13. General Terms</h5>
            <p class="core_parag">
               To the extent allowed by law, the English language version of this Contract is binding and other translations are for convenience only. This Contract is the only agreement between us regarding the Services and supersedes all prior agreements for the Services. If we don't act to enforce a breach of this Contract, that does not mean that QudosConnect has waived its right to enforce this Contract. You may not assign or transfer this Contract (or your membership or use of Services) to anyone without our consent. However, you agree that QudosConnect may assign this Contract to its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to this Contract.<br />
               <br />
               If any portion of these Terms is found to be unenforceable, the remaining portion will remain in full force and effect. If we fail to enforce any of these Terms, it will not be considered a waiver. Any amendment to or waiver of these Terms must be made in writing and signed by us. You will not transfer any of your rights or obligations under these Terms to anyone else without our consent.                            
            </p>
         </div>
         <div class="col-sm-12 d-flex flex-column mt-5">
            <h5 class="qudos_blue_text my-3">14. Complaints Regarding Content</h5>
            <p class="core_parag">
               We respect the intellectual property rights of others. We require that information posted by Members be accurate and not in violation of the intellectual property rights or other rights of third parties. We provide a policy and process for complaints concerning content posted by our Members.                            
            </p>
         </div>
      </div>
   </div>
</section>
</div>