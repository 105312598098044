import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
  
  //Service to share data between the components.
export class DataService {
  is_admin: any; 
  is_deleted: any;
  pageData = {
    is_like: 0,
    page_type:0
  };
  isProfileUpdated: boolean;
  posts = [];
  isPageEdited = false;
  constructor() {
   }
   
}
