import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals, API } from 'src/app/helpers/constant';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { GlobalFunctions } from 'src/app/helpers/service';

@Injectable({
  providedIn: 'root'
})

//service to manage settings functionalities
export class SettingsService {
  getLanguageRequest: {};
  setLanguageRequest: { qudos_user_type: any; language: any; };
  setLanguageRequestPrivacy: { qudos_user_type: any; language: any; privacy: any; };

  constructor(public globals: Globals, private http: HttpClient, private toaster: ToastrService, private serviceFunction: GlobalFunctions) { }

  //Get Languages
  getLanguages(data): Observable<any> {
    this.getLanguageRequest = {
      qudos_user_type: this.globals.user.qudos_user_type,
    }
    return this.http.post<any>(API.GET_LANGUAGE, this.getLanguageRequest).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.languageList = response.languages;
          data.languageData = response.selected_language[0].language;
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //set Languages
  setLanguage(data): Observable<any> {
    this.setLanguageRequest = {
      qudos_user_type: this.globals.user.qudos_user_type,
      language: data.languageData
    }
    return this.http.post<any>(API.SET_LANGUAGE, this.setLanguageRequest).pipe(
      map(response => {
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //SET_LANGUAGE_privacy
  setLanguagePrivacy(data): Observable<any> {
    this.setLanguageRequestPrivacy = {
      qudos_user_type: this.globals.user.qudos_user_type,
      language: data.languageData,
      privacy: data.privacy
    }
    return this.http.post<any>(API.SET_LANGUAGE_privacy, this.setLanguageRequestPrivacy).pipe(
      map(response => {
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        }
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }
}
