<div class="sign">
    <div class="container">
        <div class="row">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="sign_content">
                    <div class="row">
                        <div class="popup-main-content login_content">
                            <div id="main-login" class="row">
                                <div class="col-md-6 login-main-content">
                                    <h3>Log In </h3>
                                    Qudos inspires people from all walks of life to share their ideas and experiences.
                                </div>
                                <div class="col-md-6">
                                    <div class="login-form logn-content">
                                        <form name="login1" (ngSubmit)='login_submit()' [formGroup]="loginForm">
                                            <label>Email Address</label>
                                            <div class="row-input login-input">
                                                <img src="./assets/img/mail.png" class="mail_icon ">
                                                <input type="email" class="mobile" placeholder="Enter Email Address" [formControl]="username"
                                                    name="email">
                                                <div *ngIf="getLogin.email.errors">
                                                    <div class="errorMessage" *ngIf="getLogin.email.errors && getLogin.email.touched && !getLogin.email.errors.required"> Invalid Email address.</div>
                                                    <div class="errorMessage" *ngIf="getLogin.email.errors.required && getLogin.email.touched">Email
                                                        Address is required</div>
                                                </div>
                                                <div class="margin-bottom"></div>
                                            </div>
                                            <label>Password</label>
                                            <div class="row-input login-input">
                                                <img src="./assets/img/key.png" class="pass_icon ">
                                                <input id="password" name="password" class="password" type="password" placeholder="Enter Password"
                                                    [formControl]="password">
                                                <div *ngIf="getLogin.password.errors">
                                                    <div class="errorMessage" *ngIf="getLogin.password.errors.required && getLogin.password.touched">
                                                        Password is required</div>
                                                    <div class="errorMessage" *ngIf="getLogin.password.errors && getLogin.password.touched && !getLogin.password.errors.required">
                                                        Invalid Password.</div>
                                                </div>
                                                <div class="margin-bottom"></div>
                                            </div>
                                            <label>Logging As </label>
                                            <div class="row-input">
                                                <i class="fas fa-caret-down"></i>
                                                <select class="user-type" formControlName="user_type">
                                                    <option [ngValue]="selectedItem.value" *ngFor="let selectedItem of userType"
                                                        [selected]="selectedItem.value == 0">{{selectedItem.name}}</option>
                                                </select>
                                            </div>
                                            <button class="submit" type="submit" id="signIn">Sign In</button>
                                        </form>
                                        <div class="row-forget-pass">
                                            <div class="left-align">
                                                <a id="forget" (click)="resetPage()" class="pointer-cursor">Forgot Password?</a>
                                            </div>
                                            <div class="right-align pull-right">
                                                New to this?
                                                <a routerLink="/signup" routerLinkActive="active"> <strong>Signup</strong></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--End inner Row-->

                            <!--Start Inner Row1-->
                            <div id="reset1" class="row hidden">
                                <div class="col-md-6">
                                    <h3>Reset Password</h3>
                                    Enter your email address and we will send you a OTP to reset your password.
                                </div>
                                <div class="col-md-6">
                                    <div class="login-form">
                                        <form name="login" (ngSubmit)='checkEmail()' [formGroup]="resetForm">
                                            <label>Email Address</label>
                                            <div class="row-input">
                                                <img src="./assets/img/mail.png" class="mail_icon ">
                                                <input class="mobile" type="email" placeholder="Enter Email Address" [formControl]="emailId"
                                                    name="mobile_value">
                                                <div *ngIf="resetForm.controls.user_email.errors">
                                                    <div class="errorMessage"
                                                        *ngIf="resetForm.controls.user_email.errors.required && resetForm.controls.user_email.touched">
                                                        Email Address is required</div>
                                                    <div class="errorMessage"
                                                        *ngIf="resetForm.controls.user_email.errors && resetForm.controls.user_email.touched && !resetForm.controls.user_email.errors.required">
                                                        Invalid Email Address.</div>
                                                </div>
                                                <div class="margin-bottom"></div>
                                            </div>
                                            <label>User Type</label>
                                            <div class="row-input">
                                                <i class="fas fa-caret-down"></i>
                                                <select class="user-type" formControlName="qudos_user_type">
                                                    <option [ngValue]="selectedItem.value" *ngFor="let selectedItem of userType"
                                                        [selected]="selectedItem.value == 0">{{selectedItem.name}}</option>
                                                </select>
                                            </div>
                                            <button id="reset-2" class="submit top-margin" type="submit" [disabled]="globals.onLoading">Send
                                                OTP</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!--Start Inner Row1-->

                            <!--Start Inner Row2-->
                            <div id="reset2" class="row hidden">

                                <div id="main-hidden" class="col-md-6">
                                    <h3>Reset Password</h3>
                                    <div id="main-hidden1">Qudos continues to act as the catalyst for lasting connections between socially aware
                                        individuals. Hang on there, you will get back in track in no time.</div>
                                    <br>
                                    <br>
                                    <p>Confirm your Email Address to reset your password</p>
                                </div>

                                <div class="col-md-6">
                                    <div class="login-form"><br>
                                        <form name="forgetLogin" [formGroup]="confirmPasswordForm" (ngSubmit)="forgotPassword()">
                                            <label>Enter OTP sent to your Email Address</label>
                                            <div class="row-input">
                                                <input id="otp" type="tel" name="otp" inputmode="numeric" placeholder="0000"
                                                    [formControl]="otpNumber">
                                                <div *ngIf="confirmPasswordForm.controls.otp.errors">
                                                    <div class="errorMessage"
                                                        *ngIf="confirmPasswordForm.controls.otp.errors.required && confirmPasswordForm.controls.otp.touched">
                                                        OTP is required</div>
                                                    <div class="errorMessage"
                                                        *ngIf="confirmPasswordForm.controls.otp.errors && confirmPasswordForm.controls.otp.touched && !confirmPasswordForm.controls.otp.errors.required">
                                                        Invalid OTP.</div>
                                                </div>
                                                <div class="margin-bottom"></div>
                                            </div>

                                            <label>Enter New Password</label>
                                            <div class="row-input">
                                                <img src="./assets/img/key.png" class="pass_icon ">
                                                <input id="passwordn" value="" name="passwordn" class="password" type="password"
                                                    placeholder="Enter new password" [formControl]="new_password">
                                                <div *ngIf="confirmPasswordForm.controls.new_password.errors">
                                                    <div class="errorMessage"
                                                        *ngIf="confirmPasswordForm.controls.new_password.errors.required && confirmPasswordForm.controls.new_password.touched">
                                                        Password is required</div>
                                                    <div class="errorMessage"
                                                        *ngIf="confirmPasswordForm.controls.new_password.errors && confirmPasswordForm.controls.new_password.touched && !confirmPasswordForm.controls.new_password.errors.required">
                                                        Invalid Password.</div>
                                                </div>
                                                <div class="margin-bottom"></div>
                                            </div>
                                            <label>Confirm New Password</label>
                                            <div class="row-input">
                                                <img src="./assets/img/key.png" class="pass_icon ">
                                                <input id="passwordnc" value="" name="passwordnc" class="password" type="password"
                                                    placeholder="Enter again new password" [formControl]="confirm_password">
                                                <div *ngIf="confirmPasswordForm.controls.confirm_password.errors">
                                                    <div class="errorMessage"
                                                        *ngIf="confirmPasswordForm.controls.confirm_password.errors.required && confirmPasswordForm.controls.confirm_password.touched">
                                                        Password is required</div>
                                                    <div class="errorMessage"
                                                        *ngIf="confirmPasswordForm.controls.confirm_password.errors && confirmPasswordForm.controls.confirm_password.touched && !confirmPasswordForm.controls.confirm_password.errors.required">
                                                        Invalid Password.</div>
                                                </div>
                                                <div class="margin-bottom"></div>

                                            </div>
                                            <div class="row row-input a-color pointer-cursor">
                                                <div class="col-5"><a (click)="resendOtp()">Resend OTP</a>
                                                </div>
                                                <div class="col-7"><a (click)="changeEmailPage()">Change Email Address</a></div>
                                            </div>
                                            <button id="reset-4" class="submit" type="submit" [disabled]="globals.onLoading">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!--Start Inner Row2-->

                        <!--Start Inner Row4-->
                        <div id="reset4" class="row row-input hidden">
                            <div class="col-md-12">
                                <p class="small-show"><img src="./assets/img/tick.png" alt="sucess"></p>
                            </div>
                            <div class="col-md-6">
                                <h3>Reset Successfully</h3>
                                <p class="top-margin">Your Password has been successfuly changed, you can now continue to login.</p>
                                <div class="row clear">
                                    <div class="col-md-12">
                                        <a id="login-main" (click)="reLogin()" class="submit-login">Go to Login</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 small-hide">
                                <br>
                                <br>
                                <p><img src="./assets/img/tick.png" alt="success" class="login-success">
                                </p>
                            </div>
                        </div>
                        <!--End Inner Row4-->
                    </div>
                    <!--End Main Content Popup-->
                </div>
                <!--End Row-->
            </div>
        </div>
    </div>
</div>

<script>
    $(".inputs").keyup(function () {
        if (this.value.length == this.maxLength) {
            $(this).next('.inputs').focus();
        }
    });
</script>