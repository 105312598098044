import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

    localStorageAuth = localStorage.hasOwnProperty('auth');

    constructor() { }

    ngOnInit(): void {
    }

}
