import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/helpers/constant';
import * as _ from 'lodash';
import { SignupService } from 'src/app/services/ViewServices/signupService/signup.service';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
	countryCode: string;
	country_id: any;
	country_name: String;
	state_id: any;
	countryList: Array<{country_id: number, country_name: string}>;
	stateList: [];
	resendOtpRequest: any;
	checkDataRequest: {};
	stateListRequest: {};
	verifyOtpRequest: {};
	registerUserRequest: {};
	constructor(private builder: FormBuilder, private router: Router, private toaster: ToastrService, public globals: Globals, private signupHelper: SignupService) { }

	ngOnInit() {
		this.getCountryList();
		this.countryCode = "+1-";
	}

	//Change function to manage flag values for country code
	onCountryChange = function ($event) {
		this.countryCode = '+' + $event.dialCode + "-";
	}

	//Form controls for the signup form
	firstName = new FormControl('', [
		Validators.required,
		Validators.pattern("^[a-zA-Z-. ]*$"),
	])

	lastName = new FormControl('', [
		Validators.required,
		Validators.pattern("^[a-zA-Z-. ]*$"),
	])

	mobileNumber = new FormControl('', [
		Validators.required,
		Validators.pattern("^[0-9]*$"),
	])

	email_Id = new FormControl('', [
		Validators.required,
		Validators.pattern("[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,}$"),
	])

	city = new FormControl('', [
		Validators.required,
		Validators.pattern("^[a-zA-Z-. ]*$")
	])

	zipcode = new FormControl('', [
		Validators.required,
		Validators.pattern("^[0-9]{1,6}$"),
	])

	referral = new FormControl('', [
	])

	password = new FormControl('', [
		Validators.required,
		Validators.maxLength(32),
		Validators.pattern(".{6,32}")
	])

	otpNumber = new FormControl('', [
		Validators.maxLength(4),
		Validators.required,
		Validators.pattern('[0-9]{4}')
	])

	confirmForm = this.builder.group({
		otp: this.otpNumber,
		forgot_password: 0,
		user_email: this.email_Id,
		password: this.password
	});

	signupForm = this.builder.group({
		user_mobile: this.mobileNumber,
		user_email: this.email_Id,
		first_name: this.firstName,
		last_name: this.lastName,
		referral_code: this.referral,
		zipcode: this.zipcode,
		city: this.city,
	});

	//Function to check unique email and mobile number values
	public checkEmail() {
		$("div#signup-7").animate({ scrollTop: 0 });
		if (this.signupForm.valid) {
		this.globals.onLoading = true;

		this.checkDataRequest = {
			user_mobile: this.countryCode + this.mobileNumber.value,
			user_email: this.email_Id.value
		}
		this.signupHelper.checkData(this.checkDataRequest).subscribe(
			response => {
			if (typeof (response) == 'string') {
				response = JSON.parse(response);
			}
			if (response.error) {
				this.toaster.error(response.error, 'ERROR');
			} else {
				$("#signup-6").addClass("hidden");
				$("#signup-7").removeClass("hidden");
				$("#li-step1").removeClass("active");
				$("#li-step2").addClass("active");
				this.toaster.info(response.log, 'INFORMATION')
				window.scrollTo(0, 0);
			}
			this.globals.onLoading = false;
			},
			err => {
				this.toaster.error(err, 'ERROR');
				this.globals.onLoading = false;
			})
		} else {
		this.toaster.error('Incomplete Details', 'ERROR');
		this.globals.onLoading = false;
		}
	};

	//Fetch country list
	public getCountryList() {
		this.signupHelper.fetchCountryList().subscribe(
		response => {
			if (typeof response === "string") {
				response = JSON.parse(response);
			} else if (response.error) {
				this.toaster.error(response.error, "ERROR");
			} else {
				this.countryList = response.countries;
				this.country_id = this.countryList[0].country_id;
				this.country_name = this.countryList[0].country_name;
				this.getStateList();
			}
			this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, 'ERROR');
			this.globals.onLoading = false;
		}
		)
	}

	//Change function to fetch selected countries Id
	public getCountryId(data) {
		this.country_id = data;
		this.country_name = (_.find(this.countryList, function (o) { return o.country_id == data; })).country_name;
		this.getStateList()
	}

	//Fetch State list
	public getStateList() {
		this.stateListRequest = {
		country_id: this.country_id
		}
		this.signupHelper.fetchStateList(this.stateListRequest).subscribe(
		response => {
			if (typeof response === "string") {
				response = JSON.parse(response);
			}
			if (response.error) {
				this.toaster.error(response.error, "ERROR");
			} else {
				this.stateList = response.states;
				this.state_id = response.states[0].state_id;
			}
			this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, 'ERROR');
			this.globals.onLoading = false;
		}
		)
	}

	//Change function to fetch selected states data
	public getStateId(data) {
		this.state_id = data;
	}

	//Creating an account to signup
	public createAccount() {
		this.globals.onLoading = true;
		if (this.confirmForm.valid) {
		this.verifyOtpRequest = {
			otp: this.otpNumber.value,
			forgot_password: 0,
			user_email: this.email_Id.value
		}
		this.signupHelper.verifyOtp(this.verifyOtpRequest).subscribe( //OTP is verified first
			response => {
			if (typeof (response) == 'string') response = JSON.parse(response);
			if (response.error) {
				this.toaster.error(response.error, "ERROR");
			} else {
					this.registerUserRequest = {
					user_name: this.firstName.value + ' ' + this.lastName.value,
					user_mobile: this.countryCode + this.mobileNumber.value,
					user_email: this.email_Id.value,
					password: this.password.value,
					referral_code: this.referral.value,
					country: this.country_name,
					zipcode: this.zipcode.value,
					city: this.city.value,
					country_id: this.country_id,
					state_id: this.state_id
				}
				this.signupHelper.registerUser(this.registerUserRequest).subscribe( //Registering the user
				response => {
					if (typeof (response) == 'string') response = JSON.parse(response);
					if (response.error) {
						this.toaster.error(response.error, "ERROR");
					} else {
						$("#signup-6").addClass("hidden");
						$("#signup-7").addClass("hidden");
						$("#main-hidden").addClass("hidden");
						$("#main-hidden1").addClass("hidden");
						$("#signup-8").removeClass("hidden");
					}
					window.scrollTo(0, 0);
					this.globals.onLoading = false;
				},
				err => {
					this.toaster.error(err, 'ERROR');
					this.globals.onLoading = false;
				}
				)
			}
			window.scrollTo(0, 0);
			this.globals.onLoading = false;
			},
			err => {
			this.toaster.error(err, 'ERROR');
			this.globals.onLoading = false;
			}
		)
		}
		else {
		this.toaster.error('Invalid details', "ERROR");
		this.globals.onLoading = false;
		}
	};

	//Resend otp function
	public resendOtp() {
		this.globals.onLoading = true;
		this.resendOtpRequest = {
			user_email: this.email_Id.value,
			qudos_user_type: 0,
			is_signup_otp: 1
		}

		this.signupHelper.resendOtp(this.resendOtpRequest).subscribe(
		response => {
			if (typeof response === "string") {
				response = JSON.parse(response);
			}
			if (response.error) {
				this.toaster.error(response.error, "ERROR");
			} else {
				this.toaster.success(response.log, "SUCCESS")
			}
			this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, 'ERROR');
			this.globals.onLoading = false;
		}
		)
	};

	//Function to manage view if email has to be changed
	public changeEmail() {
		$("#signup-6").removeClass("hidden");
		$("#signup-7").addClass("hidden");
		$("#li-step2").removeClass("active");
		$("#li-step1").addClass("active");
	}

	get signupGetter() {
		return this.signupForm.controls;
	}
}
