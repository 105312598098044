import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { GlobalFunctions } from 'src/app/helpers/service';
import { UserService } from 'src/app/services/ViewServices/userServices/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css']
})
export class DriverComponent implements OnInit {
  driverId: number;
  driver = {
    user_name: "",
    user_image: "",
    country: "",
    follower_count: 0,
    total_likes: 0,
    fav_count: 0,
    total_points: 0,
    user_id: 0
  };
  offset: any;
  userId: number;
  toaster: any;

  constructor(public globals: Globals, public serviceFunction: GlobalFunctions, private userHelper: UserService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.driverId = parseInt(params.driverId);
    });
  }

  ngOnInit() {
    this.getDriverInfo();
  }

  //Get details of driver
  public getDriverInfo() {
    this.globals.onLoading = true;
    let data = this;
    data.offset = this.offset;
    data.userId = this.driverId;
    this.userHelper.fetchUserById(data, 1).subscribe(result => {
      this.globals.onLoading = false;
    }),
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
  }
}
