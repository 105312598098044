import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import { GlobalFunctions } from 'src/app/helpers/service';
import { AuthTemplateComponent } from '../../templates/modals/auth-template/auth-template.component';
import { NotificationService } from 'src/app/services/ViewServices/notificationService/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  checkPage: boolean;
  toggleActive: boolean;
  driver = {};

  @ViewChild(AuthTemplateComponent) authTemplate: AuthTemplateComponent;
  getNotificationRequest: { qudos_user_type: any; limit: number; offset: any; };
  offset: any;
  toaster: any;

  constructor(public globals: Globals, private router: Router, private serviceFunction: GlobalFunctions, private notificationHelper: NotificationService) {
    router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          //Change header in copy link view .
          if ((location.pathname.split("/")[1] == 'post' && location.pathname.split("/")[3] == '0') || location.pathname.split("/")[1] == 'group' || location.pathname.split("/")[1] == 'rider' || location.pathname.split("/")[1] == 'driver') {
            this.checkPage = true;
            this.globals.hideHeaderFooter = false;
          } else if ((location.pathname.split("/")[1] == 'post' && location.pathname.split("/")[3] == '1')) {
            this.globals.hideHeaderFooter = true; //Hide header in only image view .
          } else {
            this.checkPage = false;
            this.globals.hideHeaderFooter = false;
          }
        }
      });
  }

  ngOnInit() {
    this.checkPage = false;
    this.globals.hideHeaderFooter = false;
    this.toggleActive = true;
  }

  //Manage route change functionality when logo is clicked 
  public stateCheck() {
    if (this.globals.is_auth) {
      this.router.navigate(['/home']);
    } else {
      if ((window.location.pathname).split("/")[1] == 'signup') {
        this.router.navigate(['/login'])
      } else {
        this.router.navigate(['/login', { 'innerIndex': 1 }])
      }
    }
  }

  //toggle function to show/hide notification dropdown 
  public toggle(){
    if (this.toggleActive) {
      this.toggleActive = false;
      this.getNotifications();
    } else {
      this.toggleActive = true;
    }
  }

  //fetch notifications
  public getNotifications() {
    this.getNotificationRequest = {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: 5,
      offset: this.offset
    }
    this.notificationHelper.getNotification(this.getNotificationRequest).subscribe(
      response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, 'ERROR');
        } else {
          this.globals.notifications = response.notificatons;
          this.globals.totalNotifications = response.total_notificatons;
          for (let i = 0; i < this.globals.notifications.length; i++) {
            this.globals.notifications[i].updated_time = moment(this.globals.notifications[i].created_at).fromNow();
          }
        }
        this.globals.onLoading = false;
      },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
    )
  }

  //logout functionality
  public logout() {
    let modalOptions = {
      windowClass: 'app-modal-window-custom',
      backdropClass: 'modal-backdrop-custom',
      keyboard: false,
      backdrop: 'static',
      size: 'md'
    }
    this.serviceFunction.openModal(this.authTemplate.confirmSignout, modalOptions);
  }
}
