import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Subject, Subscription } from 'rxjs';
import { Globals } from './constant';
import * as classie from "desandro-classie";
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as bodyScrollLock from "../../assets/js/bodyScrollLock.js";
import { filter, map } from 'rxjs/operators';
import { EventData } from './events.shared';
import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalFunctions {
	moreOptionVisible = false;
	closeResult: string;
	modalOptions: NgbModalOptions;
	modalOpened = false;
	index: any;
	id: any;
	group: any;
	post: any;
	optionMenu = {
		post: {},
		id: 0,
		qudos_user_type: 0,
		qudos_user_id: 0,
		index: 0,
		menu_option: false,
		editPost: false
	};
	modalRef: any;
	private subject$ = new Subject();
	groupOptionMenu: any = {
		group: {},
		id: 0,
	};
	pageOptionMenu: any = {
		page: {},
		id: 0,
	};
	personOptionMenu: any = {
		person: {},
		id: 0,
		index: 0
	};
	subscribedEvent: any;

	constructor(private modalService: NgbModal, public globals: Globals) {
		let parentScope = this;
		const targetElement = document.querySelector("#body");

		//Close the dropdown menu if clicked anywhere on the body
		$("body:not(.menu-dropdown)").on('click', function (event) {
			if (parentScope.moreOptionVisible) {
				$(".dropdown-menu").hide();
			}
		});

		this.modalOptions = {
			backdrop: 'static',
			// backdropClass: 'customBackdrop',
			windowClass: 'app-modal-window-custom',
			size: 'lg',
			backdropClass: 'modal-backdrop-custom',
			keyboard: false,
		}
	}

	//emoji decode service
	decodeURIEmoji = function (input) {
		let inputescape = escape(input);
		inputescape = inputescape.replace(/%5Cu/g, '%u');
		let inputunescape = unescape(inputescape);
		return inputunescape;
	}

	//redirectionn link to APP
	redirectToApp = function () {
		window.location.href = environment.RIDER_APP;
	}

	//Encode html input (sent to server)
	htmlEncode = function (input) {
		input = input.replace(/\s+/g, ' ').trim();
		return input.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/\//g, '&#x2F;')
			.replace(/'/g, '&#x27;')
			.replace(/"/g, '&#x22;');
	}

	//Decode Html input (Recieved from server)
	htmlDecode = function (input) {
		return input.replace('&amp;', '&')
			.replace('&lt;', '<')
			.replace('&gt;', '>')
			.replace('&#x2F;', '\/')
			.replace('&#x27;', "'")
			.replace('&#x22;', '"');
	}

	//Function to make link appear like links in html
	urlify = function (text) {
		let urlRegex = /(https?:\/\/[^\s]+)/g;
		let tagRegex = /<a.href="(https?:\/\/[^\s]+)">/g
		if (text.match(tagRegex)) {
			return text;
		}
		else if (text.match(urlRegex)) {
			return text.replace(urlRegex, function (url) {
				return '<a href="' + url + '">' + url + '</a>';
			})
		}
		else {
			return text;
		}
	};

	//Function to hide the html of the link 
	deUrlify = function (text) {
		let whitespace = '&nbsp;'
		let urlRegex = /<a\s+href=(["'])(.*?)\1>(https?:\/\/[^\s]+)a>/g;
		let tagRegex = /<a\s+href=(["'])(.*?)\1>(@)[A-Za-z]*<\/a>/g;
		if (text.toString().indexOf(whitespace) != -1) {
			text.replace(whitespace, ' ')
		}
		if (text.toString().match(tagRegex)) {
			return text.replace(tagRegex, function (url) {
				return '[[#' + (((url.split('>')[1]).split("<")[0]).split('@')[1]) + ']]'
			})
		}
		else if (text.toString().match(urlRegex)) {
			return text.replace(urlRegex, function (url) {
				return (url.split('>')[1]).split("<")[0]
			})
		}
		else {
			return text;
		}
	};

	//Convert value to tag in the text received in post.
	convertTag = function (text, link) {
		let linkArray = link ? link.split(',') : [];
		let urlRegex = /#[A-Za-z]*/g;

		if (linkArray.length) {
			linkArray.forEach(function (data, index) {
				data = data.split('_');
				if (text.match(urlRegex)) {
					return text = text.replace((data[1].split("\r\n"))[0], function (url) {
						return '<a href="' + data[0] + '">' + (data[1].split("\r\n"))[0].replace('#', '@') + '</a>';
					})
				}
				else {
					return text;
				}
			})
			return text;
		}
		else {
			return text;
		}
	}

	//Bottom menu in mobile view closing options
	closeOptions = function (type) {
		bodyScrollLock.clearAllBodyScrollLocks();
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			this.globals.mobileView = true;
			$('#body').removeClass("custom-backdrop");

			if (type == 1) {
				classie.toggle(this.globals.userMenuButton, 'active');
				classie.toggle(this.globals.userMenuSlider, 'cbp-spmenu-open');
			}
			else if (type == 2) {
				classie.toggle(this.globals.personButton, 'active');
				classie.toggle(this.globals.personMenuSlider, 'cbp-spmenu-open');
			}
			else if (type == 3) {
				classie.toggle(this.globals.groupMenuButton, 'active');
				classie.toggle(this.globals.groupMenuSlider, 'cbp-spmenu-open');
			}
			else if (type == 4) {
				classie.toggle(this.globals.pageMenuButton, 'active');
				classie.toggle(this.globals.pageMenuSlider, 'cbp-spmenu-open');
			}

			let html = jQuery('html');
			let scrollPosition = html.data('scroll-position');
			html.css('overflow', html.data('previous-overflow'));
			window.scrollTo(scrollPosition[0], scrollPosition[1])
		}
		else {
			this.globals.mobileView = false;
		}

	};

	//Show the more options menu in mobile view
	moreOptions = function (category, index, type) {
		bodyScrollLock.disableBodyScroll(this.targetElement);

		if (type == 1) {
			let post = category

			this.optionMenu = {
				post: post,
				id: post.post_id,
				qudos_user_type: post.qudos_user_type,
				qudos_user_id: post.qudos_user_id,
				index: index,
				menu_option: (this.globals.user.user_id == post.qudos_user_id && this.globals.user.qudos_user_type == post.qudos_user_type) ? true : false,
				editPost: post.post_type != 5 && post.post_type != 6
			}
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.globals.mobileView = true;
				classie.toggle(this.globals.userMenuButton, 'active');
				classie.toggle(this.globals.userMenuSlider, 'cbp-spmenu-open');
				$('#body').addClass("custom-backdrop");

				let scrollPosition = [
					self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
					self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				];
				let html = jQuery('html'); // it would make more sense to apply this to body, but IE7 won't have that
				html.data('scroll-position', scrollPosition);
				html.data('previous-overflow', html.css('overflow'));
				html.css('overflow', 'hidden');
				window.scrollTo(scrollPosition[0], scrollPosition[1]);

			}
			else {
				this.globals.mobileView = false;
			}
		}
		else if (type == 2) {
			let person = category

			this.personOptionMenu = {
				person: person,
				id: person.user_id,
				index: index
			}
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.globals.mobileView = true;
				classie.toggle(this.globals.personButton, 'active');
				classie.toggle(this.globals.personMenuSlider, 'cbp-spmenu-open');
				$('#body').addClass("custom-backdrop");

				let scrollPosition = [
					self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
					self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				];
				let html = jQuery('html'); // it would make more sense to apply this to body, but IE7 won't have that
				html.data('scroll-position', scrollPosition);
				html.data('previous-overflow', html.css('overflow'));
				html.css('overflow', 'hidden');
				window.scrollTo(scrollPosition[0], scrollPosition[1]);

			}
			else {
				this.globals.mobileView = false;
			}
		}
		else if (type == 3) {
			let group = category
			this.groupOptionMenu = {
				group: group,
				id: group.group_id,
			}
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.globals.mobileView = true;
				classie.toggle(this.globals.groupMenuButton, 'active');
				classie.toggle(this.globals.groupMenuSlider, 'cbp-spmenu-open');
				$('#body').addClass("custom-backdrop");

				let scrollPosition = [
					self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
					self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				];
				let html = jQuery('html'); // it would make more sense to apply this to body, but IE7 won't have that
				html.data('scroll-position', scrollPosition);
				html.data('previous-overflow', html.css('overflow'));
				html.css('overflow', 'hidden');
				window.scrollTo(scrollPosition[0], scrollPosition[1]);

			}
			else {
				this.globals.mobileView = false;
			}
		}
		else if (type == 4) {
			let page = category
			this.pageOptionMenu = {
				page: page,
				id: page.page_id,
			}
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.globals.mobileView = true;
				classie.toggle(this.globals.pageMenuButton, 'active');
				classie.toggle(this.globals.pageMenuSlider, 'cbp-spmenu-open');
				$('#body').addClass("custom-backdrop");

				let scrollPosition = [
					self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
					self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				];
				let html = jQuery('html'); // it would make more sense to apply this to body, but IE7 won't have that
				html.data('scroll-position', scrollPosition);
				html.data('previous-overflow', html.css('overflow'));
				html.css('overflow', 'hidden');
				window.scrollTo(scrollPosition[0], scrollPosition[1]);

			}
			else {
				this.globals.mobileView = false;
			}
		}
	};

	//More options menu in desktop
	moreOptionsDesktop = function (category, type) {
		if (type == 1) {
			let post = category;
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.globals.mobileView = true;
				classie.toggle(this.globals.userMenuButton, 'active');
				classie.toggle(this.globals.userMenuSlider, 'cbp-spmenu-open');
				$('#body').addClass("custom-backdrop");

				let scrollPosition = [
					self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
					self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				];
				let html = jQuery('html'); // it would make more sense to apply this to body, but IE7 won't have that
				html.data('scroll-position', scrollPosition);
				html.data('previous-overflow', html.css('overflow'));
				html.css('overflow', 'hidden');
				window.scrollTo(scrollPosition[0], scrollPosition[1]);
			}
			else {
				this.globals.mobileView = false;
				$(".dropdown-menu:not(ul#postoption_" + post.post_id + ")").hide();
				$("#postoption_" + post.post_id).toggle();
				setTimeout(() => {
					if ($(".dropdown-menu").is(":visible")) {
						this.moreOptionVisible = true;
					} else {
						this.moreOptionVisible = false;
					}
				}, 10);
			}
		}
		else if (type == 2) {
			let person = category;
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.globals.mobileView = true;
				classie.toggle(this.globals.personButton, 'active');
				classie.toggle(this.globals.personMenuSlider, 'cbp-spmenu-open');
				$('#body').addClass("custom-backdrop");

				let scrollPosition = [
					self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
					self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				];
				let html = jQuery('html'); // it would make more sense to apply this to body, but IE7 won't have that
				html.data('scroll-position', scrollPosition);
				html.data('previous-overflow', html.css('overflow'));
				html.css('overflow', 'hidden');
				window.scrollTo(scrollPosition[0], scrollPosition[1]);
			}
			else {
				this.globals.mobileView = false;
				$(".dropdown-menu:not(ul#personoption_" + person.user_id + ")").hide();
				$("#personoption_" + person.user_id).toggle();

				setTimeout(() => {
					if ($(".dropdown-menu").is(":visible")) {
						this.moreOptionVisible = true;
					} else {
						this.moreOptionVisible = false;
					}
				}, 10);
			}
		}
		else if (type == 3) {
			let group = category;
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.globals.mobileView = true;
				classie.toggle(this.globals.groupMenuButton, 'active');
				classie.toggle(this.globals.groupMenuSlider, 'cbp-spmenu-open');
				$('#body').addClass("custom-backdrop");

				let scrollPosition = [
					self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
					self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				];
				let html = jQuery('html'); // it would make more sense to apply this to body, but IE7 won't have that
				html.data('scroll-position', scrollPosition);
				html.data('previous-overflow', html.css('overflow'));
				html.css('overflow', 'hidden');
				window.scrollTo(scrollPosition[0], scrollPosition[1]);
			}
			else {
				this.globals.mobileView = false;
				$(".dropdown-menu:not(ul#groupoption_" + group.group_id + ")").hide();
				$("#groupoption_" + group.group_id).toggle();

				setTimeout(() => {
					if ($(".dropdown-menu").is(":visible")) {
						this.moreOptionVisible = true;
					} else {
						this.moreOptionVisible = false;
					}
				}, 10);
			}
		}
		else if (type == 4) {
			let page = category;
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.globals.mobileView = true;
				classie.toggle(this.globals.pageMenuButton, 'active');
				classie.toggle(this.globals.pageMenuSlider, 'cbp-spmenu-open');
				$('#body').addClass("custom-backdrop");

				let scrollPosition = [
					self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
					self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				];
				let html = jQuery('html'); // it would make more sense to apply this to body, but IE7 won't have that
				html.data('scroll-position', scrollPosition);
				html.data('previous-overflow', html.css('overflow'));
				html.css('overflow', 'hidden');
				window.scrollTo(scrollPosition[0], scrollPosition[1]);
			}
			else {
				this.globals.mobileView = false;
				$(".dropdown-menu:not(ul#pageOption_" + page.page_id + ")").hide();
				$("#pageOption_" + page.page_id).toggle();

				setTimeout(() => {
					if ($(".dropdown-menu").is(":visible")) {
						this.moreOptionVisible = true;
					} else {
						this.moreOptionVisible = false;
					}
				}, 10);
			}
		}
	};

	//Modal open function
	openModal(content, modalOptions = {}, modalOpen = false) {
		this.modalOpened = !modalOpen ? false : true;
		modalOptions = Object.keys(modalOptions).length == 0 ? this.modalOptions : modalOptions;

		this.modalRef = this.modalService.open(content, modalOptions);
		this.emit(new EventData("modalOpen", true));

		this.modalRef.result.then((result) => {
			this.globals.description = '';
			if (Object.keys(this.globals.tagify).length) {
				this.globals.tagify.destroy();
				this.globals.tagify = {};
			}
			this.closeResult = `Closed with: ${result}`;
			this.emit(new EventData("modalClosed", result));
		}, (reason) => {
			this.emit(new EventData("modalClosed", true));
			window.onscroll = function () { };
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	//Modal close function
	getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		}
		else if (reason == 'Close') {
			this.modalRef.close();
		}
		else {
			return `with: ${reason}`;
		}
	}

	//Error handling
	handleError(err: HttpErrorResponse) {
		let errorMessage = '';
		if (err.error instanceof ErrorEvent) {
			errorMessage = `An error occurred: ${err.error.message}`;
		}
		else {
			// errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
			errorMessage = 'Internal server error';
		}
		return throwError(errorMessage);
	}

	//Subject emit event for communication
	emit(event: EventData) {
		this.subject$.next(event);
	}

	//Subject on event to receive the emitted value.
	on(eventName: string, action: any): Subscription {
		this.subscribedEvent = this.subject$.pipe(
			filter((e: EventData) => e.name === eventName),
			map((e: any) => e['value'])).subscribe(action);
		return this.subscribedEvent;
	}
}