import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit {
  constructor(public globals: Globals, private router: Router, private location: Location) {
    router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          //Hide topnav in mobile view in only image view during route change
          if ((location.path().split("/")[1] == 'post' && location.path().split("/")[3] == '1')) {
            this.globals.hideHeaderFooter = true;
          } else {
            this.globals.hideHeaderFooter = false;
          }
        }
      })
    //Hide topnav in mobile view in only image view during page refresh
    if (window.location.pathname.split("/")[1] == 'post' && window.location.pathname.split("/")[3] == '1') {
      this.globals.hideHeaderFooter = true;
    } else {
      this.globals.hideHeaderFooter = false;
    }
  }
  ngOnInit() {
    this.globals.hideHeaderFooter = false;
  }
}
