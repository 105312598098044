<div class="page">
  <div *ngIf="globals.pageLoad">
    <div class="row h-100vh">
      <div class="col-12 col-lg-3 col-xl-2 page_sidebar"></div>
      <div class="col-12 col-lg-6 col-xl-8 page_content">
        <div class="timeline-item centered">
          <ul>
            <div class="dummy_content"></div>
            <div class="dummy_content"></div>
            <li>
              <div class="dummyblock_main">
                <div class="dummy_post_img"></div>
                <div class="dummy_content"></div>
                <div class="dummy_content w-90"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-lg-3 col-xl-2 page_sidebar"></div>
    </div>
  </div>
  <div class="row">
    <div
      class="col-12 col-lg-3 col-xl-2 page_sidebar"
      *ngIf="!globals.pageLoad"
    ></div>
    <div class="col-12 col-lg-6 col-xl-8 page_content">
      <post-preview></post-preview>
    </div>
    <div *ngIf="globals.onLoading" class="whirl"></div>
  </div>
</div>
