export const environment = {
  production: true,
  API_URL: "https://qudosconnect_dev.ridequdos.com/",
  RIDER_APP: 'https://rd.ridequdos.com/r',
  LINK: "https://connect.qudos.dev/",
  SOCKET_ENDPOINT: "https://qudosconnectsocket_dev.ridequdos.com",
  FIREBASE_CONFIG : {
    apiKey: "AIzaSyCD6Uo-gxCehnHFhIN0thUd5GmCwRdIPMA",
    authDomain: "qudoslist-59ca2.firebaseapp.com",
    databaseURL: "https://qudoslist-59ca2.firebaseio.com",
    projectId: "qudoslist-59ca2",
    storageBucket: "qudoslist-59ca2.appspot.com",
    messagingSenderId: "295433433459",
    appId: "1:295433433459:web:de5fbdf3644eb73f815506",
    measurementId: "G-47ERNX0MRZ"
  }
};
