import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-cookies-policy',
	templateUrl: './cookies-policy.component.html',
	styleUrls: ['./cookies-policy.component.css']
})
export class CookiesPolicyComponent implements OnInit {
	localStorageAuth = localStorage.hasOwnProperty('auth');
	constructor() { }
	ngOnInit(): void {
	}

}
