<div class="row m-0" [ngClass]="{'img-disp':globals.hideHeaderFooter}">
    <div class="col-12 p-0">
        <div class="top_nav d-flex justify-navcontent-between" *ngIf="globals.is_auth">
            <a routerLinkActive="active" routerLink="/home"><i class="fal fa-list-alt top_nav-icon" routerLinkActive="fas"></i></a>
            <a routerLinkActive="active" routerLink="/network"><i class="fal fa-user-friends top_nav-icon" routerLinkActive="fas"></i></a>
            <a routerLinkActive="active" routerLink="/messages"><i class="fal fa-comments-alt top_nav-icon" routerLinkActive="fas"><span *ngIf="globals.unread_count">{{globals.unread_count}}</span></i></a>
            <a routerLinkActive="active" routerLink="/notification"><i class="fal fa-bell top_nav-icon" routerLinkActive="fas"><span *ngIf="globals.notificationCounter">{{globals.notificationCounter}}</span></i></a>
            <a routerLinkActive="active" routerLink="/settings"><i class="fal fa-cog top_nav-icon" routerLinkActive="fas"></i></a>
        </div>
    </div>
</div>