import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/helpers/constant';

@Component({
  selector: 'app-post-info',
  templateUrl: './post-info.component.html',
  styleUrls: ['./post-info.component.css']
})
export class PostInfoComponent implements OnInit,OnDestroy {

  image = '';
  content_type;
  offset = 0;
  is_post = false;
  viewPost = false;
  postId: any;
  routeSubscribe: any;

  constructor(private router: ActivatedRoute, public globals: Globals, private cdRef: ChangeDetectorRef) {
    this.routeSubscribe = this.router.params.subscribe(params => {
      this.postId = params.postId;
    })
  }

  ngOnInit() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.routeSubscribe.unsubscribe();
  }
}
