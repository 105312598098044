import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { GlobalFunctions } from 'src/app/helpers/service';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from 'src/app/services/ViewServices/groupService/group.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
  groupId: number;
  group = {
    group_name: "",
    group_image: "",
    total_members: 0,
    total_followers: 0,
    group_id: 0
  };
	offset: any;
	toaster: any;
  constructor(public globals: Globals, public serviceFunction: GlobalFunctions, private route: ActivatedRoute, private groupHelper: GroupService) {
    this.route.params.subscribe(params => {
      this.groupId = parseInt(params.groupId);
    });
  }

  ngOnInit() {
    this.getGroupInfo();
  }

  //get group details
  public getGroupInfo() {
    this.globals.onLoading = true;
    let data = this;
    data.offset = this.offset;
    data.groupId = this.groupId;
    this.groupHelper.fetchGroupById(data).subscribe(result => {
      this.globals.onLoading = false;
    }),
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;

      }
  }
}
