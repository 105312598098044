import { Injectable } from '@angular/core';
import { UserService } from '../ViewServices/userServices/user.service';
import { Globals } from 'src/app/helpers/constant';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import * as Tagify from 'src/assets/js/tagify.js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  currentEditorId = '';
  previousEditorId = '';
  currentEditorClass = '';
  inputArray = [];
  isEditValue = false;
  initialValue = '';
  firstClick: number;
  isComment: boolean;
  selectedElement: any;
  editing: boolean;

  constructor(private userHelper: UserService, public globals: Globals, private toaster: ToastrService) {
  }

  public onInput(e, isEdit) {
    let parentScope = this;
    this.firstClick += 1;
    let prefix = e.detail.prefix;
    let urlRegex = /#[A-Za-z]*/g;
    let textRegex = /[@A-Za-z]*/g;
    this.globals.tagify.settings.whitelist.length = 0; // reset the whitelist
    let data = this;

    let value = (e.detail.textContent.match(textRegex));
    if (prefix) {
      if (prefix == '@') {
        if (value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].length > 1 && value[i][0] == '@' && value[i][1] != '@') {
              value[i] = value[i].replace('@', '');

              this.userHelper.getFriendList(data, value[i]).subscribe(result => {
                for (let i = 0; i < this.globals.network.length; i++) {
                  this.globals.network[i].value = this.globals.network[i].user_name ? "#" + this.globals.network[i].user_name : '';
                  this.globals.network[i].link = environment.LINK + "userInfo/" + this.globals.network[i].user_id + "/" + this.globals.network[i].qudos_user_type;
                }
                this.globals.tagify.settings.whitelist = this.globals.network;
              },
                err => {
                  this.toaster.error(err, 'ERROR');
                })
            }
          }
        }
      }
      if (e.detail.value.length > 2) {
        this.globals.tagify.dropdown.show.call(this.globals.tagify, e.detail.value);
      }
    }
    if (this.globals.tagify.settings.id == "description") {
      this.globals.description = e.detail.textContent.replace(/\s+/g, ' ').trim();
    } else {
      this.globals.posts[this.globals.postIndex].comment_describe = e.detail.textContent.replace(/\s+/g, ' ').trim();
    }

    //In case of edit when only  a space is entered.
    if (e.detail.textContent.length != 0 && (this.firstClick == 1) && isEdit) {
      this.globals.taggedMember = [];
      this.globals.taggedArray = [];
      if (e.detail.textContent.match(urlRegex)) {
        let child = ($("." + this.globals.tagify.settings.class).children()).children();

        child.each(function (index, data) {
          parentScope.globals.taggedArray.push($(data).attr('link') + "_" + $(data)[0].innerText);
          parentScope.globals.taggedMember.push({
            qudos_user_id: parseInt($(data).attr('user_id')),
            qudos_user_type: parseInt($(data).attr('qudos_user_type'))
          })
        })
      }
    } else {
      this.globals.taggedMember = [];
      let child = this.isComment ? $("#" + this.globals.tagify.settings.id).children() : ($("." + this.globals.tagify.settings.class).children()).children();
      child.each(function (index, data) {
        parentScope.globals.taggedArray.push($(data).attr('link') + "_" + $(data)[0].innerText);
        parentScope.globals.taggedMember.push({
          qudos_user_id: parseInt($(data).attr('user_id')),
          qudos_user_type: parseInt($(data).attr('qudos_user_type'))
        })
      })
    }
  }

  //To focus back at the contenteditable div after activating tagify.
  public caretFocus(editor) {
    let range = document.createRange();
    let sel = window.getSelection();
    if (this.isComment) {
      this.selectedElement = $('#' + editor);

      if ($('#' + editor)[0].childNodes[0] && !this.isEditValue) {
        range.setStart($('#' + editor)[0].childNodes[0], this.globals.activeInput.value.length);
      }
    } else {
      this.selectedElement = $("tags").find(`[data-class='${editor}']`);
      if (this.selectedElement[0].childNodes[0] && !this.isEditValue) {
        range.setStart(this.selectedElement[0].childNodes[0], this.globals.activeInput.value.length);
      }
    }

    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    this.selectedElement.focus();
  }

  public initTagify(editor = '', value = '') {
    let parentScope = this;
    this.globals.activeInput = editor ? editor[0] : this.globals.activeInput;
    this.editing = editor ? true : false;

    if (editor) {
      this.isEditValue = true;
      this.initialValue = value;
      this.currentEditorId = $(editor).prop('id');
      this.currentEditorClass = $(editor).prop('class');

      this.globals.tagify = new Tagify(this.globals.activeInput, {
        mixTagsInterpolator: ["[[", "]]"],
        mode: 'mix',  // <--  Enable mixed-content
        pattern: /@/,  // <--  Text starting with @ or # (if single, String can be used here)
        enforceWhitelist: true,

        whitelist: this.globals.tagValue.map(function (item) {
          return typeof item == 'string' ? { value: item } : item
        }),
        dropdown: {
          enabled: 1
        },
        callbacks: {
          // add: console.log,  // callback when adding a tag
          // remove: console.log,   // callback when removing a tag
        },
        id: this.globals.activeInput.id,
        class: this.globals.activeInput.className
      }, value)

    } else {
      // init Tagify script on the above inputs
      this.globals.tagify = new Tagify(this.globals.activeInput, {
        mixTagsInterpolator: ["[[#", "]]"],
        mode: 'mix',  // <--  Enable mixed-content
        pattern: /@/,  // <--  Text starting with @ or # (if single, String can be used here)
        enforceWhitelist: true,

        // Array for initial interpolation, which allows only these tags to be used
        whitelist: this.globals.network.map(function (item) {
          return typeof item == 'string' ? { value: item } : item
        }),
        dropdown: {
          enabled: 1
        },
        callbacks: {
          // add: console.log,  // callback when adding a tag
          // remove: console.log,   // callback when removing a tag
        },
        id: this.globals.activeInput.id,
        class: this.globals.activeInput.className.split(" ")[0]
      })
    }
    if (this.globals.mobileView && this.isComment) {
      $('#' + this.globals.activeInput.id).css('white-space', 'pre-wrap');
    }
    this.firstClick = 0;
    if (this.isComment)
      this.caretFocus(this.currentEditorId)
    else
      this.caretFocus(this.currentEditorClass);

    // listen to any keystrokes which modify tagify's input
    this.globals.tagify.on('input', function (e) {
      parentScope.onInput(e, this.editing);
    });

    //add event in tagging 
    this.globals.tagify.on('add', function (e) {
      if (parentScope.globals.tagify.settings.id == "description" && !parentScope.isComment) {
        parentScope.globals.description = $("." + parentScope.globals.tagify.settings.class)[0].textContent.replace(/\s+/g, ' ').trim();
      }
      else if (parentScope.isComment) {
        parentScope.globals.posts[parentScope.globals.postIndex].comment_describe = $("span#" + parentScope.globals.tagify.settings.id)[0].textContent.replace(/\s+/g, ' ').trim();
      }

      parentScope.globals.taggedArray = [];
      parentScope.globals.taggedMember = [];
      let child = parentScope.isComment ? $("#" + parentScope.globals.tagify.settings.id).children() : ($("." + parentScope.globals.tagify.settings.class).children()).children();

      child.each(function (index, data) {
        parentScope.globals.taggedArray.push($(data).attr('link') + "_" + $(data)[0].innerText);

        parentScope.globals.taggedMember.push({
          qudos_user_id: parseInt($(data).attr('user_id')),
          qudos_user_type: parseInt($(data).attr('qudos_user_type'))
        })
      })
    })

    //remove event in tagging
    this.globals.tagify.on('remove', function (e) {
      let removeTag = _.remove(parentScope.globals.taggedArray, function (i) {
        return (i as string).split('_')[0] == e.detail.data.link;
      });
      let removeMember = _.remove(parentScope.globals.taggedMember, function (i) {
        return ((i['qudos_user_id'] == e.detail.data.user_id) && (i['qudos_user_type'] == e.detail.data.qudos_user_type));
      });
      if (parentScope.globals.tagify.settings.id == "description" && !parentScope.isComment) {
        parentScope.globals.description = $("." + parentScope.globals.tagify.settings.class)[0].textContent.replace(/\s+/g, ' ').trim();
      }
      else if (parentScope.isComment) {
        parentScope.globals.posts[parentScope.globals.postIndex].comment_describe = $("span#" + parentScope.globals.tagify.settings.id)[0].textContent.replace(/\s+/g, ' ').trim();
      }
    })

    //In case of edit post when no input is given.
    if (editor && this.globals.tagify.value.length != 0 && this.firstClick == 0) {
      this.globals.taggedMember = [];
      this.globals.taggedArray = [];
      let child = ($("." + this.globals.tagify.settings.class).children()).children();

      child.each(function (index, data) {
        parentScope.globals.taggedArray.push($(data).attr('link') + "_" + $(data)[0].innerText);

        parentScope.globals.taggedMember.push({
          qudos_user_id: parseInt($(data).attr('user_id')),
          qudos_user_type: parseInt($(data).attr('qudos_user_type'))
        })
      })

      if (this.globals.tagify.settings.id == "description") {
        this.globals.description = $("." + this.globals.tagify.settings.class)[0].textContent.replace(/\s+/g, ' ').trim();
      }
    }
  }

  public createTag(event, index = '', isEdit = false, isComment = false) {
    this.previousEditorId = this.currentEditorId;
    this.isEditValue = isEdit
    this.isComment = isComment;
    this.currentEditorId = event.target.id;
    this.currentEditorClass = event.target.className;
    this.globals.postIndex = index;
    this.globals.taggedMember = [];
    if (!isEdit) {
        if (Object.keys(this.globals.tagify).length) {
        this.globals.tagify.destroy();
          this.globals.tagify = {};
          this.previousEditorId = this.currentEditorId;
          this.globals.activeInput = this.isComment ? $('#' + event.target.id)[0] : $('.' + event.target.className.split(" ")[0])[0];
          this.initTagify();
        }
    } else {
      if (this.initialValue.length == 0) {
        this.globals.activeInput = this.isComment ? $('#' + event.target.id)[0] : $('.' + event.target.className.split(" ")[0])[0];
        this.initTagify();
      }
    }
  }
}
