import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Globals, API } from 'src/app/helpers/constant';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { GlobalFunctions } from 'src/app/helpers/service';

@Injectable({
  providedIn: 'root'
})

//Service to manage the profile functionalities
export class ProfileService {

  constructor(public globals: Globals, private toaster: ToastrService, private http: HttpClient, private serviceFunction: GlobalFunctions) { }

  //Get details of logged in user
  viewProfile(): Observable<any> {
    return this.http.post<any>(API.VIEW_PROFILE, {
      qudos_user_type: this.globals.user.qudos_user_type
    }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Edit logged in user details
  editProfile(data): Observable<any> {
    return this.http.post<any>(API.EDIT_PROFILE, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Additional details of the logged in profile
  profileCountInfo(data): Observable<any> {
    return this.http.post<any>(API.PROFILE_INFO, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }
}
