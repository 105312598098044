import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { UserService } from 'src/app/services/ViewServices/userServices/user.service';
import { GlobalFunctions } from 'src/app/helpers/service';
import { ToastrService } from 'ngx-toastr';
import { PageService } from 'src/app/services/ViewServices/pageServices/page.service';
import { EmojiService } from 'src/app/services/emojiService/emoji.service';
import { TagService } from 'src/app/services/tagService/tag.service';
import { Router } from '@angular/router';
import { GroupService } from 'src/app/services/ViewServices/groupService/group.service';
import { EventData } from 'src/app/helpers/events.shared';

@Component({
	selector: 'app-network-template',
	templateUrl: './network-template.component.html',
	styleUrls: ['./network-template.component.css'],
})
export class NetworkTemplateComponent {
	driver = { driver_image: "", user_image: "", driver_name: "", user_name: "", referral_code: "",	country: "", fav_count: 0, points: 0,};
	person = { user_image: "", user_name: "", referral_code: "", country: "", fav_count: 0, total_points: 0, follower_count: 0,};
	rider = { user_image: "", user_name: "", referral_code: "", country: "", follower_count: 0, points: 0,};
	friend = { user_name: "", driver_name: "",};
	page = { page_image: "", page_name: "", description: "", total_followers: 0, total_likes: 0, page_id: 0,};
	group: { group_name: "", group_image: "", total_members: 0, total_followers: 0 };
	isRider: any;
	selectedIndex: any;
	selectedType: number;
	viewMorePage: any;
	personId: any;
	usertype: any;
	data = [];
	drivers = [];
	riders = [];
	groups = [];
	members = [];
	pages = [];
	confirmPeople = [];
	peoples = [];
	faveDrivers = [];
	total_drivers = 0;
	total_riders = 0;
	total_groups = 0;
	total_pages = 0;
	describe: any;
	is_post: boolean;
	is_group: boolean;
	filter_name: any;
	filter_country: any;
	filter_keyword: any;
	filter_city: any;
	filter_zip: any;
	filter_seats: any;
	filter_group_name: any;
	filter_page_name: any;
	pageData: any;
	myFile: any;
	description: any;
	option: any;
	isMember: any;
	editData: any;
	tempData: any;
	groupId: any;
	groupData: any;
	requested: any;
	friend_request: any;
	is_fav_driver: any;
	is_follower: any;
	is_friend: any;
	totalFaveDrivers: number;
	total_people: number;
	tab: any;
	innerTab: any;
	groupName: any;
	group_type: any;
	is_member: any;
	total_confirmations: any;
	delete: any;
	post: any;
  	pageId: number;
	  //References for the template modals 
	@ViewChild('confirmModalNetwork') confirmModalNetwork: TemplateRef<any>;
	@ViewChild('shareDetails') shareDetails: TemplateRef<any>;
	@ViewChild('confirmModalQudos') confirmModalQudos: TemplateRef<any>;
	@ViewChild('filterRider') filterRider: TemplateRef<any>;
	@ViewChild('filterDriver') filterDriver: TemplateRef<any>;
	@ViewChild('cancelGroup') cancelGroup: TemplateRef<any>;
	@ViewChild('confirmGroup') confirmGroup: TemplateRef<any>;
	@ViewChild('createGroupSucess') createGroupSucess: TemplateRef<any>;
	@ViewChild('editGroupModal') editGroupModal: TemplateRef<any>;
	@ViewChild('filterGroup') filterGroup: TemplateRef<any>;
	@ViewChild('shareGroupModal') shareGroupModal: TemplateRef<any>;
	@ViewChild('editPageModal') editPageModal: TemplateRef<any>;
	@ViewChild('filterPage') filterPage: TemplateRef<any>;
	@ViewChild('sharePageModal') sharePageModal: TemplateRef<any>;
	@ViewChild('deletePost') deletePost: TemplateRef<any>;
	@ViewChild('shareUser') shareUser: TemplateRef<any>;
	total_followers: number;
	total_members: number;

  constructor(public globals: Globals, private userHelper: UserService, public serviceFunction: GlobalFunctions, private toaster: ToastrService, private pageHelper: PageService, public emojiService: EmojiService, public tagService: TagService, private router: Router, private groupHelper: GroupService) {
  }

  //Function to display the image name
	public imageName(data, val = '') {
		this.myFile = data[0];
		let file_name = this.myFile.name;
		let file_type = this.myFile.type
		if ((file_type.toLowerCase() != "image/jpeg") && file_type.toLowerCase() != "image/png") {
			this.toaster.error('Image can be jpg,png only', 'ERROR');
			this.myFile = undefined;
		} else {
			document.getElementsByClassName("filename")[val].innerHTML = file_name.slice(0, 20);
			document.getElementsByClassName("filename")[val].style.color = 'black';
		}
	}

  //Add favourite driver
	public addFaveDriver(driver) {
		this.globals.onLoading = true;
		let data = this;
		this.userHelper.addFaveDriver(data, driver, this.option, this.selectedIndex).subscribe(result => {
		if (this.option == 2) {
			this.is_fav_driver = result.is_fav_driver;
			this.is_follower = result.is_follower;
			this.requested = result.requested;
		} else if (this.option == 3) {
			this.data[this.selectedIndex].is_fav_driver = result.data[this.selectedIndex].is_fav_driver;
			this.data[this.selectedIndex].is_follower = result.data[this.selectedIndex].is_follower;
			this.data[this.selectedIndex].requested = result.data[this.selectedIndex].requested;
			this.data[this.selectedIndex].is_friend = result.data[this.selectedIndex].is_friend;
		}
		this.serviceFunction.getDismissReason("Close");
		this.globals.onLoading = false;
		},
		err => {
			this.serviceFunction.getDismissReason("Close");
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		})
  	};

  //Add Friends
	public addFriend(friend) {
		this.globals.onLoading = true;
		let data = this;
		this.userHelper.sendFriendRequest(data, friend, this.selectedIndex, this.selectedType, this.option).subscribe(result => {
		if (this.option == 2) {
			this.requested = result.requested;
			this.friend_request = result.friend_request;
		}
		this.serviceFunction.getDismissReason("Close");
		this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, "ERROR");
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		})
	}

  //Share 
	public share() {
		this.globals.onLoading = true;
		this.describe = this.globals.description ? this.globals.description : this.describe;
		if ((this.describe) === undefined || this.describe.length == 0) {
			this.toaster.error('Please enter a description.', 'ERROR')
			this.globals.onLoading = false;
			return;
		}
		let data = this
		data.describe = this.describe;
		this.userHelper.sharePerson(data, this.personId, this.selectedType).subscribe(result => {
		this.serviceFunction.getDismissReason("Close");
		this.router.navigate(['/home']);
		this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, "ERROR");
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		})
	}

  //Share Details
	public shareGroup() {
		this.globals.onLoading = true;

		this.describe = this.globals.description ? this.globals.description : this.describe;
		if ((this.describe) === undefined || this.describe.length == 0) {
			this.toaster.error('Please enter a description.', 'ERROR')
			this.globals.onLoading = false;
		} else {
		let data = this;
		data.groupId = this.groupId;
		if (this.option != 1)
			this.group = this.group;
		else
		  this.groupHelper.shareGroup(data, "", this.option).subscribe(result => {
			this.serviceFunction.getDismissReason("Close");
			if (this.globals.mobileView) {
				$('#body').removeClass("custom-backdrop");
				this.serviceFunction.closeOptions(3);
			}
			this.globals.onLoading = false;
		},
			err => {
			this.toaster.error(err, "ERROR");
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
			})
		}
	}

	//Share Details
	public sharePage() {
		this.globals.onLoading = true;
		this.describe = this.globals.description ? this.globals.description : this.describe;
		if ((this.describe) === undefined || this.describe.length == 0) {
			this.toaster.error('Please enter a description.', 'ERROR')
			this.globals.onLoading = false;
			return;
		}
		let data = this;
		data.describe = this.describe;
		data.pageId = this.page.page_id;
		this.pageHelper.sharePage(data).subscribe(result => {
		this.serviceFunction.getDismissReason("Close");
		if (this.globals.mobileView) {
			$('#body').removeClass("custom-backdrop");
			this.serviceFunction.closeOptions(4);
		}
		this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, "ERROR");
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		});
	}

	//Remove Favourite Drivers
	public removeFaveDriver(driver) {
		this.globals.onLoading = true;
		let data = this;
		this.userHelper.removeFaveDriver(data, driver, this.option, this.selectedIndex).subscribe(result => {
			if (this.option == 2) {
				this.is_fav_driver = result.is_fav_driver;
				this.is_follower = result.is_follower;
				this.requested = result.requested;
			} else if (this.option == undefined) {
				this.faveDrivers = result.faveDrivers;
				this.totalFaveDrivers = result.totalFaveDrivers;
			} else if (this.option == 3) {
				this.data[this.selectedIndex].is_fav_driver = result.data[this.selectedIndex].is_fav_driver;
				this.data[this.selectedIndex].is_follower = result.data[this.selectedIndex].is_follower;
				this.data[this.selectedIndex].is_friend = result.data[this.selectedIndex].is_friend;
			}
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, "ERROR");
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		})
	};

	//Remove Friend
	public removeFriend(friend) {
		this.globals.onLoading = true;
		let data = this;
		this.userHelper.removePerson(data, friend, this.selectedType, this.option, this.selectedIndex).subscribe(result => {
		if (this.option == 2) {
			this.is_friend = result.is_friend;
			this.is_follower = result.is_follower;
		} else if (this.option == 1) {
			this.peoples = result.peoples;
			this.total_people = result.total_people;
		} else if (this.option == 3) {
			this.data[this.selectedIndex].is_follower = result.data[this.selectedIndex].is_follower;
			this.data[this.selectedIndex].is_friend = result.data[this.selectedIndex].is_friend;
		}
		this.serviceFunction.getDismissReason("Close");
		this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, "ERROR");
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		})
	};

  	//Filter values in the modals
	public filterInnerTable(page, innerTab, searchType = 0) {
		let data = {
			page: page,
			innerTab: innerTab,
			filter_keyword: this.filter_keyword,
			filter_country: this.filter_country,
			filter_zip: this.filter_zip,
			filter_seats: this.filter_seats,
			filter_name: this.filter_name,
			filter_group_name: this.filter_group_name,
			filter_page_name: this.filter_page_name,
			searchType: searchType
		}
		this.serviceFunction.emit(new EventData('FilterModals', data));
	};

	//Delete page
	public deletePage() {
		this.globals.onLoading = true;
		let data = this;
		if (this.selectedType == 1)
		data.pageId = this.page.page_id;
		else if (this.selectedType == 2)
		data.pageId = this.page.page_id;

		this.pageHelper.deletePage(data).subscribe(result => {
		this.serviceFunction.getDismissReason("Close");
		this.router.navigate(['/home']);
		if (this.selectedType == 2) {
			return;
		}
		if (this.globals.mobileView) {
			$('#body').removeClass("custom-backdrop");
			this.serviceFunction.closeOptions(4);
		}
		this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, "ERROR");
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		})
	};

	//create group
	public createGroup() {
		this.globals.onLoading = true;

		let data = this;
		data.myFile = this.myFile;
		data.description = this.description;
		data.group_type = this.group_type;
		data.members = this.members;
		data.groupName = this.groupName;

		this.groupHelper.createGroup(data).subscribe(result => {
		this.serviceFunction.getDismissReason("Close");
		this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, 'ERROR');
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		});
	};

	//Cancel group
	public cancelgroup() {
		this.serviceFunction.getDismissReason("Close");
	}

	//Cancel page
	public cancelPage() {
		this.serviceFunction.getDismissReason("Close");
	}

	//Join group
	joinGroup = function () {
		this.globals.onLoading = true;
		let data = this;
		this.groupHelper.joinGroup(data, this.group, this.option).subscribe(result => {
		if (this.option == 1) {
			this.is_member = result.is_member;
			this.is_follower = result.is_follower;
			this.total_followers = result.total_followers ? result.total_followers : this.group.total_followers;
			this.total_members = result.total_members ? result.total_members : this.group.total_members;
		} else if (this.option == 2) {
			this.groups = result.groups;
			this.total_groups = result.total_groups;
		}
		this.serviceFunction.getDismissReason("Close");
		this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, 'ERROR');
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		});
	};

	//Leave group
	leaveGroup = function () {
		this.globals.onLoading = true;
		let data = this;
		this.groupHelper.leaveGroup(data, this.group, this.option).subscribe(result => {
		this.total_followers = result.total_followers ? result.total_followers : this.group.total_followers;
		this.total_members = result.total_members ? result.total_members : this.group.total_members;
		this.serviceFunction.getDismissReason("Close");
		this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, 'ERROR');
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		});
	};

	//Edit group
	public editGroupFunction(form) {
		let isPostCreated = false
		let check = form.dirty;
		if (this.globals.invalidInput) {
			this.editData.description = this.editData.description ? this.editData.description.replace(this.globals.invalidInputValue, "") : "";
			this.editData.group_name = this.editData.group_name ? this.editData.group_name.replace(this.globals.invalidInputValue, "") : "";
		}
		if (this.editData.group_name.length == 0 || this.editData.description.length == 0) {
			this.toaster.error("Please fill all the fields", "ERROR");
			return;
		} else {
		this.globals.onLoading = true;
		let imageVal = ($('#groupfile').val() as string).split('\\')[2]
		if (imageVal) {
			check = true;
		}
		if (check) {
			let file = this.myFile;
			let image_flag;
			if (this.myFile) {
				image_flag = 1;
				this.myFile = this.myFile;
				isPostCreated = true;
			} else {
				image_flag = 0;
				this.myFile = undefined;
			}

			let data = this;
			data.groupId = this.groupId;
			data.myFile = this.myFile;

			this.groupHelper.editGroup(data, image_flag).subscribe(result => {
			this.serviceFunction.getDismissReason("Close");

			if (this.globals.mobileView) {
				this.serviceFunction.getDismissReason("Close");
			}
			if (isPostCreated) {
				let auth_data = JSON.parse(localStorage.getItem('auth'));
				auth_data.total_posts += 1;
				this.globals.user = auth_data;
				localStorage.setItem('auth', JSON.stringify(auth_data));
				isPostCreated = false;
				this.router.navigate(['/groupInfo', this.groupId]);
			}
			this.globals.onLoading = false;
			this.router.navigate(['/groupInfo', this.groupId]);
			},
			err => {
				this.toaster.error(err, 'ERROR');
				// location.reload();
				if (this.globals.mobileView) {
					this.serviceFunction.getDismissReason("Close");
				}
				this.globals.onLoading = false;
			});
		}
		else {
			this.router.navigate(['/groupInfo', this.groupId]);
			this.serviceFunction.getDismissReason("Close");
		}
		}
	};

	//Edit page
	public editPage(form) {
		let isPostCreated = false;
		let check = form.dirty;
		if (this.globals.invalidInput) {
		this.editData.page_name = this.editData.page_name ? this.editData.page_name.replace(this.globals.invalidInputValue, "") : "";
		this.editData.description = this.editData.description ? this.editData.description.replace(this.globals.invalidInputValue, "") : "";
		}
		if (this.editData.page_name.length == 0 || this.editData.description.length == 0) {
			this.toaster.error("Please fill all the fields", "ERROR");
			return;
		} else {
		this.globals.onLoading = true;
		let imageVal = ($('#pagefile').val() as string).split('\\')[2]
		if (imageVal) {
			check = true;
		}
		if (check) {
			let file = this.myFile;
			let image_flag;
			if (this.myFile) {
			image_flag = 1;
			this.myFile = this.myFile;
			isPostCreated = true;
			} else {
			image_flag = 0;
			this.myFile = undefined;
			}

			let data = this;
			data.pageId = this.pageId;
			data.myFile = this.myFile;
			this.pageHelper.edit(data, this.editData).subscribe(result => {
			this.serviceFunction.getDismissReason("Close");
			if (this.globals.mobileView) {
				this.serviceFunction.getDismissReason("Close");
			}
			if (isPostCreated) {
				let auth_data = JSON.parse(localStorage.getItem('auth'));
				auth_data.total_posts += 1;
				this.globals.user = auth_data;
				localStorage.setItem('auth', JSON.stringify(auth_data));
				isPostCreated = false;
				this.router.navigate(['/pageInfo', this.pageId]);

			}
			this.globals.onLoading = false;
				// this.router.navigate(['/network/pageInfo', this.pageId]);
			},
			err => {
				this.toaster.error(err, 'ERROR');
				if (this.globals.mobileView) {
				this.serviceFunction.getDismissReason("Close");
				}
				this.globals.onLoading = false;
			});
		} else {
			this.router.navigate(['/pageInfo', this.pageId]);
			this.serviceFunction.getDismissReason("Close");
		}
		}
	};

	//Manage functionalities on closing of modals
	public closeModal = function (param) {
		if (param == 'page') {
			this.pageData = this.tempData;
		} else if (param == 'group') {
			this.groupData = this.tempData;
		}
		this.serviceFunction.getDismissReason("Close");
	};

	//Delete group
	public deleteGroup() {
		this.globals.onLoading = true;
		let data = this;
		data.groupId = this.groupId;
		this.groupHelper.deleteGroup(data).subscribe(result => {
		this.serviceFunction.getDismissReason("Close");
		this.router.navigate(['/home']);
		if (this.globals.mobileView) {
			$('#body').removeClass("custom-backdrop");
			this.serviceFunction.closeOptions(3);
		}
		this.globals.onLoading = false;
		},
		err => {
			this.toaster.error(err, 'ERROR');
			this.serviceFunction.getDismissReason("Close");
			this.globals.onLoading = false;
		});

	};
}
