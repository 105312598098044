<div class="page message_pg" id="messagePage">
	<div *ngIf="globals.pageLoad">
		<div class="mt-50 h-100vh">
			<div class="timeline-item box-0 centered mb-5 mt-5 mbl-mt-60 m-sm-0 ">
				<div class="col-md-12 ">
					<div class="dummy_content "> </div>
				</div>
				<div class="row mar-lr-15">
					<div class="col-md-12 col-lg-5 mt-5  mob-m-0">
						<ul class="dummy_msg_content">
							<li class="d-flex mb-1 mt-1 border-bottom">
								<div class="dummy_image img-circle "></div>
								<div class="dummy_wrapper">
									<div class="dummy_content"> </div>
									<div class="dummy_content"> </div>
								</div>
							</li>
							<li class="d-flex  mb-1 mt-1 border-bottom">
								<div class="dummy_image img-circle "></div>
								<div class="dummy_wrapper">
									<div class="dummy_content"> </div>
									<div class="dummy_content"> </div>
								</div>
							</li>
							<li class="d-flex  mb-1 mt-1 border-bottom">
								<div class="dummy_image img-circle"></div>
								<div class="dummy_wrapper">
									<div class="dummy_content "> </div>
									<div class="dummy_content"> </div>
								</div>
							</li>
							<li class="d-flex  mb-1 mt-1 border-bottom">
								<div class="dummy_image img-circle "></div>
								<div class="dummy_wrapper">
									<div class="dummy_content "> </div>
									<div class="dummy_content"> </div>
								</div>
							</li>
							<li class="d-flex  mb-1 mt-1 border-bottom">
								<div class="dummy_image img-circle"></div>
								<div class="dummy_wrapper">
									<div class="dummy_content "> </div>
									<div class="dummy_content"> </div>
								</div>
							</li>
							<li class="d-flex  mb-1 mt-1 border-bottom">
								<div class="dummy_image img-circle "></div>
								<div class="dummy_wrapper">
									<div class="dummy_content "> </div>
									<div class="dummy_content"> </div>
								</div>
							</li>
							<li class="d-flex  mb-1 mt-1 border-bottom">
								<div class="dummy_image img-circle"></div>
								<div class="dummy_wrapper">
									<div class="dummy_content "> </div>
									<div class="dummy_content"> </div>
								</div>
							</li>
						</ul>
					</div>
					<div class="col-md-12 col-lg-7 mt-5 border-left">
						<ul class="dummy_msg_content ">
							<li class="d-flex mb-1 mt-1 border-bottom">
								<div class="dummy_image img-circle "></div>
								<div class="dummy_wrapper">
									<div class="dummy_content"> </div>
									<div class="dummy_content"> </div>
								</div>
							</li>
							<li class="d-flex mt-2 main_msg_dummy">
								<div class="dummy_wrapper">
									<div class="dummy_content "> </div>
								</div>
								<div class="dummy_image img-circle "></div>
							</li>
							<li class="d-flex mt-2 main_msg_dummy">
								<div class="dummy_image img-circle "></div>
								<div class="dummy_wrapper">
									<div class="dummy_content "> </div>
								</div>
							</li>
							<li class="d-flex mt-2 main_msg_dummy">
								<div class="dummy_wrapper">
									<div class="dummy_content "> </div>
								</div>
								<div class="dummy_image img-circle "></div>
							</li>
							<li class="d-flex mt-2 main_msg_dummy">
								<div class="dummy_image img-circle "></div>
								<div class="dummy_wrapper">
									<div class="dummy_content"> </div>
								</div>
							</li>
							<li class="d-flex mt-2 main_msg_dummy">
								<div class="dummy_wrapper">
									<div class="dummy_content"> </div>
								</div>
								<div class="dummy_image img-circle "></div>
							</li>
							<li class="d-flex mt-2 main_msg_dummy">
								<div class="dummy_image img-circle "></div>
								<div class="dummy_wrapper">
									<div class="dummy_content"> </div>
								</div>
							</li>
							<li class="d-flex mt-2 main_msg_dummy">
								<div class="dummy_wrapper">
									<div class="dummy_content "> </div>
								</div>
								<div class="dummy_image img-circle "></div>
							</li>
							<li class="d-flex mt-2 main_msg_dummy">
								<div class="dummy_image img-circle "></div>
								<div class="dummy_wrapper">
									<div class="dummy_content"> </div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="!globals.pageLoad">
		<div class="col-12 px-0">
			<div class="card my-5 message__section">
				<div class="card-header mt-md">
					<h4 class="m-0"><i class="fal fa-comments-alt"></i>Messages</h4>
				</div>
				<div class="card-body p-0">
					<div class="row m-0">
						<div class="col-md-12 col-lg-5 p-0">
							<div class="message__user__section mob-views"
								[ngClass]="{'msg__d__none': (chatView && globals.mobileView),'message-section__border':(!chatdialogsLength && !total_members)}"
								id="top-section">
								<strong>
									<div class="unread__message d-flex justify-content-between mob-fix">
										<a class="creat__new__messges md-block" (click)="createMessage(1)">Create new message</a>
										<a class="creat__new__messges sm-block" (click)="createMessage(1)">Create new message</a>
										<ul class="d-flex">
											<li (click)="readMessages(1)" class="pointer-cursor mark_all" id="mark-1">
												Mark all read</li>
										</ul>
									</div>
								</strong>

								<div *ngIf="!chatdialogsLength && !globals.pageLoad"
									class="person-mutual-content new__message-list nothing_post">
									<p class="no_post_text">No message yet. Go start connecting.</p>
									<img src="./assets/img/NoMessage.svg" class="img-fluid ">
									<p class="no_post_text">Sorry! There is nothing here.</p>
								</div>
								<ul class="list-group message__user__list mob-mar-t-60" *ngIf="chatdialogsLength">
									<li class="list-group-item {{data.recipient_id}}" *ngFor="let data of chatdialogs;index as $index"
										data-target="#collapseExample" aria-expanded="false"
										(click)="data.activeTab||getChatHistory(data,$index)" [ngClass]="{'active':data.activeTab}">
										<div class="d-flex justify-content-between">
											<div class="message__user__list-thumb">
												<img onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
													src="{{data?.image}}" class="img-fluid rounded-circle" alt="img">
												<h4 class="active_names"><span class="message_user_name">{{data.name}}</span>
													<span class="user-inactive" *ngIf="!data.online_status && !data.room_type"></span>
													<span class="user-active" *ngIf="data.online_status && !data.room_type"></span>
												</h4>
												<span class="unread-icon" *ngIf="data.unread_messages"
													[class.disabled]="globals.onLoading">{{data.unread_messages}}</span>
												<p [hidden]="(!data.total_message_count && data.room_type)">
													<span *ngIf="data.last_message_type == 0">{{data.last_message_sent}}</span>
													<span *ngIf="data.last_message_type == 1"><i class="fas fa-image"></i> Image</span>
													<span *ngIf="data.last_message_type == 2"><i class="far fa-file-alt mr-3"></i> File</span>
												</p>
											</div>
											<div class="message__user__time">
												{{data.updated_time}}
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="col-md-12 col-lg-7 p-0" id="collapseExample">
							<div class="ridequdos__chat" [ngClass]="{'msg__d__none':!chatView && !createMessageView}">
								<div class="chat-header create__new__message__header {{recipient_id}}" *ngIf="createMessageView">
									<div *ngIf="groupNameValue" class="page_panel mob-filter main-mob-filter">
										<div class="text-center mt-3 mb-3 w-100" *ngIf="editGroupView">
											<div class="image-upload">
												<label for="file">
													<i class="fas fa-user-circle"></i>
													<span class="upload_img">
														Upload</span>
												</label>
												<input id="file" type="file" class="input-file" name="groupImage"
													accept="image/x-png,image/jpeg" (change)="updateGroupImage($event.target.files)">
											</div>
										</div>
										<span class="mob-filter__results">
											<input type="text" class="form-control groupname-box" placeholder="Enter Group Name" name=""
												[(ngModel)]="newGroupName" required />
										</span>
									</div>
									<div class="page_panel mob-filter">
										<span class="mob-filter__results-message">
											<i class="fa fa-search"></i>
											<input type="text" class="form-control" placeholder="Search name or email address" name=""
												[(ngModel)]="searchFriend" (keyup)="getMemberList()">
										</span>
									</div>
								</div>
								<div class="search__thumb__lists__sec d-flex flex-wrap border-0"
									*ngIf="createMessageView && friendListView">
									<ng-container *ngIf="members">
										<div class="search__thumb__lists mr-5" *ngFor="let data of members;index as $index">
											<a class="close__search" (click)="close($index)"><i class="fal fa-times-circle"></i></a>
											<span *ngIf="!data.group_id"><img src="{{data?.user_image}}"
													onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
													class="img-fluid rounded-circle" alt="img"></span>
											<span class="msg-usernm" *ngIf="!data.group_id">{{data.user_name.split(" ")[0]}}</span>
											<span *ngIf="data.group_id"><img src="{{data?.group_image}}"
													onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
													class="img-fluid rounded-circle" alt="img"></span>
											<span *ngIf="data.group_id">{{data.group_name.split(" ")[0]}}</span>
										</div>
									</ng-container>
								</div>
								<div class="create__message__button__group d-flex justify-content-center" *ngIf="createMessageView">
									<div class="justify-content-around d-flex message__button-group">
										<a role="button" class="btn qudos-tertiary-btn" (click)="cancel()">Cancel</a>
										<a role="button" class="btn qudos-gradient-btn creat_msg_btn">
											<span id="createNext" [hidden]="editGroupView" (click)="createNewMessage()">Create Message</span>
											<span *ngIf="editGroupView" (click)="globals.onLoading || updateGroup(recipient_id)">Update</span>
										</a>
									</div>
								</div>
								<ul class="list-group message__user__list create__new__message__list" *ngIf="createMessageView"
									id="friendAdd">
									<li class="list-group-item" *ngFor="let data of members_diff;index as $index">
										<div class="d-flex justify-content-between mt-3">
											<div class="message__user__list-thumb" *ngIf="!data.group_id">
												<img src="{{data?.user_image}}"
													onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
													class="img-fluid rounded-circle" alt="img">
												<h4><span class="message_user_name">{{data.user_name}}</span></h4>
											</div>
											<div class="message__user__list-thumb" *ngIf="data.group_id" id="member_{{data.group_id}}">
												<img src="{{data?.group_image}}"
													onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
													class="img-fluid rounded-circle" alt="img">
												<h4><span class="message_user_name">{{data.group_name}}</span></h4>
												<span class="message__user__time select_grp">Group</span>
											</div>
											<div class="message__user__time message__user_check">
												<span class="list-right">
													<label class="checkbox">
														<input type="checkbox" class="friendList" value="{{$index}}" (click)="getCheckedValue()"
															[(ngModel)]="data.checked" />
														<span class="default"></span>
													</label>
												</span>
											</div>
										</div>
									</li>
									<div class="row">
										<div class="col-12" *ngIf="(memberList.length<total_members) && !globals.onLoading">
											<button class="results__more results_more_notification" type="button" (click)="loadMore()">Load
												More</button>
										</div>
									</div>
								</ul>
								<div class="chat-header {{recipient_id}}" *ngIf="chatView && !createMessageView">
									<img onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';" *ngIf="user_image"
										[src]="user_image" class="img-fluid rounded-circle" alt="img">
									<h4><span data-toggle="tooltip" data-placement="top" title="{{receiverName}}"
											class="grp_name_msgg">{{receiverName}}</span>
										<span class="edit_grp_icon pointer-cursor"
											*ngIf="(room_type && ((adminId == globals.user.user_id) && (adminType == globals.user.qudos_user_type)))"
											(click)="editGroup(recipient_id)"><img src="./assets/img/edit@2x.png"></span>
										<span class="user-inactive" *ngIf="!online && !room_type"></span>
										<span class="user-active" *ngIf="online && !room_type"></span>
									</h4>
									<p *ngIf="online && !room_type">Online</p>
									<p *ngIf="!online && !room_type">Offline</p>
									<p *ngIf="room_type">Group</p>
									<a (click)="back()" class="msg__back" *ngIf="chatView">Back</a>
								</div>
								<div class="chat-section nothing_post" *ngIf="chatView && createMessageSuccess && !createMessageView">
									<p class="no_post_text">Yay! This is your first message, <br /> Make an impression.
									</p>
									<img src="./assets/img/NoMessage.svg" class="img-fluid no-msg-image">
								</div>
								<div class="chat-section" id="chat-1" *ngIf="chatView && !createMessageSuccess && !createMessageView">
									<div class="chat" [ngClass]="{'sender-msg': data.type == 1 ,'receiver-msg': data.type == 2 }"
										*ngFor="let data of chatHistory;index as $index">
										<div class="chat-user-thumb-sec">
											<span class="chat-user-thumb">
												<img onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
													src="{{data?.user_image}}" class="img-fluid rounded-circle" alt="img">
											</span>
											<span class="chat-seen-time">{{data.updated_at}}</span>
										</div>
										<span *ngIf="room_type && data.type ==2" class="group-sender_name">{{data.sender_name}}</span>
										<p [innerHTML]="data.content" *ngIf="data.content"></p>
										<div class="preview_img mt-3 mb-1"
											[ngClass]="{'float-right':data.type==1,'float-left':data.type==2}" *ngIf="data.message_type==1">
											<img src="{{data?.file}}" class="img-preview img-fluid" alt="img-preview" *ngIf="data.file!=null">
										</div>
										<div class="file_view mt-3 mb-2" [ngClass]="{'float-right':data.type==1,'float-left':data.type==2}"
											*ngIf="data.message_type==2">
											<h2><strong>
													<span *ngIf="data.fileName =='.msword'">Word File</span>
													<span *ngIf="data.fileName=='.pdf'">PDF File</span>
													<span *ngIf="data.fileName=='.plain'">Text File</span></strong></h2>
											<span class="d-block mt-4"><i class="far fa-file-alt mr-3"></i><span>File</span></span>
											<a href="{{data.file}}" class="file_download" target="_blank">Download</a>
										</div>
									</div>
								</div>
								<div *ngIf="globals.onLoading && globals.reconnectCount" class="whirl message_loading"></div>
								<div class="chat-sending-box" *ngIf="chatView && !createMessageView">
									<div class="msg-type">
										<textarea name="" id="messageBox" placeholder="Type a message here..." [(ngModel)]="globals.message"
											class="message_area" [ngClass]="{'margin-top__chat':!chatHistory.length}"></textarea>
										<img src="./assets/img/face.png" class="face__emoji message_emoji"
											(click)="emojiService.openEmojiPicker($event)">
									</div>
									<div class="chat-icons-options d-flex justify-content-between align-items-center mobile-view">
										<button class="btn btn-no-bg mob-show" (click)="sendMessage()" [disabled]="globals.onLoading"><i
												class="fas fa-paper-plane"></i></button>
										<label for="fileAttached" class="file-attach-btn f-btn">
											<i class="fal fa-paperclip"></i> <span id="imageName"
												*ngIf="!globals.mobileView">Attachments</span>
											<i class="fas fa-file-import hidden" *ngIf="globals.mobileView"></i>
										</label>
										<input id="fileAttached" name="file" type="file" accept=".pdf,.png,.jpg,.jpeg,.txt,.doc"
											(change)="manageAttachment($event.target.files)">
										<button class="send-btn mob-hide " (click)="sendMessage()" [disabled]="globals.onLoading"><i
												class="fas fa-paper-plane"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="globals.onLoading && !globals.reconnectCount" class="whirl"></div>
</div>