import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/ViewServices/userServices/user.service';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/helpers/constant';

@Component({
  selector: 'app-friend-request',
  templateUrl: './friend-request.component.html',
  styleUrls: ['./friend-request.component.css']
})
export class FriendRequestComponent implements OnInit {
  personId: number;
  personType: number;
  isFriend: boolean;
  userData: any = {};
  requested: any;

  constructor(private route: ActivatedRoute, private userHelper: UserService, private toaster: ToastrService, public globals: Globals, private router: Router) {
    this.route.params.subscribe(params => {
      this.personId = parseInt(params.personId);
      this.personType = parseInt(params.personType);
    })
    this.isFriend = false;
  }

  ngOnInit() {
    this.getMoreDetails();
  }

  //Get details of person
  public getMoreDetails() {
    this.globals.pageLoad = true;
    let data = this;
    this.userHelper.getPersonDetails(data, 0).subscribe(result => {
      this.globals.onLoading = false;
      this.globals.pageLoad = false;
    },
		err => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		})
  };

  // Reject Person
  public rejectPerson(user, type) {
    this.globals.onLoading = true;
    let data = this;
    data.personId = this.personId;
    data.personType = this.personType
    this.userHelper.rejectPerson(data, user, type, 0, 2).subscribe(result => {
      this.router.navigate(['/network']);
      this.globals.onLoading = false;
    },
		err => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		})
  };

  //Add person
  public addPerson(user, type) {
    this.globals.onLoading = true;
    let data = this;
    data.personId = this.personId;
    data.personType = this.personType
    this.userHelper.addPerson(data, user, type, 0, 2).subscribe(result => {
      this.isFriend = true;
      this.globals.onLoading = false;
    },
		err => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		})
  }

  //Add fave driver 
  public addFaveDriver(driver) {
    this.globals.onLoading = true;
    let data = this;
    this.userHelper.addFaveDriver(data, driver, 2).subscribe(result => {
      this.isFriend = true;
      this.globals.onLoading = false;
    },
		err => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		})
  };

  //Remove fave driver
  public removeFaveDriver(driver) {
    this.globals.onLoading = true;
    let data = this;
    this.userHelper.removeFaveDriver(data, driver, 2).subscribe(result => {
      this.router.navigate(['/network']);
      this.globals.onLoading = false;
    },
		err => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		})
  };

  //Remove friend
  public removeFriend(friend, type) {
    this.globals.onLoading = true;
    let data = this;
    this.userHelper.removePerson(data, friend, type, 2).subscribe(result => {
      this.router.navigate(['/network']);
      this.globals.onLoading = false;
    },
		err => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		})
  };
}
