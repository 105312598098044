import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { GlobalFunctions } from 'src/app/helpers/service';
import { EventData } from 'src/app/helpers/events.shared';
import { Router } from '@angular/router';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {

	image = '';
	content_type;
	posts = [];
	offset = this.posts.length;
	is_post = false;
	friendListOffset = 0;
	tagList = [];
	searchFlag = 0;
	searchKeyword: any;
	searchCountry: any;
	searchZip: any;
	searchBar: any;
	search: any;
	eventSubscription: any;

	constructor(public globals: Globals, private serviceFunction: GlobalFunctions, private router: Router, private cdRef: ChangeDetectorRef) {
	}

	ngOnInit() {
		//Event to detect search in the filter section
		this.eventSubscription = this.serviceFunction.on("fetchResult", (data: any) => {
			this.searchBar = data.searchBar;
			this.searchCountry = data.searchCountry;
			this.searchKeyword = data.searchKeyword;
			this.searchZip = data.searchZip;
		})
		this.cdRef.detectChanges();
	}

	ngOnDestroy() {
		this.eventSubscription.unsubscribe();
	}

	//fetch post after filtering
	public fetchPost() {
		this.search = 1;
		let param = {
			searchKeyword: this.searchKeyword,
			searchCountry: this.searchCountry,
			searchZip: this.searchZip,
			search: this.search
		}
		this.serviceFunction.emit(new EventData("fetchPost", param));
	}

	//clear filter functionality
	public clear() {
		this.search = 0;
		this.searchKeyword = '';
		this.searchZip = '';
		this.searchCountry = '';
		this.searchBar = false;
		this.router.navigate(['/home']);
	}
}