<div class="page copy_page" [ngClass]="{'black-theme':postType,'m-0':postType}">
    <div *ngIf="globals.pageLoad">
        <div class="row h-100vh ">
            <div class="col-12 col-lg-3 col-xl-2 page_sidebar">
            </div>
            <div class="col-12 col-lg-6 col-xl-8 page_content full-md post__copy">
                <ul class="dummy_post timeline-item border-0 pb-0 mt-2" [ngClass]="{'black-theme':postType}">
                    <li class="d-flex mt-3 main_msg_dummy">
                        <div class="dummy_image img-circle "></div>
                        <div class="dummy_wrapper">
                            <div class="dummy_content"> </div>
                        </div>
                    </li>
                </ul>
                <div class="timeline-item pt-1" [ngClass]="{'black-theme':postType}">
                    <div class="dummy_content w-100"> </div>
                    <div class="dummy_content w-100"> </div>
                    <div class="dummy_post_img mt-0 w-100 mb-0 m-lr-15"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row " [ngClass]="{'postInfo':postType && !globals.mobileView}">
        <div class="col-12 col-lg-3 col-xl-2 page_sidebar" *ngIf="!globals.pageLoad">
        </div>
        <div class="col-12 col-lg-6 col-xl-8 page_content full-md post__copy">
            <ng-container *ngIf="!globals.pageLoad">
                <ng-container *ngIf="!postType && !globals.is_auth">
                    <div class="copy-content" *ngFor="let post of posts">
                        <p><b>{{post.user_name}} is on QudosConnect</b></p>
                        <p>Join QudosConnect today to connect with {{post.user_name.split(' ')[0]}}</p>
                        <button type="button" class="join_button" routerLink="/signup" *ngIf="!globals.mobileView">Join</button>
                        <button type="button" class="join_button" (click)="serviceFunction.redirectToApp()" *ngIf="globals.mobileView">Join</button>
                        <p>or</p>
                        <button type="button" class="login-button" routerLink="/login" *ngIf="!globals.mobileView">Log In</button>
                        <button type="button" class="login-button" (click)="serviceFunction.redirectToApp()" *ngIf="globals.mobileView">Log
                            In</button>
                    </div>
                </ng-container>
                <div *ngIf="postType" class="close-link">
                    <button (click)="goBack()" type="button"><i class="fal fa-times"></i></button>
                </div>
            </ng-container>
            <post-preview></post-preview>
        </div>
        <div *ngIf="globals.onLoading" class="whirl"></div>
    </div>
</div>