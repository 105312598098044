import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Globals, API } from 'src/app/helpers/constant';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { GlobalFunctions } from 'src/app/helpers/service';

@Injectable({
  providedIn: 'root'
})

//Service to manage page functionalities
export class PageService {
  constructor(public globals: Globals, private toaster: ToastrService, private http: HttpClient, private serviceFunction: GlobalFunctions) { }

  //Follow Unfollow Page 
  followUnfollow(data, page, index, followType, type = 1): Observable<any> {
    return this.http.post<any>(API.FOLLOW_UNFOLLOW_PAGE, {
      qudos_user_type: this.globals.user.qudos_user_type,
      page_id: page.page_id,
      is_follow: followType
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          this.toaster.success(response.log, 'SUCCESS');
          data.total_followers = response.total_followers;
          data.total_likes = response.total_likes;
          if (type == 1) {
            if (followType) {
              data.pages[index].is_follower = 1;
            } else {
              data.pages[index].is_follower = 0;
            }
          }
          else if (type == 2) {
            if (followType) {
              data.pageData.is_follower = 1;
            } else {
              data.pageData.is_follower = 0;
            }
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Like Unlike Page 
  likeUnlike(data, page, index, type, pageType = 1): Observable<any> {
    return this.http.post<any>(API.LIKE_UNLIKE_PAGE, {
      qudos_user_type: this.globals.user.qudos_user_type,
      page_id: page.page_id,
      like: type
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          this.toaster.success(response.log, 'SUCCESS');
          data.total_followers = response.total_followers;
          data.total_likes = response.total_likes;
          if (pageType == 1) {
            if (type) {
              data.pages[index].is_like = 1;
              data.pages[index].is_follower = 1;
            } else {
              data.pages[index].is_like = 0;
              data.pages[index].is_follower = 0;
            }
          }
          else if (pageType == 2) {
            if (type) {
              data.pageData.is_like = 1;
              data.pageData.is_follower = 1;
            } else {
              data.pageData.is_like = 0;
              data.pageData.is_follower = 0;
            }
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  // Get list of pages
  getPagesList(data, type, loadMore): Observable<any> {
    if ((data.searchString.length > 0 && !loadMore) || (data.filter_page_name && !loadMore)) {
      data.offset = 0;
      data.pages = [];
    }

    return this.http.post<any>(API.FETCH_PAGES_LIST, {
      qudos_user_type: this.globals.user.qudos_user_type,
      limit: 10,
      searchString: data.searchString,
      sort_by: "date_registered",
      sort_order: "desc",
      offset: data.offset,
      request_type: type, //0= My Pages , 1 =Explore
      searchName: data.filter_page_name
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.total_pages = response.total_pages;
          if (loadMore) {
            data.pages = data.pages.concat(response.pages);
          } else {
            data.pages = response.pages;
          }
          data.filter_page_name = data.filter_page_name;
          if (data.filter_page_name) {
            data.searchBar = true;
            if (data.total_pages == 0) {
              data.emptyResult = true;
            }
          } else if (data.searchString) {
            if (data.total_pages == 0) {
              data.emptyResult = true;
            }
          }
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Delete Page 
  deletePage(data): Observable<any> {
    return this.http.post<any>(API.DELETE_PAGE, {
      qudos_user_type: this.globals.user.qudos_user_type,
      page_id: data.pageId,
    }
    ).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          this.toaster.success(response.log, 'SUCCESS');
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Edit page
  edit(data, page): Observable<any> {
    let file = data.myFile;
    if (file) {
      data.image = 1;
    } else {
      data.image = 0;
    }
    if ((data.description == undefined || (data.description.trim()).length == 0)) {
      data.description = '';
    } else {
      data.description = data.description;
    }
    let params = new FormData();
    params.append("access_token", this.globals.user.web_access_token);
    params.append('page_name', page.page_name);
    params.append('description', (page.description));
    params.append('image', file);
    params.append('page_type', page.page_type);
    params.append('image_flag', data.image);
    params.append('qudos_user_type', this.globals.user.qudos_user_type);
    params.append('page_id', page.page_id)

    return this.http.post<any>(API.UPDATE_PAGE, params).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          this.toaster.success(response.log, 'SUCCESS');
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Create page 
  createPage(data): Observable<any> {
    let file = data.myFile;

    if (file) {
      data.image = 1;
    } else {
      data.image = 0;
    }
    if ((data.description == undefined || (data.description.trim()).length == 0)) {
      data.description = '';
    } else {
      data.description = data.description;
    }
    let params = new FormData();
    params.append("access_token", this.globals.user.web_access_token);
    params.append('page_name', data.pageName);
    params.append('description', data.description);
    params.append('image', file);
    params.append('page_type', data.page_type);
    params.append('image_flag', data.image);
    params.append('qudos_user_type', this.globals.user.qudos_user_type);

    return this.http.post<any>(API.CREATE_PAGE, params).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          this.toaster.success(response.log, 'SUCCESS');
        }
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Get page details
  getPageDetails(data): Observable<any> {
    return this.http.post<any>(API.PAGE_INFO, {
      qudos_user_type: this.globals.user.qudos_user_type,
      page_id: data.pageId
    }).pipe(
      map(response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, "ERROR");
        } else {
          data.pageData = response.pageInfo;
          data.pageType = response.pageInfo.page_type;
          data.is_admin = response.pageInfo.is_admin;
          data.is_deleted = response.pageInfo.is_deleted;
        }
        return data;
      }),
      catchError(this.serviceFunction.handleError)
    )
  };

  //Fetch page details by ID
  fetchPageById(data): Observable<any> {
    return this.http.post<any>(API.FETCH_PAGE_BY_ID, {
      page_id: data.pageId
    })
      .pipe(
        map(
          response => {
            if (typeof (response) == 'string') {
              response = JSON.parse(response);
            }
            if (response.error) {
              this.toaster.error(response.error, "ERROR");
            } else {
              data.page = response.pageInfo[0];
            }
            return data
          }),
        catchError(this.serviceFunction.handleError)
      )
  };

  //Share page
  sharePage(data): Observable<any> {
    data.describe = this.globals.description ? this.globals.description : data.describe;
    if ((data.describe) === undefined || data.describe.length == 0) {
      return throwError('Please enter a description.');
    }
    else {
      let params = new FormData();
      params.append("access_token", this.globals.user.web_access_token);
      params.append('qudos_user_type', this.globals.user.qudos_user_type);
      params.append('tagged_users', this.globals.taggedArray.toString());
      params.append('tagged_members', JSON.stringify(this.globals.taggedMember));
      params.append('description', encodeURIComponent(this.serviceFunction.htmlEncode((data.describe))));
      params.append('page_id', data.pageId)

      return this.http.post<any>(API.SHARE_PAGE, params).pipe(
        map(
          response => {
            if (typeof (response) == 'string') {
              response = JSON.parse(response);
            }
            if (response.error) {
              this.toaster.error(response.error);
            } else {
              this.toaster.success(response.log, 'SUCCESS');
              let auth_data = JSON.parse(localStorage.getItem('auth'));
              auth_data.total_posts += 1;
              this.globals.user = auth_data;
              localStorage.setItem('auth', JSON.stringify(auth_data));
              this.globals.description = '';
              if (Object.keys(this.globals.tagify).length) {
                this.globals.tagify.destroy();
                this.globals.tagify = {};
              }
            }
            return data
          }),
        catchError(this.serviceFunction.handleError)
      )
    }
  }

}
