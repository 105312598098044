import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ProfileService } from "src/app/services/ViewServices/profileService/profile.service";
import { Globals } from "src/app/helpers/constant";
import { GlobalFunctions } from "src/app/helpers/service";
import { UserService } from "src/app/services/ViewServices/userServices/user.service";
import { GroupService } from "src/app/services/ViewServices/groupService/group.service";
import { PageService } from "src/app/services/ViewServices/pageServices/page.service";
import { UserTemplateComponent } from "../modals/user-template/user-template.component";
import { ActivatedRoute } from "@angular/router";
import { NetworkTemplateComponent } from "../modals/network-template/network-template.component";
import * as bodyScrollLock from "src/assets/js/bodyScrollLock.js";
import { PostService } from "src/app/services/ViewServices/postServices/post.service";
import { DataService } from "src/app/services/ViewServices/dataService/data.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "profile-preview",
  templateUrl: "./profile-preview.component.html",
  styleUrls: ["./profile-preview.component.css"],
})
export class ProfilePreviewComponent implements OnInit, OnDestroy {
	[x: string]: any;
	image = "";
	content_type;
	posts = [];
	offset = this.posts.length;
	is_post = false;
	userPage = false;
	groupPage = false;
	profilePage = false;
	groupProfilePage = false;
	pageInfoView = false;
	viewFaveCountOffset = 0;
	viewFave = [];
	modalOpen = false;
	searchFlag = 0;
	data = {
		interest: "",
		about: "",
		total_points: 0,
		fav_count: 0,
		follower_count: 0,
		country: "",
		user_id: 0,
		user_name: "",
		user_image: "",
	};
	driverData = [];
	friendData = [];
	pageData = {
		is_like: 0,
		is_follower: 0,
		description: "",
		total_likes: 0,
		total_followers: 0,
		page_id: 0,
		page_name: "",
		page_image: "",
		page_type: 0,
	};
	groupData = {
		total_members: 0,
		group_id: 0,
		date_added: 0,
		description: "",
		total_followers: 0,
		group_name: "",
		group_image: "",
	};
	members = [];
	pageId: any;
	groupId: number;
	personId: number;
	personType: number;
	faveUsers: any;
	requested: any;
	friend_request: any;
	option: number;
	is_fav_driver: any;
	is_friend: any;
	is_follower: any;
	is_member: number;
	is_deleted: any;
	is_admin: any;
	loadPage: number = 0;
	isProfileUpdated: boolean;
	routeSubscribe: any;
	eventSubscription: any;
	modalOpenSubscriber: any;
	viewFaveModalSubscriber: any;
	totalFaveUsers: number;
	faveUsersCount: number;
	targetElement: any;
	userData: any;
	user: any;

	constructor(
		private toaster: ToastrService,
		private profileHelper: ProfileService,
		public globals: Globals,
		public serviceFunction: GlobalFunctions,
		private userHelper: UserService,
		private groupHelper: GroupService,
		private pageHelper: PageService,
		private router: ActivatedRoute,
		private postHelper: PostService,
		private dataHelper: DataService
	) {
		//To fetch details sent through the route.
		this.routeSubscribe = this.router.params.subscribe((params) => {
		this.groupId = parseInt(params.groupId);
		this.pageId = parseInt(params.pageId);
		this.personId = parseInt(params.personId);
		this.personType = parseInt(params.personType);
		this.loadPage += 1;
		if (this.loadPage != 1) this.init();
		});
		const targetElement = document.querySelector("#body");
	}

	@ViewChild(UserTemplateComponent)
	userTemplateComponent: UserTemplateComponent;
	@ViewChild(NetworkTemplateComponent)
	networkTemplateComponent: NetworkTemplateComponent;

	ngOnInit() {
		this.init();
		this.viewFaveModalSubscriber = this.serviceFunction.on(
		"userFaveModal",
		(data: any) => {
			this.viewFaveCountOffset = data.viewFaveCountOffset;
			this.faveUsers = data.faveUsers;
			this.viewFaveCount(data.user, data.option);
		}
		);
	}

	ngOnDestroy() {
		this.routeSubscribe.unsubscribe();
		this.viewFaveModalSubscriber.unsubscribe();
		if (this.modalOpenSubscriber) this.modalOpenSubscriber.unsubscribe();
	}

	//Copy link functionality
	public linkCopy(value, type) {
		bodyScrollLock.disableBodyScroll(this.targetElement);
		let copyValue = environment.LINK;
		if (type == 2) {
			if (this.personType) {
				copyValue = copyValue + "driver/" + value;
			} else {
				copyValue = copyValue + "rider/" + value;
			}
		} else if (type == 3) {
			copyValue = copyValue + "group/" + value;
		} else if (type == 4) {
			copyValue = copyValue + "page/" + value;
		}
		this.postHelper.copyLink(copyValue, type).subscribe(
			(result) => {
				bodyScrollLock.clearAllBodyScrollLocks();
				this.toaster.info("Link Copied", "INFORMATION");
			},
			(err) => {
				this.toaster.error(err, "ERROR");
				this.globals.onLoading = false;
			}
		);
	};

	//Social media share fucntionality
	public socialMediaShare(value, type, socialType, option = 1) {
		let copyValue = environment.LINK;
		if (type == 1) {
		copyValue =
			copyValue +
			"post/" +
			value +
			"/0/" +
			this.globals.user.user_id +
			"/" +
			this.globals.user.qudos_user_type;
		}
		if (type == 2) {
			if (this.personType) {
				copyValue = copyValue + "driver/" + value;
			} else {
				copyValue = copyValue + "rider/" + value;
			}
		} else if (type == 3) {
			copyValue = copyValue + "group/" + value;
		} else if (type == 4) {
			copyValue = copyValue + "page/" + value;
		}

		if (this.globals.mobileView) {
			$("#body").removeClass("custom-backdrop");
			if (type == 1) {
				this.serviceFunction.closeOptions(1);
			} else if (type == 2) {
				this.serviceFunction.closeOptions(2);
			} else if (type == 3) {
				this.serviceFunction.closeOptions(3);
			} else if (type == 4) {
				if (option == 1) {
					this.serviceFunction.closeOptions(4);
				}
			}
		}
		//1 = facebook,2= linkedin
		if (socialType == 1)
			window.open(
				"https://www.facebook.com/sharer/sharer.php?u=" +
				encodeURIComponent(copyValue) +
				"&t=" +
				document.title,
				"_self"
			);
		else if (socialType == 2)
			window.open(
				"https://www.linkedin.com/sharing/share-offsite/?url=" + copyValue,
				"_self"
			);
	}

	//////////////////////////////////////   Profile    ///////////////////////////////////////////////
	//Fetch profile details
	public viewProfile() {
		this.globals.pageLoad = true;
		this.profileHelper.viewProfile().subscribe(
		(response) => {
			if (response.error) {
				this.toaster.error(response.error, "ERROR");
			} else {
			this.userData = response.userData;
			let auth_data = JSON.parse(localStorage.getItem("auth"));
			auth_data.user_email = this.userData.user_email;
			auth_data.mobile = this.userData.user_mobile;
			auth_data.user_mobile = this.userData.user_mobile;
			auth_data.user_image = this.userData.user_image;
			auth_data.user_name =
				this.userData.firstname + " " + this.userData.lastname;
			localStorage.setItem("auth", JSON.stringify(auth_data));
			this.globals.user =
				localStorage.getItem("auth") == "undefined" ||
				!localStorage.getItem("auth")
				? ""
				: JSON.parse(localStorage.getItem("auth"));
			this.user = this.globals.user;
			}
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		}
		);
	};

	//Open edit profile modal
	public showEditProfile() {
		this.isProfileUpdated = false;
		let modalOptions = {
		windowClass: "app-modal-window",
		size: "md",
		backdropClass: "modal-backdrop-custom",
		keyboard: false,
		backdrop: "static",
		};
		this.userTemplateComponent.choosed_avatar = false;
		if (this.user) this.userTemplateComponent.choosed_avatar = true;
		this.userTemplateComponent.editData = this.userData;
		this.userTemplateComponent.firstname = this.userData.firstname;
		this.userTemplateComponent.lastname = this.userData.lastname;
		this.userTemplateComponent.countryCode_orig =
		this.userData.user_mobile.split("-")[0];
		this.serviceFunction.openModal(
		this.userTemplateComponent.editProfileModal,
		modalOptions
		);
		this.modalOpenSubscriber = this.serviceFunction.on(
		"modalOpen",
		(data: any) => {
			window.onscroll = function () {};
			this.modalOpenSubscriber.unsubscribe();
		}
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data != "Close click") {
			this.init();
			this.dataHelper.isProfileUpdated = this.isProfileUpdated = true;
			}
			this.eventSubscription.unsubscribe();
		}
		);
	};

	//////////////////////////////////////////////// Get Details///////////////////////////////////////////////////
	//Get more user details
	public getMoreDetails(type) {
		this.globals.pageLoad = true;
		let data = this;
		this.userHelper.getPersonDetails(data, type).subscribe(
		(result) => {
			this.data = result.data;
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		}
		);
	};

	//Fetch group details
	public getGroupDetails() {
		this.globals.pageLoad = true;
		let data = this;
		data.offset = this.offset;
		data.groupId = this.groupId;
		this.groupHelper.getGroupDetails(data).subscribe(
		(result) => {
			this.groupData = result.groupData;
			this.members = result.members;
			this.total_members = result.total_members;
			this.dataHelper.is_admin = this.is_admin = result.is_admin;
			this.is_member = result.is_member;
			this.is_follower = result.is_follower;
			this.dataHelper.is_deleted = this.is_deleted = result.is_deleted;
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		}
		);
	};

	//Fetch page details
	public getPageDetails() {
		this.globals.pageLoad = true;
		let data = this;
		data.pageId = this.pageId;
		this.pageHelper.getPageDetails(data).subscribe(
		(result) => {
			this.dataHelper.pageData = this.pageData = result.pageData;
			this.pageType = result.pageType;
			this.dataHelper.is_admin = this.is_admin = result.is_admin;
			this.dataHelper.is_deleted = this.is_deleted = result.is_deleted;
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		}
		);
	};

	////////////////////////////////////////////////////////// LEAVE GROUP /////////////////////////////////////////
	//Modal to leave group
	public leaveGroupModal(group) {
		this.networkTemplateComponent.isMember = 1;
		this.networkTemplateComponent.group = group;
		this.networkTemplateComponent.groupId = this.groupId;
		this.offset = 0;
		let modalOptions = {
			windowClass: "app-modal-window-custom",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
			this.networkTemplateComponent.confirmGroup,
			modalOptions
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data != "Close click") {
				this.is_member = 0;
				this.is_follower = 0;
				this.groupData.total_followers = this.networkTemplateComponent.total_followers;
				this.groupData.total_members = this.networkTemplateComponent.total_members;
			}
			this.eventSubscription.unsubscribe();
		}
		);
	};

	////////////////////////////////////////////////////////// JOIN GROUP /////////////////////////////////////////

	//Modal to join group
	public joinGroupModal(group) {
		this.networkTemplateComponent.isMember = 0;
		this.networkTemplateComponent.group = group;
		this.networkTemplateComponent.groupId = this.groupId;
		this.networkTemplateComponent.option = 1;
		this.offset = 0;
		let modalOptions = {
			windowClass: "app-modal-window-custom",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
			this.networkTemplateComponent.confirmGroup,
			modalOptions
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data != "Close click") {
				this.is_member = this.networkTemplateComponent.is_member;
				this.is_follower = this.networkTemplateComponent.is_follower;
				this.groupData.total_followers = this.networkTemplateComponent.total_followers;
				this.groupData.total_members = this.networkTemplateComponent.total_members;
			}
			this.eventSubscription.unsubscribe();
		}
		);
	};

	////////////////////////////////////////////////////////// FOLLOW/UNFOLLOW /////////////////////////////////////////
	//To follow a Group
	public followGroup() {
		this.globals.onLoading = true;
		let data = this;
		data.groupId = this.groupId;
		this.groupHelper.followGroup(data).subscribe(
		(result) => {
			this.globals.onLoading = false;
			this.groupData.total_followers = result.total_followers
			? result.total_followers
			: this.groupData.total_followers;
			this.groupData.total_members = result.total_members
			? result.total_members
			: this.groupData.total_members;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//Unfollow Group
	public unfollowGroup() {
		this.globals.onLoading = true;
		let data = this;
		data.groupId = this.groupId;
		this.groupHelper.unfollowGroup(data).subscribe(
		(result) => {
			this.globals.onLoading = false;
			this.groupData.total_followers = result.total_followers
			? result.total_followers
			: this.groupData.total_followers;
			this.groupData.total_members = result.total_members
			? result.total_members
			: this.groupData.total_members;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//To follow a page
	public followUnfollowPage(page, followType) {
		this.globals.onLoading = true;
		let data = this;
		this.pageHelper.followUnfollow(data, page, 0, followType, 2).subscribe(
		(result) => {
			this.globals.onLoading = false;
			this.pageData.total_followers = result.total_followers
			? result.total_followers
			: this.pageData.total_followers;
			this.pageData.total_likes = result.total_likes
			? result.total_likes
			: this.pageData.total_likes;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//To like/unlike a page
	public likeUnlikePage(page, type) {
		this.globals.onLoading = true;
		let data = this;
		this.pageHelper.likeUnlike(data, page, 0, type, 2).subscribe(
		(result) => {
			this.globals.onLoading = false;
			this.pageData.total_followers = result.total_followers
			? result.total_followers
			: this.pageData.total_followers;
			this.pageData.total_likes = result.total_likes
			? result.total_likes
			: this.pageData.total_likes;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	/////////////////////////////////////////////////// Delete Group //////////////////////////////////////////////////////
	//Delete group
	public deleteGroupModal() {
		this.networkTemplateComponent.is_post = false;
		this.networkTemplateComponent.is_group = true;
		this.networkTemplateComponent.groupId = this.groupId;
		let modalOptions = {
			windowClass: "app-modal-window-custom",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
		this.networkTemplateComponent.deletePost,
		modalOptions
		);
	};

	///////////////////////////////////////////// SHARE GROUP/PAGE ////////////////////////////////////////
	// Modal to share details of a group
	public shareGroupModal(group) {
		this.networkTemplateComponent.describe = "";
		this.networkTemplateComponent.group = group;
		this.networkTemplateComponent.groupId = this.groupId;
		let modalOptions = {
			windowClass: "app-modal-window",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
			this.networkTemplateComponent.shareGroupModal,
			modalOptions
		);
	};

	// Modal to share details of a page
	public sharePageModal(page) {
		this.networkTemplateComponent.describe = "";
		this.networkTemplateComponent.page = page;
		this.networkTemplateComponent.pageId = page.page_id;
		let modalOptions = {
			windowClass: "app-modal-window",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
			this.networkTemplateComponent.sharePageModal,
			modalOptions
		);
	};
	
	//////////////////////////////////////////////// Follow Driver/Rider  ///////////////////////////////////////////////////
	//To follow a Driver/Rider
	public addFollower() {
		this.globals.onLoading = true;
		let data = this;
		this.userHelper
		.addFollow(data, this.personId, this.personType, 0, 2)
		.subscribe(
			(result) => {
			this.globals.onLoading = false;
			},
			(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			}
		);
	};

	//To remove the follower
	public removeFollower() {
		this.globals.onLoading = true;
		let data = this;
		this.userHelper
		.removeFollow(data, this.personId, this.personType, 0, 2)
		.subscribe(
			(result) => {
			this.globals.onLoading = false;
			},
			(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			}
		);
	};

	//////////////////////////////////////////////// Share Driver/Rider Details ///////////////////////////////////////////////////
	// Modal to share details of a driver/rider
	public sharePerson(person) {
		this.networkTemplateComponent.personId = this.personId;
		this.networkTemplateComponent.selectedType = this.personType;
		this.networkTemplateComponent.person = person;
		let modalOptions = {
			windowClass: "app-modal-window",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
		this.networkTemplateComponent.shareUser,
		modalOptions
		);
	};
	//////////////////////////////////////////////// Add Driver/Rider ///////////////////////////////////////////////////
	//Modal to add driver
	public addFavouriteDriver(driver) {
		this.isRider = false;
		this.networkTemplateComponent.isRider = false;
		this.networkTemplateComponent.driver = driver;
		this.networkTemplateComponent.option = this.option = 2;
		this.serviceFunction.openModal(
			this.networkTemplateComponent.confirmModalNetwork
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data != "Close click") {
				if (this.option == 2) {
					this.is_fav_driver = this.networkTemplateComponent.is_fav_driver;
					this.requested = this.networkTemplateComponent.requested;
					this.is_follower = this.networkTemplateComponent.is_follower;
					this.data.fav_count =
					this.data.fav_count >= 0 ? this.data.fav_count + 1 : 0;
				}
				this.eventSubscription.unsubscribe();
			}
		}
		);
	};

	//Modal function to add rider/driver friend
	public sendFriendRequest(friend, type) {
		this.networkTemplateComponent.friend = friend;
		this.networkTemplateComponent.selectedType = type;
		this.networkTemplateComponent.option = this.option = 2;
		this.networkTemplateComponent.selectedIndex = 0;
		this.networkTemplateComponent.isRider = !type;
		this.serviceFunction.openModal(
			this.networkTemplateComponent.confirmModalNetwork
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data != "Close click") {
				if (this.option == 2) {
					this.requested = this.networkTemplateComponent.requested;
					this.friend_request = this.networkTemplateComponent.friend_request;
				}
				this.eventSubscription.unsubscribe();
			}
		}
		);
	};

	//////////////////////////////////////////////// Remove Driver/Rider ///////////////////////////////////////////////////
	//confirm modal to remove driver
	public removeFavouriteDriver(driver) {
		this.networkTemplateComponent.isRider = false;
		this.networkTemplateComponent.driver = driver;
		this.networkTemplateComponent.option = this.option = 2;
		this.serviceFunction.openModal(
			this.networkTemplateComponent.confirmModalQudos
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data != "Close click") {
				if (this.option == 2) {
					this.is_fav_driver = this.networkTemplateComponent.is_fav_driver;
					this.is_follower = this.networkTemplateComponent.is_follower;
					this.data.fav_count =
					this.data.fav_count > 0 ? this.data.fav_count - 1 : 0;
				}
				this.eventSubscription.unsubscribe();
			}
		}
		);
	};

	//Modal to remove rider/driver as a friend
	public removePersonFriend(friend, type) {
		this.networkTemplateComponent.isRider = !type;
		this.networkTemplateComponent.friend = friend;
		this.networkTemplateComponent.selectedType = type;
		this.networkTemplateComponent.option = this.option = 2;
		this.serviceFunction.openModal(
			this.networkTemplateComponent.confirmModalQudos
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data != "Close click") {
				if (this.option == 2) {
					this.is_friend = this.networkTemplateComponent.is_friend;
					this.is_follower = this.networkTemplateComponent.is_follower;
					this.data.follower_count =
					this.data.follower_count > 0 ? this.data.follower_count - 1 : 0;
				}
				this.eventSubscription.unsubscribe();
			}
		}
		);
	};

	//////////////////////////////////////////////// Reject Driver/Rider ///////////////////////////////////////////////////
	//reject friend request of friend
	public rejectPerson(user, type) {
		this.globals.onLoading = true;
		let data = this;
		this.userHelper.rejectPerson(data, user, type, 0, 2).subscribe(
		(result) => {
			this.requested = result.requested;
			this.globals.onLoading = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//To cancel the friend request
	public cancelRequest(user, type) {
		this.globals.onLoading = true;
		let data = this;
		this.userHelper.cancelRequest(data, user, type, 0, 2).subscribe(
		(result) => {
			this.globals.onLoading = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//////////////////////////////////////////////// Accept Driver/Rider ///////////////////////////////////////////////////
	//add person
	public addPerson(user, type) {
		this.globals.onLoading = true;
		let data = this;
		this.userHelper.addPerson(data, user, type, 0, 2).subscribe(
		(result) => {
			this.requested = result.requested;
			this.friend_request = result.friend_request;
			this.data.follower_count =
			this.data.follower_count >= 0 ? this.data.follower_count + 1 : 0;
			this.globals.onLoading = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//edit group
	public editGroupModal(groupData) {
		this.networkTemplateComponent.groupData = groupData;
		this.networkTemplateComponent.editData = groupData;
		this.networkTemplateComponent.tempData = { ...groupData };
		this.networkTemplateComponent.groupId = this.groupId;
		let modalOptions = {
			windowClass: "app-modal-window",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
		this.networkTemplateComponent.editGroupModal,
		modalOptions
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data != "Close click") {
			this.getGroupDetails();
			}
			this.eventSubscription.unsubscribe();
		}
		);
	};

	////////////////////////////////////////////// Edit Page /////////////////////////////////////////////////////
	//edit page
	public editPageModal(pageData) {
		this.networkTemplateComponent.pageData = pageData;
		this.networkTemplateComponent.editData = pageData;
		this.networkTemplateComponent.tempData = { ...pageData };
		let modalOptions = {
			windowClass: "app-modal-window",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
			this.networkTemplateComponent.editPageModal,
			modalOptions
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data != "Close click") {
				this.getPageDetails();
				this.dataHelper.isPageEdited = true;
			}
			this.eventSubscription.unsubscribe();
		}
		);
	};

	//delete page
	public deletePageModal() {
		this.is_post = false;
		this.is_group = false;
		this.networkTemplateComponent.is_post = false;
		this.networkTemplateComponent.is_group = false;
		this.networkTemplateComponent.pageId = this.pageId;
		let modalOptions = {
			windowClass: "app-modal-window-custom",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
		this.networkTemplateComponent.deletePost,
		modalOptions
		);
	};

	/////////////////////////////// View fave count ///////////////////////////////////////////////////
	//View fave counts
	public viewFaveCount(user, loadMore = false) {
		let data = this;
		data.viewFaveCountOffset = loadMore ? this.viewFaveCountOffset : 0;
		this.globals.onLoading = true;
		this.userHelper.viewFaveCountfunction(data, user, loadMore).subscribe(
		(result) => {
			this.userTemplateComponent.faveUsersCount = this.faveUsersCount =
			result.faveUsersCount;
			this.userTemplateComponent.totalFaveUsers = this.totalFaveUsers =
			result.totalFaveUsers;
			this.userTemplateComponent.faveUsers = this.faveUsers =
			result.faveUsers;
			this.userTemplateComponent.globals.onLoading = this.globals.onLoading;
			this.viewFave = result.viewFave;
			if (!loadMore && !this.serviceFunction.modalOpened) {
				this.userTemplateComponent.user = user;
				let modalOptions = {
					windowClass: "app-modal-window",
					size: "md",
					backdropClass: "modal-backdrop-custom",
					keyboard: false,
					backdrop: "static",
				};
				this.serviceFunction.openModal(
					this.userTemplateComponent.viewFaveCount,
					modalOptions,
					true
				);
				this.eventSubscription = this.serviceFunction.on(
					"modalClosed",
					(data: any) => {
						this.viewFave = [];
						this.serviceFunction.modalOpened = false;
						this.eventSubscription.unsubscribe();
					}
				);
			}
			this.globals.onLoading = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//Initial function
	init = function () {
		if (location.pathname == "/profile") {
			this.profilePage = true;
			$("#userButton").hide();
			$("#groupEditButton").hide();
			$("#pageEditButton").hide();
			$("#groupImage").hide();
			this.getMoreDetails(1);
			this.viewProfile();
		} else if (location.pathname.split("/")[1] == "userInfo") {
			$("#editButton").hide();
			$("#groupImage").hide();
			$("#groupEditButton").hide();
			$("#pageEditButton").hide();
			this.personId = location.pathname.split("/")[2];
			this.personType = parseInt(location.pathname.split("/")[3]);
			this.userPage = true;
			this.getMoreDetails(2);
		} else if (location.pathname.split("/")[1] == "groupInfo") {
			$("#groupEditButton").hide();
			$("#editButton").hide();
			$("#userImage").hide();
			$("#pageEditButton").hide();
			this.groupPage = true;
			this.getGroupDetails();
		} else if (location.pathname.split("/")[1] == "groupProfile") {
			$("#editButton").hide();
			$("#userImage").hide();
			$("#pageEditButton").hide();
			this.groupProfilePage = true;
			this.getGroupDetails();
		} else if (location.pathname.split("/")[1] == "pageInfo") {
			$("#groupEditButton").hide();
			$("#editButton").hide();
			$("#userImage").hide();
			this.pageInfoView = true;
			this.getPageDetails();
		}
	};
}
