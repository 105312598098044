import { Component, OnInit, OnDestroy } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-group-profile',
  templateUrl: './group-profile.component.html',
  styleUrls: ['./group-profile.component.css']
})
export class GroupProfileComponent implements OnInit,OnDestroy {
  image = '';
  content_type;
  offset = 0;
  groupId: number;
  routeSubscribe: any;
  
  constructor(public globals: Globals, private router: ActivatedRoute) {
    this.globals.posts = [];
    this.routeSubscribe = this.router.params.subscribe(params => {
      this.groupId = parseInt(params.groupId)
    })
   }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.routeSubscribe.unsubscribe();
  }

}
