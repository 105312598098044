import {
  Component,
  OnInit,
  AfterViewChecked,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { Globals } from "src/app/helpers/constant";
import { GlobalFunctions } from "src/app/helpers/service";
import { ToastrService } from "ngx-toastr";
import { PostService } from "src/app/services/ViewServices/postServices/post.service";
import { EmojiService } from "src/app/services/emojiService/emoji.service";
import * as bodyScrollLock from "src/assets/js/bodyScrollLock.js";
import { PostTemplateComponent } from "../modals/post-template/post-template.component";
import { TagService } from "src/app/services/tagService/tag.service";
import { EventData } from "src/app/helpers/events.shared";
import { Router, NavigationEnd } from "@angular/router";
import { DataService } from "src/app/services/ViewServices/dataService/data.service";
import { NetworkTemplateComponent } from "../modals/network-template/network-template.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "post-preview",
  templateUrl: "./post-preview.component.html",
  styleUrls: ["./post-preview.component.css"],
})
export class PostPreviewComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  posts = [];
  post = {};
  image = "";
  content_type;
  comments = [];
  likedUsers = [];
  offset = 0;
  is_post = false;
  is_group: boolean;
  friendListOffset = 0;
  likesOffset = 0;
  tagList = [];
  search = 0;
  searchFlag = 0;
  mainPage = false;
  groupPage = false;
  profilePage = false;
  postInfoPage = false;
  postCopyLinkPage = false;
  groupProfilePage = false;
  pageView = false;
  viewPost = false;
  searchBar = false;
  commentOffset = 0;
  modalOpen = false;
  createNewPost = false;
  total_posts: number = 0;
  totalPosts: number;
  userMenuButton: HTMLElement;
  userMenuSlider: HTMLElement;
  personButton: HTMLElement;
  personMenuSlider: HTMLElement;
  groupMenuButton: HTMLElement;
  groupMenuSlider: HTMLElement;
  pageMenuButton: HTMLElement;
  pageMenuSlider: HTMLElement;
  searchCountry: any = "";
  searchKeyword: any = "";
  searchZip: any = "";
  editingPost = false;
  posts_length: any = 0;
  is_admin: any;
  is_deleted: any = false;
  isProfileUpdated: boolean;
  likedUsersLength: any;
  commentsLength: number;
  myFile: any;
  isModalOpen: boolean;
  observer: any;
  commentObserver: any;
  countryChanged: boolean = false;
  loadPage: number = 0;
  fetchPostSubscriber: any;
  loadMoreSubscriber: any;
  likeModalSubscriber: any;
  modalOpenSubscriber: any;
  modalClosedSubscriber: any;
  postType: any;
  description: any;
  newPost: any;
  groupId: number;
  pageData: any = {
    is_like: 0,
    page_type: 0,
  };
  is_friend: any;
  eventSubscription: any;
  targetElement: any;
  pageId: number;
  editValue: boolean;
  index: number;

  @ViewChild(PostTemplateComponent)
  postTemplateComponent: PostTemplateComponent;
  @ViewChild(NetworkTemplateComponent)
  networkTemplateComponent: NetworkTemplateComponent;
  scrollHandler: () => void;
	personType: any;
	totalLikedUsers: any;
	totalUsers: any;
	users: any;
	personId: any;
	postId: string;
	self: any;

  constructor(
    public globals: Globals,
    public serviceFunction: GlobalFunctions,
    private toaster: ToastrService,
    private postHelper: PostService,
    public emojiService: EmojiService,
    public tagService: TagService,
    private router: Router,
    private dataHelper: DataService,
    private cdRef: ChangeDetectorRef
  ) {
    const targetElement = document.querySelector("#body");
    let parentScope = this;

    //Scroll function
    this.scrollHandler = function () {
      if (!parentScope.isModalOpen) {
        if (location.pathname == "/home") {
          if (
            !parentScope.globals.onLoading &&
            $(window).scrollTop() >
              $(document).height() -
                $(window).height() -
                $("footer").height() -
                100
          ) {
            if (parentScope.total_posts > parentScope.totalPosts) {
              parentScope.globals.onLoading = true;
              parentScope.offset = parentScope.totalPosts;
              if (parentScope.search == 0) {
                parentScope.fetchPosts(0, 0, true);
              } else if (parentScope.search == 1) {
                parentScope.fetchPosts(0, 1, true);
              }
            } else {
              parentScope.search = parentScope.search;
            }
          }
        } else if (location.pathname == "/profile") {
          if (
            !parentScope.globals.onLoading &&
            $(window).scrollTop() >
              $(document).height() -
                $(window).height() -
                $("footer").height() -
                100
          ) {
            if (parentScope.total_posts > parentScope.totalPosts) {
              parentScope.globals.onLoading = true;

              parentScope.offset = parentScope.totalPosts;
              parentScope.fetchPosts(1, 0, true);
            }
          }
        } else if (location.pathname.split("/")[1] == "userInfo") {
          if (
            !parentScope.globals.onLoading &&
            $(window).scrollTop() >
              $(document).height() -
                $(window).height() -
                $("footer").height() -
                100
          ) {
            if (parentScope.total_posts > parentScope.totalPosts) {
              parentScope.globals.onLoading = true;

              parentScope.offset = parentScope.totalPosts;
              parentScope.fetchUserPosts(true);
            }
          }
        } else if (location.pathname.split("/")[1] == "groupInfo") {
          if (
            !parentScope.globals.onLoading &&
            $(window).scrollTop() >
              $(document).height() -
                $(window).height() -
                $("footer").height() -
                100
          ) {
            if (parentScope.total_posts > parentScope.totalPosts) {
              parentScope.globals.onLoading = true;

              parentScope.offset = parentScope.totalPosts;
              parentScope.getGroupPosts(true);
            }
          }
        } else if (location.pathname.split("/")[1] == "pageInfo") {
          if (
            !parentScope.globals.onLoading &&
            $(window).scrollTop() >
              $(document).height() -
                $(window).height() -
                $("footer").height() -
                100
          ) {
            if (parentScope.total_posts > parentScope.totalPosts) {
              // parentScope.globals.onLoading = true;
              parentScope.globals.pageLoad = false;
              parentScope.offset = parentScope.totalPosts;
              parentScope.fetchPagePosts(true);
            }
          }
        }
      }
    };

    //Post Auto-Load on Scroll Function
    document.addEventListener("scroll", parentScope.scrollHandler, false);

    this.observer = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadPage += 1;
        if (this.loadPage != 1) this.init();
      }
    });
  }

  ngOnInit() {
    this.init();
    this.totalPosts = this.posts.length;
    this.likeModalSubscriber = this.serviceFunction.on(
      "viewLikesModal",
      (data: any) => {
        this.likesOffset = data.likesOffset;
        this.likedUsersLength = data.likedUsersLength;
        this.viewLikes(data.post, data.option);
      }
    );
    this.commentObserver = this.serviceFunction.on(
      "getPostComments",
      (data: any) => {
        this.getPostComments(data.post, data.index);
      }
    );
    this.fetchPostSubscriber = this.serviceFunction.on(
      "fetchPost",
      (data: any) => {
        this.isModalOpen = true;
        this.searchCountry = data.searchCountry;
        this.searchKeyword = data.searchKeyword;
        this.searchZip = data.searchZip;
        this.search = data.search;
        this.fetchPosts(0, this.search);
        if (this.globals.mobileView)
          this.serviceFunction.getDismissReason("Close");
      }
    );
    this.loadMoreSubscriber = this.serviceFunction.on(
      "loadMoreComments",
      (data: any) => {
        this.loadMoreComments(data);
      }
    );
    this.modalOpenSubscriber = this.serviceFunction.on(
      "modalOpen",
      (data: any) => {
        this.isModalOpen = true;
        let scrollTop = document.documentElement.scrollTop;
        let scrollLeft = document.documentElement.scrollLeft;

        // if any scroll is attempted, set this to the previous value
        window.onscroll = function () {
          window.scrollTo(scrollLeft, scrollTop);
        };
      }
    );
    this.modalClosedSubscriber = this.serviceFunction.on(
      "modalClosed",
      (data: any) => {
        this.isModalOpen = false;
        window.onscroll = function () {};
      }
    );
  }

  ngAfterViewChecked() {
    let parentScope = this;
    (<any>$("#country")).countrySelect();

    $("#country").on("change", function () {
      parentScope.countryChanged = true;
    });

    this.globals.userMenuButton = document.getElementById("userMenuButton");
    this.globals.userMenuSlider = document.getElementById("cbp-spmenu-s4");

    this.globals.personButton = document.getElementById("personButton");
    this.globals.personMenuSlider = document.getElementById("cbp-spmenu-s6");

    this.globals.groupMenuButton = document.getElementById("groupMenuButton");
    this.globals.groupMenuSlider = document.getElementById("cbp-spmenu-s7");

    this.globals.pageMenuButton = document.getElementById("pageMenuButton");
    this.globals.pageMenuSlider = document.getElementById("cbp-spmenu-s8");

    this.is_admin = this.dataHelper.is_admin;
    this.is_deleted = this.dataHelper.is_deleted;
    this.pageData.is_like = this.dataHelper.pageData.is_like;
    this.pageData.page_type = this.dataHelper.pageData.page_type;
    this.isProfileUpdated = this.dataHelper.isProfileUpdated;

    if (this.isProfileUpdated) {
      this.init();
      this.dataHelper.isProfileUpdated = this.isProfileUpdated = false;
    }

    if (
      this.dataHelper.isPageEdited &&
      location.pathname.split("/")[1] == "pageInfo"
    ) {
      this.posts = [];
      this.fetchPagePosts();
      this.dataHelper.isPageEdited = false;
    }

    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.observer.unsubscribe();
    this.commentObserver.unsubscribe();
    this.fetchPostSubscriber.unsubscribe();
    this.likeModalSubscriber.unsubscribe();
    this.modalOpenSubscriber.unsubscribe();
    this.modalClosedSubscriber.unsubscribe();
    this.loadMoreSubscriber.unsubscribe();
    this.posts = [];
    this.totalPosts = this.posts.length;
    document.removeEventListener("scroll", this.scrollHandler, false);
  }

	//Reset functionality
	public clear() {
		this.search = 0;
		this.myFile = undefined;
		this.searchBar = false;
		this.createNewPost = false;
		this.globals.onLoading = false;
		this.globals.pageLoad = false;
		this.globals.invalidInput = false;
		this.globals.invalidInputValue = "";
		this.searchCountry = this.postTemplateComponent.searchCountry = "";
		this.searchKeyword = this.postTemplateComponent.searchKeyword = "";
		this.searchZip = this.postTemplateComponent.searchZip = "";
		this.router.navigate([location.pathname]);
	}

	//Function to manage sharing content on social media
	public socialMediaShare(value, type, socialType, option = 1) {
		let copyValue = environment.LINK;
		if (type == 1) {
		copyValue =
			copyValue +
			"post/" +
			value +
			"/0/" +
			this.globals.user.user_id +
			"/" +
			this.globals.user.qudos_user_type;
		}
		if (type == 2) {
		if (this.personType) {
			copyValue = copyValue + "driver/" + value;
		} else {
			copyValue = copyValue + "rider/" + value;
		}
		} else if (type == 3) {
		copyValue = copyValue + "group/" + value;
		} else if (type == 4) {
		copyValue = copyValue + "page/" + value;
		}

		if (this.globals.mobileView) {
		$("#body").removeClass("custom-backdrop");
		if (type == 1) {
			this.serviceFunction.closeOptions(1);
		} else if (type == 2) {
			this.serviceFunction.closeOptions(2);
		} else if (type == 3) {
			this.serviceFunction.closeOptions(3);
		} else if (type == 4) {
			if (option == 1) {
			this.serviceFunction.closeOptions(4);
			}
		}
		}
		//1= facebook ,2 = linkedin
		if (socialType == 1)
		window.open(
			"https://www.facebook.com/sharer/sharer.php?u=" +
			encodeURIComponent(copyValue) +
			"&t=" +
			document.title,
			"_self"
		);
		else if (socialType == 2)
		window.open(
			"https://www.linkedin.com/sharing/share-offsite/?url=" + copyValue,
			"_self"
		);
	}

	//Image select functionality
	public fileName(data) {
		this.myFile = data[0];
		let file_name = $("#file").val();
		let video_name = $("#file2").val();

		let file_type = (<string>$("#file").val()).slice(
		(<string>$("#file").val()).lastIndexOf(".")
		);
		if (
		file_type.toLowerCase() != ".jpg" &&
		file_type.toLowerCase() != ".jpeg" &&
		file_type.toLowerCase() != ".png"
		) {
		this.toaster.error("Image can be jpg,png only", "ERROR");
		this.myFile = undefined;
		} else {
		this.createNewPost = true;
		file_name = (<string>$("#file").val()).split("\\")[2];

		document.getElementById("imageName").innerHTML = file_name.slice(0, 20);
		document.getElementById("imageName").style.color = "black";
		if (video_name) {
			document.getElementById("videoName").innerHTML = "";
		}
		}
	}

	//Video select functionality
	public videoName(data) {
		this.myFile = data[0];
		let file_name = $("#file").val();
		let video_name = $("#file2").val();
		let file_type = (<string>$("#file2").val()).slice(
		(<string>$("#file2").val()).lastIndexOf(".")
		);
		if (file_type.toLowerCase() != ".mp4") {
		this.toaster.error("Video can be mp4 only", "ERROR");
		this.myFile = undefined;
		} else {
		this.createNewPost = true;
		video_name = (<string>$("#file2").val()).split("\\")[2];
		document.getElementById("videoName").innerHTML = video_name.slice(0, 20);
		document.getElementById("videoName").style.color = "black";
		if (file_name) {
			document.getElementById("imageName").innerHTML = "";
		}
		}
	}

	//Copy link functionality
	public linkCopy(value, type) {
		bodyScrollLock.disableBodyScroll(this.targetElement);
		let copyValue = environment.LINK;
		if (type == 1) {
		copyValue =
			copyValue +
			"post/" +
			value +
			"/0/" +
			this.globals.user.user_id +
			"/" +
			this.globals.user.qudos_user_type;
		}
		if (type == 2) {
		if (this.personType) {
			copyValue = copyValue + "driver/" + value;
		} else {
			copyValue = copyValue + "rider/" + value;
		}
		} else if (type == 3) {
		copyValue = copyValue + "group/" + value;
		} else if (type == 4) {
		copyValue = copyValue + "page/" + value;
		}
		this.postHelper.copyLink(copyValue, type).subscribe(
		(result) => {
			bodyScrollLock.clearAllBodyScrollLocks();
			this.toaster.info("Link Copied", "INFORMATION");
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	}

	//Load more comments
	public loadMoreComments(event) {
		let index = event.target.id.split("_")[1];
		let post = event.target.id.split("_")[2];

		let param = {
		post_id: post,
		};
		if (
		!this.globals.onLoading &&
		this.posts[index].totalComments > this.posts[index].commentsLength
		) {
		this.globals.onLoading = true;
		this.commentOffset = this.posts[index].commentsLength;
		let data = this;
		data.posts = this.posts;
		this.postHelper.getPostComments(param, index, data, true).subscribe(
			(result) => {
			this.globals.onLoading = false;
			},
			(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			}
		);
		}
	}
	//////////////////////////////////// Create Posts///////////////////////////////////////////////////

	//Create New Post Mobile Modal Function
	public launchMobilePost(type) {
		if (type) {
			$("#imageName").click();
		}
		let modalOptions = { windowClass: "app-modal-window", backdropClass: "modal-backdrop-custom", keyboard: false, backdrop: "static", size: "lg",};
		this.postTemplateComponent.myFile = this.myFile;
		this.postTemplateComponent.image = this.image;
		this.postTemplateComponent.content_type = this.content_type;
		this.postTemplateComponent.scopeData = this;
		this.postTemplateComponent.description = "";

		if (this.globals.mobileView) {
			this.serviceFunction.openModal(
				this.postTemplateComponent.mobilePost,
				modalOptions
			);
		}
	};

	// Create new Post
	public newPostInfo() {
		this.globals.onLoading = true;
		this.newPost = true;
		let type;
		let data = this;
		data.myFile = this.myFile;
		data.image = this.image;
		data.content_type = this.content_type;
		if (this.globals.mobileView) {
		if (location.pathname.split("/")[1] == "groupInfo") {
			type = 2;
			this.launchMobilePost(0);
			this.globals.onLoading = false;
			return;
		} else if (location.pathname.split("/")[1] == "pageInfo") {
			type = 3;
			this.launchMobilePost(0);
			this.globals.onLoading = false;
			return;
		} else {
			type = 1;
		}
		} else {
		if (location.pathname.split("/")[1] == "groupInfo") {
			type = 2;
		} else if (location.pathname.split("/")[1] == "pageInfo") {
			type = 3;
		} else {
			type = 1;
		}
		}
		this.postHelper.newPostInfo(data, type).subscribe(
		(result) => {
			// location.reload();
			if (
			window.location.href.includes("groupInfo") ||
			window.location.href.includes("pageInfo")
			) {
			location.reload();
			} else {
			this.router.navigate(["/home"]);
			}
			this.globals.onLoading = false;
			this.newPost = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.newPost = false;
		}
		);
	};

  	//////////////////////////////////// Get Posts///////////////////////////////////////////////////

	//Fetch logged in users posts
	public fetchPosts(timelineVar, searchvalue = 0, loadMore = false) {
		this.isModalOpen = true;
		this.search = searchvalue;
		if (loadMore) {
			this.globals.onLoading = true;
		} else {
		if (!this.globals.invalidInput) this.globals.pageLoad = true;
		}

		this.description = "";

		if (!this.globals.mobileView) {
			if (this.countryChanged && !loadMore) {
				this.searchCountry = (<any>$("#country")).countrySelect(
				"getSelectedCountryData"
				)["name"];
			} else if (!this.countryChanged) {
				this.searchCountry = "";
			}
		} else {
			this.searchCountry = this.searchCountry;
		}
		if (this.globals.invalidInput) {
		this.globals.pageLoad = false;
		this.searchZip = this.searchZip
			? this.searchZip.replace(this.globals.invalidInputValue, "")
			: "";
		this.searchCountry = this.searchCountry
			? this.searchCountry.replace(this.globals.invalidInputValue, "")
			: "";
		this.searchKeyword = this.searchKeyword
			? this.searchKeyword.replace(this.globals.invalidInputValue, "")
			: "";
		}
		if (
		this.search &&
		(typeof this.searchKeyword == "undefined" ||
			this.searchKeyword.length == 0) &&
		(typeof this.searchCountry == "undefined" ||
			this.searchCountry.length == 0) &&
		(typeof this.searchZip == "undefined" || this.searchZip.length == 0)
		) {
		this.toaster.error("Please enter a search value", "ERROR");
		this.searchBar = false;
		this.search = 0;
		let param = {
			searchBar: this.searchBar,
			searchCountry: "",
			searchKeyword: "",
			searchZip: "",
		};
		this.serviceFunction.emit(new EventData("fetchResult", param));
		this.globals.invalidInput = false;
		this.globals.invalidInputValue = "";
		this.globals.pageLoad = false;
		this.globals.onLoading = false;
		} else {
		let data = this;
		data.offset = this.offset;
		data.search = this.search;
		data.posts = this.posts;
		data.searchCountry = this.searchCountry
			? this.searchCountry.split("(")[0].trim()
			: "";

		this.postHelper.getPosts(timelineVar, data, loadMore).subscribe(
			(result) => {
			this.postTemplateComponent.posts = this.posts = result.posts;
			this.postTemplateComponent.total_posts = this.total_posts =
				result.total_posts;
			this.postTemplateComponent.posts_length = this.posts_length =
				result.posts_length;
			this.postTemplateComponent.totalPosts = this.totalPosts =
				result.totalPosts;
			this.isModalOpen = false;
			if (searchvalue == 1) {
				this.searchBar = true;
				let param = {
				searchBar: this.searchBar,
				searchCountry: this.searchCountry,
				searchKeyword: this.searchKeyword,
				searchZip: this.searchZip,
				};
				this.serviceFunction.emit(new EventData("fetchResult", param));
			}
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
			},
			(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
			}
		);
		}
	};

	//Fetch other users posts
	public fetchUserPosts(loadMore = false) {
		this.isModalOpen = true;
		this.personId = location.pathname.split("/")[2];
		this.personType = parseInt(location.pathname.split("/")[3]);
		if (loadMore) {
		this.globals.onLoading = true;
		} else {
		this.globals.pageLoad = true;
		}

		let data = this;
		data.offset = this.offset;
		data.posts = this.posts;

		this.postHelper.getUserPosts(data).subscribe(
		(result) => {
			this.postTemplateComponent.posts = this.posts = result.posts;
			this.postTemplateComponent.total_posts = this.total_posts =
			result.total_posts;
			this.postTemplateComponent.posts_length = this.posts_length =
			result.posts_length;
			this.postTemplateComponent.totalPosts = this.totalPosts =
			result.totalPosts;
			this.isModalOpen = false;

			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		}
		);
	};

	// To Fetch group Posts
	public getGroupPosts(loadMore = false) {
		this.isModalOpen = true;
		this.groupId = parseInt(location.pathname.split("/")[2]);
		if (loadMore) {
		this.globals.onLoading = true;
		} else {
		this.globals.pageLoad = true;
		}

		let data = this;
		data.offset = this.offset;
		data.groupId = this.groupId;
		data.posts = this.posts;

		this.postHelper.getGroupPosts(data).subscribe(
		(result) => {
			this.postTemplateComponent.posts = this.posts = result.posts;
			this.postTemplateComponent.total_posts = this.total_posts =
			result.total_posts;
			this.postTemplateComponent.posts_length = this.posts_length =
			result.posts_length;
			this.postTemplateComponent.totalPosts = this.totalPosts =
			result.totalPosts;
			this.isModalOpen = false;

			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		}
		);
	};

	//Fetch post by id
	public getPostInfo(type) {
		// this.globals.onLoading = true;
		this.globals.pageLoad = true;

		this.postId = location.pathname.split("/")[2];
		this.postType = type == 1 ? parseInt(location.pathname.split("/")[3]) : "";
		this.personId = type == 1 ? parseInt(location.pathname.split("/")[4]) : "";
		this.personType =
		type == 1 ? parseInt(location.pathname.split("/")[5]) : "";
		let data = this;
		data.postId = this.postId;
		data.viewPost = this.viewPost;

		this.postHelper.getPostById(type, data).subscribe(
		(result) => {
			this.postTemplateComponent.posts =
			this.dataHelper.posts =
			this.posts =
				result.posts;
			this.postTemplateComponent.posts_length = this.posts_length =
			result.posts_length;
			this.self = result.self;
			this.is_friend = result.is_friend;
			this.viewPost = result.viewPost;
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		}
		);
	};

	//Filtering posts by search
	public filterPeople() {
		let modalOptions = {
		windowClass: "app-modal-window",
		backdropClass: "modal-backdrop-custom",
		keyboard: false,
		backdrop: "static",
		size: "md",
		};
		this.serviceFunction.openModal(
		this.postTemplateComponent.searchPeople,
		modalOptions
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data == "Close click") {
				this.clear();
			}
			this.eventSubscription.unsubscribe();
		}
		);
	};

	//To fetch posts of a specific page.
	public fetchPagePosts(loadMore = false) {
		this.isModalOpen = true;
		this.pageId = parseInt(location.pathname.split("/")[2]);
		if (loadMore) {
			this.globals.onLoading = true;
		} else {
			this.globals.pageLoad = true;
		}
		let data = this;
		data.offset = this.offset;
		data.pageId = this.pageId;
		data.posts = this.posts;

		this.postHelper.fetchPagePosts(data).subscribe(
		(result) => {
			this.postTemplateComponent.posts = this.posts = result.posts;
			this.postTemplateComponent.total_posts = this.total_posts =
			result.total_posts;
			this.postTemplateComponent.posts_length = this.posts_length =
			result.posts_length;
			this.postTemplateComponent.totalPosts = this.totalPosts =
			result.totalPosts;
			this.isModalOpen = false;

			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
			this.globals.pageLoad = false;
		}
		);
	};

	//Share Post Emoji Function
	public emojiDecoding(input) {
		if (input.includes("<a")) {
			return input;
		} else {
			var decodeCode = this.serviceFunction.decodeURIEmoji(input);
			decodeCode = decodeCode.toString();
			if (decodeCode.includes("http")) {
				let urlStringList = decodeCode.split("http");
				if (urlStringList.length > 2) {
					for (let i = 1; i < urlStringList.length; i++) {
						let urlString = urlStringList[i].split(" ")[0];
						urlString = "http" + urlString;
						let url: string =
						"<a href='" + urlString + "'>" + urlString + "</a>";
						url = url.replace(/&#x2F;/g, "/");
						decodeCode = decodeCode.replace(urlString, url);
					}
				} else {
					let urlString = urlStringList[1].split(" ")[0];
					urlString = "http" + urlString;
					let url: string = "<a href='" + urlString + "'>" + urlString + "</a>";
					decodeCode = decodeCode.replace(urlString, url);
				}
			}
		}
		return decodeCode;
	};

  	//////////////////////////////////////////////// Share Posts ///////////////////////////////////////////////////
	//Share Post Modal Function
	public sharePost(post) {
		this.postTemplateComponent.post = post;
		this.postTemplateComponent.posts = this.posts;
		this.postTemplateComponent.share_post_description = post.description;
		this.postTemplateComponent.groupPage = this.groupPage;
		this.postTemplateComponent.describe = "";
		this.postTemplateComponent.groupId = this.groupId;
		this.postTemplateComponent.pageId = this.pageId;
		this.postTemplateComponent.pageView = this.pageView;
		let modalOptions = {
			windowClass: "app-modal-window",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
			size: "md",
		};
		this.serviceFunction.openModal(
			this.postTemplateComponent.sharePost,
			modalOptions
		);
	};

  	///////////////////////////////////// Like Post ////////////////////////////////////////////////////////

	// Like/Unlike Post
	public likePost(index, post, status) {
		this.globals.onLoading = true;
		let data = this;
		this.postHelper.likePost(index, post, status, data).subscribe(
			(result) => {
				this.globals.onLoading = false;
			},
			(err) => {
				this.toaster.error(err, "ERROR");
				this.globals.onLoading = false;
			}
		);
	};

	//View the likes details of a particular post
	public viewLikes(post, loadMore = false) {
		let data = this;
		data.likesOffset = loadMore ? this.likesOffset : 0;
		this.globals.onLoading = true;
		this.postHelper.viewLikes(data, post, loadMore).subscribe(
		(result) => {
			this.postTemplateComponent.users = this.users = result.users;
			this.postTemplateComponent.totalUsers = this.totalUsers =
			result.totalUsers;
			this.postTemplateComponent.totalLikedUsers = this.totalLikedUsers =
			result.totalLikedUsers;
			this.postTemplateComponent.likedUsersLength = this.likedUsersLength =
			result.likedUsersLength;
			this.postTemplateComponent.globals.onLoading = this.globals.onLoading;
			this.likedUsers = result.likedUsers;
			if (!loadMore && !this.serviceFunction.modalOpened) {
				let modalOptions = {
					windowClass: "app-modal-window",
					backdropClass: "modal-backdrop-custom",
					keyboard: false,
					backdrop: "static",
					size: "md",
				};
				this.postTemplateComponent.post = post;
				this.serviceFunction.openModal(
					this.postTemplateComponent.viewLikesModal,
					modalOptions,
					true
				);
				this.eventSubscription = this.serviceFunction.on(
					"modalClosed",
					(data: any) => {
						this.likedUsers = [];
						this.serviceFunction.modalOpened = false;
						this.eventSubscription.unsubscribe();
					}
				);
			}
			this.globals.onLoading = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	/////////////////////////////////////////////////// Comment Post //////////////////////////////////////////////////////
	//Create comment
	public commentDesktop(post, index) {
		this.globals.onLoading = true;
		let data = this;
		this.postHelper.commentDesktop(post, index, data).subscribe(
			(result) => {
				this.posts[index] = result.posts[index];
				data.posts = this.posts;
				this.getPostComments(post, index);
				this.globals.onLoading = false;
			},
			(err) => {
				this.toaster.error(err, "ERROR");
				this.globals.onLoading = false;
			}
		);
	};

	//load comments of a particular post
	public commentPost(post, index) {
		this.postTemplateComponent.post = post;
		this.postTemplateComponent.post.comment_describe = "";
		this.postTemplateComponent.selectedIndex = index;
		this.postTemplateComponent.limit = 2;
		this.postTemplateComponent.posts = this.posts;
		let modalOptions = {
			windowClass: "app-modal-window",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		let data = this;
		data.posts = this.posts;
		this.postHelper.getPostComments(post, index, data).subscribe(
		(result) => {
			this.serviceFunction.openModal(
			this.postTemplateComponent.commentPost,
			modalOptions
			);
			this.globals.onLoading = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	//Fetch Existing Comments on Post
	public getPostComments(post, index, type = 1) {
		this.globals.onLoading = true;
		let data = this;
		if (type == 0) {
			this.posts[index].comments = [];
			this.commentOffset = 0;
		}
		data.commentOffset = this.commentOffset;
		data.posts = this.posts;
		data.index = index;
		data.posts[index].comments = this.posts[index].comments;
		this.postHelper.getPostComments(post, index, data, false).subscribe(
		(result) => {
			this.globals.onLoading = false;
		},
		(err) => {
			this.toaster.error(err, "ERROR");
			this.globals.onLoading = false;
		}
		);
	};

	/////////////////////////////////////////////////// Delete Post //////////////////////////////////////////////////////
	//Delete post
	public deletePost(post, index) {
		this.postTemplateComponent.is_post = true;
		this.postTemplateComponent.postId = post.post_id;
		this.postTemplateComponent.selectedIndex = index;
		this.postTemplateComponent.postType = this.postType;
		this.postTemplateComponent.post = post;
		this.postTemplateComponent.posts = this.posts;
		let modalOptions = {
			windowClass: "app-modal-window-custom",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
			this.postTemplateComponent.deletePost,
			modalOptions
		);

		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
		(data: any) => {
			if (data != "Close click") {
				this.posts = this.postTemplateComponent.posts;
				this.total_posts = this.postTemplateComponent.total_posts;
				this.totalPosts = this.postTemplateComponent.totalPosts;
			}
			this.eventSubscription.unsubscribe();
		}
		);
	};
	/////////////////////////////////////////////////// Edit Post //////////////////////////////////////////////////////

	//Edit Post Modal Function
	public editPost(post, index) {
		this.postTemplateComponent.editValue = this.editValue = false;
		this.postTemplateComponent.tempPost = { ...post };
		this.postTemplateComponent.selectedIndex = index;
		this.postTemplateComponent.post = post;
		this.postTemplateComponent.posts = this.posts;
		this.postTemplateComponent.tagList = post.tagWhitelist;
		this.postTemplateComponent.editingPost = this.editingPost = true;
		this.postTemplateComponent.post.description = this.serviceFunction.deUrlify(
		this.serviceFunction.htmlDecode(
			this.postTemplateComponent.post.description
		)
		);
		let modalOptions = {
			windowClass: "app-modal-window",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
			this.postTemplateComponent.editPost,
			modalOptions
		);
		this.eventSubscription = this.serviceFunction.on(
		"modalClosed",
			(data: any) => {
				if (data != "Close click") {
				this.postTemplateComponent.editingPost = this.editingPost = false;
				}
				this.eventSubscription.unsubscribe();
			}
		);
	};

	/////////////////////////////////////////////////// Edit Privacy //////////////////////////////////////////////////////
	//Edit Post Modal Function
	public editPrivacy(post, index, option) {
		this.postTemplateComponent.post = post;
		this.postTemplateComponent.posts = this.posts;
		this.postTemplateComponent.selectedIndex = index;
		this.postTemplateComponent.option = option;
		this.postTemplateComponent.tempPost = { ...post };
		let modalOptions = {
			windowClass: "app-modal-window custom-editprivcy",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
			this.postTemplateComponent.editPrivacy,
			modalOptions
		);
	};

	///////////////////////////////////////////// SHARE GROUP/PAGE ////////////////////////////////////////
	// Modal to share details of a group
	public shareGroupModal(group) {
		this.networkTemplateComponent.describe = "";
		this.networkTemplateComponent.group = group;
		this.networkTemplateComponent.groupId = this.groupId;
		let modalOptions = {
			windowClass: "app-modal-window",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
			this.networkTemplateComponent.shareGroupModal,
			modalOptions
		);
	};

	// Modal to share details of a page
	public sharePageModal(page) {
		this.networkTemplateComponent.describe = "";
		this.networkTemplateComponent.page = page;
		this.networkTemplateComponent.pageId = page.page_id;
		let modalOptions = {
			windowClass: "app-modal-window",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
		this.networkTemplateComponent.sharePageModal,
		modalOptions
		);
	};

  /////////////////////////////////////////////////// Delete Group/Page //////////////////////////////////////////////////////

	//Delete group
	public deleteGroupModal() {
		this.networkTemplateComponent.is_post = false;
		this.networkTemplateComponent.is_group = true;
		this.networkTemplateComponent.groupId = this.groupId;
		let modalOptions = {
			windowClass: "app-modal-window-custom",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
		this.networkTemplateComponent.deletePost,
		modalOptions
		);
	};

	//delete page
	public deletePageModal() {
		this.is_post = false;
		this.is_group = false;
		this.networkTemplateComponent.is_post = false;
		this.networkTemplateComponent.is_group = false;
		this.networkTemplateComponent.pageId = this.pageId;
		let modalOptions = {
			windowClass: "app-modal-window-custom",
			size: "md",
			backdropClass: "modal-backdrop-custom",
			keyboard: false,
			backdrop: "static",
		};
		this.serviceFunction.openModal(
		this.networkTemplateComponent.deletePost,
		modalOptions
		);
	};

	//initial function
	public init() {
		this.posts = [];
		this.offset = 0;
		this.myFile = undefined;
		this.searchBar = false;
		this.createNewPost = false;
		this.globals.description = "";
		this.description = "";
		this.content_type = undefined;
		this.countryChanged = false;
		if (location.pathname == "/home") {
			this.fetchPosts(0);
			this.mainPage = true;
		} else if (location.pathname == "/profile") {
			this.fetchPosts(1);
			this.profilePage = true;
		} else if (location.pathname.split("/")[1] == "groupInfo") {
			this.groupPage = true;
			this.getGroupPosts();
		} else if (location.pathname.split("/")[1] == "userInfo") {
			this.fetchUserPosts();
		} else if (location.pathname.split("/")[1] == "postInfo") {
			this.getPostInfo(0);
			this.postInfoPage = true;
		} else if (location.pathname.split("/")[1] == "post") {
			this.getPostInfo(1);
			this.postCopyLinkPage = true;
		} else if (location.pathname.split("/")[1] == "groupProfile") {
			this.groupProfilePage = true;
		} else if (location.pathname.split("/")[1] == "pageInfo") {
			this.pageView = true;
			this.fetchPagePosts();
		}
	};

	public callTAgService(data, index, value, commentId) {
		if (value !== null && value !== "" && value !== undefined) {
		this.tagService.createTag(data, index, false, true);
		let commentText = document.getElementById(commentId);
		commentText.focus();
		}
	}
}
