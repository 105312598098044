import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { GlobalFunctions } from 'src/app/helpers/service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/ViewServices/dataService/data.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit, AfterViewChecked {
  postId: any;
  postType: number;
  personId: any;
  personType: any;
  post = {};
  posts: any[];

  constructor(public globals: Globals, public serviceFunction: GlobalFunctions, private route: ActivatedRoute, private router: Router, private cdrf: ChangeDetectorRef, private dataHelper: DataService) {
    this.route.params.subscribe(params => {
      this.postId = params.postId;
      this.postType = parseInt(params.type);
      this.personId = params.personId;
      this.personType = params.personType;
    })
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.posts = this.dataHelper.posts;
    this.cdrf.detectChanges();
  }
  image = '';
  content_type;
  offset = 0;
  is_post = false;
  viewPost = false;

  //Back button functionality
  public goBack() {
    if (this.globals.oldPath == location.pathname) {
      this.router.navigate(['/home']);
    }
    else {
      window.location = this.globals.oldPath;
    }
  }
}
