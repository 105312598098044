import { Injectable, OnInit } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { Globals } from 'src/app/helpers/constant';
import { GlobalFunctions } from 'src/app/helpers/service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnInit {
  socket: any;
  user_type: any;
  socketAuth: number = 0;

  constructor(public globals: Globals, private serviceFunction: GlobalFunctions) {
  }
  ngOnInit() {
    //Manage disconnect functionality - remove all listeners
    this.socket.on('disconnect', function (data) {
      this.socket.removeAllListeners('sendMessage');
      this.socket.removeAllListeners('receiveMessage');
      this.socket.removeAllListeners('messageHistory');
      this.socket.removeAllListeners('readMessages');
      this.socket.removeAllListeners('totalUnreadCount');
      this.socket.removeAllListeners('chatRooms');
      this.socket.removeAllListeners('subscribeRooms');
      this.socket.removeAllListeners('joinRoom');
    })
  }

  init() {
    //this.socket = io.connect(environment.SOCKET_ENDPOINT);
    // this.socket = io.connect("https://connect.qudos.dev");
    this.socket = io.connect("http://18.211.29.221:8000");
  }

  public getSocket() {
    return this.socket;
  }

  //Connecting to a socket connection
  public connect() {
    return Observable.create((observer) => {
      this.socket.on('connect', function (data) {
        observer.next(data);
        return data;
      });
    });
  }

  //authorizing the socket connection
  public auth(socketParams) {
    this.socket.emit('auth', socketParams);
    return Observable.create((observer) => {
      this.socket.on('auth', function (data) {
        observer.next(data);
        return data;
      });
    });
  }

  //To manage notifications events
  public fetchNotification() {
    this.user_type = this.globals.user.qudos_user_type;
    this.socket.emit('qudoslistNotification', { qudos_user_type: this.user_type, limit: 10, offset: this.globals.notifyOffset });
    return Observable.create((observer) => {
      this.socket.on('qudoslistNotification', function (data) {
        observer.next(data);
        return data;
      });
    });
  }

  //Manage fetching of chat rooms in messaging module
  public fetchChatRooms() {
    this.socket.emit('chatRooms', { qudos_user_id: this.globals.user.user_id, qudos_user_type: this.globals.user.qudos_user_type });
    return Observable.create((observer) => {
      this.socket.on('chatRooms', function (data) {
        observer.next(data);
        return data;
      });
    });
  }

  //Event to fetch unread count in chat module
  public fetchUnreadCount() {
    this.socket.emit('totalUnreadCount', {
      qudos_user_id: this.globals.user.user_id,
      qudos_user_type: this.globals.user.qudos_user_type
    })
    setInterval(() => {
      this.socket.emit('totalUnreadCount', {
        qudos_user_id: this.globals.user.user_id,
        qudos_user_type: this.globals.user.qudos_user_type
      })
    }, 30000);
    return Observable.create((observer) => {
      this.socket.on('totalUnreadCount', function (data) {
        observer.next(data);
        return data;
      });
    });
  }

  //Event to start a new chat with a friend or create group .
  public joinRooms(type, data) {
    if (type == 1) {
      this.socket.emit('joinRoom', {
        qudos_user_id: this.globals.user.user_id,
        qudos_user_type: this.globals.user.qudos_user_type,
        occupant_id: data.occupant_id,
        occupant_type: data.occupant_type,
        group_name: data.group_name
      });
    } else if (type == 2) {
      this.socket.emit('joinRoom', {
        qudos_user_id: this.globals.user.user_id,
        qudos_user_type: this.globals.user.qudos_user_type,
        occupant_id: data.occupant_id,
        occupant_type: data.occupant_type,
        group_name: data.occupant_type,
        member_count: data.member_count
      });
    } else if (type == 3) {
      this.socket.emit('joinRoom', {
        qudos_user_id: this.globals.user.user_id,
        qudos_user_type: this.globals.user.qudos_user_type,
        occupant_id: data.occupant_id,
        occupant_type: data.occupant_type,
      });
    }
    this.sendJoinedRoomSuccess();
  }

  //Event to send success message if a chat is created successfully.
  public sendJoinedRoomSuccess() {
    return Observable.create((observer) => {
      this.socket.on('joinRoom', function (data) {
        observer.next(data);
        return data;
      });
    });
  }

  //Event to fetch chat history with a particular chat
  public fetchMessageHistory(data) {
    let request = {
      qudos_user_id: this.globals.user.user_id,
      qudos_user_type: this.globals.user.qudos_user_type,
      room_id: data.room_id,
      receiver_id: data.receiver_id,
      receiver_type: data.receiver_type,
      limit: data.limit,
      skip: data.skip
    }
    this.socket.emit('messageHistory', request)
  }


  public receiveMessageHistory() {
    return Observable.create((observer) => {
      this.socket.on('messageHistory', function (data) {
        observer.next(data);
        return data;
      });
    });
  }

  //Event to send message 
  public sendMessage(data) {
    this.socket.emit('sendMessage', {
      qudos_user_id: this.globals.user.user_id,
      qudos_user_type: this.globals.user.qudos_user_type,
      qudos_user_name: this.globals.user.user_name,
      user_image: this.globals.user.user_image,
      occupant_id: data.occupant_id,
      occupant_type: data.occupant_type,
      message: data.message,
      message_type: data.message_type,
      room_id: data.room_id,
      room_type: data.room_type,
      content_url: data.content_url
    })
  }

  //Event to recieve send message success
  public getSendMessageSuccess() {
    return Observable.create((observer) => {
      this.socket.on('sendMessage', function (data) {
        observer.next(data);
        return data;
      });
    });
  }

  //Event to receive messages sent
  public receiveMessage() {
    return Observable.create((observer) => {
      this.socket.on('receiveMessage', function (data) {
        observer.next(data);
        return data;
      });
    });
  }

  //Event to subscribe to a chat
  public subscribeRooms(dialogList) {
    this.socket.emit('subscribeRooms', {
      rooms: dialogList
    });
    return Observable.create((observer) => {
      this.socket.on('subscribeRooms', function (data) {
        observer.next(data);
        return data;
      });
    });
  }

  //Event to read messages in a chat room
  public readMessages(data) {
    this.socket.emit('readMessages', {
      qudos_user_id: this.globals.user.user_id,
      qudos_user_type: this.globals.user.qudos_user_type,
      is_all: data.is_all,
      alert_id: data.alert_id
    })
    return Observable.create((observer) => {
      this.socket.on('readMessages', function (data) {
        observer.next(data);
        return data;
      });
    });
  }
}