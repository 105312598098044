import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { GlobalFunctions } from 'src/app/helpers/service';
import { Observable } from 'rxjs';
import { API } from 'src/app/helpers/constant';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

//Service to manage the signup functionalities
export class SignupService {

  constructor(private http: HttpClient, private toaster: ToastrService, private serviceFunction: GlobalFunctions) { }

  //To check details for duplicate enteries
  checkData(data): Observable<any> {
    return this.http.post<any>(API.SIGNUP_CHECK_DATA, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //fetch list of countries
  fetchCountryList(): Observable<any> {
    return this.http.get<any>(API.FETCH_COUNTRY_LIST).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //fetch list of states
  fetchStateList(data): Observable<any> {
    return this.http.post<any>(API.FETCH_STATE_LIST, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //Verify otp
  verifyOtp(data): Observable<any> {
    return this.http.post<any>(API.SIGNUP_VERIFY_OTP, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //resend otp
  resendOtp(data): Observable<any> {
    return this.http.post<any>(API.RESEND_OTP, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

  //register details of the user
  registerUser(data): Observable<any> {
    return this.http.post<any>(API.REGISTER_USER, data).pipe(
      map(response => {
        return response;
      }),
      catchError(this.serviceFunction.handleError)
    )
  }

}
