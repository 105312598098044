<div class="page network_page">
  <div *ngIf="globals.pageLoad">
    <div class="row h-100vh">
      <div class="col-12 col-lg-3 col-xl-2 page_sidebar">
        <div class="timeline-item mt-5">
          <div class="text-center">
            <div class="dummy_image d-block img-circle mb-4"></div>
          </div>
          <ul>
            <li class="dummy_content w-60"></li>
            <li class="dummy_content w-60"></li>
            <br />
            <li class="dummy_content w-60"></li>
            <li class="dummy_content w-70"></li>
            <br />
            <li class="hr"></li>
            <li class="dummy_content w-60"></li>
            <li class="dummy_content w-70"></li>
            <br />
            <li class="hr"></li>
            <li class="dummy_content w-60"></li>
            <li class="dummy_content w-70"></li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-8 page_content mx-auto md-full">
        <div class="d-mds-none">
          <div class="timeline-item centered mb-5 mbl-mb-0">
            <div class="dummy_content"></div>
          </div>
          <div class="timeline-item centered">
            <ul>
              <div class="dummy_content mob-mb-20"></div>
              <li>
                <div class="row mar-lr-15">
                  <div class="col-12 col-xs-6 col-lg-12 col-xl-6">
                    <div class="user flex-row dummy_animation"></div>
                  </div>
                  <div class="col-12 col-xs-6 col-lg-12 col-xl-6">
                    <div class="user flex-row dummy_animation"></div>
                  </div>
                </div>
              </li>
              <li>
                <div class="row mar-lr-15">
                  <div class="col-12 col-xs-6 col-lg-12 col-xl-6">
                    <div class="user flex-row dummy_animation">
                      <div class="user-info"></div>
                    </div>
                  </div>
                  <div class="col-12 col-xs-6 col-lg-12 col-xl-6">
                    <div class="user flex-row dummy_animation"></div>
                  </div>
                </div>
              </li>
              <li>
                <div class="row mar-lr-15">
                  <div class="col-12 col-xs-6 col-lg-12 col-xl-6">
                    <div class="user flex-row dummy_animation">
                      <div class="user-info"></div>
                    </div>
                  </div>
                  <div class="col-12 col-xs-6 col-lg-12 col-xl-6">
                    <div class="user flex-row dummy_animation"></div>
                  </div>
                </div>
              </li>
              <li>
                <div class="row mar-lr-15">
                  <div class="col-12 col-xs-6 col-lg-12 col-xl-6">
                    <div class="user flex-row dummy_animation">
                      <div class="user-info"></div>
                    </div>
                  </div>
                  <div class="col-12 col-xs-6 col-lg-12 col-xl-6">
                    <div class="user flex-row dummy_animation"></div>
                  </div>
                </div>
              </li>
              <li>
                <div class="row mar-lr-15">
                  <div class="col-12 col-xs-6 col-lg-12 col-xl-6">
                    <div class="user flex-row dummy_animation"></div>
                  </div>
                  <div class="col-12 col-xs-6 col-lg-12 col-xl-6">
                    <div class="user flex-row dummy_animation"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="d-mds-block dummy_network_user">
          <div class="dummy_content w-60 mb-4"></div>
          <div class="dummy_content w-70"></div>
          <ul class="dummy_msg_content">
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="d-flex">
                  <div class="dummy_image img-circle"></div>
                  <div class="dummy_wrapper">
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                    <div class="dummy_content"></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-lg-3 col-xl-2 page_sidebar">
        <div class="timeline-item">
          <ul class="w-100">
            <li>
              <div class="dummy_content w-100 mb-5"></div>
              <div class="dummy_content w-100"></div>
              <div class="dummy_content dummy_input"></div>
            </li>
            <li>
              <div class="dummy_content w-100"></div>
              <div class="dummy_content dummy_input"></div>
            </li>
            <li>
              <div class="dummy_content dummy_input"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!globals.pageLoad">
    <div class="col-12 col-lg-3 col-xl-2 page_sidebar">
      <div class="summary">
        <div class="summary__avatar">
          <img
            src="{{ globals.user?.user_image }}"
            alt=""
            onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
          />
        </div>
        <div
          class="summary__title"
          ngDefaultControl
          data-toggle="tooltip"
          data-placement="top"
          title="{{ globals.user.user_name }}"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="globals.user.user_name"
        >
          {{ globals.user.user_name }}
        </div>

        <a routerLink="/profile" class="summary__profile">My Profile</a>
        <div class="summary__stat">
          <span
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="globals.user.total_followers"
            >{{ globals.user.total_followers }}</span
          >
          <p>Followers</p>
        </div>

        <div class="summary__stat">
          <span
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="globals.user.total_posts"
            >{{ globals.user.total_posts }}</span
          >
          <p>Posts</p>
        </div>

        <div class="summary__stat">
          <span
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="globals.user.total_likes"
            >{{ globals.user.total_likes }}</span
          >
          <p>Likes</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 col-xl-8 page_content mobpad-0 full-md">
      <!-- Desktop filter search start -->
      <div class="hidden-mobile filter">
        <ul class="nav justify-content-start" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link py-0 px-0"
              data-toggle="tab"
              [ngClass]="{ active: tab == 1 }"
              [routerLink]="['/network/qudosFav']"
              ><button [ngClass]="{ active: tab == 1 }">
                QudosFave™ <span>Drivers</span>
              </button></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-0 px-0"
              data-toggle="tab"
              [ngClass]="{ active: tab == 2 }"
              [routerLink]="['/network']"
              ><button [ngClass]="{ active: tab == 2 }">People</button></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-0 px-0"
              data-toggle="tab"
              [ngClass]="{ active: tab == 3 }"
              [routerLink]="['/network/group']"
              ><button [ngClass]="{ active: tab == 3 }">Groups</button></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-0 px-0"
              data-toggle="tab"
              [ngClass]="{ active: tab == 4 }"
              [routerLink]="['/network/page']"
              ><button [ngClass]="{ active: tab == 4 }">Page</button></a
            >
          </li>
        </ul>
      </div>
      <!-- Desktop filter search end -->

      <!-- Mobile filter search start -->
      <span class="visible-mobile">
        <div class="filter px-0">
          <ul
            class="nav justify-content-center w-100 nav-mobiles nav_secondary"
            role="tablist"
          >
            <li class="nav-item text-center">
              <a
                class="nav-link py-0 w-100 h-100"
                data-toggle="tab"
                [ngClass]="{ active: tab == 1 }"
                [routerLink]="['/network/qudosFav']"
              >
                <button class="first-child-btn mx-0">
                  <span class="w-100">
                    <img src="./assets/img/myfave.png" class="fav-icon"
                  /></span>
                  QudosFave™ <span>Drivers</span>
                </button>
              </a>
            </li>
            <li class="nav-item text-center">
              <a
                class="nav-link py-0 w-100 h-100"
                data-toggle="tab"
                [ngClass]="{ active: tab == 2 }"
                [routerLink]="['/network']"
                ><button class="mx-0">
                  <i class="fas fa-user-friends w-100"></i>People
                </button></a
              >
            </li>
            <li class="nav-item text-center">
              <a
                class="nav-link py-0 w-100 h-100"
                data-toggle="tab"
                [ngClass]="{ active: tab == 3 }"
                [routerLink]="['/network/group']"
                ><button class="mx-0">
                  <i class="fas fa-users w-100"></i>Groups
                </button></a>
            </li>
            <li class="nav-item text-center">
              <a
                class="nav-link py-0 w-100 h-100"
                data-toggle="tab"
                [ngClass]="{ active: tab == 4 }"
                [routerLink]="['/network/page']"
                ><button class="mx-0">
                  <i class="fas fa-flag w-100"></i>Page
                </button></a
              >
            </li>
          </ul>
        </div>
      </span>
      <!-- Mobile filter search end -->

      <div class="page_panel results pt-0 mobpad-0 main_cntnt">
        <div class="results_content">
          <div class="row tab-content">
            <div
              id="qudosfave-network"
              class="tab-pane"
              [ngClass]="{ active: tab == 1, in: tab == 1 }"
            >
              <!-- Desktop navbar view start -->
              <span class="hidden-mobile">
                <ul class="ml-4 my-4 col nav">
                  <li class="nav-item">
                    <a
                      data-toggle="tab"
                      class="nav-link"
                      aria-controls="qudosfave-network--myqudos"
                      [ngClass]="{ active: innerTab == 1 }"
                      [routerLink]="['/network/qudosFav/myFav']"
                      >My Faves™</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      data-toggle="tab"
                      class="nav-link"
                      aria-controls="qudosfave-network--explore"
                      [routerLink]="['/network/qudosFav/explore']"
                      [ngClass]="{ active: innerTab == 2 }"
                      >Explore</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      data-toggle="tab"
                      class="nav-link"
                      aria-controls="qudosfave-network--pending"
                      [routerLink]="['/network/qudosFav/pending']"
                      [ngClass]="{ active: innerTab == 3 }"
                      >Pending</a
                    >
                  </li>
                  <li class="nav-item flex-grow-1">
                    <a
                      data-toggle="tab"
                      class="nav-link text-right"
                      *ngIf="searchBar"
                      (click)="clear()"
                      [ngClass]="{ active: searchBar }"
                      >Clear All</a
                    >
                  </li>
                </ul>
              </span>
              <!-- Desktop navbar view end -->
              <!-- Mobile navbar view start -->
              <span class="visible-mobile pb-2 pt-3">
                <ul class="col nav text-center justify-content-center px-4">
                  <li class="nav-item">
                    <a
                      data-toggle="tab"
                      class="nav-link h-100"
                      aria-controls="qudosfave-network--myqudos"
                      [ngClass]="{ active: innerTab == 1 }"
                      [routerLink]="['/network/qudosFav/myFav']"
                    >
                      <i class="fas fa-user w-100"></i>My Faves™
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      data-toggle="tab"
                      class="na w-100 h-100"
                      aria-controls="qudosfave-network--explore"
                      [ngClass]="{ active: innerTab == 2 }"
                      [routerLink]="['/network/qudosFav/explore']"
                    >
                      <i class="fas fa-compass w-100"></i>Explore
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      data-toggle="tab"
                      class="na w-100 h-100"
                      aria-controls="qudosfave-network--pending"
                      [ngClass]="{ active: innerTab == 3 }"
                      [routerLink]="['/network/qudosFav/pending']"
                    >
                      <i class="fas fa-bars w-100"></i>Pending
                    </a>
                  </li>
                </ul>
              </span>
              <!-- Mobile navbar view end -->

              <!-- Qudos searchbar start -->
              <div class="visible-mobile container">
                <div class="row">
                  <div class="col mobpad-0">
                    <section class="qudosfaves__searchbar">
                      <div class="form-group has-search">
                        <span
                          class="fal fa-search form-control-feedback"
                        ></span>
                        <input
                          type="text"
                          class="form-control"
                          name="search"
                          placeholder="Search"
                          [(ngModel)]="searchString"
                          (keyup.enter)="checkSearchString($event)"
                        />
                        <span class="form-control-hover-icon">
                          <a
                            role="button"
                            *ngIf="isSearchActive"
                            (click)="innerTable(tab, innerTab)"
                          >
                            <i class="fas fa-search"></i>
                          </a>
                          <a
                            role="button"
                            *ngIf="!searchBar && !isSearchActive"
                            (click)="filterModals()"
                          >
                            <img src="./assets/img/filter-icon@3x.png" />
                          </a>
                          <a
                            role="button"
                            *ngIf="searchBar && !isSearchActive"
                            (click)="clear()"
                            class="close-icon"
                          >
                            <i class="fas fa-times-circle"></i>
                          </a>
                        </span>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <!-- Qudos searchbar end -->

              <div class="tab-content">
                <!-- QudosFave Featured tab start -->
                <div
                  id="qudosfave-network--featured"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 4, in: innerTab == 4 }"
                >
                  <div class="container">
                    <!-- <div class="col-12 col-xs-6 col-lg-12 col-xl-6">

                                        <div class="user">
                                            <div class="user-info">
                                                <div class="user__img">
                                                    <img src="./assets/img/avatar.jpg" alt="image">
                                                </div>
                                                <div class="user__meta">
                                                    <h3><a >Tyler Guthrie</a></h3>
                                                    <p>Toyota Camry Black</p>
                                                    <span class="visible-mobile">
                                                            <i class="fas fa-heart"></i><span>16</span>
                                                    <i class="fas fa-star"></i><span>250</span>
                                                    </span>
                                                    <span class="hidden-mobile">
                                                            <i class="fas fa-heart"></i><span>111</span>
                                                    <i class="fas fa-star"></i><span>111</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <span class="hidden-mobile">
                                                    <div class="user__actions">
                                                        <a >View Profile</a>
                                                        <a >Message</a>
                                                    </div>
                                                </span>
                                            <span class="visible-mobile">
                                                    <div class="user__mobile__actions">
                                                        <a ><i class="fas fa-user-plus"></i></a>
                                                        <a ><i class="fas fa-share-alt"></i></a>
                                                    </div>
                                                </span>

                                            </div>
                                        </div> -->
                    <div class="col-md-12 text-center">
                      <h3 class="empty-data">No data exists to display.</h3>
                    </div>
                    <!-- <div class="col-xs-12">
                                            <button class="results__more" type="button">Load More</button>
                                        </div> -->
                  </div>
                </div>
                <!-- QudosFave Featured tab end -->

                <!-- QudosFave MyQudos tab start -->
                <div
                  id="qudosfave-network--myqudos"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 1, in: innerTab == 1 }"
                >
                  <div class="container">
                    <div
                      class="col-12 col-xs-6 col-lg-12 col-xl-6"
                      *ngFor="let data of faveDrivers; index as $index"
                    >
                      <div class="user flex-row">
                        <div class="user-info">
                          <div class="user__img">
                            <img
                              src="{{ data?.driver_image }}"
                              alt=""
                              onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                              alt="image"
                            />
                          </div>
                          <div class="user__meta">
                            <h3>
                              <a
                                data-toggle="tooltip"
                                title="{{ data.driver_name }}"
                                routerLink="/userInfo/{{ data.driver_id }}/1"
                                >{{ data.driver_name }}
                              </a>
                            </h3>
                            <p>
                              {{ data.car_make }} {{ data.car_model }}
                              {{ data.car_color }}
                            </p>
                            <span class="visible-mobile">
                              <i class="fas fa-heart"></i>
                              <span> {{ data.fav_count }}</span>
                              <i class="fas fa-star share-icon"></i>
                              <span> {{ data.points }}</span>

                              <p
                                (click)="removeFavouriteDriver(data)"
                                *ngIf="!globals.user.qudos_user_type"
                              >
                                Unlist
                              </p>
                              <p
                                (click)="removePersonFriend(data, 1)"
                                *ngIf="globals.user.qudos_user_type"
                              >
                                Unlist
                              </p>
                            </span>
                            <span class="hidden-mobile">
                              <i class="fas fa-heart"></i>
                              <span>{{ data.fav_count }}</span>
                              <i class="fas fa-star share-icon"></i>
                              <span>{{ data.points }}</span>
                            </span>
                          </div>
                        </div>
                        <span class="hidden-mobile">
                          <div class="user__actions">
                            <a
                              (click)="removeFavouriteDriver(data)"
                              *ngIf="!globals.user.qudos_user_type"
                              >Unlist</a
                            >
                            <a
                              (click)="removePersonFriend(data, 1)"
                              *ngIf="globals.user.qudos_user_type"
                              >Unlist</a
                            >
                            <a class="anchor-pad-0">
                              <span
                                class="span_padding"
                                *ngIf="!data.is_follower"
                                (click)="addFollower(data, 1, $index)"
                                >Follow</span
                              >
                              <span
                                class="span_padding"
                                *ngIf="data.is_follower"
                                (click)="removeFollower(data, 1, $index)"
                                >Unfollow</span
                              >
                            </a>
                            <a (click)="sharePerson(data, 1)">Share</a>
                          </div>
                        </span>
                        <span class="visible-mobile mob-show-usr-action">
                          <div class="user__mobile__actions">
                            <a (click)="sharePerson(data, 1)"
                              ><i class="fas fa-share-alt"></i
                            ></a>
                            <a>
                              <i
                                class="fas fa-user-times"
                                *ngIf="data.is_follower"
                                (click)="removeFollower(data, 1, $index)"
                              ></i>
                              <i
                                class="fas fa-user-check"
                                *ngIf="!data.is_follower"
                                (click)="addFollower(data, 1, $index)"
                              ></i>
                            </a>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-md-12 text-center nothing_post"
                      *ngIf="!totalFaveDrivers && !globals.onLoading"
                    >
                      <img
                        src="./assets/img/Start_Adding-fav.png"
                        class="img-fluid"
                        *ngIf="!emptyResult"
                      />
                      <p class="no_post_text" *ngIf="!emptyResult">
                        Go to explore and Start adding<br />
                        your favorite drivers.
                      </p>
                      <h3 class="empty-data" *ngIf="emptyResult">
                        No data exists to display.
                      </h3>
                    </div>
                    <div
                      class="col-xs-12"
                      *ngIf="
                        faveDrivers.length < totalFaveDrivers &&
                        !globals.onLoading &&
                        totalFaveDrivers > 10
                      "
                    >
                      <button
                        class="results__more"
                        type="button"
                        (click)="loadMore(4)"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>

                <!-- QudosFave MyQudos tab end -->

                <!-- QudosFave Explore tab start -->
                <div
                  id="qudosfave-network--explore"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 2, in: innerTab == 2 }"
                >
                  <div class="container">
                    <div
                      class="col-12 col-xs-6 col-lg-12 col-xl-6"
                      *ngFor="let data of drivers; index as $index"
                    >
                      <div class="user">
                        <div class="user-info">
                          <div class="user__img">
                            <img
                              src="{{ data?.driver_image }}"
                              alt=""
                              onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                              alt="image"
                            />
                          </div>
                          <div class="user__meta">
                            <h3>
                              <a
                                data-toggle="tooltip"
                                title="{{ data.driver_name }}"
                                routerLink="/userInfo/{{ data.driver_id }}/1"
                                >{{ data.driver_name }}</a
                              >
                            </h3>
                            <p>{{ data.car_make }} {{ data.car_model }}</p>
                            <span class="visible-mobile">
                              <i class="fas fa-heart"></i>
                              <span>{{ data.fav_count }}</span>
                              <i class="fas fa-star share-icon"></i>
                              <span>{{ data.points }}</span>
                              <p *ngIf="!globals.user.qudos_user_type">
                                <span
                                  (click)="addFavouriteDriver(data)"
                                  *ngIf="!data.requested"
                                  >Add</span
                                >
                                <span
                                  *ngIf="data.requested"
                                  (click)="addFavouriteDriver(data)"
                                  >Accept</span
                                >
                              </p>
                              <p *ngIf="globals.user.qudos_user_type">
                                <span
                                  *ngIf="
                                    !data.requested && !data.friend_request
                                  "
                                  (click)="sendFriendRequest(data, 1, $index)"
                                  >Add</span
                                >
                                <span
                                  *ngIf="data.friend_request && !data.requested"
                                  (click)="cancelRequest(data, 1, $index)"
                                  >Cancel</span
                                >
                              </p>
                            </span>
                            <span class="hidden-mobile">
                              <i class="fas fa-heart"></i>
                              <span>{{ data.fav_count }}</span>
                              <i class="fas fa-star share-icon"></i>
                              <span>{{ data.points }}</span>
                            </span>
                          </div>
                        </div>
                        <span class="hidden-mobile">
                          <div class="user__actions">
                            <a
                              *ngIf="!globals.user.qudos_user_type"
                              class="anchor-pad-0"
                            >
                              <span
                                (click)="addFavouriteDriver(data)"
                                *ngIf="!data.requested"
                                class="span_padding"
                                >Add</span
                              >
                              <span
                                *ngIf="data.requested"
                                (click)="addFavouriteDriver(data)"
                                class="span_padding"
                                >Accept</span
                              >
                            </a>
                            <a
                              *ngIf="globals.user.qudos_user_type"
                              class="anchor-pad-0"
                            >
                              <span
                                class="span_padding"
                                *ngIf="!data.requested && !data.friend_request"
                                (click)="sendFriendRequest(data, 1, $index)"
                                >Add</span
                              >
                              <span
                                class="span_padding"
                                *ngIf="data.friend_request && !data.requested"
                                (click)="cancelRequest(data, 1, $index)"
                                >Cancel</span
                              >
                            </a>
                            <a class="anchor-pad-0">
                              <span
                                class="span_padding"
                                *ngIf="!data.is_follower"
                                (click)="addFollower(data, 1, $index)"
                                >Follow</span
                              >
                              <span
                                class="span_padding"
                                *ngIf="data.is_follower"
                                (click)="removeFollower(data, 1, $index)"
                                >Unfollow</span
                              >
                            </a>
                            <a (click)="sharePerson(data, 1)">Share</a>
                          </div>
                        </span>
                        <span class="visible-mobile mob-show-usr-action">
                          <div class="user__mobile__actions">
                            <a>
                              <i
                                class="fas fa-user-times"
                                *ngIf="data.is_follower"
                                (click)="removeFollower(data, 1, $index)"
                              ></i>
                              <i
                                class="fas fa-user-check"
                                *ngIf="!data.is_follower"
                                (click)="addFollower(data, 1, $index)"
                              ></i>
                            </a>
                            <a (click)="sharePerson(data, 1)"
                              ><i class="fas fa-share-alt"></i
                            ></a>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-md-12 text-center"
                      *ngIf="!total_drivers && !globals.onLoading"
                    >
                      <h3 class="empty-data" *ngIf="emptyResult">
                        No data exists to display.
                      </h3>
                    </div>
                    <div
                      class="col-xs-12"
                      *ngIf="
                        drivers.length < total_drivers &&
                        !globals.onLoading &&
                        total_drivers > 10
                      "
                    >
                      <button
                        class="results__more"
                        type="button"
                        (click)="loadMore(1)"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
                <!-- QudosFave Explore tab end -->

                <!-- QudosFave Pending tab start -->
                <div
                  id="qudosfave-network--pending"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 3, in: innerTab == 3 }"
                >
                  <div class="container">
                    <div
                      class="col-12 col-xs-6 col-lg-12 col-xl-6"
                      *ngFor="let driver of confirmPeople; index as $index"
                    >
                      <div class="user">
                        <div class="user-info">
                          <div class="user__img">
                            <img
                              src="{{ driver?.driver_image }}"
                              onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                              alt="image"
                            />
                          </div>
                          <div class="user__meta">
                            <h3>
                              <a
                                data-toggle="tooltip"
                                title="{{ driver.driver_name }}"
                                routerLink="/userInfo/{{ driver.driver_id }}/1"
                                >{{ driver.driver_name }}</a
                              >
                            </h3>
                            <p>{{ driver.car_make }} {{ driver.car_model }}</p>
                            <span class="visible-mobile">
                              <i class="fas fa-heart"></i>
                              <span>{{ driver.fav_count }} </span>
                              <i class="fas fa-star share-icon"></i>
                              <span>{{ driver.points }}</span>
                            </span>
                            <span class="hidden-mobile">
                              <i class="fas fa-heart"></i>
                              <span>{{ driver.fav_count }}</span>
                              <i class="fas fa-star share-icon"></i>
                              <span>{{ driver.points }}</span>
                            </span>
                          </div>
                        </div>
                        <span class="hidden-mobile">
                          <div class="user__actions">
                            <a
                              (click)="addFavouriteDriver(driver)"
                              *ngIf="!globals.user.qudos_user_type"
                              >Accept</a
                            >
                            <a
                              (click)="addPerson(driver, 1, $index)"
                              *ngIf="globals.user.qudos_user_type"
                              >Accept</a
                            >
                            <a (click)="rejectPerson(driver, 1, $index)"
                              >Reject</a
                            >
                            <a class="anchor-pad-0">
                              <span
                                class="span_padding"
                                *ngIf="!driver.is_follower"
                                (click)="addFollower(driver, 1, $index)"
                                >Follow</span
                              >
                              <span
                                class="span_padding"
                                *ngIf="driver.is_follower"
                                (click)="removeFollower(driver, 1, $index)"
                                >Unfollow</span
                              >
                            </a>
                          </div>
                        </span>
                        <span class="visible-mobile mob-show-usr-action">
                          <div class="user__mobile__actions">
                            <a (click)="rejectPerson(driver, 1, $index)"
                              ><i class="fas fa-times"></i
                            ></a>
                            <a
                              (click)="addFavouriteDriver(driver)"
                              *ngIf="!globals.user.qudos_user_type"
                              ><i class="fas fa-check"></i
                            ></a>
                            <a
                              (click)="addPerson(driver, 1, $index)"
                              *ngIf="globals.user.qudos_user_type"
                              ><i class="fas fa-check"></i
                            ></a>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-md-12 text-center nothing_post"
                      *ngIf="!total_confirmations && !globals.onLoading"
                    >
                      <img
                        src="./assets/img/No_Pending_yet 11.png"
                        class="img-fluid"
                        *ngIf="!emptyResult"
                      />
                      <p class="no_post_text" *ngIf="!emptyResult">
                        Hmmm,no pending request yet.
                      </p>
                      <h3 class="empty-data" *ngIf="emptyResult">
                        No data exists to display.
                      </h3>
                    </div>
                    <div
                      class="col-xs-12"
                      *ngIf="
                        confirmPeople.length < total_confirmations &&
                        !globals.onLoading &&
                        total_confirmations > 10
                      "
                    >
                      <button
                        class="results__more"
                        type="button"
                        (click)="loadMore(8)"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- People tab start -->
            <div
              id="network--people"
              class="tab-pane"
              [ngClass]="{ active: tab == 2, in: tab == 2 }"
            >
              <!-- Desktop navbar view start -->
              <span class="hidden-mobile">
                <ul class="ml-4 my-4 col nav">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      aria-controls="people-network--explore"
                      [ngClass]="{ active: innerTab == 2 }"
                      [routerLink]="['/network/people/explore']"
                      >Explore</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      aria-controls="people-network--myfriends"
                      [ngClass]="{ active: innerTab == 1 }"
                      [routerLink]="['/network/people/myfriends']"
                      >My Friends</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      aria-controls="people-network--pending"
                      [ngClass]="{ active: innerTab == 3 }"
                      [routerLink]="['/network/people/pending']"
                      >Pending</a
                    >
                  </li>
                  <li class="nav-item flex-grow-1">
                    <a
                      data-toggle="tab"
                      class="nav-link text-right"
                      *ngIf="searchBar"
                      (click)="clear()"
                      [ngClass]="{ active: searchBar }"
                      >Clear All</a
                    >
                  </li>
                </ul>
              </span>
              <!-- Desktop navbar view end -->
              <!-- Mobile navbar view start -->
              <span class="visible-mobile pb-2 pt-3 mr-bpt-0">
                <ul class="col nav text-center justify-content-center px-4">
                  <li class="nav-item">
                    <a
                      class="w-100 h-100 nav-link"
                      data-toggle="tab"
                      aria-controls="people-network--explore"
                      [ngClass]="{ active: innerTab == 2 }"
                      (click)="innerTable(tab, 2)"
                      ><i class="fas fa-compass w-100"></i>Explore</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="w-100 h-100 nav-link"
                      data-toggle="tab"
                      aria-controls="people-network--myfriends"
                      [ngClass]="{ active: innerTab == 1 }"
                      (click)="innerTable(tab, 1)"
                      ><i class="fas fa-users"></i>My Friends</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="w-100 h-100 nav-link"
                      data-toggle="tab"
                      aria-controls="people-network--pending"
                      [ngClass]="{ active: innerTab == 3 }"
                      (click)="innerTable(tab, 3)"
                      ><i class="fas fa-bars"></i>Pending</a
                    >
                  </li>
                </ul>
              </span>
              <!-- Mobile navbar view end -->
              <!-- Qudos searchbar start -->
              <div class="visible-mobile container">
                <div class="row">
                  <div class="col mobpad-0">
                    <section class="qudosfaves__searchbar">
                      <div class="form-group has-search">
                        <span
                          class="fal fa-search form-control-feedback"
                        ></span>
                        <!-- <input type="text" class="form-control" placeholder="Search2" [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="searchString" (keyup.enter)="checkSearchString($event)"> -->
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search"
                          [ngModelOptions]="{ standalone: true }"
                          [(ngModel)]="searchString"
                          (keyup.enter)="checkSearchString($event)"
                        />
                        <span class="form-control-hover-icon">
                          <a
                            role="button"
                            *ngIf="isSearchActive"
                            (click)="innerTable(tab, innerTab)"
                          >
                            <i class="fas fa-search"></i>
                          </a>
                          <a
                            role="button"
                            *ngIf="!searchBar && !isSearchActive"
                            (click)="filterModals()"
                          >
                            <img src="./assets/img/filter-icon@3x.png" />
                          </a>
                          <a
                            role="button"
                            *ngIf="searchBar && !isSearchActive"
                            (click)="clear()"
                            class="close-icon"
                          >
                            <i class="fas fa-times-circle"></i>
                          </a>
                        </span>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <!-- Qudos searchbar end -->
              <div class="tab-content">
                <!-- People/MyFriends tab start -->
                <div
                  id="people-network--myfriends"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 1, in: innerTab == 1 }"
                >
                  <div class="container">
                    <div
                      class="col-12 col-xs-6 col-lg-12 col-xl-6 md-fl"
                      *ngFor="let people of peoples; index as $index"
                    >
                      <div class="user flex-row">
                        <div class="user-info">
                          <div class="user__img">
                            <img
                              src="{{ people?.user_image }}"
                              onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                              alt="image"
                            />
                          </div>
                          <div class="user__meta">
                            <h3>
                              <a
                                data-toggle="tooltip"
                                title="{{ people.user_name }}"
                                routerLink="/userInfo/{{ people.user_id }}/0"
                                >{{ people.user_name }}</a
                              >
                            </h3>
                            <p>{{ people.referral_code }}</p>
                            <span class="visible-mobile">
                              <i class="fas fa-users"></i>
                              <span>{{ people.follower_count }} </span>
                              <i class="fas fa-star share-icon"></i>
                              <span> {{ people.points }} </span>
                              <p>
                                <a (click)="removePersonFriend(people, 0)"
                                  >Unlist</a
                                >
                              </p>
                            </span>
                            <span class="hidden-mobile">
                              <i class="fas fa-users"></i>
                              <span>{{ people.follower_count }}</span>
                              <i class="fas fa-star share-icon"></i>
                              <span>{{ people.points }}</span>
                            </span>
                          </div>
                        </div>
                        <span class="hidden-mobile">
                          <div class="user__actions">
                            <a (click)="removePersonFriend(people, 0)"
                              >Unlist</a
                            >
                            <a class="anchor-pad-0">
                              <span
                                class="span_padding"
                                *ngIf="!people.is_follower"
                                (click)="addFollower(people, 0, $index)"
                                >Follow</span
                              >
                              <span
                                class="span_padding"
                                *ngIf="people.is_follower"
                                (click)="removeFollower(people, 0, $index)"
                                >Unfollow</span
                              >
                            </a>
                            <a (click)="sharePerson(people, 0)">Share</a>
                          </div>
                        </span>
                        <span class="visible-mobile mob-show-usr-action">
                          <div class="user__mobile__actions">
                            <a (click)="sharePerson(people, 0)"
                              ><i class="fas fa-share-alt"></i
                            ></a>
                            <a>
                              <i
                                class="fas fa-user-times"
                                *ngIf="people.is_follower"
                                (click)="removeFollower(people, 0, $index)"
                              ></i>
                              <i
                                class="fas fa-user-check"
                                *ngIf="!people.is_follower"
                                (click)="addFollower(people, 0, $index)"
                              ></i>
                            </a>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-md-12 text-center nothing_post"
                      *ngIf="!total_people && !globals.onLoading"
                    >
                      <img
                        src="./assets/img/start-adding.png"
                        class="start_adding_1 img-fluid"
                        *ngIf="!emptyResult"
                      />
                      <p class="no_post_text" *ngIf="!emptyResult">
                        Start connecting <br />by adding your friends.
                      </p>
                      <h3 class="empty-data" *ngIf="emptyResult">
                        No data exists to display.
                      </h3>
                    </div>
                    <div
                      class="col-xs-12"
                      *ngIf="
                        peoples.length < total_people &&
                        !globals.onLoading &&
                        total_people > 10
                      "
                    >
                      <button
                        class="results__more"
                        type="button"
                        (click)="loadMore(5)"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
                <!-- People/MyFriends tab end -->

                <!-- People/Explore tab start -->
                <div
                  id="people-network--explore"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 2, in: innerTab == 2 }"
                >
                  <div class="container">
                    <div
                      class="col-12 col-xs-6 col-lg-12 col-xl-6"
                      *ngFor="let rider of riders; index as $index"
                    >
                      <div class="user">
                        <div class="user-info">
                          <div class="user__img">
                            <img
                              src="{{ rider?.user_image }}"
                              onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                              alt="image"
                            />
                          </div>
                          <div class="user__meta">
                            <h3>
                              <a
                                data-toggle="tooltip"
                                title="{{ rider.user_name }}"
                                routerLink="/userInfo/{{ rider.user_id }}/0"
                                >{{ rider.user_name }}</a
                              >
                            </h3>
                            <p>{{ rider.city }}</p>
                            <span class="visible-mobile">
                              <i class="fas fa-users"></i>
                              <span>{{ rider.follower_count }} </span>
                              <i class="fas fa-star share-icon"></i>
                              <span>{{ rider.points }} </span>
                              <p>
                                <span
                                  *ngIf="
                                    !rider.requested && !rider.friend_request
                                  "
                                  (click)="sendFriendRequest(rider, 0, $index)"
                                  >Add</span
                                >
                                <span
                                  *ngIf="
                                    rider.friend_request && !rider.requested
                                  "
                                  (click)="cancelRequest(rider, 0, $index)"
                                  >Cancel</span
                                >
                              </p>
                            </span>
                            <span class="hidden-mobile">
                              <i class="fas fa-users"></i>
                              <span>{{ rider.follower_count }}</span>
                              <i class="fas fa-star share-icon"></i>
                              <span>{{ rider.points }}</span>
                            </span>
                          </div>
                        </div>
                        <span class="hidden-mobile">
                          <div class="user__actions">
                            <a class="anchor-pad-0">
                              <span
                                class="span_padding"
                                *ngIf="
                                  !rider.requested && !rider.friend_request
                                "
                                (click)="sendFriendRequest(rider, 0, $index)"
                                >Add</span
                              >
                              <span
                                class="span_padding"
                                *ngIf="rider.friend_request && !rider.requested"
                                (click)="cancelRequest(rider, 0, $index)"
                                >Cancel</span
                              >
                            </a>
                            <a class="anchor-pad-0">
                              <span
                                class="span_padding"
                                *ngIf="!rider.is_follower"
                                (click)="addFollower(rider, 0, $index)"
                                >Follow</span
                              >
                              <span
                                class="span_padding"
                                *ngIf="rider.is_follower"
                                (click)="removeFollower(rider, 0, $index)"
                                >Unfollow</span
                              >
                            </a>
                            <a (click)="sharePerson(rider, 0)">Share</a>
                          </div>
                        </span>
                        <span class="visible-mobile mob-show-usr-action">
                          <div class="user__mobile__actions">
                            <a>
                              <i
                                class="fas fa-user-times"
                                *ngIf="rider.is_follower"
                                (click)="removeFollower(rider, 0, $index)"
                              ></i>
                              <i
                                class="fas fa-user-check"
                                *ngIf="!rider.is_follower"
                                (click)="addFollower(rider, 0, $index)"
                              ></i>
                            </a>
                            <a (click)="sharePerson(rider, 0)"
                              ><i class="fas fa-share-alt"></i
                            ></a>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-md-12 text-center"
                      *ngIf="!total_riders && !globals.onLoading"
                    >
                      <h3 class="empty-data" *ngIf="emptyResult">
                        No data exists to display.
                      </h3>
                    </div>
                    <div
                      class="col-xs-12"
                      *ngIf="
                        riders.length < total_riders &&
                        !globals.onLoading &&
                        total_riders > 10
                      "
                    >
                      <button
                        class="results__more"
                        type="button"
                        (click)="loadMore(2)"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
                <!-- People/Explore tab end -->

                <!-- People/Pending tab start -->
                <div
                  id="people-network--pending"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 3, in: innerTab == 3 }"
                >
                  <div class="container">
                    <div
                      class="col-12 col-xs-6 col-lg-12 col-xl-6"
                      *ngFor="let people of confirmPeople; index as $index"
                    >
                      <div class="user">
                        <div class="user-info">
                          <div class="user__img">
                            <img
                              src="{{ people?.user_image }}"
                              onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                              alt="image"
                            />
                          </div>
                          <div class="user__meta">
                            <h3>
                              <a
                                data-toggle="tooltip"
                                title="{{ people.user_name }}"
                                routerLink="/userInfo/{{ people.user_id }}/0"
                                >{{ people.user_name }}</a
                              >
                            </h3>
                            <p>{{ people.city }}</p>
                            <span class="visible-mobile">
                              <i class="fas fa-users"></i
                              ><span> {{ people.follower_count }} </span>
                              <i class="fas fa-star share-icon"></i
                              ><span> {{ people.points }} </span>
                              <p>
                                <span
                                  *ngIf="!people.is_follower"
                                  (click)="addFollower(people, 0, $index)"
                                  >Follow</span
                                >
                                <span
                                  *ngIf="people.is_follower"
                                  (click)="removeFollower(people, 0, $index)"
                                  >Unfollow</span
                                >
                              </p>
                            </span>
                            <span class="hidden-mobile">
                              <i class="fas fa-users"></i
                              ><span> {{ people.follower_count }}</span>
                              <i class="fas fa-star share-icon"></i
                              ><span> {{ people.points }}</span>
                            </span>
                          </div>
                        </div>
                        <span class="hidden-mobile">
                          <div class="user__actions">
                            <a (click)="addPerson(people, 0, $index)">Accept</a>
                            <a (click)="rejectPerson(people, 0, $index)"
                              >Reject</a
                            >
                            <a class="anchor-pad-0">
                              <span
                                class="span_padding"
                                *ngIf="!people.is_follower"
                                (click)="addFollower(people, 0, $index)"
                                >Follow</span
                              >
                              <span
                                class="span_padding"
                                *ngIf="people.is_follower"
                                (click)="removeFollower(people, 0, $index)"
                                >Unfollow</span
                              >
                            </a>
                          </div>
                        </span>
                        <span class="visible-mobile mob-show-usr-action">
                          <div class="user__mobile__actions">
                            <a
                              ><i
                                class="fas fa-times"
                                (click)="rejectPerson(people, 0, $index)"
                              ></i
                            ></a>
                            <a
                              ><i
                                class="fas fa-check"
                                (click)="addPerson(people, 0, $index)"
                              ></i
                            ></a>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-md-12 text-center nothing_post"
                      *ngIf="!total_confirmations && !globals.onLoading"
                    >
                      <img
                        src="./assets/img/No_Pending_yet 11.png"
                        class="img-fluid"
                        *ngIf="!emptyResult"
                      />
                      <p class="no_post_text" *ngIf="!emptyResult">
                        Hmmm,no pending request yet.
                      </p>
                      <h3 class="empty-data" *ngIf="emptyResult">
                        No data exists to display.
                      </h3>
                    </div>
                    <div
                      class="col-xs-12"
                      *ngIf="
                        confirmPeople.length < total_confirmations &&
                        !globals.onLoading &&
                        total_confirmations > 10
                      "
                    >
                      <button
                        class="results__more"
                        type="button"
                        (click)="loadMore(9)"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
                <!-- People/Pending tab start -->
              </div>
            </div>
            <!-- People tab end -->

            <!-- Groups tab start -->
            <div
              id="network--groups"
              class="tab-pane"
              [ngClass]="{ active: tab == 3, in: tab == 3 }"
            >
              <!-- Desktop navbar view start -->
              <span class="hidden-mobile">
                <ul class="ml-4 my-4 col nav">
                  <li [ngClass]="{ active: innerTab == 2 }">
                    <a
                      data-toggle="tab"
                      aria-controls="groups-network--explore"
                      [ngClass]="{ active: innerTab == 2 }"
                      [routerLink]="['/network/group/explore']"
                      >Explore</a
                    >
                    <!-- (click)="innerTable(tab, 2)" -->
                  </li>
                  <li [ngClass]="{ active: innerTab == 1 }">
                    <a
                      data-toggle="tab"
                      aria-controls="groups-network--mygroups"
                      [ngClass]="{ active: innerTab == 1 }"
                      [routerLink]="['/network/group/mygroups']"
                      >My Groups</a
                    >
                    <!-- (click)="innerTable(tab, 1)" -->

                  </li>
                  <li [ngClass]="{ active: innerTab == 3 }">
                    <a
                      data-toggle="tab"
                      aria-controls="groups-network--create"
                      [ngClass]="{ active: innerTab == 3 }"
                      [routerLink]="['/network/group/creategroups']"
                      >Create Group</a
                    >
                    <!-- (click)="innerTable(tab, 3)" -->

                  </li>
                  <li class="nav-item flex-grow-1">
                    <a
                      data-toggle="tab"
                      class="nav-link text-right"
                      *ngIf="searchBar && !createTab"
                      (click)="clear()"
                      [ngClass]="{ active: searchBar }"
                      >Clear All</a
                    >
                  </li>
                </ul>
              </span>
              <!-- Desktop navbar view end -->
              <!-- Mobile navbar view start -->
              <span class="visible-mobile pb-2 pt-3">
                <ul
                  class="col nav text-center justify-content-center px-4"
                  id="group-6"
                >
                  <li class="nav-item">
                    <a
                      class="w-100 h-100 nav-link"
                      data-toggle="tab"
                      aria-controls="groups-network--explore"
                      [ngClass]="{ active: innerTab == 2 }"
                      (click)="innerTable(tab, 2)"
                      ><i class="fas fa-compass w-100"></i>Explore</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="w-100 h-100 nav-link"
                      data-toggle="tab"
                      aria-controls="groups-network--mygroups"
                      [ngClass]="{ active: innerTab == 1 }"
                      (click)="innerTable(tab, 1)"
                      ><i class="fas fa-users-class"></i>My Groups</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="w-100 h-100 nav-link"
                      data-toggle="tab"
                      aria-controls="groups-network--create"
                      [ngClass]="{ active: innerTab == 3 }"
                      (click)="innerTable(tab, 3)"
                      ><i class="fas fa-plus-circle w-100"></i>Create</a
                    >
                  </li>
                </ul>
              </span>
              <!-- Mobile navbar view end -->
              <!-- Qudos searchbar start -->
              <div class="visible-mobile container">
                <div class="row" id="userSearch">
                  <div class="col mobpad-0">
                    <section class="qudosfaves__searchbar">
                      <div class="form-group has-search">
                        <span
                          class="fal fa-search form-control-feedback"
                        ></span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search"
                          [ngModelOptions]="{ standalone: true }"
                          [(ngModel)]="searchString"
                          (keyup.enter)="checkSearchString($event)"
                        />
                        <span class="form-control-hover-icon">
                          <a
                            role="button"
                            *ngIf="isSearchActive"
                            (click)="innerTable(tab, innerTab)"
                          >
                            <i class="fas fa-search"></i>
                          </a>
                          <a
                            role="button"
                            *ngIf="!searchBar && !isSearchActive"
                            class="search-icon"
                            (click)="filterModals()"
                          >
                            <img src="./assets/img/filter-icon@3x.png" />
                          </a>
                          <a
                            role="button"
                            *ngIf="searchBar && !isSearchActive"
                            (click)="clear()"
                            class="close-icon"
                          >
                            <i class="fas fa-times-circle"></i>
                          </a>
                        </span>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <!-- Qudos searchbar end -->
              <div class="tab-content">
                
                <!-- Groups/MyGroups tab start -->
                <div
                  id="groups-network--mygroups"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 1, in: innerTab == 1 }"
                >
                  <div class="container">
                    <div
                      class="col-12 col-xs-6 col-lg-12 col-xl-6"
                      *ngFor="let data of groups; index as $index"
                    >
                      <div class="user">
                        <div class="user-info">
                          <div class="user__img">
                            <img
                              src="{{ data?.group_image }}"
                              onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                              alt="image"
                            />
                          </div>
                          <div class="user__meta">
                            <h3>
                              <a
                                data-toggle="tooltip"
                                title="{{ data.group_name }}"
                                routerLink="/groupInfo/{{ data.group_id }}"
                                >{{ data.group_name }}</a
                              >
                            </h3>
                            <p>Members ({{ data.total_members }})</p>
                            <span>
                              <span *ngIf="data.group_type">(Private)</span>
                              <span *ngIf="!data.group_type">(Public)</span>
                            </span>
                            <span class="visible-mobile">
                              <p>
                                <a (click)="leaveGroupModal(data)">Unlist</a>
                              </p>
                            </span>
                          </div>
                        </div>
                        <span class="hidden-mobile">
                          <div class="user__actions">
                            <a (click)="leaveGroupModal(data)">Unlist</a>
                            <a class="anchor-pad-0">
                              <span
                                class="span_padding"
                                *ngIf="!data.is_follower"
                                (click)="followGroup(data, $index)"
                                >Follow</span
                              >
                              <span
                                class="span_padding"
                                *ngIf="data.is_follower"
                                (click)="unfollowGroup(data, $index)"
                                >Unfollow</span
                              >
                            </a>
                            <a (click)="shareGroupModal(data)">Share</a>
                          </div>
                        </span>

                        <span class="visible-mobile mob-show-usr-action">
                          <div class="user__mobile__actions">
                            <a (click)="shareGroupModal(data)"
                              ><i class="fas fa-share-alt"></i
                            ></a>
                            <a>
                              <i
                                class="fas fa-user-check"
                                *ngIf="!data.is_follower"
                                (click)="followGroup(data, $index)"
                              ></i>
                              <i
                                class="fas fa-user-times"
                                *ngIf="data.is_follower"
                                (click)="unfollowGroup(data, $index)"
                              ></i>
                            </a>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-md-12 text-center nothing_post"
                      *ngIf="!total_groups && !globals.onLoading"
                    >
                      <img
                        src="assets/img/no_groups_icon.png"
                        class="img-fluid"
                        *ngIf="!emptyResult"
                      />
                      <p class="no_post_text" *ngIf="!emptyResult">
                        Join a group today or simply <br />
                        create one.
                      </p>
                      <h3 class="empty-data" *ngIf="emptyResult">
                        No data exists to display.
                      </h3>
                    </div>
                    <div
                      class="col-xs-12"
                      *ngIf="
                        groups.length < total_groups &&
                        !globals.onLoading &&
                        total_groups > 10
                      "
                    >
                      <button
                        class="results__more"
                        type="button"
                        (click)="loadMore(7)"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Groups/MyGroups tab end -->
                
                <!-- Groups/Explore tab start -->
                <div
                  id="groups-network--explore"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 2, in: innerTab == 2 }"
                >
                  <div class="container">
                    <div
                      class="col-12 col-xs-6 col-lg-12 col-xl-6"
                      *ngFor="let group of groups; index as $index"
                    >
                      <div class="user">
                        <div class="user-info">
                          <div class="user__img">
                            <img
                              src="{{ group?.group_image }}"
                              onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                              alt="image"
                            />
                          </div>
                          <div class="user__meta">
                            <h3>
                              <a
                                data-toggle="tooltip"
                                title="{{ group.group_name }}"
                                routerLink="/groupInfo/{{ group.group_id }}"
                                >{{ group.group_name }}</a
                              >
                            </h3>
                            <p>Members ({{ group.total_members }})</p>
                            <span>
                              <span *ngIf="group.group_type">(Private)</span>
                              <span *ngIf="!group.group_type">(Public)</span>
                            </span>
                          </div>
                        </div>
                        <span class="hidden-mobile">
                          <div class="user__actions">
                            <a class="anchor-pad-0">
                              <span
                                (click)="joinGroupModal(group)"
                                class="span_padding"
                                >Join</span
                              >
                            </a>
                            <a (click)="shareGroupModal(group)">Share</a>
                          </div>
                        </span>
                        <span class="visible-mobile mob-show-usr-action">
                          <div class="user__mobile__actions">
                            <a
                              ><i
                                class="fas fa-user-plus"
                                (click)="joinGroupModal(group)"
                              ></i
                            ></a>
                            <a
                              ><i
                                class="fas fa-share-alt"
                                (click)="shareGroupModal(group)"
                              ></i
                            ></a>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-md-12 text-center"
                      *ngIf="!total_groups && !globals.onLoading"
                    >
                      <h3 class="empty-data" *ngIf="emptyResult">
                        No data exists to display.
                      </h3>
                    </div>
                    <div
                      class="col-xs-12"
                      *ngIf="
                        groups.length < total_groups &&
                        !globals.onLoading &&
                        total_groups > 10
                      "
                    >
                      <button
                        class="results__more"
                        type="button"
                        (click)="loadMore(3)"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Groups/Explore tab end -->

                <!-- Groups/Create tab start -->
                <div
                  id="groups-network--create"
                  class="tab-pane w-100"
                  [ngClass]="{ active: innerTab == 3, in: innerTab == 3 }"
                >
                  <div class="container" id="qn-groups">
                    <div class="page_panel results border-bottom-0 mobpad-0">
                      <section class="network__tabs">
                        <div id="myGroup-networkTabContent">
                          <div id="qn-create-groups">
                            <section
                              class="row qn__create-groups pb-0"
                              id="group-3"
                            >
                              <div class="col-xs-12 qn__create-groups-head">
                                <div class="image-upload">
                                  <label for="file">
                                    <i
                                      class="fas fa-user-circle"
                                      *ngIf="!uploadImage"
                                    ></i>
                                    <img
                                      src=""
                                      id="newGroupImage"
                                      [ngClass]="{
                                        ' new-group-image': uploadImage
                                      }"
                                    />
                                    <span class="upload_img">
                                      <span *ngIf="!uploadImage">Upload</span>
                                      <span *ngIf="uploadImage"
                                        >Uploaded
                                        <img
                                          class="edit-icon"
                                          src="./assets/img/edit.png"
                                      /></span>
                                    </span>
                                  </label>
                                  <input
                                    id="file"
                                    type="file"
                                    name="groupImage"
                                    accept="image/x-png,image/jpeg"
                                    (change)="
                                      fileName($event.target.files, false)
                                    "
                                  />
                                </div>
                              </div>
                              <div class="col-xs-12 qn__create-groups-form">
                                <form class="pagecreate-form">
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="container text-center">
                                        <input
                                          type="text"
                                          class="form-control name"
                                          placeholder="Group Name"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          [(ngModel)]="groupName"
                                          name="group-name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-12 px-0">
                                      <!-- Qudos searchbar start -->
                                      <div class="qudosfaves__searchbar">
                                        <div class="form-group has-search">
                                          <span
                                            class="
                                              fal
                                              fa-search
                                              form-control-feedback
                                            "
                                          ></span>
                                          <input
                                            type="text"
                                            class="form-control pt-1"
                                            name="search"
                                            placeholder="Search name or email"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [(ngModel)]="searchFriend"
                                            (keyup.enter)="
                                              checkSearchString($event)
                                            "
                                          />
                                        </div>
                                      </div>
                                      <!-- Qudos searchbar end -->
                                    </div>
                                    <div class="col-md-12 px-0">
                                      <div
                                        class="
                                          btn-toolbar
                                          justify-content-between
                                        "
                                      >
                                        <a
                                          class="btn qudos-secondary-btn"
                                          (click)="cancelGroupModal(1)"
                                          >Cancel</a
                                        >
                                        <a
                                          class="btn qudos-primary-btn"
                                          id="nextStep"
                                          (click)="createGroupStep()"
                                          >Create Group</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </form>
                                <div class="col-md-12 px-2">
                                  <div
                                    class="
                                      card
                                      border-left-0
                                      border-right-0
                                      border-bottom-0
                                      mx-auto
                                    "
                                  >
                                    <ul
                                      class="list-group list-group-flush mt-3"
                                    >
                                      <li
                                        class="list-group-item"
                                        *ngFor="
                                          let friend of friends_diff;
                                          index as $index
                                        "
                                      >
                                        <span class="list-left">
                                          <img
                                            src="{{ friend?.user_image }}"
                                            alt="user-image"
                                            class="rounded-circle"
                                            width="50px"
                                            height="50px"
                                            onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                          />
                                          <p>{{ friend.user_name }}</p>
                                        </span>
                                        <span class="list-right">
                                          <label class="checkbox">
                                            <input
                                              type="checkbox"
                                              class="friendList"
                                              value="{{ $index }}"
                                              (click)="getCheckedValue()"
                                              name="search"
                                              [ngModelOptions]="{
                                                standalone: true
                                              }"
                                              [(ngModel)]="friend.checked"
                                            />
                                            <span class="default"></span>
                                          </label>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="row">
                                    <div
                                      class="col-xs-12"
                                      *ngIf="
                                        friends.length < total_friends &&
                                        !globals.onLoading &&
                                        total_friends > 10
                                      "
                                    >
                                      <button
                                        class="results__more"
                                        type="button"
                                        (click)="loadMore(6)"
                                      >
                                        Load More
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              class="row qn__create-groups pb-0"
                              id="group-4"
                            >
                              <div class="col-12 qn__create-groups-form">
                                <div class="margin-bottom">
                                  <h3 class="privacy-title">Public</h3>
                                  <div class="radio">
                                    <input
                                      type="radio"
                                      [value]="0"
                                      name="privacy"
                                      checked
                                      [(ngModel)]="group_type"
                                    />
                                  </div>
                                  <p class="privacy-text">
                                    Anyone can find this group, see who's in it
                                    and what they post.
                                  </p>
                                </div>
                                <div class="margin-bottom">
                                  <h3 class="privacy-title">Closed</h3>
                                  <span class="radio">
                                    <input
                                      type="radio"
                                      [value]="1"
                                      name="privacy"
                                      [(ngModel)]="group_type"
                                    />
                                  </span>
                                  <p class="privacy-text">
                                    Anyone can find this group, and see who runs
                                    it . Only Members can see who's in it and
                                    what they post.
                                  </p>
                                </div>
                                <div>
                                  <input
                                    type="button"
                                    value="Next"
                                    class="
                                      person-button
                                      margin-bottom
                                      qudos-gradient-btn
                                      next_btn
                                    "
                                    (click)="createGroupModal()"
                                    id="group-5"
                                  />
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                <!-- Groups/Create tab end -->
              </div>
            </div>
            <!-- Groups tab end -->

            <!-- Page tab start -->
            <div
              id="network--page"
              class="tab-pane w-100"
              [ngClass]="{ active: tab == 4, in: tab == 4 }"
            >
              <span class="hidden-mobile">
                <ul class="ml-4 my-4 col nav">
                  <li [ngClass]="{ active: innerTab == 2 }">
                    <a
                      data-toggle="tab"
                      aria-controls="page-network--Explore"
                      [ngClass]="{ active: innerTab == 2 }"
                      [routerLink] = "['/network/page/explore']" 
                      >Explore</a
                    >
                    <!-- (click)="innerTable(tab, 2)" -->
                  </li>
                  <li [ngClass]="{ active: innerTab == 1 }">
                    <a
                      data-toggle="tab"
                      aria-controls="page-network--mypages"
                      [ngClass]="{ active: innerTab == 1 }"
                      [routerLink] = "['/network/page/mypage']" 
                      >My Page</a
                    >
                    <!-- (click)="innerTable(tab, 1)" -->

                  </li>
                  <li [ngClass]="{ active: innerTab == 3 }">
                    <a
                      data-toggle="tab"
                      aria-controls="page-network--pagecreate"
                      [ngClass]="{ active: innerTab == 3 }"
                      [routerLink] = "['/network/page/createpages']"
                      >Create Pages</a
                    >
                    <!-- (click)="innerTable(tab, 3)" -->

                  </li>
                  <li class="nav-item flex-grow-1">
                    <a
                      data-toggle="tab"
                      class="nav-link text-right"
                      *ngIf="searchBar && !createTab"
                      (click)="clear()"
                      [ngClass]="{ active: searchBar }"
                      >Clear All</a
                    >
                  </li>
                </ul>
              </span>
              <span class="visible-mobile pb-2 pt-3 w-100">
                <ul class="col nav text-center justify-content-center px-4">
                  <li class="nav-item">
                    <a
                      class="w-100 h-100 nav-link"
                      data-toggle="tab"
                      aria-controls="pageexplore"
                      [ngClass]="{ active: innerTab == 2 }"
                      (click)="innerTable(tab, 2)"
                      ><i class="fas fa-compass w-100"></i>Explore</a
                    >
                  </li>

                  <li class="nav-item">
                    <a
                      class="w-100 h-100 nav-link"
                      data-toggle="tab"
                      aria-controls="mypage"
                      [ngClass]="{ active: innerTab == 1 }"
                      (click)="innerTable(tab, 1)"
                      ><i class="fas fa-flag"></i>My Page</a
                    >
                  </li>

                  <li class="nav-item">
                    <a
                      class="w-100 h-100 nav-link"
                      data-toggle="tab"
                      aria-controls="pagecreate"
                      [ngClass]="{ active: innerTab == 3 }"
                      (click)="innerTable(tab, 3)"
                      ><i class="fas fa-plus-circle w-100"></i>Create</a
                    >
                  </li>
                </ul>
              </span>
              <div class="tab-content hidden-mobile">
                <div
                  id="page-network--mypages"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 1, in: innerTab == 1 }"
                >
                  <div class="container">
                    <div
                      class="col-12 col-xs-6 col-lg-12 col-xl-6"
                      *ngFor="let data of pages; index as $index"
                    >
                      <div class="user">
                        <div class="user-info">
                          <div class="user__img">
                            <img
                              onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                              alt="image"
                              src="{{ data?.page_image }}"
                            />
                          </div>
                          <div class="user__meta">
                            <h3>
                              <a
                                data-toggle="tooltip"
                                title="{{ data.page_name }}"
                                routerLink="/pageInfo/{{ data.page_id }}"
                                >{{ data.page_name }}</a
                              >
                            </h3>
                            <p>{{ data.description }}</p>
                            <span>
                              <span *ngIf="data.page_type"> Private</span>
                              <span *ngIf="!data.page_type"> Public</span>
                            </span>
                            <span class="hidden-mobile">
                              <i class="fas fa-users"></i
                              ><span> {{ data.total_followers }}</span>
                              <i class="fas fa-thumbs-up share-icon"></i
                              ><span> {{ data.total_likes }}</span>
                            </span>
                          </div>
                        </div>
                        <span class="hidden-mobile">
                          <div class="user__actions" [hidden]="data.is_admin">
                            <a class="anchor-pad-0">
                              <span
                                *ngIf="!data.is_like"
                                class="span_padding"
                                (click)="likeUnlikePage(data, $index, 1)"
                                >Like</span
                              >
                              <span
                                *ngIf="data.is_like"
                                class="span_padding"
                                (click)="likeUnlikePage(data, $index, 0)"
                                >Unlike</span
                              >
                            </a>
                            <a class="anchor-pad-0">
                              <span
                                *ngIf="!data.is_follower"
                                (click)="followUnfollowPage(data, $index, 1)"
                                class="span_padding"
                                >Follow</span
                              >
                              <span
                                *ngIf="data.is_follower"
                                (click)="followUnfollowPage(data, $index, 0)"
                                class="span_padding"
                                >Unfollow</span
                              >
                            </a>
                            <a (click)="sharePageModal(data)">Share</a>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-md-12 text-center nothing_post"
                      *ngIf="!total_pages && !globals.onLoading"
                    >
                      <img
                        src="./assets/img/PAGE.png"
                        class="start_adding_1 img-fluid"
                        *ngIf="!emptyResult"
                      />
                      <p class="no_post_text" *ngIf="!emptyResult">
                        Create your first page and<br />
                        make lasting impressions.
                      </p>
                      <h3 class="empty-data" *ngIf="emptyResult">
                        No data exists to display.
                      </h3>
                    </div>
                    <div
                      class="col-xs-12"
                      *ngIf="
                        pages.length < total_pages &&
                        !globals.onLoading &&
                        total_pages > 10
                      "
                    >
                      <button
                        class="results__more"
                        type="button"
                        (click)="loadMore(10)"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  id="page-network--Explore"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 2, in: innerTab == 2 }"
                >
                  <div class="container">
                    <div class="col-12 col-xs-12">
                      <div class="my_page Suggested_groups">
                        <div class="row">
                          <div
                            class="col-12 col-xs-6 col-lg-12 col-xl-6"
                            *ngFor="let data of pages; index as $index"
                          >
                            <div class="user">
                              <div class="user-info">
                                <div class="user__img">
                                  <img
                                    onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                    alt="image"
                                    src="{{ data?.page_image }}"
                                  />
                                </div>
                                <div class="user__meta">
                                  <h3>
                                    <a
                                      data-toggle="tooltip"
                                      title="{{ data.page_name }}"
                                      routerLink="/pageInfo/{{ data.page_id }}"
                                      >{{ data.page_name }}</a
                                    >
                                  </h3>
                                  <p>{{ data.description }}</p>
                                  <span>
                                    <span *ngIf="data.page_type"> Private</span>
                                    <span *ngIf="!data.page_type"> Public</span>
                                  </span>
                                  <span class="hidden-mobile">
                                    <i class="fas fa-users"></i
                                    ><span> {{ data.total_followers }}</span>
                                    <i class="fas fa-thumbs-up share-icon"></i
                                    ><span> {{ data.total_likes }}</span>
                                  </span>
                                </div>
                              </div>
                              <span class="hidden-mobile">
                                <div class="user__actions">
                                  <a class="anchor-pad-0">
                                    <span
                                      *ngIf="!data.is_like"
                                      (click)="likeUnlikePage(data, $index, 1)"
                                      class="span_padding"
                                      >Like</span
                                    >
                                    <span
                                      *ngIf="data.is_like"
                                      (click)="likeUnlikePage(data, $index, 0)"
                                      class="span_padding"
                                      >Unlike</span
                                    >
                                  </a>
                                  <a class="anchor-pad-0">
                                    <span
                                      class="span_padding"
                                      *ngIf="!data.is_follower"
                                      (click)="
                                        followUnfollowPage(data, $index, 1)
                                      "
                                      >Follow</span
                                    >
                                    <span
                                      class="span_padding"
                                      *ngIf="data.is_follower"
                                      (click)="
                                        followUnfollowPage(data, $index, 0)
                                      "
                                      >Unfollow</span
                                    >
                                  </a>
                                  <a (click)="sharePageModal(data)">Share</a>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div
                            class="col-md-12 text-center add_frst"
                            *ngIf="!total_pages && !globals.onLoading"
                          >
                            <h3 class="empty-data" *ngIf="emptyResult">
                              No data exists to display.
                            </h3>
                          </div>
                          <div
                            class="col-xs-12"
                            *ngIf="
                              pages.length < total_pages &&
                              !globals.onLoading &&
                              total_pages > 10
                            "
                          >
                            <button
                              class="results__more"
                              type="button"
                              (click)="loadMore(11)"
                            >
                              Load More
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="page-network--pagecreate"
                  class="tab-pane"
                  [ngClass]="{ active: innerTab == 3, in: innerTab == 3 }"
                >
                  <div class="container">
                    <div class="col-12 col-lg-12 col-xl-12">
                      <div id="qn-create-groups">
                        <section class="row qn__create-groups pb-0">
                          <div class="col-xs-12 qn__create-groups-head">
                            <div class="image-upload">
                              <label for="pagefileWeb">
                                <i class="fas fa-user-circle"></i>
                                <span class="uplaod_txt">Upload</span>
                              </label>
                              <input
                                id="pagefileWeb"
                                type="file"
                                accept="image/x-png,image/jpeg"
                                (change)="
                                  fileName($event.target.files, true, '0')
                                "
                              />
                            </div>
                          </div>
                          <div class="col-xs-12 qn__create-groups-form">
                            <form class="pagecreate-form">
                              <div class="form-group">
                                <div class="row">
                                  <div class="container text-center">
                                    <input
                                      type="text"
                                      class="form-control name"
                                      placeholder="Page Name"
                                      [ngModelOptions]="{ standalone: true }"
                                      [(ngModel)]="pageName"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <i class="fas fa-caret-down"></i>
                                <select
                                  class="form-control"
                                  name="page-type"
                                  [(ngModel)]="selectedPageType"
                                >
                                  <option
                                    *ngFor="let selectedItem of pageType"
                                    [ngValue]="selectedItem"
                                  >
                                    {{ selectedItem.name }}
                                  </option>
                                </select>
                              </div>
                              <div class="col-md-12 px-0">
                                <div
                                  class="btn-toolbar justify-content-between"
                                >
                                  <button
                                    class="btn qudos-primary-btn"
                                    (click)="createPage()"
                                    [disabled]="globals.onLoading"
                                  >
                                    Create Page
                                  </button>
                                  <button
                                    class="btn qudos-secondary-btn"
                                    (click)="cancelPageModal(0)"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="visible-mobile container">
                <div class="tab-content tabcontents">
                  <div
                    id="mypage"
                    class="tab-pane"
                    [ngClass]="{ active: innerTab == 1 }"
                  >
                    <div class="visible-mobile">
                      <div class="row">
                        <div class="col">
                          <section class="qudosfaves__searchbar">
                            <div class="form-group has-search">
                              <span
                                class="fal fa-search form-control-feedback"
                              ></span>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Search"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="searchString"
                                name="Search"
                                (keyup.enter)="checkSearchString($event)"
                              />
                              <span class="form-control-hover-icon">
                                <a
                                  role="button"
                                  *ngIf="isSearchActive"
                                  (click)="innerTable(tab, innerTab)"
                                >
                                  <i class="fas fa-search"></i>
                                </a>
                                <a
                                  role="button"
                                  (click)="filterModals()"
                                  *ngIf="!searchBar && !isSearchActive"
                                >
                                  <img src="./assets/img/filter-icon@3x.png" />
                                </a>
                                <a
                                  role="button"
                                  *ngIf="searchBar && !isSearchActive"
                                  (click)="clear()"
                                  class="close-icon"
                                >
                                  <i class="fas fa-times-circle"></i>
                                </a>
                              </span>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col-12 col-xs-12">
                          <div class="my_page">
                            <div class="row">
                              <div
                                class="col-6 col-xs-6"
                                *ngFor="let data of pages"
                              >
                                <div class="pages_card card">
                                  <span class="edit_page dropdown">
                                    <a
                                      data-toggle="dropdown"
                                      class="dropdown-toggle"
                                    ></a>
                                    <i class="far fa-ellipsis-h fa-lg"></i>
                                    <ul class="edit_links dropdown-menu">
                                      <li>
                                        <a (click)="editPageModal(data)"
                                          >Edit</a
                                        >
                                      </li>
                                      <li>
                                        <a (click)="deletePageModal(data, 2)"
                                          >Delete</a
                                        >
                                      </li>
                                      <li>
                                        <a (click)="linkCopy(data.page_id)"
                                          >Copy link</a
                                        >
                                      </li>
                                    </ul>
                                  </span>
                                  <div class="text-center">
                                    <div class="page_profile">
                                      <img
                                        src="{{ data?.page_image }}"
                                        onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                        alt="image"
                                      />
                                    </div>
                                    <h3
                                      routerLink="/pageInfo/{{ data.page_id }}"
                                    >
                                      {{ data.page_name }}
                                    </h3>
                                    <h5>{{ data.description }}</h5>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-12 text-center nothing_post"
                                *ngIf="!total_pages && !globals.onLoading"
                              >
                                <img
                                  src="./assets/img/PAGE.png"
                                  class="img-fluid"
                                />
                                <p class="no_post_text">
                                  Create your first page and<br />
                                  make lasting impressions.
                                </p>
                              </div>
                              <div
                                class="col-xs-12"
                                *ngIf="
                                  pages.length < total_pages &&
                                  !globals.onLoading &&
                                  total_pages > 10
                                "
                              >
                                <button
                                  class="results__more"
                                  type="button"
                                  (click)="loadMore(10)"
                                >
                                  Load More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="pageexplore"
                    class="tab-pane"
                    [ngClass]="{ active: innerTab == 2 }"
                  >
                    <div class="visible-mobile">
                      <div class="row">
                        <div class="col">
                          <section class="qudosfaves__searchbar">
                            <div class="form-group has-search">
                              <span
                                class="fal fa-search form-control-feedback"
                              ></span>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Search"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="searchString"
                                name="Search"
                                (keyup.enter)="checkSearchString($event)"
                              />
                              <span class="form-control-hover-icon">
                                <a
                                  role="button"
                                  *ngIf="isSearchActive"
                                  (click)="innerTable(tab, innerTab)"
                                >
                                  <i class="fas fa-search"></i>
                                </a>
                                <a
                                  role="button"
                                  (click)="filterModals()"
                                  *ngIf="!searchBar && !isSearchActive"
                                >
                                  <img src="./assets/img/filter-icon@3x.png" />
                                </a>
                                <a
                                  role="button"
                                  *ngIf="searchBar && !isSearchActive"
                                  (click)="clear()"
                                  class="close-icon"
                                >
                                  <i class="fas fa-times-circle"></i>
                                </a>
                              </span>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col-12 col-xs-12">
                          <div class="my_page">
                            <div class="row">
                              <div
                                class="col-6 col-xs-6"
                                *ngFor="let data of pages"
                              >
                                <div class="pages_card card">
                                  <div class="text-center">
                                    <div class="page_profile">
                                      <img
                                        src="{{ data?.page_image }}"
                                        onerror="this.src='https://qudos-s3.s3.amazonaws.com/user_profile/user.png';"
                                        alt="image"
                                      />
                                    </div>
                                    <h3
                                      routerLink="/pageInfo/{{ data.page_id }}"
                                    >
                                      {{ data.page_name }}
                                    </h3>
                                    <h5>{{ data.description }}</h5>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-12 text-center nothing_post"
                                *ngIf="!total_pages && !globals.onLoading"
                              >
                                <h3 class="empty-data" *ngIf="emptyResult">
                                  No data exists to display.
                                </h3>
                              </div>
                              <div
                                class="col-xs-12"
                                *ngIf="
                                  pages.length < total_pages &&
                                  !globals.onLoading &&
                                  total_pages > 10
                                "
                              >
                                <button
                                  class="results__more"
                                  type="button"
                                  (click)="loadMore(11)"
                                >
                                  Load More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="pagecreate"
                    class="tab-pane"
                    [ngClass]="{ active: innerTab == 3 }"
                  >
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <div id="qn-create-groups" class="my_page">
                            <section class="row qn__create-groups pb-0">
                              <div class="col-xs-12 qn__create-groups-head">
                                <div class="image-upload">
                                  <label for="pageFileMobile">
                                    <i class="fas fa-user-circle"></i>
                                    <span class="uplaod_txt">Upload</span>
                                  </label>
                                  <input
                                    id="pageFileMobile"
                                    type="file"
                                    accept="image/x-png,image/jpeg"
                                    (change)="
                                      fileName($event.target.files, true, '1')
                                    "
                                  />
                                </div>
                              </div>
                              <div class="col-xs-12 qn__create-groups-form">
                                <form class="pagecreate-form">
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="container text-center">
                                        <input
                                          type="text"
                                          class="form-control name"
                                          placeholder="Page Name"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          [(ngModel)]="pageName"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <i class="fas fa-caret-down"></i>
                                    <select
                                      class="form-control"
                                      name="page-type"
                                      [(ngModel)]="selectedPageType"
                                    >
                                      <option
                                        *ngFor="let selectedItem of pageType"
                                        [ngValue]="selectedItem"
                                      >
                                        {{ selectedItem.name }}
                                      </option>
                                    </select>
                                  </div>
                                  <div class="col-md-12 px-0">
                                    <div
                                      class="
                                        btn-toolbar
                                        justify-content-between
                                      "
                                    >
                                      <button
                                        class="btn qudos-primary-btn"
                                        (click)="createPage()"
                                        [disabled]="globals.onLoading"
                                      >
                                        Create Page
                                      </button>
                                      <button
                                        class="btn qudos-secondary-btn"
                                        (click)="cancelPageModal(0)"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Page tab end -->

          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 col-xl-2 page_sidebar" *ngIf="tab == 1">
      <div class="search-box">
        <div class="search-box__title">Looking for:</div>
        <div class="search-box__group">
          <label for="">Search</label>
          <input
            type="text"
            placeholder="Name, vehicle, etc"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="filter_keyword"
          />
        </div>
        <div class="search-box__group">
          <label for="">Seating Capacity</label>
          <i class="fas fa-caret-down"></i>
          <select [(ngModel)]="filter_seats">
            <option value="undefined" selected>Number of seats</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="6">6</option>
            <option value="8">8</option>
          </select>
        </div>
        <div class="search-box__group">
          <label for="">Country</label>
          <input
            type="text"
            placeholder="Country"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="filter_country"
          />
        </div>
        <div class="search-box__group">
          <label for="">Zip Code</label>
          <input
            type="text"
            placeholder="Enter Zip Code"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="filter_zip"
          />
        </div>
        <div class="search-box__group">
          <button
            class="search-box__btn looking_search"
            type="button"
            (click)="innerTable(tab, innerTab, 1)"
          >
            Search
          </button>
        </div>
      </div>
      <div class="search-box__group__sidebar">
        <div>
          <a routerLink="/terms" class="footer_link__sidebar">Terms</a>
          <a routerLink="/privacy" class="footer_link__sidebar">Privacy</a>
          <a routerLink="/posting" class="footer_link__sidebar"
            >Posting Policy</a
          >
        </div>
        <div>
          <span class="footer_copyright__sidebar"
            >© Qudos Technologies, Inc</span
          >
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 col-xl-2 page_sidebar" *ngIf="tab == 2">
      <div class="search-box">
        <div class="search-box__title">Looking for:</div>
        <div class="search-box__group">
          <label for="">Name </label>
          <input
            type="text"
            placeholder="Name"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="filter_name"
          />
        </div>
        <div class="search-box__group">
          <label for="">Country</label>
          <input
            type="text"
            placeholder="Country"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="filter_country"
          />
        </div>
        <div class="search-box__group">
          <button
            class="search-box__btn looking_search"
            type="button"
            (click)="innerTable(tab, innerTab, 1)"
          >
            Search
          </button>
        </div>
      </div>
      <div class="search-box__group__sidebar">
        <div>
          <a routerLink="/terms" class="footer_link__sidebar">Terms</a>
          <a routerLink="/privacy" class="footer_link__sidebar">Privacy</a>
          <a routerLink="/posting" class="footer_link__sidebar"
            >Posting Policy</a
          >
        </div>
        <div>
          <span class="footer_copyright__sidebar"
            >© Qudos Technologies, Inc</span
          >
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 col-xl-2 page_sidebar" *ngIf="tab == 3">
      <div class="search-box">
        <div class="search-box__title">Looking for:</div>
        <div class="search-box__group">
          <label for="">Group Name</label>
          <input
            type="text"
            placeholder="Name of group"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="filter_group_name"
          />
        </div>
        <div class="search-box__group">
          <button
            class="search-box__btn looking_search"
            type="button"
            (click)="innerTable(tab, innerTab, 1)"
          >
            Search
          </button>
        </div>
      </div>
      <div class="search-box__group__sidebar">
        <div>
          <a routerLink="/terms" class="footer_link__sidebar">Terms</a>
          <a routerLink="/privacy" class="footer_link__sidebar">Privacy</a>
          <a routerLink="/posting" class="footer_link__sidebar"
            >Posting Policy</a
          >
        </div>
        <div>
          <span class="footer_copyright__sidebar"
            >© Qudos Technologies, Inc</span
          >
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 col-xl-2 page_sidebar" *ngIf="tab == 4">
      <div class="search-box">
        <div class="search-box__title">Looking for:</div>
        <div class="search-box__group">
          <label for="">Page Name</label>
          <input
            type="text"
            placeholder="Name of page"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="filter_page_name"
          />
        </div>
        <div class="search-box__group">
          <button
            class="search-box__btn looking_search"
            type="button"
            (click)="innerTable(tab, innerTab, 1)"
          >
            Search
          </button>
        </div>
      </div>
      <div class="search-box__group__sidebar">
        <div>
          <a routerLink="/terms" class="footer_link__sidebar">Terms</a>
          <a routerLink="/privacy" class="footer_link__sidebar">Privacy</a>
          <a routerLink="/posting" class="footer_link__sidebar"
            >Posting Policy</a
          >
        </div>
        <div>
          <span class="footer_copyright__sidebar"
            >© Qudos Technologies, Inc</span
          >
        </div>
      </div>
    </div>
    <!-- <div *ngIf="globals.onLoading" class="whirl"></div> -->
  </div>
</div>

<app-network-template></app-network-template>
