import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { GlobalFunctions } from 'src/app/helpers/service';
import { PageService } from 'src/app/services/ViewServices/pageServices/page.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {
  pageId: number;
  page = {
    page_name: "",
    page_image: "",
    description: "",
    total_followers: 0,
    total_likes: 0,
    page_id: 0
  };
  offset: any;
  toaster: any;

  constructor(public globals: Globals, public serviceFunction: GlobalFunctions, private route: ActivatedRoute, private pageHelper: PageService) {
    this.route.params.subscribe(params => {
      this.pageId = parseInt(params.pageId);
    })
  }

  ngOnInit() {
    this.getPageInfo();
  }

  //Get page details
  public getPageInfo() {
    this.globals.onLoading = true;
    let data = this;
    data.offset = this.offset;
    data.pageId = this.pageId;
    this.pageHelper.fetchPageById(data).subscribe(result => {
      this.globals.onLoading = false;
    }),
    err => {
      this.toaster.error(err, 'ERROR');
      this.globals.onLoading = false;
      }
  }
}
