import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/services/authService/auth-service.service';
import { Globals } from 'src/app/helpers/constant';

@Component({
	selector: 'app-auth-template',
	templateUrl: './auth-template.component.html',
	styleUrls: ['./auth-template.component.css']
})
export class AuthTemplateComponent implements OnInit {

	constructor(public authService: AuthService, public globals: Globals) { }

	ngOnInit() {
	}

	@ViewChild('confirmSignout') confirmSignout: TemplateRef<any>;

}
