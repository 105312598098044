import { Component, OnInit, OnDestroy } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { SocketService } from 'src/app/services/socket/socket.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/services/ViewServices/notificationService/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit, OnDestroy {
  notifyOffset: number;
  read: any[];
  clearCounter;
  notifySound: HTMLMediaElement;
  notificationReadRequest: { qudos_user_type: any; alert_id: any; is_all: any; };

  constructor(public globals: Globals, private socketService: SocketService, private toaster: ToastrService, private notificationHelper: NotificationService, private router: Router) {
    window.onload = function () {
      $('i.notify').addClass("activeMenu")
    };
  }

  ngOnInit() {
    $('i.notify').addClass("activeMenu")
    this.notifyOffset = 0;
    this.read = [];
    this.globals.onLoading = false;

    //load notification socket event
    this.socketService.fetchNotification().subscribe(data => {
      this.globals.onLoading = false;
      if (data.flag && data.data.count >= 0) {
        this.globals.pageLoad = false;
        this.globals.notificationCounter = data.data.count;
        this.globals.totalNotifications = data.data.total_notification_count;
        if (this.globals.notifyOffset > 0) {
          this.globals.notifications = this.globals.notifications.concat(data.data.paginated_notification)
        }
        else if (this.globals.notifyOffset == 0) {
          this.globals.notifications = data.data.paginated_notification;
        }

        for (let i = 0; i < this.globals.notifications.length; i++) {
          this.globals.notifications[i].updated_time = moment(this.globals.notifications[i].created_at).fromNow();
        }
        this.globals.onLoading = false;
      }
      else {
        this.socketService.fetchNotification();
      };
    },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.onLoading = false;
      }
    )
  }

  //Load notifications
  public openNotifications(data, type) {
    for (let i = 0; i < data.length; i++) {
      this.read.push(data[i].alert_id);
      this.globals.notifications[i].is_read = 1;
    }
    this.notificationRead(6, this.read.toString(), type);
  }

  //send ids for notifications read
  public notificationRead(type, data, readType) {
    this.globals.pageLoad = true;
    this.notificationReadRequest = {
      qudos_user_type: this.globals.user.qudos_user_type,
      alert_id: type == 6 ? data : data.alert_id,
      is_all: readType
    }
    this.notificationHelper.readNotification(this.notificationReadRequest).subscribe(
      response => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toaster.error(response.error, 'ERROR');
        }
        else {
          if (readType) {
            this.globals.notificationCounter = 0;
          }
          else {
            if (!data.is_read) {
              this.globals.notificationCounter = this.globals.notificationCounter > 0 ? (this.globals.notificationCounter - 1) : this.globals.notificationCounter;
              data.is_read = 1;
            }
            else {
              this.globals.notificationCounter = this.globals.notificationCounter;
            }
          }

          if (type == 1) {
            this.router.navigate(['/userInfo/' + data.qudos_user_id_from + "/" + data.qudos_user_type_from]);
          }
          else if (type == 2) {
            this.router.navigate(['/postInfo/' + data.post_id]);
          }
          else if (type == 3) {
            this.router.navigate(['/groupInfo/' + data.group_id]);
          }
          else if (type == 4) {
            this.router.navigate(['/friendRequest/' + data.qudos_user_id_from + "/" + data.qudos_user_type_from]);
          }
          else if (type == 5) {
            this.router.navigate(['/pageInfo/' + data.page_id]);
          }
        }
        this.globals.pageLoad = false;
      },
      err => {
        this.toaster.error(err, 'ERROR');
        this.globals.pageLoad = false;
      }
    )
  }

  //Load More Data
  public loadMore() {
    this.globals.notifyOffset += 10;
    this.socketService.fetchNotification();
    this.globals.pageLoad = true;
  }

  ngOnDestroy() {
    $('i.notify').removeClass("activeMenu")
  }
}
