<section class="sign">
    <div class="container">
        <div class="row">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="sign_content">
                    <!--Start Row Sign Up-->
                    <div class="row">
                        <div class="popup-main-content login_content">
                            <form name="signup" class="signup_form" [formGroup]="signupForm">
                                <!--Start Sign up Step 1-->
                                <div class="row row-input">
                                    <div id="main-hidden" class="col-md-6">
                                        <h3>Rider Sign Up</h3>
                                        <ul id="ny" class="signup-step">
                                            <li id="li-step1" class="active">
                                                <a id="1-step"></a>
                                            </li>
                                            <li id="li-step2">
                                                <a id="2-step"></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="main-hidden1" class="col-md-6 login-main-content logn-content">
                                        Qudos platform creates an opportunity to build long-standing relationships only trusted service providers can offer.
                                    </div>
                                </div>
                                <!--End Sign up Step 1-->

                                <!--Start Sign up Step 6-->
                                <div id="signup-6" class="row row-input">
                                    <div class="row row-input">
                                        <div class="col-md-6">
                                            <label>First Name</label>
                                            <input id="firstName" class="user-input" name="fname" type="text" placeholder="Your First Name" [formControl]="firstName">
                                            <div *ngIf="signupGetter.first_name.errors">
                                                <div class="errorMessage" *ngIf="signupGetter.first_name.errors && signupGetter.first_name.touched && !signupGetter.first_name.errors.required">
                                                    First Name can have only alphabets</div>
                                                <div class="errorMessage" *ngIf="signupGetter.first_name.errors.required && signupGetter.first_name.touched">First Name is required</div>
                                            </div>
                                            <div class="margin-bottom"></div>
                                        </div>

                                        <div class="col-md-6">
                                            <label>Last Name</label>
                                            <input id="lastName" class="user-input" name="lname" type="text" placeholder="Your Last Name" [formControl]="lastName">
                                            <div *ngIf="signupGetter.last_name.errors">
                                                <div class="errorMessage" *ngIf="signupGetter.last_name.errors && signupGetter.last_name.touched && !signupGetter.last_name.errors.required">
                                                    Last Name can have only alphabets
                                                </div>
                                                <div class="errorMessage" *ngIf="signupGetter.last_name.errors.required && signupGetter.last_name.touched">
                                                    Last Name is required
                                                </div>
                                            </div>
                                            <div class="margin-bottom"></div>
                                        </div>

                                        <div class="col-md-6">
                                            <label>Mobile Number</label>
                                            <div class="row-input">
                                                <input type="tel" id="phone" ng2TelInput [formControl]="mobileNumber" name="phone" inputmode="numeric" (countryChange)="onCountryChange($event)">
                                                <div *ngIf="signupGetter.user_mobile.errors">
                                                    <div class="errorMessage" *ngIf="signupGetter.user_mobile.errors && signupGetter.user_mobile.touched && !signupGetter.user_mobile.errors.required">
                                                        Mobile Number can have only numbers
                                                    </div>
                                                    <div class="errorMessage" *ngIf="signupGetter.user_mobile.errors.required && signupGetter.user_mobile.touched">
                                                        Mobile Number is required
                                                    </div>
                                                </div>
                                                <div class="margin-bottom"></div>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                        <div class="col-md-6">
                                            <label>Email Address</label>
                                            <div class="row-input">
                                                <img src="./assets/img/mail.png" class="mail_icon ">
                                                <input id="email_Id" class="email-add" name="email" type="email" placeholder="Enter your email" [formControl]="email_Id">
                                                <div *ngIf="signupGetter.user_email.errors">
                                                    <div class="errorMessage" *ngIf="signupGetter.user_email.errors && signupGetter.user_email.touched && !signupGetter.user_email.errors.required">
                                                        Invalid Email address.
                                                    </div>
                                                    <div class="errorMessage" *ngIf="signupGetter.user_email.errors.required && signupGetter.user_email.touched">
                                                        Email Address is required
                                                    </div>
                                                </div>
                                                <div class="margin-bottom"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Country</label>
                                            <i class="fas fa-caret-down"></i>
                                            <select class="user-input" name="country" placeholder="Choose Country" [(ngModel)]="country_id" required (change)="getCountryId(country_id)" [ngModelOptions]="{standalone: true}">
                                                <option *ngFor="let selectedItem of countryList" [value]="selectedItem.country_id">
                                                    {{selectedItem.country_name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label>State</label>
                                            <i class="fas fa-caret-down"></i>
                                            <select class="user-input" name="state" placeholder="Choose State" [(ngModel)]="state_id" required (change)="getStateId(state_id)" [ngModelOptions]="{standalone: true}">
                                                <option *ngFor="let selectedItem of stateList" [value]="selectedItem.state_id">
                                                    {{selectedItem.state_name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label>City</label>
                                            <input id="city" class="user-input" name="city" type="text" placeholder="Enter City" [formControl]="city">
                                            <div *ngIf="signupGetter.city.errors">
                                                <div class="errorMessage" *ngIf="signupGetter.city.errors && signupGetter.city.touched && !signupGetter.city.errors.required">
                                                    Invalid City
                                                </div>
                                                <div class="errorMessage" *ngIf="signupGetter.city.errors.required && signupGetter.city.touched">
                                                    City is required
                                                </div>
                                            </div>
                                            <div class="margin-bottom"></div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Zipcode</label>
                                            <input class="user-input" name="zipcode" type="tel" inputmode="numeric" placeholder="Enter Zipcode" [formControl]="zipcode" required string-to-number pattern="^[0-9]{1,6}">
                                            <div *ngIf="signupGetter.zipcode.errors">
                                                <div class="errorMessage" *ngIf="signupGetter.zipcode.errors && signupGetter.zipcode.touched && !signupGetter.zipcode.errors.required">
                                                    Enter Valid Zipcode
                                                </div>
                                                <div class="errorMessage" *ngIf="signupGetter.zipcode.errors.required && signupGetter.zipcode.touched">
                                                    Zipcode is required
                                                </div>
                                            </div>
                                            <div class="margin-bottom"></div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Referring code</label>
                                            <input id="referral" class="user-input" name="referral" type="text" placeholder="Enter Referring Code (Optional)" [formControl]="referral">
                                        </div>
                                    </div>
                                    <div class="col-md-6 but-next">
                                        <button id="fifth-step" class="submit pointer-cursor" (click)="checkEmail()" [disabled]="globals.onLoading">Next</button>
                                        <p>Already have an account? <a routerLink="/login" routerLinkActive="active"><strong>Login</strong></a></p>
                                    </div>
                                </div>
                            </form>
                            <!--End Sign up Step 6-->

                            <!--Start Sign up Step 7-->
                            <form name="signupStep2" [formGroup]="confirmForm">
                                <div id="signup-7" class="row row-input  hidden">
                                    <div class="col-md-6 top-margin">
                                        <p><strong>Confirm your Email Address</strong></p>
                                        <div class="row row-input">
                                            <label>Enter OTP sent to your Email Address</label>
                                            <input id="otpNumber" type="text" name="verify-phone" class="phone-verify" placeholder="0000" inputmode="numeric" [formControl]="otpNumber">
                                            <div *ngIf="confirmForm.controls.otp.errors">
                                                <div class="errorMessage" *ngIf="confirmForm.controls.otp.errors && confirmForm.controls.otp.touched && !confirmForm.controls.otp.errors.required">
                                                    Invalid OTP
                                                </div>
                                                <div class="errorMessage" *ngIf="confirmForm.controls.otp.errors.required && confirmForm.controls.otp.touched">
                                                    OTP is required
                                                </div>
                                            </div>
                                            <div class="margin-bottom"></div>
                                        </div>
                                        <div class="row row-input">
                                            <label>Password</label>
                                            <input id="password_val" class="password" placeholder="Create new password" name="password" type="password" [formControl]="password">
                                            <div *ngIf="confirmForm.controls.password.errors">
                                                <div class="errorMessage" *ngIf="confirmForm.controls.password.errors && confirmForm.controls.password.touched && !confirmForm.controls.password.errors.required">
                                                    Invalid Password
                                                </div>
                                                <div class="errorMessage" *ngIf="confirmForm.controls.password.errors.required && confirmForm.controls.password.touched">
                                                    Password is required
                                                </div>
                                            </div>
                                            <div class="margin-bottom"></div>
                                        </div>

                                        <div class="row row-input a-color">
                                            <div class="col-xs-5"><button id="resendOtp" (click)="resendOtp()" [disabled]="globals.onLoading" class="pointer-cursor">Resend OTP</button></div>
                                            <div class="col-xs-7"><a (click)="changeEmail()" class="pointer-cursor">Change Email Address</a></div>
                                        </div>
                                        <div class="margin-bottom"></div>

                                        <div class="row row-input top-margin">
                                            <button id="six-step" class="submit pointer-cursor" (click)="createAccount()" [disabled]="globals.onLoading">Create My
                                                Rider Account</button>
                                        </div>
                                        <div class="row row-input top-margin a-color" id="last-login">
                                            <p>Already have an account? <a routerLink="/login" routerLinkActive="active"><strong>Login</strong></a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <!--End Sign up Step 7-->

                                <!-- Start Sign Up Step 8-->
                                <div id="signup-8" class="row row-input  hidden">
                                    <div class="col-md-12">
                                        <p class="small-show"><img src="./assets/img/mail-sucess.png" alt="success">
                                        </p>
                                    </div>
                                    <div class="col-md-6">
                                        <h3>Welcome to Qudos</h3>
                                        <p class="top-margin">We're currently doing beta testing in New York City but we're excited about launching soon. Qudos will reach out to you once we are open in your area.
                                        </p>
                                        <div class="row clear">
                                            <div class="col-md-6">
                                                <a routerLink="/login" routerLinkActive="active" class="submit-veri">Ok, Thanks!</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 small-hide">
                                        <p align="center"><br>
                                            <br><img src="./assets/img/mail-sucess.png" width="200" height="200" alt="sucess">
                                        </p>
                                    </div>
                                </div>
                                <!-- End Sign Up Step 8-->
                            </form>
                        </div>
                    </div>
                    <!--End Row Sign Up-->
                </div>
            </div>
        </div>
    </div>
</section>