import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/helpers/constant';
import { throwError } from 'rxjs';
import { LoginService } from 'src/app/services/ViewServices/loginService/login.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	userType = [
		{ name: 'Rider', value: 0 },
		{ name: 'Driver', value: 1 }
	];
	selectedUser = this.userType[0];
	$: any;
	oldEmailId = '';
	mySubscription: any;
	routeSubscriber: any;
	innerIndex: any;
	sub: any;

	constructor(private builder: FormBuilder, private router: Router, private toaster: ToastrService, public globals: Globals, private loginHelper: LoginService, private route: ActivatedRoute) {
		this.routeSubscriber = this.route.data.subscribe(params => {
			this.innerIndex = params.innerIndex;
		})
		this.sub = this.route.params.subscribe(params => {
			if (Object.keys(params).length)
				this.init(parseInt(params.innerIndex))
		});

		this.globals.is_auth = false;
	}

	ngOnInit() {
		this.init(this.innerIndex);
	}

	ngOnDestroy() {
		this.routeSubscriber.unsubscribe();
		this.sub.unsubscribe();
	}
	//Form controls for the login form,forgot password form
	username = new FormControl('', [
		Validators.required,
		Validators.email,
		Validators.maxLength(128),
		Validators.pattern("[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,}$")
	])

	password = new FormControl('', [
		Validators.required,
		Validators.maxLength(32),
		Validators.pattern(".{6,32}")
	])

	emailId = new FormControl('', [
		Validators.required,
		Validators.email,
		Validators.maxLength(128),
		Validators.pattern("[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,}$")
	])

	new_password = new FormControl('', [
		Validators.required,
		Validators.maxLength(32),
		Validators.pattern(".{6,32}")
	])

	confirm_password = new FormControl('', [
		Validators.required,
		Validators.maxLength(32),
		Validators.pattern(".{6,32}")
	])

	otpNumber = new FormControl('', [
		Validators.required,
		Validators.maxLength(4),
		Validators.pattern("[0-9]{4}")
	])

	loginForm: FormGroup = this.builder.group({
		email: this.username,
		password: this.password,
		user_type: [this.userType[0].value]
	})

	resetForm: FormGroup = this.builder.group({
		qudos_user_type: [this.userType[0].value],
		user_email: this.emailId
	})

	confirmPasswordForm: FormGroup = this.builder.group({
		new_password: this.new_password,
		confirm_password: this.confirm_password,
		otp: this.otpNumber,
		qudos_user_type: [this.userType[0].value]
	})

	public resetPage() {
		this.innerIndex = 2;
		this.emailId.reset();
		window.scrollTo(0, 0);
		this.init(this.innerIndex);
	}

	public changeEmailPage() {
		$("#main-login").addClass("hidden");
		$("#reset1").removeClass("hidden");
		$("#reset2").addClass("hidden");
		$("#reset3").addClass("hidden");
		$("#reset4").addClass("hidden");
		this.otpNumber.reset();
		this.new_password.reset();
		this.confirm_password.reset();
	}

	public loginMainPage() {
		$("#main-login").removeClass("hidden");
		$("#reset1").addClass("hidden");
		$("#reset2").addClass("hidden");
		$("#reset3").addClass("hidden");
		$("#reset4").addClass("hidden");
		$("#reset5").addClass("hidden");
	}
	//Login 
	public login_submit() {
		$('#signIn').css('box-shadow', '0 0 0 0.3rem rgba(58, 63, 68, 0.5)');
		if (this.loginForm.valid) {
			this.globals.onLoading = true;
			this.loginHelper.login(this.loginForm.value).subscribe(
				response => {
					if (typeof (response) == 'string') {
						response = JSON.parse(response);
					}
					if (response.flag == 133) {
						$('#signIn').removeAttr("style");
						let user_data = {};
						if (response.user_details && response.user_details.qudos_user_type == 0) {
							user_data = {
								user_name: response.user_details.user_name,
								user_id: response.user_details.user_id,
								web_access_token: response.user_details.web_access_token,
								user_email: response.user_details.user_email,
								user_mobile: response.user_details.user_mobile,
								user_image: response.user_details.user_image,
								referral_code: response.user_details.referral_code,
								qudos_user_type: response.user_details.qudos_user_type,
								country: response.user_details.country,
								language: response.user_details.language,
								state_name: response.user_details.state_name,
								total_followers: response.user_details.total_followers,
								total_likes: response.user_details.total_likes,
								total_posts: response.user_details.total_posts
							}
						}
						else {
							user_data = {
								user_name: response.user_details.driver_name,
								user_id: response.user_details.driver_id,
								web_access_token: response.user_details.web_access_token,
								user_email: response.user_details.driver_email,
								user_mobile: response.user_details.driver_mobile,
								user_image: response.user_details.driver_image,
								referral_code: response.user_details.referral_code,
								qudos_user_type: response.user_details.qudos_user_type,
								country: response.user_details.country,
								language: response.user_details.language,
								state_name: response.user_details.state_name,
								total_followers: response.user_details.total_followers,
								total_likes: response.user_details.total_likes,
								total_posts: response.user_details.total_posts
							}
						}
						localStorage.setItem('auth', JSON.stringify(user_data));
						this.globals.is_auth = true;
						if (this.globals.postCopylink) {
							location.replace(this.globals.postCopylink);
						}
						else {
							this.router.navigate(['/home']);
							this.globals.user = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));
						}
					}
					else {
						$('#signIn').removeAttr("style");
						this.toaster.error(response.error, 'ERROR');
					}
					this.globals.onLoading = false;
				},
				err => {
					this.toaster.error(err, 'ERROR');
					this.globals.onLoading = false;
				}
			)
		}
		else {
			this.toaster.error('Invalid details', "ERROR");
			this.globals.onLoading = false;
		}
		return false;
	};

	//Check Email ID is present during forgot password 
	public checkEmail() {
		if (!this.resetForm.valid) {
			// this.toaster.error('Invalid details', "ERROR");
			this.globals.onLoading = false;
			return throwError('Invalid details');
		}
		this.globals.onLoading = true;
		this.loginHelper.forgotPassword(this.resetForm.value).subscribe(
			response => {
				if (typeof (response) == 'string') {
					response = JSON.parse(response);
				}
				if (response.flag == 2003) {
					this.toaster.info(response.log, 'INFORMATION');
					this.innerIndex = 3;
					this.init(this.innerIndex);
					// this.oldEmailId = this.emailId;
				} else {
					this.toaster.error(response.error, 'ERROR');
				}
				window.scrollTo(0, 0);
				this.globals.onLoading = false;
			},
			err => {
				this.toaster.error(err, 'ERROR');
				this.globals.onLoading = false;
			}
		)
	};

	//Resend OTP to the email
	public resendOtp() {
		this.globals.onLoading = true;
		this.loginHelper.resendOtp(this.resetForm.value).subscribe(
			response => {
				if (typeof response === "string") {
					response = JSON.parse(response);
				}
				if (response.error) {
					this.toaster.error(response.error, "ERROR");
				} else {
					this.toaster.success(response.log, "SUCCESS")
				}
				this.globals.onLoading = false;
			},
			err => {
				this.toaster.error(err, 'ERROR');
				this.globals.onLoading = false;
			})
	};

	//Enter the new password and confirm password to reset the password
	public forgotPassword() {
		this.globals.onLoading = true;
		// this.confirmPasswordForm.value.user_email = this.oldEmailId.value;
		if (this.confirmPasswordForm.valid) {
			this.loginHelper.resetPassword(this.confirmPasswordForm.value).subscribe(
				response => {
					if (typeof (response) == 'string')
						response = JSON.parse(response);
					if (response.error) {
						this.toaster.error(response.error, 'ERROR');
					} else {
						this.toaster.success(response.log, 'SUCCESS');
						this.innerIndex = 4;
						this.init(this.innerIndex);
					}
					window.scrollTo(0, 0);
					this.globals.onLoading = false;
				},
				err => {
					this.toaster.error(err, 'ERROR');
					this.globals.onLoading = false;
				})
		}
		else {
			this.globals.onLoading = false;
			this.toaster.error('Invalid details', 'ERROR');
		}
	}

	//Back to login page
	public reLogin() {
		this.init(1);
	}

	get getLogin() {
		return this.loginForm.controls;
	}

	init(index) {
		this.innerIndex = index;
		if (index == 1) {
			this.otpNumber.reset();
			this.new_password.reset();
			this.confirm_password.reset();
			this.emailId.reset();
			this.username.reset();
			this.password.reset();
			$("#main-login").removeClass("hidden");
			$("#main-login").addClass("active");
			$("#reset1").removeClass("active");
			$("#reset1").addClass("hidden");
			$("#reset2").addClass("hidden");
			$("#reset3").addClass("hidden");
			$("#reset4").removeClass("active");
			$("#reset4").addClass("hidden");
		} else if (index == 2) {
			$("#main-login").addClass("hidden");
			$("#reset1").removeClass("hidden");
		} else if (index == 3) {
			$("#main-login").addClass("hidden");
			$("#reset1").addClass("hidden");
			$("#reset2").removeClass("hidden");
		} else if (index == 4) {
			$("#main-login").addClass("hidden");
			$("#reset1").addClass("hidden");
			$("#reset2").addClass("hidden");
			$("#reset3").addClass("hidden");
			$("#reset4").removeClass("hidden");
		}
	}
}
