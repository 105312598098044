import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Globals } from 'src/app/helpers/constant';
import { GlobalFunctions } from 'src/app/helpers/service';
import { ToastrService } from 'ngx-toastr';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { EventData } from 'src/app/helpers/events.shared';
import { EmojiService } from 'src/app/services/emojiService/emoji.service';
import { TagService } from 'src/app/services/tagService/tag.service';
import { ProfileService } from 'src/app/services/ViewServices/profileService/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-template',
  templateUrl: './user-template.component.html',
  styleUrls: ['./user-template.component.css']
})
export class UserTemplateComponent implements OnInit {
  editData: any;
  firstname: any;
  lastname: any;
  countryCode_orig: any;
  choosed_avatar: boolean;
  user: any;
  totalFaveUsers: any;
  faveUsers: any;
  viewFaveCountOffset: any;
  countryCode: any;
  myFile: any;
  isRider: any;
  driver: {
    user_name: ""
  };
  friend: {
    user_name: ""
  };
  faveUsersCount: [];
  removeFriend: any;
  removeFaveDriver: any;
  addFriend: any;
  addFaveDriver: any;
  modalOpenSubscriber: any;

  constructor(public globals: Globals, public serviceFunction: GlobalFunctions, private toaster: ToastrService, private builder: FormBuilder, private emojiService: EmojiService, private tagService: TagService, private profileHelper: ProfileService, private router: Router) {
  }

  ngOnInit() {
    this.modalOpenSubscriber = this.serviceFunction.on('modalOpen', (data: any) => {
      window.onscroll = function () { };
      this.modalOpenSubscriber.unsubscribe();
    })
  }

  @ViewChild('viewFaveCount') viewFaveCount: TemplateRef<any>;
  @ViewChild('editProfileModal') editProfileModal: TemplateRef<any>;

  onCountryChange = function ($event) {
    this.countryCode = $event.dialCode;
  }

	public imageName(data) {
		this.myFile = data[0];
		let file_type = (<string>$('#file').val()).slice((<string>$('#file').val()).lastIndexOf('.'))
		if ((file_type.toLowerCase() != ".jpg" && file_type.toLowerCase() != '.jpeg') && file_type.toLowerCase() != ".png") {
			this.toaster.error('Image can be jpg,png only', 'ERROR');
		} else {
			let file_name = ($('#file').val() as string).split('\\')[2];
			document.getElementById("image-name").innerHTML = file_name.slice(0, 20);
			document.getElementById("image-name").style.color = 'black';
		}
	}

	//Show user details page
	public viewUser(id, type) {
		this.serviceFunction.getDismissReason('Close');
		this.router.navigate(['/userInfo/' + id + '/' + type])
	}

	//Form controls validations
	firstName = new FormControl('', [
		Validators.required,
		Validators.pattern('^[a-zA-Z]*$')
	])

	lastName = new FormControl('', [
		Validators.required,
		Validators.pattern('^[a-zA-Z]*$')
	])

	zipCode = new FormControl('', [
		Validators.required,
		Validators.maxLength(6),
	])

	bio = new FormControl('', [
		Validators.required,
	])

	interest = new FormControl('', [
		Validators.required,
	])

	//Form to check validations for editing profile
	editProfileForm = this.builder.group({
		firstName: this.firstName,
		lastName: this.lastName,
		bio: this.bio,
		interest: this.interest,
		zipCode: this.zipCode,
	})

	//Edit profile functionality 
	public editProfile() {
		let check = this.editProfileForm.dirty;
		let checkStatus = this.editProfileForm.valid;
		if (!checkStatus) {
			this.toaster.error("Please fill all the fields", "ERROR");
		}
		else {
			if ((this.firstname.length + this.lastname.length) > 255) {
				this.toaster.error("Username length should not be greater than 255", 'ERROR');
			} else {
				let imageVal = ($('#file').val() as string).split('\\')[2]

				if (imageVal) {
				// Mobile number or flag changed
					check = true;
				}
				if (check && checkStatus) {
					this.globals.onLoading = true;
					let image_flag;
					if (this.myFile) {
						image_flag = 1;
						this.myFile = this.myFile;
					} else {
						image_flag = 0;
						this.myFile = undefined;
					}
					this.countryCode = this.countryCode_orig + '-';
					let number_array = this.editData.user_mobile.split("-");
					this.editData.user_mobile = number_array[number_array.length - 1];
					let params = new FormData();
					params.append('qudos_user_type', this.globals.user.qudos_user_type);
					params.append('access_token', this.globals.user.web_access_token);
					params.append('firstname', this.editData.firstname);
					params.append('lastname', this.editData.lastname);
					params.append('about', this.editData.about);
					params.append('hobby', this.editData.hobby);
					params.append('interest', this.editData.interest);
					params.append("image", this.myFile);
					params.append('image_flag', image_flag);
					params.append('zipcode', this.editData.zipcode);
					params.append('mobile', this.countryCode + this.editData.user_mobile);
					params.append('email', this.editData.user_email);
					this.profileHelper.editProfile(params).subscribe(response => {
						if (response.error) {
							this.toaster.error(response.error, 'ERROR');
							if (this.globals.mobileView) {
								this.serviceFunction.getDismissReason("Close");;
							}
						} else {
							this.toaster.success(response.log, 'SUCCESS');
							this.serviceFunction.getDismissReason("Close");
							if (image_flag) {
								let auth_data = JSON.parse(localStorage.getItem('auth'));
								auth_data.total_posts += 1;
								localStorage.setItem('auth', JSON.stringify(auth_data));

								this.globals.user = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));
							}
						}
					this.globals.onLoading = false;
				},
					err => {
					this.toaster.error(err, "ERROR");
					this.globals.onLoading = false;
					})
				} else {
					this.serviceFunction.getDismissReason("Close");;
				}
			}
		}
	};

  //Function to show the details of the people who made a driver fave
  public countFaves() {
    if (!this.globals.onLoading && (this.totalFaveUsers > this.faveUsers)) {
      this.globals.onLoading = true;
      this.viewFaveCountOffset = this.faveUsers;
      let params = {
        viewFaveCountOffset: this.viewFaveCountOffset,
        faveUsers: this.faveUsers,
        user: this.user,
        option: true
      }
      this.serviceFunction.emit(new EventData("userFaveModal", params));
    }
  }
}
